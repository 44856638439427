var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { ArticleLink } from './ArticleLink.old';
export var SidebarItem = function (_a) {
    var article = _a.article, title = _a.title, currentDepth = _a.currentDepth, _b = _a.maxDepth, maxDepth = _b === void 0 ? 2 : _b, handleOnClick = _a.handleOnClick, category = _a.category, children = _a.children, expanded = _a.expanded, isActiveUrl = _a.isActiveUrl, hidden = _a.hidden, anchor = _a.anchor, url = _a.url;
    var childSidebarItems;
    if (isActiveUrl && children && currentDepth < maxDepth) {
        var newDepth_1 = currentDepth + 1;
        var items = children
            .filter(function (c) { return !c.hidden; })
            .map(function (child) { return (React.createElement(SidebarItem, __assign({}, child, { article: child, key: child.url, currentDepth: newDepth_1, maxDepth: maxDepth, handleOnClick: handleOnClick, url: child.url }))); });
        childSidebarItems = (React.createElement("ul", { className: "sidebar-item-list sidebar-item-list-depth".concat(newDepth_1, " ").concat(isActiveUrl ? 'is-current' : '') }, items));
    }
    if (hidden)
        return null;
    if (category) {
        return React.createElement("li", { className: "sidebar-category" }, title);
    }
    return (React.createElement("li", { className: "sidebar-item sidebar-item-depth".concat(currentDepth, " ").concat(expanded && "expanded-".concat(expanded), " ").concat(isActiveUrl ? 'is-current' : '', " "), onClick: function () { return handleOnClick(url); } },
        React.createElement(ArticleLink, { url: url, external: article.external, forceFullReload: article.forceFullReload, anchor: anchor }, title),
        childSidebarItems));
};
