var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
export var MetricsId;
(function (MetricsId) {
    MetricsId["NavigationCard"] = "navigation-card";
    MetricsId["SectionPipelineLink"] = "section-pipeline-link";
    MetricsId["QuickstartsCard"] = "quickstarts-card";
    MetricsId["AccordionControlButtons"] = "accordion-control-buttons";
    MetricsId["TabPanel"] = "tab-panel";
    MetricsId["GlossaryLearnMoreLink"] = "glossary-learn-more-link";
})(MetricsId || (MetricsId = {}));
export var withMetricsId = function (WrappedComponent, id) { return function (props) {
    return React.createElement(WrappedComponent, __assign({ "data-metrics-id": id }, props));
}; };
