import { Avatar, AvatarBlock, Box, GalleryLayout, useMediaQuery, useTheme } from '@auth0/quantum-product';
import * as React from 'react';
import { assetUrl } from '../../../../util/assetUrl';
import { Entry } from './Entry';
var TITLES = {
    spa: 'Single Page App',
    webapp: 'Regular Web App',
    backend: 'Backend/API',
    native: 'Native/Mobile App',
};
var DESCRIPTIONS = {
    spa: 'Javascript web app that runs in the browser',
    webapp: 'Traditional web app that runs on the server',
    backend: 'An API or service protected by Auth0',
    native: 'Mobile or desktop app that runs natively on a device',
};
export var Category = React.forwardRef(function (props, ref) {
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down(theme.layout.tabletBreakpoint));
    var name = props.name, quickstarts = props.quickstarts;
    if (!(quickstarts === null || quickstarts === void 0 ? void 0 : quickstarts.length))
        return React.createElement(React.Fragment, null);
    return (React.createElement(Box, { component: "section", sx: { mt: 5 }, ref: ref },
        React.createElement(AvatarBlock, { sx: { mb: 4, pt: 3 }, avatar: React.createElement(Avatar, { sx: { padding: 0, borderRadius: '12px' }, size: "large", src: "/docs/img/quickstarts/".concat(name, "-quantum.svg"), type: "logo", variant: "rounded" }), title: TITLES[name], id: name, titleTypographyProps: { variant: 'h2', sx: { fontSize: { xs: 'h4.fontSize', sm: 'h2.fontSize' } } }, descriptionTypographyProps: { variant: 'subtitle1' }, description: DESCRIPTIONS[name] }),
        React.createElement(GalleryLayout, null, quickstarts.map(function (qs) {
            var _a;
            var url = qs.url;
            // Don't use interactive QS if on mobile until we implement the mobile views
            if (!isMobile) {
                var article = (_a = qs.hiddenArticles) === null || _a === void 0 ? void 0 : _a.find(function (article) { return article.interactive; });
                if (article) {
                    url = article.url;
                }
            }
            return (React.createElement(Entry, { key: qs.title, title: qs.title, url: url, logo: assetUrl("/docs/img/platforms/".concat(qs.logo, ".svg")) }));
        }))));
});
