var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ArrowRightIcon, Button, ColumnLayout, styled } from '@auth0/quantum-product';
import * as React from 'react';
import { login, signup } from '../../../action/navigateAuthentication';
import AuthDropdown from '../../AuthDropdown';
import { useFluxible } from '../../hooks/useFluxible';
var Root = styled(ColumnLayout, {
    shouldForwardProp: function (prop) { return !['isAuthenticated', 'placement'].includes(prop); },
})(function (_a) {
    var _b;
    var theme = _a.theme, isAuthenticated = _a.isAuthenticated, placement = _a.placement;
    return (_b = {
            gridAutoColumns: placement === 'drawer' ? 'initial' : 'min-content',
            gridAutoFlow: placement === 'drawer' ? 'row' : 'column',
            alignItems: 'center',
            borderTop: placement === 'drawer' ? "1px solid ".concat(theme.palette.divider) : 0,
            padding: placement === 'drawer' ? theme.spacing(2, 3, 0) : 0
        },
        _b[theme.breakpoints.down('md')] = {
            display: !isAuthenticated && placement === 'header' ? 'none' : 'grid',
            margin: placement === 'drawer' ? theme.spacing(0, -3) : !isAuthenticated ? 0 : theme.spacing(0, 4, 0),
        },
        _b);
});
export var ActionArea = function (_a) {
    var isAuthenticated = _a.isAuthenticated, user = _a.user, placement = _a.placement;
    var executeAction = useFluxible().executeAction;
    var isButtonFullWidth = placement === 'drawer';
    var buttonSize = placement === 'header' ? 'small' : 'large';
    return (React.createElement(Root, { sx: {
            gridTemplateColumns: placement === 'drawer' ? ['1fr 1fr', '1fr 1fr', '1fr 1fr'] : ['none', 'none', 'none'],
        }, gutter: placement === 'drawer' ? 2 : 1, placement: placement, isAuthenticated: isAuthenticated && !!user }, isAuthenticated && user ? (placement === 'header' && React.createElement(AuthDropdown, __assign({}, user.profile))) : (React.createElement(React.Fragment, null,
        React.createElement(Button, { size: buttonSize, href: "https://auth0.com/get-started?place=header&type=button&text=talk%20to%20sales", target: "_blank", variant: "outlined", sx: __assign({ textTransform: 'none' }, (placement === 'drawer' && { order: 2, gridColumn: '1 / 3' })), fullWidth: isButtonFullWidth, endIcon: placement === 'drawer' && React.createElement(ArrowRightIcon, null) }, "Contact sales"),
        React.createElement(Button, { variant: placement === 'drawer' ? 'outlined' : 'link', size: buttonSize, component: "a", sx: __assign({ textTransform: 'none' }, (placement === 'drawer' && { order: 1 })), onClick: function () { return executeAction(login(window.location.pathname)); }, color: "inherit", fullWidth: isButtonFullWidth, id: "login-btn" }, "Log in"),
        React.createElement(Button, { size: buttonSize, color: "primary", variant: "contained", sx: { textTransform: 'none' }, onClick: function () { return executeAction(signup); }, fullWidth: isButtonFullWidth }, "Sign up")))));
};
