var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { styled, Text } from '@auth0/quantum-product';
import * as React from 'react';
import { SidebarItem } from '../Sidebar/SidebarItem';
var SubsectionTitle = styled(Text)(function (_a) {
    var theme = _a.theme;
    return ({
        letterSpacing: 0.25,
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightMedium,
        textTransform: 'uppercase',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(1),
    });
});
export var SidebarSubsection = function (_a) {
    var title = _a.title, items = _a.items, _b = _a.maxDepth, maxDepth = _b === void 0 ? 2 : _b;
    return (React.createElement(React.Fragment, null,
        React.createElement(SubsectionTitle, null, title), items === null || items === void 0 ? void 0 :
        items.map(function (item) { return (React.createElement(SidebarItem, __assign({}, item, { article: item, key: item.url, url: item.url, currentDepth: 0, maxDepth: maxDepth }))); })));
};
