import { connectToStores } from 'fluxible-addons-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';

import { QUANTUM_UI } from '../../../../flags';
import ApplicationStore from '../../../stores/ApplicationStore';
import UserStore from '../../../stores/UserStore';
import ApiSelector from './ApiSelector';
import ClientSelector from './ClientSelector';
import ConfiguredResources from './ConfiguredResources';
import DownloadInstructions from './DownloadInstructions';
import styles from './styles.module.styl';

const buttonStyle = QUANTUM_UI ? 'btn-success-quantum-theme' : 'btn-success';

const DownloadSampleModal = (
  {
    show,
    handleHide,
    showSelector,
    showClient,
    showApi,
    selectedClientName,
    selectedApiName,
    downloadInstructions,
    downloadInProgress,
    downloadSample,
    githubSampleLink,
    trackViewGithubSample,
    isFramedMode,
    userApis,
    userClientsByType,
    userContext,
    selectedApiId,
    selectedClientId,
    dashboardApisUrl,
  },
  context
) => {
  const handleUserResourceChange = (action, selectedId) => {
    context.executeAction(action, { selectedId });
  };

  return (
    <Modal id="qs-sample-modal" bsClass="modal" show={show} onHide={handleHide}>
      <Modal.Header bsClass="modal-header has-border" closeButton>
        <Modal.Title>Download sample</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className={styles.description}>
          You are downloading a working sample configured for &nbsp;
          <ConfiguredResources
            showClient={showClient}
            showApi={showApi}
            selectedClientName={selectedClientName}
            selectedApiName={selectedApiName}
          />
        </p>

        <div className={styles.forms}>
          {showSelector && showClient && (
            <ClientSelector
              handleSelected={handleUserResourceChange}
              userClientsByType={userClientsByType}
              selectedClientId={selectedClientId}
            />
          )}
          {showSelector && showApi && (
            <ApiSelector
              handleSelected={handleUserResourceChange}
              userApis={userApis}
              selectedApiId={selectedApiId}
              createApiUrl={dashboardApisUrl}
            />
          )}
        </div>

        <DownloadInstructions content={downloadInstructions} userContext={userContext} isFramedMode={isFramedMode} />
      </Modal.Body>
      <Modal.Footer>
        <a
          href={githubSampleLink}
          onClick={trackViewGithubSample}
          target="_blank"
          rel="noreferrer noopener"
          className="btn btn-transparent"
        >
          View on Github
        </a>
        <button disabled={downloadInProgress} onClick={() => downloadSample()} className={`${buttonStyle} btn`}>
          {downloadInProgress ? 'Downloading...' : 'Download'}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

DownloadSampleModal.contextTypes = {
  executeAction: PropTypes.func,
};

DownloadSampleModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleHide: PropTypes.func.isRequired,
  showSelector: PropTypes.bool,
  showClient: PropTypes.bool.isRequired,
  showApi: PropTypes.bool.isRequired,
  downloadInstructions: PropTypes.string.isRequired,
  downloadInProgress: PropTypes.bool.isRequired,
  downloadSample: PropTypes.func.isRequired,
  githubSampleLink: PropTypes.string.isRequired,
  trackViewGithubSample: PropTypes.func.isRequired,
  isFramedMode: PropTypes.bool.isRequired,
  dashboardApisUrl: PropTypes.string.isRequired,
  selectedClientName: PropTypes.string,
  selectedApiName: PropTypes.string,
  userApis: PropTypes.array.isRequired,
  userClientsByType: PropTypes.object.isRequired,
  userContext: PropTypes.object.isRequired,
  selectedApiId: PropTypes.string,
  selectedClientId: PropTypes.string,
};

export default connectToStores(DownloadSampleModal, [ApplicationStore, UserStore], (context) => {
  const applicationStore = context.getStore(ApplicationStore);
  const userStore = context.getStore(UserStore);

  return {
    userApis: userStore.getApis(),
    userContext: userStore.getUserContext(),
    selectedClientName: userStore.getSelectedClientName(),
    selectedApiName: userStore.getSelectedApiName(),
    selectedApiId: userStore.getSelectedApiId(),
    selectedClientId: userStore.getSelectedClientId(),
    dashboardApisUrl: applicationStore.getDashboardApisUrl(),
  };
});
