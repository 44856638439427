const anchorHighlighter = (links) => () => {
  const currentScrollHeight = window.pageYOffset;
  const top = $('.docs-content-container').scrollTop() + $('header').outerHeight();

  links.find((link) => {
    const offset = $(`#${link.anchor}`).offset();
    if (offset) {
      if (currentScrollHeight > offset.top - top) {
        if (!$(`.sidebar-item-list a[data-anchor='${link.anchor}']`).hasClass('active')) {
          $(`.sidebar-item-list a:not([data-anchor='${link.anchor}'])`).removeClass('active');
          $(`.sidebar-item-list a[data-anchor='${link.anchor}']`).addClass('active');
        }
        return true;
      }
    }

    return false;
  });
};

const linkFormatter = (childLevelLinks) => (link) => {
  if (link.isActiveUrl && childLevelLinks.length) {
    return {
      ...link,
      children: childLevelLinks.map((child) => ({
        ...child,
        title: child.content,
        url: `#${child.anchor}`,
      })),
    };
  }
  return link;
};

export default {
  anchorHighlighter,
  linkFormatter,
};
