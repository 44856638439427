import React from 'react';
import PropTypes from 'prop-types';
import sendMessageToParentFrame from '../../../util/sendMessageToParentFrame';
import styles from './styles.module.styl';

const DownloadSteps = ({ isFramedMode, steps }) => (
  <ol className={styles.steps}>
    {steps.map((step) => (
      <li className={styles.item} key={step.anchor}>
        <a
          href={`#${step.anchor}`}
          onClick={() => {
            if (isFramedMode) {
              sendMessageToParentFrame({ type: 'anchorScroll', top: $(`#${step.anchor}`).offset().top });
            }
          }}
        >
          {step.content}
        </a>
      </li>
    ))}
  </ol>
);

DownloadSteps.propTypes = {
  isFramedMode: PropTypes.bool.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      anchor: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default DownloadSteps;
