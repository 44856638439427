var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { RowLayout } from '@auth0/quantum-product';
import * as React from 'react';
import { HeaderWrapper } from '../HeaderWrapper';
import { OverviewContainer } from '../OverviewContainer';
export var Article = function (_a) {
    var meta = _a.meta, children = _a.children;
    var classes = (meta === null || meta === void 0 ? void 0 : meta.classes) || '';
    // @TODO - topic pages are probably not used anymore and this can be removed
    var isNotTopicPage = !classes.includes('topic-page');
    return (React.createElement(React.Fragment, null,
        isNotTopicPage && meta && React.createElement(HeaderWrapper, { meta: meta }),
        React.createElement(RowLayout, { gutter: 2 },
            (meta === null || meta === void 0 ? void 0 : meta.overview) && React.createElement(OverviewContainer, __assign({ type: "overview" }, meta.overview.props)),
            (meta === null || meta === void 0 ? void 0 : meta.prerequisites) && React.createElement(OverviewContainer, __assign({ type: "prerequisites" }, meta.prerequisites.props)),
            children)));
};
