import strings from '../../lib/strings';

export default function getQuickstartMetadata(selected = {}) {
  return new Promise((resolve, reject) => {
    const { quickstart, platform, version, article } = selected;

    if (!quickstart) {
      return resolve({
        pageTitle: strings.SITE_TITLE,
      });
    }

    if (!platform) {
      return resolve({
        pageTitle: `Auth0 ${quickstart.title} Quickstarts`,
        pageDescription: `Browse ${quickstart.title.toLowerCase()} quickstarts to learn how to quickly add authentication to your app.`,
      });
    }

    const platformDescription =
      `Learn how to quickly add authentication to your ${platform.title} app. ` +
      'Authenticate with any social or enterprise identity provider.';

    if (!article) {
      return resolve({
        pageTitle: `Auth0 ${platform.title} SDK Quickstarts`,
        pageDescription: platformDescription,
      });
    }

    return resolve({
      pageTitle: `Auth0 ${platform.title} SDK Quickstarts: ${article.title}`,
      pageDescription: article.description || platformDescription,
      pageCanonicalUrl: article.canonical || article.url,
    });
  });
}
