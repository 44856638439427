var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Box, styled } from '@auth0/quantum-product';
import * as React from 'react';
var buttonDiameterMap = {
    small: 85,
    medium: 90,
    large: 108,
};
var GraphButton = styled(Box)(function (_a) {
    var theme = _a.theme, _b = _a.size, size = _b === void 0 ? 'large' : _b;
    return ({
        position: 'absolute',
        opacity: 0.5,
        borderRadius: '50%',
        width: buttonDiameterMap[size],
        height: buttonDiameterMap[size],
        backgroundColor: theme.palette.background.default,
    });
});
export var sectionPipelineButtonClass = 'SectionPipeline-button';
export var SectionPipelineButton = function (props) { return (React.createElement(GraphButton, __assign({ className: sectionPipelineButtonClass }, props))); };
