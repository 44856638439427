import { connectToStores } from 'fluxible-addons-react';
import PropTypes from 'prop-types';
import React from 'react';

import ApplicationStore from '../../stores/ApplicationStore';
import QuickstartStore from '../../stores/QuickstartStore';
import { TutorialNavigator } from '../quickstarts/TutorialNavigator/TutorialNavigator.old';
import { Spinner } from '../Spinner';
import TryBanner from '../TryBanner';

class QuickstartsPage extends React.Component {
  render() {
    const { quickstarts, isAuthenticated, isFramedMode } = this.props;
    if (!quickstarts) {
      return <Spinner />;
    }

    let tryBanner;

    if (!isAuthenticated && !isFramedMode) {
      tryBanner = <TryBanner />;
    }

    return (
      <div className="document docs-quickstart-selector">
        <TutorialNavigator {...this.props} />
        {tryBanner}
      </div>
    );
  }
}

QuickstartsPage.contextTypes = {
  getStore: PropTypes.func,
};

QuickstartsPage.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isFramedMode: PropTypes.bool.isRequired,
  quickstarts: PropTypes.object,
};

export default connectToStores(QuickstartsPage, [ApplicationStore, QuickstartStore], (context) => {
  const appStore = context.getStore(ApplicationStore);
  const quickstartStore = context.getStore(QuickstartStore);
  return {
    quickstarts: quickstartStore.getQuickstarts(),
    quickstart: quickstartStore.getCurrentQuickstart(),
    isFramedMode: appStore.isFramedMode(),
    breadcrumbs: quickstartStore.getBreadcrumbs(),
    theme: appStore.getTheme(),
  };
});
