var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState } from 'react';
// based on: https://usehooks.com/useOnScreen/
export function useOnScreen(element, rootMargin) {
    if (rootMargin === void 0) { rootMargin = '1px'; }
    // State and setter for storing whether element is visible
    var _a = __read(useState(false), 2), isIntersecting = _a[0], setIntersecting = _a[1];
    useEffect(function () {
        var observer = new IntersectionObserver(function (_a) {
            var _b = __read(_a, 1), entry = _b[0];
            // Update our state when observer callback fires
            setIntersecting(entry.isIntersecting);
        }, {
            rootMargin: rootMargin,
        });
        if (element) {
            observer.observe(element);
        }
        return function () {
            if (element) {
                observer.unobserve(element);
            }
        };
    }, [element]);
    return isIntersecting;
}
