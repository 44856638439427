import { styled } from '@auth0/quantum-product';
import { connectToStores } from 'fluxible-addons-react';
import React from 'react';
import { performSelectApi } from '../../../action/performUserResourceActions';
import ApplicationStore from '../../../stores/ApplicationStore';
import UserStore from '../../../stores/UserStore';
import canUseStore from '../../../util/canUseStore';
import { useFluxible } from '../../hooks/useFluxible';
import SelectApi from './SelectApi';
var SelectWrapper = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        marginLeft: theme.spacing(1.5),
        display: 'inline',
    });
});
var SelectLabel = styled('span')(function () { return ({
    fontWeight: 500,
}); });
var ConfiguredWithApi = function (_a) {
    var apis = _a.apis, selectedApiId = _a.selectedApiId;
    var context = useFluxible();
    var handleUserResourceChange = function (action, selectedId) {
        context.executeAction(action, { selectedId: selectedId });
    };
    return (React.createElement("div", null,
        React.createElement(SelectLabel, null, "Configured with"),
        React.createElement(SelectWrapper, null,
            React.createElement(SelectApi, { apis: apis, selectedApiId: selectedApiId, handleApiChange: function (value) { return handleUserResourceChange(performSelectApi, value); } }))));
};
export default connectToStores(ConfiguredWithApi, [ApplicationStore, UserStore], function (context) {
    if (!canUseStore(context))
        return {};
    var userStore = context.getStore(UserStore);
    return {
        isAuthenticated: userStore.isAuthenticated(),
        apis: userStore.getApis(),
        selectedApiId: userStore.getSelectedApiId(),
    };
});
