import { BaseStore } from 'fluxible/addons';
import { get, some } from 'lodash';

import getQuickstartUrl from '../util/getQuickstartUrl';
import normalizeUrl from '../util/normalizeUrl';
import sendMessageToParentFrame from '../util/sendMessageToParentFrame';

class QuickstartStore extends BaseStore {
  constructor(dispatcher) {
    super(dispatcher);
    this.flags = {};
    this.quickstarts = undefined;
    this.currentQuickstart = undefined;
    this.currentPlatform = undefined;
    this.currentVersion = undefined;
    this.currentArticle = undefined;
    this.breadcrumbs = [];
    this.landingPageBreadcrumbs = [];
    this.sidebarBreadcrumbs = [];
    this.sidebarItems = [];
    this.appChoiceView = undefined;
    this.apiChoiceView = undefined;
    this.appExplorerActiveFileName = undefined;
  }

  isFramedMode() {
    return !!this.flags.framed;
  }

  isSingleQuickstartMode() {
    return !!this.flags.singleQuickstart;
  }

  handleModeFlagsLoaded(payload) {
    this.flags = payload.flags;
    this.emitChange();
  }

  getQuickstarts() {
    return this.quickstarts;
  }

  getCurrentQuickstart() {
    return this.currentQuickstart;
  }

  getCurrentPlatform() {
    return this.currentPlatform;
  }

  getCurrentVersion() {
    return this.currentVersion;
  }

  getCurrentArticle() {
    return this.currentArticle;
  }

  getSidebarItems() {
    return this.sidebarItems;
  }

  getBreadcrumbs() {
    return this.breadcrumbs;
  }

  getLandingPageBreadcrumbs() {
    return this.landingPageBreadcrumbs;
  }

  getSidebarBreadcrumbs() {
    return this.sidebarBreadcrumbs;
  }

  getMetadataNextSteps() {
    return this.metadataNextSteps;
  }

  getSidebarArticlesNextSteps() {
    return this.sidebarArticlesNextSteps;
  }

  getQuickstartType() {
    return get(this.currentQuickstart, 'name');
  }

  getAppChoiceView() {
    return this.appChoiceView;
  }

  getApiChoiceView() {
    return this.apiChoiceView;
  }

  getAllBreadcrumbs() {
    const quickstartId = get(this.currentQuickstart, 'name');
    const platformId = get(this.currentPlatform, 'name');
    const articleId = get(this.currentArticle, 'name');
    const isArticleInteractive = get(this.currentArticle, 'interactive');

    // Only add a link to the article if there are multiple articles for the platform,
    // and we aren't running in framed mode (which restricts us to a single article).
    const shouldAddArticleLink =
      this.currentArticle && this.currentPlatform.articles.length > 1 && !this.isFramedMode();

    const breadcrumbsItemsData = [
      {
        addIf: shouldAddArticleLink,
        title: 'Docs',
        url: '/docs',
      },
      {
        // Add a link to the quickstarts page as long as we aren't restricted to a single app type.
        addIf: !this.isSingleQuickstartMode(),
        title: 'Quickstarts',
      },
      {
        addIf: !!this.currentQuickstart,
        title: get(this.currentQuickstart, 'title'),
        urlData: { quickstartId },
      },
      {
        addIf: !!this.currentPlatform,
        title: get(this.currentPlatform, 'title'),
        // Appending /interactive to all the quickstart breadcrumb platform link to distinguish interactive from non-interactive articles.
        urlData: { quickstartId, platformId: isArticleInteractive ? platformId + '/interactive' : platformId },
        includeInSidebar: true,
      },
      {
        addIf: false,
        title: get(this.currentArticle, 'title'),
        urlData: { quickstartId, platformId, articleId },
        includeInSidebar: true,
      },
    ];

    const breadcrumbs = breadcrumbsItemsData
      .filter((item) => item.addIf)
      .map((item) => ({
        title: item.title,
        url: item.url || getQuickstartUrl(item.urlData, this.isFramedMode(), this.isSingleQuickstartMode()),
        includeInSidebar: item.includeInSidebar,
      }));

    return {
      breadcrumbs,
      sidebarBreadcrumbs: breadcrumbs.filter((b) => b.includeInSidebar),
      landingPageBreadcrumbs: [
        { title: 'Docs', url: '/docs' },
        { title: 'Quickstarts', url: '/docs/quickstarts' },
      ],
    };
  }

  getProcessedSidebarItems(url) {
    const quickstart = this.currentVersion || this.currentPlatform;
    const articles = quickstart ? quickstart.articles : [];

    if (!articles.length) return articles;

    articles.forEach((article) => {
      /* eslint-disable no-param-reassign */
      if (normalizeUrl(article.url) === normalizeUrl(url)) {
        article.isActiveUrl = true;
      } else {
        article.isActiveUrl = false;
      }

      return article;
    });

    // If no url matches, it is the index(first)
    if (!some(articles, 'isActiveUrl')) {
      articles[0].isActiveUrl = true;
    }

    return articles;
  }

  getAppExplorerActiveFileName() {
    return this.appExplorerActiveFileName;
  }

  handleQuickstartSelected({ quickstart, platform, article, version, url, isFramedMode }) {
    this.currentQuickstart = quickstart;
    this.currentPlatform = platform;
    this.currentArticle = article;
    this.currentVersion = version;
    this.sidebarItems = this.getProcessedSidebarItems(url);

    const allBreadcrumbs = this.getAllBreadcrumbs();
    this.breadcrumbs = allBreadcrumbs.breadcrumbs;
    this.sidebarBreadcrumbs = allBreadcrumbs.sidebarBreadcrumbs;
    this.appExplorerActiveFile = undefined;

    if (platform) {
      const parent = version || platform;
      const showSidebarArticlesNextSteps = isFramedMode && parent.articles.length > 1;
      this.sidebarArticlesNextSteps =
        showSidebarArticlesNextSteps &&
        parent.articles
          // Don't show the current article in the next steps list
          .filter((art) => art.name !== article.name)
          .map((art) => ({
            text: art.title,
            icon: art.budicon,
            href: getQuickstartUrl(
              {
                quickstartId: quickstart.name,
                platformId: platform.name,
                versionId: version && version.name,
                articleId: art.name,
              },
              isFramedMode,
              this.isSingleQuickstartMode()
            ),
          }));
      this.metadataNextSteps = article.nextSteps.length > 0 && article.nextSteps;
      this.emitChange();
    }

    if (isFramedMode) {
      sendMessageToParentFrame({
        type: 'tutorialSelected',
        tutorial: {
          quickstart,
          platform,
          article,
          version,
          url,
        },
      });
    }
    this.emitChange();
  }

  handleQuickstartsLoaded(payload) {
    this.quickstarts = payload.quickstarts;
    const allBreadcrumbs = this.getAllBreadcrumbs();
    this.landingPageBreadcrumbs = allBreadcrumbs.landingPageBreadcrumbs;
    this.emitChange();
  }

  handleAppChoiceViewChanged(payload) {
    this.appChoiceView = payload.view;
    this.emitChange();
  }

  handleApiChoiceViewChanged(payload) {
    this.apiChoiceView = payload.view;
    this.emitChange();
  }

  handleQuickstartAppExplorerActiveFileChanged(payload) {
    this.appExplorerActiveFileName = payload.file.filename;
    this.emitChange();
  }

  dehydrate() {
    return {
      flags: this.flags,
      quickstarts: this.quickstarts,
      currentQuickstart: this.currentQuickstart,
      currentPlatform: this.currentPlatform,
      currentVersion: this.currentVersion,
      currentArticle: this.currentArticle,
      sidebarItems: this.sidebarItems,
      breadcrumbs: this.breadcrumbs,
      sidebarBreadcrumbs: this.sidebarBreadcrumbs,
      landingPageBreadcrumbs: this.landingPageBreadcrumbs,
      metadataNextSteps: this.metadataNextSteps,
      sidebarArticlesNextSteps: this.sidebarArticlesNextSteps,
      appChoiceView: this.appChoiceView,
      apiChoiceView: this.apiChoiceView,
      appExplorerActiveFile: this.appExplorerActiveFile,
    };
  }

  rehydrate(state) {
    this.flags = state.flags;
    this.quickstarts = state.quickstarts;
    this.currentQuickstart = state.currentQuickstart;
    this.currentPlatform = state.currentPlatform;
    this.currentVersion = state.currentVersion;
    this.currentArticle = state.currentArticle;
    this.sidebarItems = state.sidebarItems;
    this.breadcrumbs = state.breadcrumbs;
    this.sidebarBreadcrumbs = state.sidebarBreadcrumbs;
    this.landingPageBreadcrumbs = state.landingPageBreadcrumbs;
    this.metadataNextSteps = state.metadataNextSteps;
    this.sidebarArticlesNextSteps = state.sidebarArticlesNextSteps;
    this.appChoiceView = state.appChoiceView;
    this.apiChoiceView = state.apiChoiceView;
    this.appExplorerActiveFile = state.appExplorerActiveFile;
  }
}

QuickstartStore.storeName = 'QuickstartStore';
QuickstartStore.handlers = {
  QUICKSTART_SELECTED: 'handleQuickstartSelected',
  QUICKSTARTS_LOAD_SUCCESS: 'handleQuickstartsLoaded',
  MODE_FLAGS_LOADED: 'handleModeFlagsLoaded',
  APP_CHOICE_VIEW_CHANGED: 'handleAppChoiceViewChanged',
  API_CHOICE_VIEW_CHANGED: 'handleApiChoiceViewChanged',
  QUICKSTART_APP_EXPLORER_ACTIVE_FILE_CHANGED: 'handleQuickstartAppExplorerActiveFileChanged',
};

export default QuickstartStore;
