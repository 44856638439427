import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Sidebar } from '../../Sidebar/Sidebar.old';
import { anchorHighlighter, linkFormatter } from './utils';

const QuickstartSidebar = ({ childLevelLinks, topLevelLinks, sidebarBreadcrumbs, url }) => {
  const [sidebarItems, setSidebarItems] = useState(null);

  useEffect(() => {
    setSidebarItems(topLevelLinks.map(linkFormatter(childLevelLinks)));

    const childLinks = [...childLevelLinks].reverse();
    const onScroll = anchorHighlighter(childLinks);

    window.addEventListener('scroll', onScroll, false);
    return () => window.removeEventListener('scroll', onScroll, false);
  }, [childLevelLinks]);

  return (
    <Sidebar
      section="Tutorials"
      items={sidebarItems || topLevelLinks}
      breadcrumbs={sidebarBreadcrumbs}
      url={url}
      isQuickstart
    />
  );
};

QuickstartSidebar.defaultProps = {
  childLevelLinks: [],
  topLevelLinks: [],
  sidebarBreadcrumbs: [],
};

QuickstartSidebar.propTypes = {
  childLevelLinks: PropTypes.array,
  topLevelLinks: PropTypes.array,
  sidebarBreadcrumbs: PropTypes.array,
  url: PropTypes.string.isRequired,
};

export default QuickstartSidebar;
