var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ArrowRightIcon, ChevronDownIcon, ChevronRightIcon, SidebarLink, styled } from '@auth0/quantum-product';
import * as React from 'react';
import { useFluxible } from '../hooks/useFluxible';
import { ArticleLink } from './ArticleLink';
var StyledSidebarLink = styled(SidebarLink)(function (_a) {
    var theme = _a.theme;
    return ({
        '& .QuantumSidebarLink-link': {
            paddingLeft: 0,
        },
        '& .QuantumSidebarLink-startIcon': {
            width: 0,
        },
        '& .QuantumSidebarLink-endIcon': {
            marginRight: theme.spacing(1),
        },
        '& .QuantumSidebarLink-title': {
            marginLeft: 0,
            whiteSpace: 'normal',
        },
    });
});
export var SidebarItem = function (_a) {
    var article = _a.article, title = _a.title, currentDepth = _a.currentDepth, _b = _a.maxDepth, maxDepth = _b === void 0 ? 2 : _b, children = _a.children, isActiveUrl = _a.isActiveUrl, isCurrentUrl = _a.isCurrentUrl, hidden = _a.hidden, anchor = _a.anchor, url = _a.url, props = __rest(_a, ["article", "title", "currentDepth", "maxDepth", "children", "isActiveUrl", "isCurrentUrl", "hidden", "anchor", "url"]);
    var context = useFluxible();
    if (hidden)
        return null;
    var childrenToRender = children === null || children === void 0 ? void 0 : children.filter(function (c) { return !c.hidden; });
    var hasChildrenToRender = Array.isArray(childrenToRender) && childrenToRender.length > 0;
    var shouldRenderChildren = hasChildrenToRender && isActiveUrl && currentDepth < maxDepth;
    var handleClick = function () {
        context.trackEvent('select:user_resource', { trackData: url });
    };
    var renderChildren = function () {
        return childrenToRender === null || childrenToRender === void 0 ? void 0 : childrenToRender.map(function (child) { return (React.createElement(SidebarItem, __assign({}, child, { article: child, key: child.url, url: child.url, maxDepth: maxDepth, currentDepth: currentDepth + 1 }))); });
    };
    var renderEndIcon = function () {
        if (article.external) {
            return React.createElement(ArrowRightIcon, null);
        }
        if (hasChildrenToRender) {
            if (shouldRenderChildren) {
                return React.createElement(ChevronDownIcon, null);
            }
            return React.createElement(ChevronRightIcon, null);
        }
    };
    var articleLinkProps = __assign(__assign(__assign({}, props), { url: url, anchor: anchor, depth: currentDepth, external: article.external, forceFullReload: article.forceFullReload, isActiveUrl: isActiveUrl, isCurrentUrl: isCurrentUrl, onClick: handleClick }), (article.forceFullReload && { classes: { endIcon: 'externalLink' } }));
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledSidebarLink, __assign({ title: title, variant: currentDepth ? 'secondary' : 'primary', endIcon: renderEndIcon(), component: ArticleLink }, articleLinkProps)),
        shouldRenderChildren && renderChildren()));
};
