var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { fade, styled } from '@auth0/quantum-product';
import { NavLink } from '../NavLink';
import { MetricsId, withMetricsId } from '../WithMetricsId';
import { sectionPipelineButtonClass } from './SectionPipelineButton';
var StyledNavLink = styled(NavLink)(function (_a) {
    var _b, _c;
    var theme = _a.theme, name = _a.name, active = _a.active;
    return (__assign(__assign({}, ((active === name || active === 'All') && (_b = {},
        _b["& .".concat(sectionPipelineButtonClass)] = {
            opacity: 0,
        },
        _b))), (_c = {}, _c["&:hover .".concat(sectionPipelineButtonClass)] = {
        cursor: 'pointer',
        opacity: 1,
        backgroundColor: 'transparent',
        boxShadow: "0px 4px 8px ".concat(fade(theme.palette.primary.main, 0.25)),
    }, _c)));
});
export var SectionPipelineLink = withMetricsId(StyledNavLink, MetricsId.SectionPipelineLink);
