import { NavLink } from 'fluxible-router';
import * as React from 'react';
import parse from 'url-parse';
export var ArticleLink = function (_a) {
    var children = _a.children, external = _a.external, forceFullReload = _a.forceFullReload, anchor = _a.anchor, url = _a.url;
    var href = url && !external ? parse(url).pathname : url;
    return (React.createElement(NavLink, { className: external ? 'arrow-item' : '', href: anchor ? "#".concat(anchor) : href, followLink: forceFullReload },
        children,
        external && React.createElement("i", { className: "icon-budicon-519" })));
};
