import { defaultTheme as theme } from '@auth0/quantum-product';
export var getTypography = function () {
    return {
        h1: {
            fontSize: theme.typography.pxToRem(40),
            lineHeight: theme.typography.pxToRem(44),
            letterSpacing: -0.8,
        },
        h2: {
            fontSize: theme.typography.pxToRem(32),
            lineHeight: theme.typography.pxToRem(40),
            letterSpacing: -0.5,
        },
        h3: {
            fontSize: theme.typography.pxToRem(28),
            lineHeight: theme.typography.pxToRem(36),
            letterSpacing: -0.3,
        },
        h4: {
            fontSize: theme.typography.pxToRem(24),
            lineHeight: theme.typography.pxToRem(32),
            letterSpacing: -0.2,
        },
        h5: {
            fontSize: theme.typography.pxToRem(20),
            lineHeight: theme.typography.pxToRem(28),
            letterSpacing: -0.1,
        },
        h6: {
            fontSize: theme.typography.pxToRem(18),
            lineHeight: theme.typography.pxToRem(24),
            letterSpacing: -0.1,
        },
        code: {
            fontFamily: 'Roboto Mono,Menlo,Monaco,Consolas,Courier New,monospace',
            '-webkit-font-smoothing': 'subpixel-antialiased',
        },
    };
};
