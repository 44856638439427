import * as React from 'react';

const Clock15Minutes = () => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
      <g fill="#5C666F" fillRule="evenodd">
        <path d="M6.5 13C2.91 13 0 10.09 0 6.5S2.91 0 6.5 0 13 2.91 13 6.5 10.09 13 6.5 13zM6.5.867C3.39.867.867 3.39.867 6.5S3.39 12.132 6.5 12.132c3.11 0 5.633-2.522 5.633-5.634C12.133 3.39 9.61.866 6.5.866z" />
        <path d="M6.5 6.608V0s3.03.092 4.773 2.15C13.016 4.21 13 6.5 13 6.5l-6.5.108z" />
      </g>
    </svg>
  );
};

export default Clock15Minutes;
