import PropTypes from 'prop-types';
import React from 'react';
import Gravatar from 'react-gravatar';

import { QUANTUM_UI } from '../../../../flags';
import CommunityAuthor from './CommunityAuthor';
import styles from './styles.module.styl';

const CompanyIcon = () => (
  <img
    className={styles.auth0Logo}
    src="https://cdn.auth0.com/styleguide/components/1.0.8/media/logos/img/badge.svg"
    alt=""
  />
);

const PlatformInformation = ({ author, githubData }) => {
  if (author.community) {
    const { org, repo } = githubData;
    return <CommunityAuthor org={org} repo={repo} />;
  }

  return (
    <div className={styles.author}>
      <Gravatar email={author.email} default="mm" className={styles.avatar} />
      <div>
        <h5 className={styles.authorName}>
          By {author.name} {!QUANTUM_UI && <CompanyIcon />}
        </h5>
      </div>
    </div>
  );
};

PlatformInformation.defaultProps = {
  author: {
    community: false,
  },
  githubData: {},
};

PlatformInformation.propTypes = {
  author: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    community: PropTypes.bool.isRequired,
  }),
  githubData: PropTypes.shape({
    org: PropTypes.string,
    repo: PropTypes.string,
  }),
};

export default PlatformInformation;
