var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Button, FieldSet, Form, FormActions, SelectField, styled } from '@auth0/quantum-product';
import * as React from 'react';
import { useState } from 'react';
var FormContainer = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        margin: theme.spacing(5),
    });
});
export var SelectResource = function (_a) {
    var _b;
    var label = _a.label, helperText = _a.helperText, resources = _a.resources, selectedId = _a.selectedId, renderOptions = _a.renderOptions, onSave = _a.onSave, onCancel = _a.onCancel;
    var _c = __read(useState({ selectedId: selectedId }), 2), state = _c[0], setState = _c[1];
    var handleChange = function (selectedId) {
        setState(__assign(__assign({}, state), { selectedId: selectedId }));
    };
    return (React.createElement(FormContainer, null,
        React.createElement(Form, { fullWidth: true, layout: "inline" },
            React.createElement(FieldSet, null,
                React.createElement(SelectField, { label: label, helperText: helperText, onChange: function (event) { return handleChange(event.target.value); }, value: (_b = resources.find(function (resource) { return resource.id === state.selectedId; })) === null || _b === void 0 ? void 0 : _b.id, fullWidth: true }, renderOptions)),
            React.createElement(FormActions, null,
                React.createElement(Button, { color: "primary", variant: "contained", onClick: function () { return onSave(state.selectedId); } }, "Save"),
                React.createElement(Button, { variant: "outlined", onClick: function () { return onCancel(); } }, "Cancel")))));
};
