var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { styled } from '@auth0/quantum-product';
import * as React from 'react';
import { ColumnStack } from '../../../../layouts';
import { NavLink } from '../../../../NavLink';
var ALPHABET = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
];
var alphabetHalfLength = Math.floor(ALPHABET.length / 2);
var LetterAnchor = styled(NavLink)(function (_a) {
    var theme = _a.theme, disabled = _a.disabled;
    return (__assign({ textAlign: 'center' }, (disabled && {
        color: theme.palette.grey[400],
        pointerEvents: 'none',
        '&:hover': {
            transform: 'none',
        },
    })));
});
var LettersBarContainer = styled(ColumnStack)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            gridAutoFlow: 'row'
        },
        _b[theme.breakpoints.up('xs')] = {
            gridTemplateColumns: 'repeat(auto-fit, minmax(0, 1fr))',
        },
        _b[theme.breakpoints.down('md')] = {
            gridTemplateColumns: "repeat(".concat(alphabetHalfLength, ", minmax(0, 1fr))"),
        },
        _b);
});
export var LettersBar = function (_a) {
    var letters = _a.letters;
    return (React.createElement(LettersBarContainer, { sx: { gridColumnGap: 1, gridRowGap: 0.5 } }, ALPHABET.map(function (letter) { return (React.createElement(LetterAnchor, { followLink: true, variant: "h5", key: letter, href: "#".concat(letter), disabled: !letters.includes(letter) }, letter)); })));
};
