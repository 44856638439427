var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { RowLayout, Table, TableBody, Text } from '@auth0/quantum-product';
import React from 'react';
import { Entry } from '../Entry';
var TITLES = {
    spa: 'Single-Page Application (SPA) SDK Libraries',
    webapp: 'Regular Web Application SDK Libraries',
    backend: 'Backend Service and API SDK Libraries',
    native: 'Native and Mobile Application SDK Libraries',
    mgmt: 'Management API SDK Libraries',
    lock: 'Lock SDK Libraries',
};
var DESCRIPTIONS = {
    spa: 'Need to protect a JavaScript application that runs entirely in a browser? Choose your technology below.  ',
    webapp: 'Have a traditional web application that runs on a server? Auth0 maintains these SDK libraries for the most popular languages and environments. ',
    backend: 'Does your API or service need authentication? Auth0 has SDKs for common API and service development tools.',
    native: 'Developing  mobile or desktop applications that run natively on a user’s device? Choose from one of these SDKs to get started.',
    mgmt: 'Need to programmatically perform Auth0 administrative tasks? Choose from one of these management libraries.',
    lock: "Do you use Lock, Auth0's legacy self-hosted login experience? You can still rely on these libraries for self-hosted login.",
};
export var Category = function (_a) {
    var name = _a.name, _b = _a.sdks, sdks = _b === void 0 ? [] : _b;
    if (!sdks.length)
        return React.createElement(React.Fragment, null);
    return (React.createElement(RowLayout, { gutter: 0, sx: { gap: 2, pt: 3 }, id: name, as: "section" },
        React.createElement(Text, { variant: "h3", "data-library-type": name }, TITLES[name]),
        React.createElement(Text, { variant: "body2" }, DESCRIPTIONS[name]),
        React.createElement(Table, { sx: { mt: 3, minWidth: { xs: '100%' } } },
            React.createElement(TableBody, null, sdks.map(function (sdk) { return (React.createElement(Entry, __assign({}, sdk))); })))));
};
