var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createQuantumTheme, defaultTheme as theme } from '@auth0/quantum-product';
import { okta as oktaTokens } from '@auth0/quantum-tokens';
import { ColorScheme } from '../@types/ColorScheme';
import { layout } from './layout';
import { palette } from './palette';
import { getTypography } from './typography';
export var getTheme = function (options) {
    var _a, _b, _c, _d;
    if (options === void 0) { options = { colorScheme: ColorScheme.Light }; }
    var colorScheme = options.colorScheme, extendedOptions = __rest(options, ["colorScheme"]);
    var typography = getTypography();
    var themes = createQuantumTheme({
        tokens: oktaTokens,
        themeOptions: __assign({ layout: __assign(__assign({}, layout), { tabletBreakpoint: layout.sidebarWidth + layout.articleContentWidth + theme.spacingPX(5) + theme.spacingPX(6), mobileBreakpoint: layout.articleContentWidth + theme.spacingPX(6), landingBreakpoint: layout.landingContentWidth + layout.sidebarWidth + theme.spacingPX(10), tocWidth: layout.header1440 - layout.sidebarWidth - layout.articleContentWidth - 3 * theme.spacingPX(5), homePageDesktopBreakpoint: layout.homePageContentWidth + theme.spacingPX(6) }), palette: __assign({}, palette), breakpoints: {
                values: {
                    xs: 0,
                    sm: 600,
                    md: 960,
                    lg: 1360,
                    xl: 1920,
                },
            }, typography: {
                h1: __assign(__assign({}, typography === null || typography === void 0 ? void 0 : typography.h1), (_a = {}, _a[theme.breakpoints.down('sm')] = __assign({}, typography === null || typography === void 0 ? void 0 : typography.h2), _a[theme.breakpoints.down('xs')] = __assign({}, typography === null || typography === void 0 ? void 0 : typography.h3), _a)),
                h2: __assign(__assign({}, typography === null || typography === void 0 ? void 0 : typography.h2), (_b = {}, _b[theme.breakpoints.down('sm')] = __assign({}, typography === null || typography === void 0 ? void 0 : typography.h3), _b[theme.breakpoints.down('xs')] = __assign({}, typography === null || typography === void 0 ? void 0 : typography.h4), _b)),
                h3: __assign(__assign({}, typography === null || typography === void 0 ? void 0 : typography.h3), (_c = {}, _c[theme.breakpoints.down('sm')] = __assign({}, typography === null || typography === void 0 ? void 0 : typography.h4), _c[theme.breakpoints.down('xs')] = __assign({}, typography === null || typography === void 0 ? void 0 : typography.h5), _c)),
                h4: __assign(__assign({}, typography === null || typography === void 0 ? void 0 : typography.h4), (_d = {}, _d[theme.breakpoints.down('sm')] = __assign({}, typography === null || typography === void 0 ? void 0 : typography.h5), _d)),
                h5: __assign({}, typography === null || typography === void 0 ? void 0 : typography.h5),
                h6: __assign({}, typography === null || typography === void 0 ? void 0 : typography.h6),
                overline: __assign(__assign({}, theme.typography.overline), { fontFamily: '"Aeonik", sans-serif', fontSize: theme.typography.pxToRem(12), lineHeight: theme.typography.pxToRem(18), letterSpacing: 1.2 }),
                code1: __assign(__assign({}, typography === null || typography === void 0 ? void 0 : typography.code), { fontSize: theme.typography.pxToRem(14), lineHeight: theme.typography.pxToRem(20), letterSpacing: 0.15 }),
                code2: __assign(__assign({}, typography === null || typography === void 0 ? void 0 : typography.code), { fontSize: theme.typography.pxToRem(12), lineHeight: theme.typography.pxToRem(18), letterSpacing: 0.25 }),
                caption: {
                    fontSize: theme.typography.pxToRem(12),
                    lineHeight: theme.typography.pxToRem(18),
                },
                fontWeightLight: 400,
            }, components: function () { return ({
                MuiButton: {
                    styleOverrides: {
                        root: {
                            whiteSpace: 'nowrap',
                        },
                    },
                },
                MuiAppBar: {
                    styleOverrides: {
                        root: {
                            alignItems: 'center',
                            height: 64,
                            borderBottom: 0,
                        },
                        colorDefault: {
                            backgroundColor: theme.tokens.color_bg_page,
                            color: theme.tokens.color_fg_bold,
                        },
                    },
                },
            }); } }, extendedOptions),
    });
    return colorScheme === ColorScheme.Dark || colorScheme === ColorScheme.SystemDark ? themes.dark : themes.light;
};
