import * as React from 'react';
import changeAppChoiceViewAction from '../../../../action/changeAppChoiceView';
import { useFluxible } from '../../../hooks/useFluxible';
import { ConfigureApplication } from './ConfigureApplication';
import { CreateApplication } from './CreateApplication';
import { SelectApplication } from './SelectApplication';
export var AppChoice = function (_a) {
    var view = _a.view, children = _a.children, type = _a.type;
    var context = useFluxible();
    var setView = function (view) {
        context.executeAction(changeAppChoiceViewAction, { view: view });
    };
    switch (view) {
        case 'create':
            return React.createElement(CreateApplication, { type: type, setView: setView });
        case 'select':
            return React.createElement(SelectApplication, { setView: setView });
        case 'configure':
            return React.createElement(ConfigureApplication, { setView: setView });
        default:
            return React.createElement(React.Fragment, null, children);
    }
};
