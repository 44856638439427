var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChevronDownIcon, DropdownMenu, DropdownMenuList, DropdownMenuListItem, Link, styled, Text, useDropdownMenuState, } from '@auth0/quantum-product';
import { connectToStores } from 'fluxible-addons-react';
import * as React from 'react';
import DocumentStore from '../../../../stores/DocumentStore';
import QuickstartStore from '../../../../stores/QuickstartStore';
import canUseStore from '../../../../util/canUseStore';
var StepNumber = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        background: theme.palette.primary.main,
        borderRadius: '50%',
        width: theme.typography.pxToRem(24),
        height: theme.typography.pxToRem(24),
        display: 'flex',
        alignItems: 'center',
        color: theme.tokens.color_bg_button_inverse_static,
        justifyContent: 'center',
        fontSize: theme.typography.body1.fontSize,
        marginRight: theme.spacing(1),
    });
});
export var StepHeader = function (props) {
    var anchor = props.anchor, content = props.content, number = props.number, isTopBar = props.isTopBar;
    var showDropDown = isTopBar && number !== 0;
    return showDropDown ? (React.createElement(StepsWithDropdown, __assign({}, props))) : (React.createElement(Link, __assign({ color: "textPrimary" }, (!isTopBar && { sx: { mt: 3, mb: 2, display: 'flex', alignItems: 'center' } }), { href: "#".concat(anchor), id: anchor, startIcon: number > 0 ? React.createElement(StepNumber, null, number) : undefined }),
        React.createElement(Text, { variant: isTopBar ? 'h5' : 'h2', sx: { fontSize: !isTopBar ? 'h3' : 'inherit' } }, content)));
};
var StepsWithDropdownComponent = function (_a) {
    var anchor = _a.anchor, content = _a.content, _b = _a.steps, steps = _b === void 0 ? [] : _b, number = _a.number;
    var _c = useDropdownMenuState({ baseId: "header-menu-".concat(number) }), triggerProps = _c.triggerProps, menuProps = _c.menuProps;
    return (React.createElement(React.Fragment, null,
        React.createElement(Link, __assign({ color: "textPrimary", sx: { display: 'flex', alignItems: 'center', cursor: 'pointer' }, startIcon: React.createElement(StepNumber, null, number), endIcon: React.createElement(ChevronDownIcon, null) }, triggerProps),
            React.createElement(Text, { variant: "h5" }, content)),
        React.createElement(DropdownMenu, __assign({ placement: "left", size: "large" }, menuProps),
            React.createElement(DropdownMenuList, null, steps.map(function (step) { return (React.createElement(DropdownMenuListItem, { key: step.content, title: step.content, href: "#".concat(step.anchor), startIcon: React.createElement(StepNumber, null, step.number) })); })))));
};
var StepsWithDropdown = connectToStores(StepsWithDropdownComponent, [QuickstartStore], function (context) {
    if (!canUseStore(context))
        return {};
    var quickstartStore = context.getStore(QuickstartStore);
    var article = quickstartStore.getCurrentArticle();
    var doc = article && context.getStore(DocumentStore).getDocument(article.url);
    var steps = (doc && doc.meta.steps) || [];
    return {
        steps: steps,
        currentQuickstart: quickstartStore.getCurrentArticle(),
    };
});
