import { Select } from '@auth0/quantum-product';
import * as React from 'react';
import urljoin from 'url-join';
export var VersionSelector = function (_a) {
    var url = _a.url, version = _a.version, versioning = _a.versioning;
    var options = versioning.versions.map(function (value) { return ({
        value: value,
        text: value,
    }); });
    var handleChange = function (event) {
        var _a;
        var newVersion = (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value;
        var newVersionUrl = url.replace("/".concat(version), "/".concat(newVersion));
        // Redirect to the corresponding article in the new target version.
        // NB: We can't do a partial refresh with navigateAction here, because we may end
        // up needing to redirect if the article doesn't exist in the target version.
        document.location = newVersionUrl !== url ? newVersionUrl : urljoin(versioning.baseUrl, version);
    };
    return React.createElement(Select, { label: "Version", value: version, options: options, onChange: handleChange });
};
