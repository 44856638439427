var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Heap, heapEvent } from './heap';
import { MetricsEventType } from './MetricsEventType';
var heap = new Heap();
export { MetricsEventType };
export var track = function (event, data) {
    var _a = data || {}, dwh = _a.dwh, properties = __rest(_a, ["dwh"]);
    if (heapEvent.includes(event)) {
        heap.track(event, properties);
    }
};
export var addEventProperties = function (properties) { return heap.addEventProperties(properties); };
export default {
    track: track,
    addEventProperties: addEventProperties,
};
