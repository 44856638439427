import PropTypes from 'prop-types';
import React from 'react';

import ApiExplorer from './ApiExplorer';

const LANGUAGES = [
  { key: 'http', name: 'HTTP' },
  { key: 'shell', name: 'Shell' },
  { key: 'javascript', name: 'JavaScript' },
];

const AuthApiPage = (props) => <ApiExplorer {...props} title="Authentication API" languages={LANGUAGES} />;

AuthApiPage.propTypes = {
  doc: PropTypes.object,
  currentRoute: PropTypes.object,
};

export default AuthApiPage;
