import { connectToStores } from 'fluxible-addons-react';
import ApplicationStore from '../../../stores/ApplicationStore';
import DocumentStore from '../../../stores/DocumentStore';
import { ArticlePage } from './ArticlePage';
export default connectToStores(ArticlePage, [ApplicationStore, DocumentStore], function (context, _a) {
    var url = _a.currentRoute.url;
    return ({
        isFramedMode: context.getStore(ApplicationStore).isFramedMode(),
        doc: context.getStore(DocumentStore).getDocument(url),
        url: url,
    });
});
