var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { MetricsEventType } from '@a0/docs-components/metrics';
import { Box, Button, FieldSet, Form, Text, TextField } from '@auth0/quantum-product';
import * as React from 'react';
import { FeedbackMode } from '../../FeedbackSection';
import { BooleanFeedback } from '../../FeedbackSection/BooleanFeedback';
import { useFluxible } from '../../hooks/useFluxible';
import { NavLink } from '../../NavLink';
import { EditLink } from './EditLink';
import { ButtonsContainer, FormContainer, TopContent } from './styled';
var FeedbackFooterMode;
(function (FeedbackFooterMode) {
    FeedbackFooterMode["Ready"] = "READY";
    FeedbackFooterMode["Input"] = "INPUT";
    FeedbackFooterMode["Thanks"] = "THANKS";
})(FeedbackFooterMode || (FeedbackFooterMode = {}));
var initialState = {
    mode: FeedbackFooterMode.Ready,
    selected: FeedbackMode.None,
    comment: '',
};
export var FeedbackSection = function (_a) {
    var articleUrl = _a.articleUrl, contentfulId = _a.contentfulId, githubEditLink = _a.githubEditLink, _b = _a.isQuickstart, isQuickstart = _b === void 0 ? false : _b;
    var context = useFluxible();
    var _c = __read(React.useState(initialState), 2), state = _c[0], setState = _c[1];
    React.useEffect(function () {
        setState(__assign({}, initialState));
    }, [articleUrl]);
    var updateState = function (updates) {
        setState(__assign(__assign({}, state), updates));
    };
    var getTrackingData = function (data) { return (__assign({ path: window.location.pathname, url: window.location.toString(), title: document.title.split('-').length > 0 ? document.title[0].trim() : document.title, referrer: document.referrer }, data)); };
    var trackData = function (eventName, data) {
        context.trackEvent(eventName, getTrackingData(data));
    };
    var handleCommentChange = function (e) {
        updateState({ comment: e.target.value });
    };
    var handleBooleanFeedback = function (feedback) {
        if (state.mode === FeedbackFooterMode.Ready) {
            context.trackEvent(MetricsEventType.FeedbackBooleanClick, {
                value: feedback,
                component: 'ArticleFooter',
                dwh: { event: 'feedback:submit', properties: getTrackingData({ value: feedback === FeedbackMode.Positive }) },
            });
            updateState({
                mode: FeedbackFooterMode.Input,
                selected: feedback,
            });
        }
    };
    var sendCommentFeedback = function (positive, comment) {
        fetch('/docs/submit-feedback', {
            method: 'POST',
            headers: new Headers({
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
                page_title: document.title,
                page_url: window.location.href,
                positive: positive,
                comment: comment,
            }),
        });
    };
    var handleCommentFeedback = function (event) {
        event.preventDefault();
        sendCommentFeedback(state.selected === FeedbackMode.Positive, state.comment);
        context.trackEvent(MetricsEventType.FeedbackSubmitComment, {
            value: state.selected,
            component: 'ArticleFooter',
            comment: state.comment,
            dwh: {
                event: 'feedback:submit',
                properties: getTrackingData({ value: state.selected === FeedbackMode.Positive }),
            },
        });
        updateState({ mode: FeedbackFooterMode.Thanks });
    };
    var thanksTitle = 'Thanks you for your feedback!';
    var readyTitle = isQuickstart ? 'Did it work?' : 'Was this article helpful?';
    var title = state.mode === FeedbackFooterMode.Thanks ? thanksTitle : readyTitle;
    return (React.createElement(React.Fragment, null,
        React.createElement(TopContent, null,
            React.createElement(Text, { variant: "body2", color: "textSecondary" }, title),
            state.mode !== FeedbackFooterMode.Thanks && (React.createElement(ButtonsContainer, null,
                React.createElement(BooleanFeedback, { useButtons: true, value: state.selected, onChange: handleBooleanFeedback }))),
            React.createElement(EditLink, { contentfulId: contentfulId, githubEditLink: githubEditLink, trackData: trackData })),
        state.mode === FeedbackFooterMode.Input && (React.createElement(FormContainer, null,
            React.createElement(Form, { fullWidth: true, sx: { gridGap: '20px' }, layout: "inline", onSubmit: handleCommentFeedback },
                React.createElement(FieldSet, null,
                    React.createElement(TextField, { fullWidth: true, multiline: true, rows: 3, placeholder: state.selected === FeedbackMode.Positive
                            ? 'Glad to hear that! Any way we can improve?'
                            : 'Sorry to hear that. How can we help you?', onChange: handleCommentChange, helperText: React.createElement(Text, { variant: "body2", color: "textSecondary" },
                            "Can't find what you are looking for?  Check out ",
                            React.createElement(NavLink, { followLink: true, color: "primary", href: "https://community.auth0.com/", onClick: function () { return trackData('click:view-community'); }, target: "_blank", rel: "noreferrer noopener" }, "Auth0 Community")) })),
                React.createElement(Box, { display: "flex", justifyContent: "flex-end", marginTop: 1 },
                    React.createElement(Button, { color: "primary", variant: "contained", type: "submit", disabled: !state.comment }, "Submit")))))));
};
