var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AppBar, AppBarActions, AppBarContent, styled } from '@auth0/quantum-product';
import * as React from 'react';
import { Sticky } from 'react-sticky';
import { StepHeader } from '../StepHeader';
import { HeaderButtons } from './HeaderButtons';
var HeaderBar = styled(AppBar)(function (_a) {
    var _b;
    var theme = _a.theme, isSticky = _a.isSticky;
    return (__assign(__assign({ visibility: 'hidden', transform: 'translateY(-100%)', transition: 'visibility 0s linear 0.2s, transform 0.2s linear', padding: theme.spacing(0, 3), backgroundColor: theme.tokens.color_bg_page }, (isSticky && {
        visibility: 'visible',
        transform: 'translateY(0%)',
        transitionDelay: '0s',
    })), (_b = {}, _b[theme.breakpoints.down('sm')] = {
        padding: 0,
    }, _b)));
});
var HeaderContent = styled(AppBarContent)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            width: '100%',
            borderBottom: "1px solid ".concat(theme.tokens.color_border_default)
        },
        _b[theme.breakpoints.down('md')] = {
            maxWidth: '100%',
            padding: theme.spacing(0, theme.layout.mobileMarginX),
        },
        _b);
});
export var Header = function (_a) {
    var activeStep = _a.activeStep;
    return (React.createElement(Sticky, { disableCompensation: true }, function (_a) {
        var isSticky = _a.isSticky;
        return (React.createElement(HeaderBar, { isSticky: isSticky },
            React.createElement(HeaderContent, null,
                React.createElement(AppBarActions, null,
                    React.createElement(StepHeader, __assign({}, activeStep, { isTopBar: true }))),
                React.createElement(AppBarActions, { sx: { flex: 1, justifyContent: 'flex-end' } },
                    React.createElement(HeaderButtons, null)))));
    }));
};
