import metrics from '@a0/docs-components/metrics';
import canUseDOM from '@a0/docs-components/util/canUseDOM';
import cx from 'classnames';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { FeedbackSection } from '../FeedbackSection';
export var VideoWrapper = function (_a) {
    var _b = _a.padding, padding = _b === void 0 ? '56.25% 0 0 0' : _b, video = _a.video;
    React.useEffect(function () {
        $(document).ready(function () {
            $('#wistia-video-bookmarks a').on('click', function (e) {
                var _a;
                metrics.track('click:videos:bookmark', { track_data: e.target.innerHTML });
                (_a = document.getElementById('video-wrapper')) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
            });
            $('#wistia-video-bookmarks a').each(function (index, element) {
                var anchor = element;
                var time = anchor.href.split('time=')[1];
                if (time) {
                    var timeDec = parseInt(time, 10) / 60;
                    var minutes = Math.floor(timeDec);
                    var secondsDec = timeDec - minutes;
                    var seconds = "".concat(Math.round(secondsDec * 60)).padStart(2, '0');
                    anchor.text = "[".concat(minutes, ":").concat(seconds, "] ").concat(anchor.text);
                }
            });
        });
    }, []);
    var time = 0;
    if (canUseDOM() && window.location.hash) {
        time = +window.location.hash.split('?time=')[1];
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "video-wrapper", className: "wistia_responsive_padding", style: { padding: padding, position: 'relative' } },
            React.createElement("div", { className: "wistia_responsive_wrapper", style: { height: '100%', left: '0', position: 'absolute', top: '0', width: '100%' } },
                React.createElement("div", { className: cx("wistia_async_".concat(video, " wistia_embed videoFoam=true"), time > 0 ? "autoPlay=true time=".concat(time) : ''), style: { height: '100%', position: 'relative', width: '100%' } },
                    React.createElement("div", { className: "wistia_swatch", style: { height: '100%', left: '0', position: 'absolute', top: '0', width: '100%', overflow: 'hidden' } },
                        React.createElement("img", { alt: "video placeholder", src: "https://fast.wistia.com/embed/medias/".concat(video, "/swatch"), style: { filter: 'blur(5px)', height: '100%', objectFit: 'contain', width: '100%' } }))))),
        React.createElement(Helmet, null,
            React.createElement("script", { src: "https://fast.wistia.com/embed/medias/pdlav16113.jsonp", async: true, crossOrigin: "true" }),
            React.createElement("script", { src: "https://fast.wistia.com/assets/external/E-v1.js", async: true, crossOrigin: "true" })),
        React.createElement(FeedbackSection, { helpful: "Was this video helpful?", trackingCode: "videos", trackingData: video, useButtons: true })));
};
