import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.styl';

// @todo: replace Title component with this one, will need to modify reactportals

const updateWindowHash = (id) => {
  window.location.hash = id;
};

const AnchorTitle = ({ title, tag }) => {
  const anchor = title.replace(/\s/g, '-');

  return React.createElement(
    tag,
    {
      id: anchor,
      className: styles.anchorText,
      onClick: () => updateWindowHash(anchor),
    },
    title
  );
};

AnchorTitle.propTypes = {
  tag: PropTypes.oneOf(['h2', 'h3', 'h4', 'h5', 'h6']).isRequired,
  title: PropTypes.string.isRequired,
};

AnchorTitle.defaults = {
  tag: 'h2',
  title: 'Title',
};

export default AnchorTitle;
