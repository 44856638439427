import React from 'react';
import PropTypes from 'prop-types';
import styles from '../styles.module.styl';
import { QUICKSTART_TYPE_DESCRIPTIONS } from '../../../util/clientTypesHelper';

const CreateResource = ({ dashboardClientsUrl, dashboardApisUrl, showCreateClientMessage, currentQuickstartType }) => (
  <div className={styles.textSection}>
    <p>To configure this snippet</p>
    <a
      href={showCreateClientMessage ? dashboardClientsUrl : dashboardApisUrl}
      target="_blank"
      rel="noopener noreferrer"
      className="btn btn-sm btn-transparent"
    >
      {showCreateClientMessage
        ? `Create ${
            currentQuickstartType ? `a ${QUICKSTART_TYPE_DESCRIPTIONS[currentQuickstartType]}` : 'an Application'
          }`
        : 'Create an API'}
    </a>
  </div>
);

CreateResource.contextTypes = {
  executeAction: PropTypes.func,
};

CreateResource.propTypes = {
  dashboardClientsUrl: PropTypes.string.isRequired,
  dashboardApisUrl: PropTypes.string.isRequired,
  showCreateClientMessage: PropTypes.bool,
  currentQuickstartType: PropTypes.string,
};

export default CreateResource;
