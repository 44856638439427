import * as React from 'react';
import { performSelectClient } from '../../../../action/performUserResourceActions';
import QuickstartStore from '../../../../stores/QuickstartStore';
import UserStore from '../../../../stores/UserStore';
import { mapOptionTypes } from '../../../../util/clientTypesHelper';
import { useFluxible } from '../../../hooks/useFluxible';
import { SelectResource } from './SelectResource';
export var SelectApplication = function (_a) {
    var setView = _a.setView;
    var context = useFluxible();
    var currentQuickstartType = context.getStore(QuickstartStore).getQuickstartType();
    var clients = context
        .getStore(UserStore)
        .getQuickstartCompatibleClients(currentQuickstartType);
    var clientsByType = context.getStore(UserStore).getQuickstartCompatibleClientsByType(currentQuickstartType);
    var selectedId = context.getStore(UserStore).getSelectedClientId();
    var save = function (selectedId) {
        context.executeAction(performSelectClient, { selectedId: selectedId });
        setView('configure');
    };
    return (React.createElement(SelectResource, { resources: clients.map(function (_a) {
            var client_id = _a.client_id, name = _a.name;
            return ({ id: client_id, name: name });
        }), selectedId: selectedId, renderOptions: mapOptionTypes(clientsByType), onSave: save, label: "Select your Application", helperText: "Select the application to configure for this quickstart", onCancel: function () { return setView('default'); } }));
};
