import { IconButton, SearchIcon, TextField, XCircleIcon } from '@auth0/quantum-product';
import React from 'react';
export var SearchField = function (_a) {
    var value = _a.value, performSearch = _a.performSearch;
    return (React.createElement("form", { role: "search", autoComplete: "off", onSubmit: function (e) { return e.preventDefault(); } },
        React.createElement(TextField, { icon: React.createElement(SearchIcon, { sx: { mr: 1, ml: 1 } }), id: "search-input", fullWidth: true, type: "text", placeholder: "Search for your technology...", value: value, InputProps: { sx: { height: 48, br: 3, fontSize: 18 } }, onChange: function (evt) { return performSearch(evt.target.value); }, actions: value
                ? [
                    React.createElement(IconButton, { size: "large", onClick: function () { return performSearch(''); } },
                        React.createElement(XCircleIcon, { fontSize: 20 })),
                ]
                : undefined })));
};
