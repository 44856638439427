var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import styles from './styles.module.styl';
var defaultIconCode = '345';
export var NextStepsList = function (_a) {
    var title = _a.title, items = _a.items, linkComponent = _a.linkComponent, _b = _a.linkProps, linkProps = _b === void 0 ? {} : _b;
    var Link = linkComponent;
    return (React.createElement(React.Fragment, null,
        React.createElement("h5", { className: styles.title }, title),
        React.createElement("ul", { className: styles.list }, items.map(function (item) { return (React.createElement(Link, __assign({ key: item.href, href: item.href, className: styles.listItemLink }, linkProps),
            React.createElement("li", { className: styles.listItem },
                React.createElement("span", { className: "icon icon-budicon-".concat(item.icon || defaultIconCode, " ").concat(styles.icon) }),
                React.createElement("span", { className: styles.text }, item.text)))); }))));
};
