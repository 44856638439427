var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import { createPortal } from 'react-dom';
import { Accordion } from '../Accordion';
import { AlertContainer } from '../AlertContainer';
import { Checkpoint } from '../Checkpoint';
import CodeBlock from '../CodeBlock';
import DefinitionTooltip from '../DefinitionTooltip';
import ImageZoom from '../ImageZoom';
import MicrositeFurtherReading from '../Microsites/FurtherReading';
import MicrositeSteps from '../Microsites/Steps';
import { StepHeader } from '../pages/TutorialPage.new/StepHeader';
import { TabsContainer } from '../TabsContainer';
import { Title } from '../Title';
import { VideoWrapper } from '../VideoWrapper';
var componentFactory = function (portalName) {
    var portal;
    switch (portalName) {
        case 'Title':
            portal = Title;
            break;
        case 'ImageZoom':
            portal = ImageZoom;
            break;
        case 'CodeBlock':
            portal = CodeBlock;
            break;
        case 'MicrositeSteps':
            portal = MicrositeSteps;
            break;
        case 'MicrositeFurtherReading':
            portal = MicrositeFurtherReading;
            break;
        case 'DefinitionTooltip':
            portal = DefinitionTooltip;
            break;
        case 'VideoWrapper':
            portal = VideoWrapper;
            break;
        case 'AlertContainer':
            portal = AlertContainer;
            break;
        case 'StepHeader':
            portal = StepHeader;
            break;
        case 'Checkpoint':
            portal = Checkpoint;
            break;
        case 'TabsContainer':
            portal = TabsContainer;
            break;
        case 'Accordion':
            portal = Accordion;
            break;
        default:
            throw new Error('No component found with the specified name');
    }
    return portal;
};
export var PortalsRenderer = function (_a) {
    var portals = _a.portals, meta = _a.meta;
    var _b = __read(React.useState(false), 2), isMounted = _b[0], setIsMounted = _b[1];
    React.useEffect(function () {
        setIsMounted(true);
    }, []);
    return (React.createElement(React.Fragment, null, isMounted &&
        portals.map(function (_a) {
            var component = _a.component, containerID = _a.containerID, props = _a.props;
            var container = document.getElementById("".concat(containerID));
            if (!container)
                return null;
            // Remove portals content server-side rendered so we can
            // render the React components inside the container (client-side) and prevent duplication
            Array.from(container.children).forEach(function (node) {
                if (node.hasAttribute('data-react-universal-portal')) {
                    node.remove();
                }
            });
            var Component = componentFactory(component);
            return createPortal(React.createElement(Component, __assign({}, props, { meta: meta })), container);
        })));
};
