import { BaseStore } from 'fluxible/addons';
import { cloneDeep } from 'lodash';

import normalizeUrl from '../util/normalizeUrl';

const markActiveItems = (url) => (article) => {
  if (article.url === url) {
    return { ...article, isActiveUrl: true, isCurrentUrl: true };
  } else if (Array.isArray(article.children)) {
    const markedChildren = article.children.map(markActiveItems(url));
    const found = markedChildren.find((c) => c.isActiveUrl === true);
    if (found) {
      return { ...article, isActiveUrl: true, isCurrentUrl: false, children: markedChildren };
    }
  }
  return { ...article, isActiveUrl: false, isCurrentUrl: false };
};

class NavigationStore extends BaseStore {
  constructor(dispatcher) {
    super(dispatcher);
    this.navigation = null;
    this.cards = null;
    this.glossary = {};
    this.breadcrumbs = [];
    this.sidebarBreadcrumbs = [];
    this.activeList = [];
  }

  getSections() {
    if (this.navigation) {
      return this.navigation.sections;
    }
    return [];
  }

  getMetadata(url) {
    if (this.navigation) {
      return this.navigation.metadata[normalizeUrl(url)];
    }
    return undefined;
  }

  getNavigationItems(section, breadcrumbs) {
    if (!this.navigation) {
      return [];
    }
    if (section !== 'libraries') {
      return this.navigation.sidebar[section];
    }

    // Build library sidebar
    let navigationItems = [];

    if (breadcrumbs.length > 1) {
      // The 2nd item in breadcrumbs is the child this article belongs to
      const libraryPath = `/docs/${breadcrumbs[1].path}`;
      const libraryArticles = this.navigation.sidebar[section];
      // Filters out the rest of the library articles
      navigationItems = libraryArticles.filter((a) => a.url === libraryPath);

      if (!navigationItems.length) {
        // if it doesnt exist in the sidebar.yml, add itself alone to the sidebar
        navigationItems = [breadcrumbs[breadcrumbs.length - 1]];
      }
    }

    return navigationItems;
  }

  getBreadcrumbs() {
    return this.breadcrumbs;
  }

  getSidebarBreadcrumbs() {
    return this.sidebarBreadcrumbs;
  }

  getCards() {
    if (this.cards) {
      return this.cards;
    }
    return undefined;
  }

  getGlossary() {
    return this.glossary;
  }

  handleArticleLoaded({ url, doc }) {
    if (!doc || !doc.meta) return;
    this.setupArticleNav({ url, doc });
    this.emitChange();
  }

  setupArticleNav({ url, doc }) {
    const section = doc.meta.section;
    const unmarkedSidebar = this.navigation.unmarkedSidebar[section];

    // Mark which Articles are Active in Navigation
    const sideBarArticles = unmarkedSidebar.map(markActiveItems(url));
    this.navigation.sidebar[section] = sideBarArticles;

    // Update Breadcrumbs in navigation
    this.breadcrumbs = [{ title: 'Docs', url: '/docs' }, ...doc.meta.breadcrumbs];
  }

  handleNavigationLoaded(payload) {
    this.navigation = {
      sections: payload.sections,
      sidebar: payload.sidebar,
      unmarkedSidebar: cloneDeep(payload.sidebar),
    };
    this.emitChange();
  }

  handleCardsLoaded(payload) {
    this.cards = payload.cards;
    this.emitChange();
  }

  handleGlossaryLoaded(payload) {
    this.glossary = payload.glossary;
    this.emitChange();
  }

  dehydrate() {
    return {
      navigation: this.navigation,
      cards: this.cards,
      glossary: this.glossary,
      breadcrumbs: this.breadcrumbs,
      sidebarBreadcrumbs: this.sidebarBreadcrumbs,
    };
  }

  rehydrate(state) {
    this.navigation = state.navigation;
    this.cards = state.cards;
    this.glossary = state.glossary;
    this.breadcrumbs = state.breadcrumbs;
    this.sidebarBreadcrumbs = state.sidebarBreadcrumbs;
  }
}

NavigationStore.storeName = 'NavigationStore';
NavigationStore.handlers = {
  NAVIGATION_LOAD_SUCCESS: 'handleNavigationLoaded',
  CARDS_LOAD_SUCCESS: 'handleCardsLoaded',
  GLOSSARY_LOAD_SUCCESS: 'handleGlossaryLoaded',
  ARTICLE_LOAD_SUCCESS: 'handleArticleLoaded',
};

export default NavigationStore;
