import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import styles from './styles.module.styl';

function CommunityAuthor({ org, repo }) {
  const [lastUpdated, setLastUpdated] = useState(null);

  useEffect(() => {
    if (org && repo) {
      fetch(`https://api.github.com/repos/${org}/${repo}`)
        .then((r) => r.json())
        .then(({ updated_at: updatedAt }) => setLastUpdated(updatedAt));
    }
  }, []);

  return (
    <div className={styles.anonymousCommunityAuthor}>
      <div className={styles.communityInfo}>
        {org && repo ? (
          <a
            href={`https://github.com/${org}/${repo}/contributors`}
            className={styles.communityText}
            target="_blank"
            rel="noopener noreferrer"
          >
            Community maintained
            <i className="icon-budicon-522" />
          </a>
        ) : (
          <span className={styles.communityText}>Community maintained</span>
        )}
        <div className={styles.lastUpdated}>{lastUpdated && `Last Updated ${format(lastUpdated, 'MM/DD/YYYY')}`}</div>
      </div>
    </div>
  );
}

CommunityAuthor.propTypes = {
  org: PropTypes.string.isRequired,
  repo: PropTypes.string.isRequired,
};

export default CommunityAuthor;
