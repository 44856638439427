var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { MetricsEventType } from '@a0/docs-components/metrics';
import { Box, Button, Text, TextField } from '@auth0/quantum-product';
import * as React from 'react';
import { useFluxible } from '../hooks/useFluxible';
import { BooleanFeedback } from './BooleanFeedback';
import { FeedbackMode } from './feedbackModes';
import { FeedbackModal, FeedbackSectionContainer } from './styled';
var positiveFeedbackTitle = 'Glad to hear that! Any way we can improve?';
var negativeFeedbackTitle = 'Sorry to hear that. Tell us what happened';
export var FeedbackSection = function (_a) {
    var trackingCode = _a.trackingCode, trackingData = _a.trackingData, _b = _a.helpful, helpful = _b === void 0 ? 'Was this helpful?' : _b, _c = _a.useButtons, useButtons = _c === void 0 ? false : _c, _d = _a.alignModal, alignModal = _d === void 0 ? 'right' : _d;
    var context = useFluxible();
    var feedbackModalRef = React.useRef(null);
    var _e = __read(React.useState(FeedbackMode.None), 2), feedbackMode = _e[0], setFeedbackMode = _e[1];
    var _f = __read(React.useState(false), 2), showFeedbackModal = _f[0], setShowFeedbackModal = _f[1];
    var _g = __read(React.useState(''), 2), message = _g[0], setMessage = _g[1];
    var handleMessageChange = function (event) {
        setMessage(event.target.value);
    };
    var hideFeedbackModalOnOuterClick = function (event) {
        if (feedbackModalRef.current && !$(event.target).closest(feedbackModalRef.current).length) {
            setFeedbackMode(FeedbackMode.None);
            setShowFeedbackModal(false);
            $(document).off('click', hideFeedbackModalOnOuterClick);
        }
    };
    var handleBooleanFeedback = function (feedback) {
        context.trackEvent(MetricsEventType.FeedbackBooleanClick, {
            value: feedback,
            component: 'FeedbackSection',
            dwh: {
                event: "submit:".concat(trackingCode, ":feedback-helpful"),
                properties: {
                    trackData: feedback === FeedbackMode.Positive,
                    track_data2: trackingData,
                },
            },
        });
        setFeedbackMode(feedback);
        setShowFeedbackModal(true);
        $(document).on('click', hideFeedbackModalOnOuterClick);
    };
    var handleMessageFeedback = function () {
        var comment = message;
        if (trackingCode === 'quickstarts:code-snippet' ||
            trackingCode === 'docs:auth-api' ||
            trackingCode === 'docs:management-api') {
            comment = "[".concat(trackingCode, ":").concat(trackingData, "] - ").concat(message);
        }
        context.trackEvent(MetricsEventType.FeedbackSubmitComment, {
            value: feedbackMode,
            comment: message,
            component: 'FeedbackSection',
            dwh: {
                event: "submit:".concat(trackingCode, ":feedback-message"),
                properties: {
                    trackData: message,
                    track_data2: feedbackMode,
                    track_data3: trackingData,
                },
            },
        });
        setFeedbackMode(FeedbackMode.Sent);
        setShowFeedbackModal(false);
        fetch('/docs/submit-feedback', {
            method: 'POST',
            headers: new Headers({
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
                page_title: document.title,
                page_url: window.location.href,
                positive: feedbackMode === FeedbackMode.Positive,
                comment: comment,
            }),
        });
    };
    return (React.createElement(FeedbackSectionContainer, { display: "flex", alignItems: "baseline", justifyContent: "flex-end" },
        feedbackMode === FeedbackMode.Sent ? (React.createElement(Text, { variant: "caption" }, "Thanks for your feedback!")) : (React.createElement(React.Fragment, null,
            React.createElement(Text, null, helpful),
            React.createElement(BooleanFeedback, { value: feedbackMode, useButtons: useButtons, onChange: handleBooleanFeedback }))),
        showFeedbackModal && (React.createElement(FeedbackModal, { gutter: 3, ref: feedbackModalRef, sx: { left: alignModal === 'left' ? 0 : undefined } },
            React.createElement(Text, { variant: "caption" }, feedbackMode === FeedbackMode.Positive ? positiveFeedbackTitle : negativeFeedbackTitle),
            React.createElement(TextField, { fullWidth: true, multiline: true, rows: 5, value: message, onChange: handleMessageChange }),
            React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "flex-end" },
                React.createElement(Button, { color: "primary", variant: "contained", disabled: !message, onClick: handleMessageFeedback }, "Send"))))));
};
