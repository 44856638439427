import PropTypes from 'prop-types';
import React from 'react';

import { QUANTUM_UI } from '../../../../flags';
import Clock2MinutesIcon from './Clock2Minutes';
import Clock15MinutesIcon from './Clock15Minutes';
import DownloadSteps from './DownloadSteps';
import styles from './styles.module.styl';

const buttonStyle = QUANTUM_UI ? 'btn-success-quantum-theme' : 'btn-success';

const DownloadBox = ({
  isAuthenticated,
  requiredResources,
  showSteps,
  steps = [],
  description,
  showDownloadModal,
  githubSampleLink,
  trackViewGithubSample,
  handleLogin,
  isFramedMode,
}) => (
  <div className={styles.downloadBox}>
    <div className={styles.section}>
      <div>
        <h3 className={styles.title}>I want to integrate with my app</h3>
        <span className={styles.timer}>
          <Clock15MinutesIcon />
          15 minutes
        </span>
        {showSteps ? (
          <DownloadSteps steps={steps.filter((s) => s.level === 2)} isFramedMode={isFramedMode} />
        ) : (
          <p className={styles.description}>{description}</p>
        )}
      </div>
      {!showSteps && (
        <a href={`#${steps[0].anchor}`} className={`${styles.btn} ${buttonStyle} btn btn-block`}>
          Follow Quickstart
        </a>
      )}
      <div className={styles.separator}>Or</div>
    </div>
    <div className={styles.section}>
      <div>
        <h3 className={styles.title}>I want to explore a sample app</h3>
        <span className={styles.timer}>
          <Clock2MinutesIcon />2 minutes
        </span>
        <p className={styles.description}>
          {isAuthenticated && requiredResources ? (
            <span>
              To get a sample configured with your account settings create {requiredResources} first or check it out on
              Github.
            </span>
          ) : (
            <span>Get a sample configured with your account settings or check it out on Github.</span>
          )}
        </p>
      </div>
      <div>
        {isAuthenticated ? (
          <button
            onClick={showDownloadModal}
            className={`${styles.btn} ${buttonStyle} btn btn-block`}
            disabled={!!requiredResources}
          >
            Download sample
          </button>
        ) : (
          // change style here?
          <button onClick={handleLogin} className={`${styles.btn} ${buttonStyle} btn btn-block`}>
            Log In & Download Sample
          </button>
        )}
        <a
          className={`${styles.btn} btn btn-transparent btn-block`}
          target="_blank"
          rel="noopener noreferrer"
          href={githubSampleLink}
          onClick={trackViewGithubSample}
        >
          View on Github
        </a>
      </div>
    </div>
  </div>
);

DownloadBox.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isFramedMode: PropTypes.bool.isRequired,
  requiredResources: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]).isRequired,
  showSteps: PropTypes.bool.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      anchor: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  description: PropTypes.string.isRequired,
  showDownloadModal: PropTypes.func.isRequired,
  githubSampleLink: PropTypes.string.isRequired,
  trackViewGithubSample: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
};

export default DownloadBox;
