import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import cx from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import navigateToQuickstart from '../../../action/navigateToQuickstart';
import CommunityMaintainedIcon from '../CommunityIcon';
import styles from './styles.module.styl';

class Platform extends React.Component {
  handleClick = () => {
    const { quickstart, platform } = this.props;
    const payload = {
      quickstartId: quickstart.name,
      platformId: platform.name,
    };
    this.context.executeAction(navigateToQuickstart, payload);
  };

  render() {
    const { platform, otherType } = this.props;
    const isCommunityMaintained = get(this.props, 'platform.author.community');
    const showCommunityBadge = isCommunityMaintained && !otherType;

    return (
      <li>
        <div className={cx(styles.platform, otherType && styles.otherPlatformType)} onClick={this.handleClick}>
          <div data-name={platform.logo_name || platform.name} className="circle-logo">
            <div className={cx('logo', styles.logo)} />
            <div className={cx('title', styles.title)}>{platform.title}</div>
          </div>
          {showCommunityBadge && (
            <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-logo">Community maintained</Tooltip>}>
              <div className={styles.communityBadge}>
                <CommunityMaintainedIcon className={styles.communityBadgeIcon} />
              </div>
            </OverlayTrigger>
          )}
        </div>
      </li>
    );
  }
}

Platform.defaultProps = {
  otherType: false,
};

Platform.propTypes = {
  quickstart: PropTypes.object,
  platform: PropTypes.object,
  otherType: PropTypes.bool,
};

Platform.contextTypes = {
  executeAction: PropTypes.func,
  trackEvent: PropTypes.func.isRequired,
};

export default Platform;
