var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { RowLayout, styled, Text } from '@auth0/quantum-product';
import Fuse from 'fuse.js';
import { sortBy } from 'lodash';
import * as React from 'react';
import { ContentContainer } from '../../layouts';
import { Spinner } from '../../Spinner';
import { LettersBar } from './components/LettersBar';
import { SearchField } from './components/SearchField';
import { Terms } from './components/Terms';
var INITIAL_STATE = {
    search: '',
    searchActive: false,
    searchTerms: [],
    searchTermLetters: [],
    searchTermsByLetters: {},
};
var GlossaryContainer = styled(ContentContainer)(function (_a) {
    var theme = _a.theme;
    return ({
        maxWidth: theme.layout.mobileBreakpoint,
        margin: '0 auto',
        padding: theme.spacing(0, 3),
    });
});
export var GlossaryPage = function (_a) {
    var terms = _a.terms, termLetters = _a.termLetters, termsByLetter = _a.termsByLetter, query = _a.query;
    var _b = __read(React.useState(INITIAL_STATE), 2), state = _b[0], setState = _b[1];
    var performSearch = function (search) {
        if (!search) {
            setState(INITIAL_STATE);
            return;
        }
        var searchTerms = new Fuse(terms, {
            threshold: 0.2,
            location: 0,
            distance: 1000,
            minMatchCharLength: 1,
            keys: ['id', 'title'],
        }).search(search);
        var glossary = { termsByLetter: {} };
        sortBy(searchTerms, 'id').forEach(function (_a, id) {
            var _b = _a === void 0 ? {} : _a, item = _b.item;
            var letter = item.title.charAt(0).toUpperCase();
            glossary.termsByLetter[letter] = glossary.termsByLetter[letter] || [];
            glossary.termsByLetter[letter].push(__assign({ id: id }, item));
        });
        setState({
            search: search,
            searchActive: true,
            searchTerms: searchTerms,
            searchTermsByLetters: glossary.termsByLetter,
            searchTermLetters: Object.keys(glossary.termsByLetter),
        });
    };
    React.useEffect(function () {
        if (query) {
            performSearch(query);
        }
    }, []);
    var search = state.search, searchActive = state.searchActive, searchTermLetters = state.searchTermLetters, searchTermsByLetters = state.searchTermsByLetters;
    return (React.createElement(GlossaryContainer, null,
        React.createElement(RowLayout, { gutter: 4, sx: { py: 10 } },
            React.createElement(RowLayout, { gutter: 3 },
                React.createElement(Text, { variant: "h1" }, "Identity Glossary"),
                React.createElement(Text, { variant: "body2" }, "We've put together a glossary of identity terms for newcomers and seasoned developers, alike. Hopefully this helps put any identity terminology confusion to rest.")),
            React.createElement(SearchField, { placeholder: "Search by name", value: search, onChange: function (e) { return performSearch(e.target.value); } }),
            React.createElement(LettersBar, { letters: searchActive ? searchTermLetters : termLetters }),
            !terms ? (React.createElement(Spinner, null)) : (React.createElement(Terms, { letters: searchActive ? searchTermLetters : termLetters, termsByLetter: searchActive ? searchTermsByLetters : termsByLetter })))));
};
