import React from 'react';
import PropTypes from 'prop-types';
import { QUICKSTART_TYPE_DESCRIPTIONS } from '../../../util/clientTypesHelper';

const SampleRequiredUserResources = ({
  showCreateClient,
  showCreateAPI,
  dashboardClientsUrl,
  dashboardApisUrl,
  currentQuickstartType,
}) => (
  <React.Fragment>
    {showCreateClient && (
      <a href={dashboardClientsUrl} target="_blank" rel="noreferrer noopener">
        {currentQuickstartType ? `a ${QUICKSTART_TYPE_DESCRIPTIONS[currentQuickstartType]}` : 'an application'}
      </a>
    )}
    {showCreateClient && showCreateAPI && ' and '}
    {showCreateAPI && (
      <a href={dashboardApisUrl} target="_blank" rel="noreferrer noopener">
        an API
      </a>
    )}
  </React.Fragment>
);

SampleRequiredUserResources.propTypes = {
  showCreateClient: PropTypes.bool.isRequired,
  showCreateAPI: PropTypes.bool.isRequired,
  dashboardClientsUrl: PropTypes.string.isRequired,
  dashboardApisUrl: PropTypes.string.isRequired,
  currentQuickstartType: PropTypes.string,
};

export default SampleRequiredUserResources;
