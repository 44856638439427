var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { MetricsEventType } from '@a0/docs-components/metrics';
import { Link, RowLayout, styled } from '@auth0/quantum-product';
import * as React from 'react';
import { isFindEvent } from '../../util/keyboardEvents';
import { useFluxible } from '../hooks/useFluxible';
import { MetricsId } from '../WithMetricsId';
import { AccordionPanel } from './AccordionPanel';
var StyledLinkDiv = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        width: 'fit-content',
        margin: "0 0 ".concat(theme.spacing(1), " auto"),
        display: 'flex',
        gap: theme.spacing(0.625),
    });
});
var ExpandCollapseButtons = function (_a) {
    var onClick = _a.onClick;
    return (React.createElement(StyledLinkDiv, { "data-metrics-id": MetricsId.AccordionControlButtons, className: "accordion-control-buttons" },
        React.createElement(Link, { color: "primary", component: "button", onClick: function () { return onClick(true); }, underline: "none" }, "Expand all"),
        "/",
        React.createElement(Link, { color: "primary", component: "button", onClick: function () { return onClick(false); }, underline: "none" }, "Collapse all")));
};
export var Accordion = function (_a) {
    var accordions = _a.accordions;
    var context = useFluxible();
    var _b = __read(React.useState(Array(accordions.length).fill(false)), 2), accordionState = _b[0], setAccordionState = _b[1];
    var toggleAllAccordionState = function (state) {
        setAccordionState(Array(accordions.length).fill(state));
    };
    var toggleSingleAccordionState = function (index) {
        var newState = accordionState.map(function (accordion, i) { return (i == index ? !accordion : accordion); });
        setAccordionState(newState);
    };
    React.useEffect(function () {
        var onCtrlFindPress = function (e) {
            if (isFindEvent(e) && accordionState.some(function (value) { return !value; })) {
                setAccordionState(Array(accordions.length).fill(true));
                context.trackEvent(MetricsEventType.AccordionSearchExpandAll);
            }
        };
        window.addEventListener('keydown', onCtrlFindPress);
        return function () {
            window.removeEventListener('keydown', onCtrlFindPress);
        };
    }, [accordionState]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ExpandCollapseButtons, { onClick: toggleAllAccordionState }),
        React.createElement(RowLayout, { gutter: 2, className: "accordion-panels" }, accordions.map(function (props, index) { return (React.createElement(AccordionPanel, __assign({}, __assign(__assign({}, props), { index: index }), { expanded: accordionState[index], onChange: function () { return toggleSingleAccordionState(index); } }))); }))));
};
