import * as React from 'react';

const Clock2Minutes = () => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
      <g fill="#5C666F" fillRule="evenodd">
        <path d="M6.5 13a6.5 6.5 0 1 1 0-13 6.5 6.5 0 0 1 0 13zM6.5.867a5.633 5.633 0 1 0 0 11.266A5.633 5.633 0 0 0 6.5.867z" />
        <path d="M6.5 6.608V0s3.03.092 4.773 2.151c1.743 2.06-.716-.384-.716-.384L6.5 6.608z" />
      </g>
    </svg>
  );
};

export default Clock2Minutes;
