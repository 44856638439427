var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button, Card, fade, RowLayout, styled, Text } from '@auth0/quantum-product';
import * as React from 'react';
import navigateToQuickstart from '../../../action/navigateToQuickstart';
import { useFluxible } from '../../hooks/useFluxible';
import { useQuickstartUrl } from '../../hooks/useQuickstartUrl';
import { NavLink } from '../../NavLink';
import { MetricsId, withMetricsId } from '../../WithMetricsId';
import { Symbol } from './Symbol';
var Root = styled(Card, { shouldForwardProp: function (prop) { return prop !== 'qsVariant'; } })(function (_a) {
    var _b;
    var theme = _a.theme, qsVariant = _a.qsVariant;
    return (__assign(__assign({ cursor: 'pointer', width: 232, height: 345, padding: theme.spacing(2), borderRadius: theme.spacing(2), border: "1px solid ".concat(theme.palette.divider), '&:hover': {
            boxShadow: "inset 0px 0px 0px 3px ".concat(fade(theme.palette.primary.main, 0.25)),
            borderColor: fade(theme.palette.primary.main, 0.25),
        } }, (qsVariant === 'home' && {
        width: 280,
        height: 318,
        borderRadius: 6,
        border: '4px solid transparent',
        borderBottomWidth: 2,
        '&:hover': {
            boxShadow: theme.shadows[2],
            border: "4px solid ".concat(fade(theme.palette.primary.main, 0.25)),
        },
    })), (_b = { transition: 'border 0.1s' }, _b[theme.breakpoints.down(qsVariant === 'home' ? theme.layout.homePageDesktopBreakpoint : theme.layout.landingBreakpoint)] = {
        width: '100%',
    }, _b)));
});
var DescriptionText = styled(Text)({
    textAlign: 'center',
});
var QuickstartComponent = function (_a) {
    var quickstart = _a.quickstart, _b = _a.showCTA, showCTA = _b === void 0 ? true : _b, _c = _a.variant, variant = _c === void 0 ? 'home' : _c, props = __rest(_a, ["quickstart", "showCTA", "variant"]);
    var executeAction = useFluxible().executeAction;
    var url = useQuickstartUrl({ quickstartId: quickstart.name });
    var handleClick = function (event) {
        event.preventDefault();
        executeAction(navigateToQuickstart, {
            quickstartId: quickstart.name,
        });
    };
    return (React.createElement(NavLink, { style: { zIndex: 1 }, href: url, onClick: handleClick },
        React.createElement(Root, __assign({}, props, { elevation: variant === 'home' ? 1 : 0, size: "medium", qsVariant: variant, variant: "elevation", "data-type": quickstart.name }),
            React.createElement(RowLayout, { gutter: 3, sx: { justifyItems: 'center' } },
                React.createElement(Symbol, __assign({}, { type: quickstart.name })),
                React.createElement(RowLayout, { gutter: 1, sx: { justifyItems: 'center' } },
                    React.createElement(Text, { variant: "h6" }, quickstart.title),
                    React.createElement(DescriptionText, { variant: "body1" }, quickstart.description)),
                React.createElement(Text, { color: "textSecondary", variant: "caption" }, quickstart.example),
                showCTA && (React.createElement(Button, { size: "small", color: "info" }, "Launch Quickstart"))))));
};
export var Quickstart = withMetricsId(QuickstartComponent, MetricsId.QuickstartsCard);
