import PropTypes from 'prop-types';
import React from 'react';

import { QUANTUM_UI } from '../../../../flags';
import { FeedbackSection } from '../../ArticleFooter/FeedbackSection';
import Header from '../Header';
import ReadyToBuild from '../ReadyToBuild';
import newStyles from './styles.module.styl';
import oldStyles from './styles.old.module.styl';

const styles = QUANTUM_UI ? newStyles : oldStyles;

const MicrositeTemplate = ({ contentReady, renderContent, doc }) => (
  <React.Fragment>
    <div className={styles.headerContainer}>
      <Header {...doc.meta} />
    </div>

    {doc.meta.ctaLink && <ReadyToBuild {...doc.meta} position="top" />}

    <div className={styles.micrositeContainer}>
      {contentReady && renderContent(doc)}

      <hr />

      <div className={styles.feedbackSection}>
        <FeedbackSection articleUrl={doc.url} githubEditLink={doc.meta.editUrl} contentfulId={doc.meta.contentfulId} />
      </div>
    </div>

    {doc.meta.ctaLink && <ReadyToBuild {...doc.meta} position="bottom" />}
  </React.Fragment>
);

MicrositeTemplate.propTypes = {
  contentReady: PropTypes.bool.isRequired,
  renderContent: PropTypes.func.isRequired,
  doc: PropTypes.object,
};

MicrositeTemplate.defaultProps = {
  contentReady: false,
  renderContent: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  doc: {
    meta: {},
  },
};

export default MicrositeTemplate;
