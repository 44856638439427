import { navigateAction } from 'fluxible-router';
import * as React from 'react';
import { useFluxible } from '../../hooks/useFluxible';
import { Select } from '../../Select';
var getLanguageId = function (pathname) { var _a; return ((_a = pathname.split('/').slice().pop()) === null || _a === void 0 ? void 0 : _a.split('-').pop()) || ''; };
export var LanguageSelector = function (_a) {
    var languages = _a.languages, pathname = _a.pathname;
    var context = useFluxible();
    var handleChange = function (e) {
        var currentLanguageID = getLanguageId(pathname);
        var selectedLanguageID = e.target.value;
        var url = pathname.replace(currentLanguageID, selectedLanguageID);
        context.executeAction(navigateAction, { url: url });
    };
    var options = languages.map(function (lang) { return ({
        label: lang.name,
        value: lang.url,
    }); });
    return (React.createElement(Select, { options: options, selected: options.findIndex(function (v) { return v.value === getLanguageId(pathname); }), handleChange: handleChange, label: "Language" }));
};
