import xssFilters from 'xss-filters';
var VARS = {
    __AUTH0_USERNAME__: function (data) { return data.account.userName; },
    __AUTH0_APPNAME__: function (data) { return data.account.appName; },
    __AUTH0_TENANT__: function (data) { return data.account.tenant; },
    __AUTH0_NAMESPACE__: function (data) { return data.account.namespace; },
    __AUTH0_CLIENT_ID__: function (data) { return data.account.clientId; },
    __AUTH0_CLIENT_SECRET__: function (data) { return data.account.clientSecret; },
    __AUTH0_CALLBACK__: function (data) { return data.account.callback; },
    __AUTH0_CONNECTION_NAME__: function (data) { return data.connectionName; },
    __AUTH0_API_IDENTIFIER__: function (data) { return data.apiIdentifier; },
    // modify manage urls with # sign to match new urls
    'https://__AUTH0_MANAGE_URL__/#': function (data) { return data.manage_url; },
    // modify manage urls without # sign
    'https://__AUTH0_MANAGE_URL__': function (data) { return data.manage_url; },
};
/**
 * This replaces placeholders in document content with the actual values
 * for the current user.
 */
export default function replaceUserVars(html, context) {
    var transformed = html;
    Object.keys(VARS).forEach(function (key) {
        var value = xssFilters.inHTMLData(VARS[key](context));
        // eslint-disable-next-line security/detect-non-literal-regexp
        transformed = transformed.replace(new RegExp(key, 'g'), value);
    });
    return transformed;
}
