var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useOnScreen } from '@a0/docs-components/hooks/useOnScreen';
import metrics from '@a0/docs-components/metrics';
import { Autocomplete, Avatar, AvatarBlock, Input, SearchIcon, styled, useTheme } from '@auth0/quantum-product';
import { navigateAction } from 'fluxible-router';
import { debounce } from 'lodash';
import * as React from 'react';
import performSearchAction from '../../action/performSearch';
import SearchService from '../../services/SearchService.client';
import { useFluxible } from '../hooks/useFluxible';
import { getIdenticonNameFromSection, Identicon } from '../Identicon';
import { ContentContainer } from '../layouts';
var getOptionLabel = function (result) { return result.title; };
var filterOptions = function (options) { return options.filter(function (opt) { return opt.title && opt.uri; }); };
var isOptionEqualToValue = function (option, value) { return option.title === value.title; };
var getSectionFromUri = function (uri) {
    var path = uri.split('/docs/')[1];
    if (!path) {
        return 'home';
    }
    return path.split('/')[0];
};
var OptionsListItem = styled('li')(function (_a) {
    var theme = _a.theme;
    return ({
        '& .QuantumTitleBlock-description': {
            color: theme.palette.text.primary,
        },
    });
});
var SearchInput = styled(Input)(function (_a) {
    var theme = _a.theme, placement = _a.placement;
    if (placement === 'homepage') {
        return {
            height: theme.spacing(8),
            borderRadius: theme.spacing(3),
            fontSize: theme.typography.body1.fontSize,
        };
    }
    if (placement === 'header' || placement === 'drawer') {
        return {
            width: 'auto',
            height: theme.spacing(4.25),
        };
    }
    return {};
});
var SearchWrapper = styled(ContentContainer)(function (_a) {
    var _b;
    var theme = _a.theme, placement = _a.placement, _c = _a.isFocused, isFocused = _c === void 0 ? false : _c;
    if (placement === 'header' || placement === 'drawer') {
        return _b = {
                width: isFocused ? 'max(260px, 60%)' : 260,
                maxWidth: 'auto',
                margin: 0
            },
            _b[theme.breakpoints.down(theme.layout.tabletBreakpoint + theme.spacingPX(5))] = {
                display: 'none',
            },
            _b;
    }
    return {};
});
export var SearchBox = function (_a) {
    var _b = _a.placeholder, placeholder = _b === void 0 ? 'Search the docs' : _b, _c = _a.text, text = _c === void 0 ? '' : _c, _d = _a.placement, placement = _d === void 0 ? 'header' : _d, _e = _a.numberOfResults, numberOfResults = _e === void 0 ? 6 : _e, onChange = _a.onChange;
    var lastSearch = React.useRef();
    var inputElRef = React.useRef(null);
    var _f = __read(React.useState(text), 2), textState = _f[0], setTextState = _f[1];
    var _g = __read(React.useState([]), 2), options = _g[0], setOptions = _g[1];
    var _h = __read(React.useState(false), 2), focused = _h[0], setFocused = _h[1];
    var _j = __read(React.useState(false), 2), loading = _j[0], setLoading = _j[1];
    var executeAction = useFluxible().executeAction;
    var theme = useTheme();
    var isVisible = useOnScreen(inputElRef.current, placement === 'homepage' ? "-".concat(theme.layout.appBarHeight, "px") : '0px');
    var handleOnChange = function (value) {
        if (onChange) {
            onChange(value);
        }
    };
    var handleSubmit = function (evt) {
        evt.preventDefault();
        if (inputElRef.current) {
            inputElRef.current.blur();
        }
        executeAction(performSearchAction, { query: textState });
        handleOnChange(textState);
    };
    var searchResults = React.useCallback(debounce(function (query) { return __awaiter(void 0, void 0, void 0, function () {
        var requestTime, _a, results, searchQueryUid, responseTime;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    requestTime = new Date().getTime();
                    return [4 /*yield*/, SearchService.search({
                            query: query,
                            page: 1,
                            numberOfResults: numberOfResults,
                        })];
                case 1:
                    _a = _b.sent(), results = _a.results, searchQueryUid = _a.searchUid;
                    responseTime = new Date().getTime() - requestTime;
                    SearchService.logSearchEvent({
                        query: query,
                        page: 1,
                        searchQueryUid: searchQueryUid,
                        responseTime: responseTime,
                    });
                    lastSearch.current = { query: query, searchQueryUid: searchQueryUid, results: results };
                    setOptions(results);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, 500), [numberOfResults]);
    React.useEffect(function () {
        setTextState(text);
    }, [text]);
    React.useEffect(function () {
        if (focused && inputElRef.current) {
            inputElRef.current.select();
        }
    }, [focused]);
    React.useEffect(function () {
        if (textState) {
            setLoading(true);
            searchResults(textState);
        }
        else {
            setOptions([]);
        }
    }, [textState]);
    React.useEffect(function () {
        if (!isVisible) {
            setTextState('');
            setOptions([]);
        }
    }, [isVisible]);
    var handleSearchFocus = function () {
        setFocused(true);
        metrics.track('onFocus:docs:search-action');
    };
    return (React.createElement("form", { id: "search", role: "search", autoComplete: "off", style: { width: '100%' }, onSubmit: handleSubmit },
        React.createElement(Autocomplete, { id: "search-box", blurOnSelect: true, disablePortal: true, loading: loading, options: options, inputValue: textState, filterOptions: filterOptions, getOptionLabel: getOptionLabel, isOptionEqualToValue: isOptionEqualToValue, loadingText: "Loading results...", noOptionsText: 'There were no results for this search. Please try again.', componentsProps: {
                popper: { open: !!textState },
            }, onInputChange: function (event, value, reason) {
                if (reason === 'input') {
                    setTextState(value);
                }
            }, onChange: function (event, selectedOption) {
                if (selectedOption) {
                    setTextState('');
                    handleOnChange('');
                    SearchService.recordClickthrough({
                        query: lastSearch.current.query,
                        searchQueryUid: lastSearch.current.searchQueryUid,
                        sourceName: selectedOption.raw.source,
                        documentPosition: lastSearch.current.results.findIndex(function (result) { return result.uri === selectedOption.uri; }) + 1,
                        documentUri: selectedOption.uri,
                        documentUriHash: selectedOption.raw.urihash,
                    });
                    var url = selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.uri;
                    var relativePath = url.includes('auth0.com/docs') && (url === null || url === void 0 ? void 0 : url.split('auth0.com')[1]);
                    if (relativePath) {
                        executeAction(navigateAction, { url: relativePath });
                    }
                    else if (url) {
                        executeAction(navigateAction, { url: url });
                    }
                }
            }, renderInput: function (params) {
                return (React.createElement(SearchWrapper, { placement: placement, isFocused: focused, ref: params.InputProps.ref },
                    React.createElement(SearchInput, { type: "text", id: "search-input", inputRef: inputElRef, placement: placement, placeholder: placeholder, inputProps: params.inputProps, onFocus: handleSearchFocus, onBlur: function () { return setFocused(false); }, size: placement === 'header' ? 'small' : 'medium', startAdornment: React.createElement(SearchIcon, { size: 18, onClick: function () { return setFocused(true); }, style: { margin: placement === 'homepage' ? theme.spacing(0, 1, 0, 2) : theme.spacing(0, 1) } }) })));
            }, renderOption: function (props, _a) {
                var title = _a.title, uri = _a.uri, description = _a.raw.description;
                var section = getSectionFromUri(uri);
                var iconName = getIdenticonNameFromSection(section);
                return (React.createElement(OptionsListItem, __assign({}, props, { key: uri }),
                    React.createElement(AvatarBlock, { title: title, description: placement === 'homepage' ? description : undefined, avatar: React.createElement(Avatar, { type: "resource", size: placement === 'header' ? 'small' : 'medium' }, iconName && React.createElement(Identicon, { color: "primary", name: iconName })) })));
            } })));
};
