var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { styled } from '@auth0/quantum-product';
import * as React from 'react';
import { Sticky, StickyContainer } from 'react-sticky';
import { ApiFeedback, useApiFeedback } from '../../ApiFeedback';
import { ContentStyler } from '../../ContentRenderer';
import { PortalsRenderer } from '../../PortalsRenderer';
import { OverlaySpinner } from '../../Spinner';
var AuthApiContent = styled(ContentStyler)(function (_a) {
    var _b, _c;
    var theme = _a.theme;
    return (__assign(__assign({}, theme.typography.body1), { letterSpacing: -0.01, '& h1': {
            fontSize: "".concat(theme.typography.pxToRem(40), " !important"),
            lineHeight: "".concat(theme.typography.pxToRem(44), " !important"),
        }, '& ul > li': {
            listStyleType: 'none',
        }, '& .btn-primary': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                textDecoration: 'none',
                backgroundColor: theme.palette.primary.dark,
            },
            '&:active': {
                backgroundColor: theme.palette.primary.dark,
            },
        }, '& .label-primary': {
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.light,
        }, '& .label-danger': {
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main,
        }, '& .badge-info': {
            backgroundColor: theme.palette.success.main,
        }, '& .badge-danger': {
            backgroundColor: theme.palette.error.main,
        }, 
        // Alert portal
        '& .alert-portal-container': (_b = {
                marginRight: 40,
                marginLeft: 40
            },
            _b[theme.breakpoints.up(768)] = {
                marginRight: 'calc(50% + 40px)',
            },
            _b), 
        // Title portal
        '& .title-portal-container': (_c = {
                paddingRight: 40,
                paddingLeft: 40,
                display: 'flex',
                alignItems: 'baseline',
                gap: 0.5,
                transform: "translateX(-".concat(theme.spacing(2.5), ")")
            },
            _c[theme.breakpoints.up(768)] = {
                marginRight: '50%',
            },
            _c), '& .title-portal-icon': {
            opacity: 0,
        }, '& .title-portal-text': {
            marginTop: theme.spacing(1.5),
            paddingTop: theme.spacing(2),
            marginBottom: theme.spacing(1.25),
        }, 
        // Alert portal
        '& .alert-content > p': __assign(__assign({}, theme.typography.body2), { letterSpacing: 0 }), '& .alert-content bold, .alert-content strong': {
            fontWeight: theme.typography.fontWeightMedium,
        }, '& .alert-content > p > a': {
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.text.primary,
        }, '& .alert-content > p > a:hover': {
            textDecoration: 'none',
        }, '& code .alert-content': {
            lineHeight: theme.typography.pxToRem(18),
            letterSpacing: 0.25,
        }, '& .feedback-container': {
            width: 'min-content',
            whiteSpace: 'nowrap',
            marginLeft: theme.spacing(5),
            cursor: 'pointer',
            // Compensate for missing ssr style
            '& .MuiButton-sizeSmall': {
                padding: theme.spacing(0, 1),
                color: theme.tokens.color_fg_link_primary,
            },
        } }));
});
export var ApiExplorer = function (_a) {
    var _b = _a.doc, doc = _b === void 0 ? {} : _b, title = _a.title, languages = _a.languages;
    var _c = __read(React.useState(true), 2), loading = _c[0], setLoading = _c[1];
    var _d = useApiFeedback(), feedbackContainer = _d.feedbackContainer, feedbackHTML = _d.feedbackHTML, renderFeedback = _d.renderFeedback;
    // Inject feedback placeholders into DOM
    React.useEffect(function () {
        var _a;
        if (!feedbackHTML) {
            return;
        }
        if (doc.html || doc.meta) {
            // Delay jQuery init until after html render
            var moreInfoNodes = (_a = document.querySelector('.api-content')) === null || _a === void 0 ? void 0 : _a.querySelectorAll('h3[id="more-information"]');
            moreInfoNodes === null || moreInfoNodes === void 0 ? void 0 : moreInfoNodes.forEach(function (node) {
                var _a;
                var moreInfoList = (_a = node.closest('div[id^=portal-title]')) === null || _a === void 0 ? void 0 : _a.nextElementSibling;
                if (moreInfoList) {
                    var div = document.createElement('div');
                    div.className = 'feedback-container';
                    // inline margin otherwise jQuery scroll calculations are off
                    div.style.marginTop = '24px';
                    // Replace placeholder with portal as soon as mouse enters container
                    div.addEventListener('mouseenter', renderFeedback);
                    div.innerHTML = window.DOMPurify ? window.DOMPurify.sanitize(feedbackHTML) : feedbackHTML;
                    moreInfoList.insertAdjacentElement('afterend', div);
                }
            });
            setTimeout(function () {
                window.initApiExplorer(languages);
            });
            setLoading(false);
        }
    }, [doc, feedbackHTML]);
    return (React.createElement(React.Fragment, null,
        loading && React.createElement(OverlaySpinner, null),
        React.createElement(StickyContainer, null,
            React.createElement("section", { className: "content api-explorer" },
                React.createElement("div", { className: "api-sidebar tocify-wrapper" },
                    React.createElement("div", { className: "sticky-nav-placeholder" },
                        React.createElement(Sticky, null, function (_a) {
                            var style = _a.style;
                            return (React.createElement("div", { style: style },
                                React.createElement("div", { className: "sticky-nav" },
                                    React.createElement("div", { className: "sticky-nav-selected" },
                                        React.createElement("span", null, "Menu"),
                                        React.createElement("i", { className: "icon-budicon-460" })),
                                    React.createElement("div", { id: "toc" },
                                        React.createElement("div", { className: "title" }, title)))));
                        }))),
                React.createElement("div", { className: "page-wrapper" },
                    React.createElement("div", { className: "dark-box" }),
                    React.createElement("div", { className: "lang-selector-container" },
                        React.createElement(Sticky, null, function (_a) {
                            var isSticky = _a.isSticky, style = _a.style;
                            return (React.createElement("div", { style: style, className: isSticky ? 'sticky lang-mobile-sticky' : '' },
                                React.createElement("div", { className: "lang-selector" },
                                    React.createElement("div", { className: "lang-selector-selected" },
                                        React.createElement("span", { className: "language-label" }, "Language:"),
                                        React.createElement("span", { className: "language js-selected-language" }),
                                        React.createElement("i", { className: "icon-budicon-460" })),
                                    languages.map(function (language) { return (React.createElement("a", { href: "#".concat(language.key), key: language.key, "data-language-name": language.key }, language.name)); }))));
                        })),
                    React.createElement(AuthApiContent, { className: "api-content", dangerouslySetInnerHTML: { __html: doc.html } }),
                    React.createElement(PortalsRenderer, { portals: doc.meta.portals, meta: doc.meta }),
                    feedbackContainer && React.createElement(ApiFeedback, { trackingCode: "docs:auth-api", container: feedbackContainer }))))));
};
