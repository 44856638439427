import * as React from 'react';

const CommunityIcon = () => {
  return (
    <svg version="1.1" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <circle id="a" cx="20" cy="20" r="20" />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <g transform="translate(-244 -1670)">
          <g transform="translate(246 1672)">
            <mask id="b" fill="white">
              <use xlinkHref="#a" />
            </mask>
            <use fill="#FFFFFF" xlinkHref="#a" />
            <g fill="#111113" mask="url(#b)">
              <g transform="translate(3 8)">
                <circle cx="28" cy="9" r="4" />
                <circle cx="6" cy="9" r="4" />
                <rect y="15" width="12" height="13" rx="4" />
                <rect x="22" y="15" width="12" height="13" rx="4" />
                <rect x="7" y="13" width="20" height="22" rx="8" stroke="#fff" strokeWidth="3" />
                <circle cx="17" cy="6" r="6" />
              </g>
            </g>
            <circle cx="20" cy="20" r="20" stroke="#111113" strokeWidth="3" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CommunityIcon;
