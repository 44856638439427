var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Dialog, DialogContent, DialogTitle, styled } from '@auth0/quantum-product';
import * as React from 'react';
var ImgContainer = styled('img')({
    width: '100%',
    height: '100%',
});
export var useImageMagnify = function (containerNode) {
    var _a = __read(React.useState(''), 2), imgSrc = _a[0], setImgSrc = _a[1];
    React.useEffect(function () {
        // queryImages and attachHandler
        var imageElements = containerNode === null || containerNode === void 0 ? void 0 : containerNode.querySelectorAll('img');
        imageElements === null || imageElements === void 0 ? void 0 : imageElements.forEach(function (image) {
            image.style.cursor = 'pointer';
        });
        //On back page click, close dialog.
        var backClickListener = function () {
            setImgSrc('');
        };
        var imgClickListener = function (event) {
            var _a;
            var target = event.target;
            //Note: video place holders has a src for wistia.com. We need to ignore this video placeholder to prevent magnification
            if (target.nodeName === 'IMG' && ((_a = target.parentNode) === null || _a === void 0 ? void 0 : _a.nodeName) !== 'A' && !target.src.includes('wistia.com')) {
                setImgSrc(target.src);
            }
        };
        window.addEventListener('popstate', backClickListener);
        containerNode === null || containerNode === void 0 ? void 0 : containerNode.addEventListener('click', imgClickListener);
        return function () {
            containerNode === null || containerNode === void 0 ? void 0 : containerNode.removeEventListener('click', imgClickListener);
            window.removeEventListener('popstate', backClickListener);
        };
    }, [containerNode]);
    return [imgSrc, setImgSrc];
};
export var ImageMagnify = function (_a) {
    var imgSrc = _a.imgSrc, onClose = _a.onClose;
    var _b = __read(React.useState(imgSrc), 2), currentImgSrc = _b[0], setCurrentImgSrc = _b[1];
    React.useEffect(function () {
        if (imgSrc) {
            setCurrentImgSrc(imgSrc);
        }
    }, [imgSrc]);
    return (React.createElement(Dialog, { open: !!imgSrc, fullWidth: false, maxWidth: "lg", disableContentWrap: true, onClose: function () { return onClose(); } },
        React.createElement(DialogTitle, { onClose: function () { return onClose(); } }),
        React.createElement(DialogContent, null,
            React.createElement(ImgContainer, { src: currentImgSrc, alt: "Placeholder" }))));
};
