import * as React from 'react';
import styles from './styles.module.styl';
var windowExists = typeof window !== 'undefined';
var ENV = windowExists ? window.env.NODE_ENV : process.env.NODE_ENV;
export var EditLink = function (_a) {
    var contentfulId = _a.contentfulId, githubEditLink = _a.githubEditLink, trackData = _a.trackData;
    var content;
    if (contentfulId && ENV !== 'production') {
        content = (React.createElement(React.Fragment, null,
            React.createElement("span", null, "Any suggestion or typo? "),
            React.createElement("a", { className: styles.editLink, href: "https://app.contentful.com/spaces/cdy7uua7fh8z/entries/".concat(contentfulId), onClick: function () { return trackData('click:edit-contentful'); }, target: "_blank", rel: "noreferrer noopener nofollow" }, "Edit on Contentful")));
    }
    else if (githubEditLink) {
        content = (React.createElement(React.Fragment, null,
            React.createElement("span", null, "Any suggestion or typo? "),
            React.createElement("a", { className: styles.editLink, href: githubEditLink, onClick: function () { return trackData('click:edit-github'); }, target: "_blank", rel: "noreferrer noopener nofollow" }, "Edit on GitHub")));
    }
    return React.createElement("div", { className: styles.suggestions }, content);
};
