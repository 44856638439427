import * as React from 'react';
import { PortalsRenderer } from '../PortalsRenderer';
import { ContentStyler } from './ContentStyler';
var noop = function () { }; // eslint-disable-line @typescript-eslint/no-empty-function
export var ContentRenderer = function (_a) {
    var html = _a.html, _b = _a.meta, meta = _b === void 0 ? {} : _b, _c = _a.afterRender, afterRender = _c === void 0 ? noop : _c, _d = _a.styleContent, styleContent = _d === void 0 ? false : _d;
    var containerRef = React.useRef(null);
    var StyledContent = styleContent ? ContentStyler : 'div';
    React.useEffect(function () {
        afterRender(containerRef.current);
    }, [html]);
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledContent, { ref: containerRef, dangerouslySetInnerHTML: { __html: html } }),
        React.createElement(PortalsRenderer, { portals: meta.portals, meta: meta })));
};
