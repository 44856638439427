import { connectToStores } from 'fluxible-addons-react';
import NavigationStore from '../../stores/NavigationStore';
import canUseStore from '../../util/canUseStore';
import { DefinitionTooltip } from './DefinitionTooltip';
export default connectToStores(DefinitionTooltip, [NavigationStore], function (context, _a) {
    var dataKey = _a.dataKey;
    if (!canUseStore(context))
        return {};
    var glossary = context.getStore(NavigationStore).glossary;
    var term = glossary && dataKey && glossary.termsById[dataKey];
    return { term: term };
});
export * from './styles';
