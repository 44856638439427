import NavigationStore from '../stores/NavigationStore';
export default function loadCards(context) {
    var logger = context.getService('LoggingService');
    var success = function (_a) {
        var cards = _a.cards;
        context.dispatch('CARDS_LOAD_SUCCESS', { cards: cards });
        logger.debug('Cards loaded successfully.');
        return Promise.resolve();
    };
    var failure = function (err) {
        context.dispatch('CARDS_LOAD_FAILURE', { err: err });
        logger.warn('Error loading cards.', { err: err });
        return Promise.resolve();
    };
    // First, check to see if the content has already been loaded.
    var cards = context.getStore(NavigationStore).getCards();
    if (cards) {
        return success({ cards: cards });
    }
    // If the cards haven't been loaded (or a previous load resulted in
    // an error), try to load it using the NavigationService.
    context.dispatch('CARDS_LOADING');
    return context.getService('NavigationService').loadCards().then(success).catch(failure);
}
