var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import metrics from '@a0/docs-components/metrics';
import jwtDecode from 'jwt-decode';
var SEARCH_TOKEN_PATH = '/docs/search/token';
var SEARCH_TOKEN_LOCAL_STORAGE_KEY = 'a0_docs_search_token';
var MS_IN_HOUR = 1000 * 60 * 60;
var BAD_REQUEST_RETRY_TYPES = [
    'InvalidToken',
    'ExpiredTokenException',
    'UnauthorizedHub',
    'UnauthorizedOrg',
    'NoWorkgroupAssociatedWithToken',
];
var postRequest = function (_a) {
    var url = _a.url, token = _a.token, body = _a.body;
    return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, fetch(url, {
                        method: 'POST',
                        headers: new Headers({
                            'Content-Type': 'application/json',
                            Accept: 'application/json',
                            Authorization: "Bearer ".concat(token),
                        }),
                        body: JSON.stringify(body),
                    })];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, response.json()];
            }
        });
    });
};
var SearchService = /** @class */ (function () {
    function SearchService() {
    }
    SearchService.prototype.shouldRefreshToken = function (token) {
        if (!token) {
            return true;
        }
        var expirationTime;
        try {
            var exp = jwtDecode(token).exp;
            if (!exp) {
                return true;
            }
            expirationTime = exp;
        }
        catch (e) {
            console.warn('Removing Invalid Token', e);
            localStorage.removeItem(SEARCH_TOKEN_LOCAL_STORAGE_KEY);
            return true;
        }
        var msUntilExpiration = new Date(expirationTime * 1000).getTime() - new Date().getTime();
        var hoursUntilExpiration = msUntilExpiration / MS_IN_HOUR;
        if (hoursUntilExpiration < 2) {
            return true;
        }
        return false;
    };
    SearchService.prototype.getSearchToken = function () {
        return __awaiter(this, void 0, void 0, function () {
            var searchToken, response, token;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        searchToken = localStorage.getItem(SEARCH_TOKEN_LOCAL_STORAGE_KEY) || '';
                        if (!(!searchToken || this.shouldRefreshToken(searchToken))) return [3 /*break*/, 3];
                        return [4 /*yield*/, fetch(SEARCH_TOKEN_PATH)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        token = (_a.sent()).token;
                        if (!token) {
                            throw new Error('Failed to fetch search token');
                        }
                        localStorage.setItem(SEARCH_TOKEN_LOCAL_STORAGE_KEY, token);
                        searchToken = token;
                        _a.label = 3;
                    case 3: return [2 /*return*/, searchToken];
                }
            });
        });
    };
    SearchService.prototype.makeRequest = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var token, response, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getSearchToken()];
                    case 1:
                        token = _a.sent();
                        return [4 /*yield*/, postRequest(__assign(__assign({}, params), { token: token }))];
                    case 2:
                        response = _a.sent();
                        if (!(response.statusCode === 401 || // Unauthorized
                            response.statusCode === 419 || // Expired Token
                            BAD_REQUEST_RETRY_TYPES.includes(response.type))) return [3 /*break*/, 5];
                        localStorage.removeItem(SEARCH_TOKEN_LOCAL_STORAGE_KEY);
                        return [4 /*yield*/, this.getSearchToken()];
                    case 3:
                        token = _a.sent();
                        return [4 /*yield*/, postRequest(__assign(__assign({}, params), { token: token }))];
                    case 4:
                        response = _a.sent();
                        _a.label = 5;
                    case 5:
                        if (response.statusCode >= 400) {
                            error = new Error(response.message);
                            error.statusCode = response.status;
                            console.error('Error in search request', error);
                            throw error;
                        }
                        return [2 /*return*/, response];
                }
            });
        });
    };
    SearchService.prototype.search = function (_a) {
        var _b, _c;
        var query = _a.query, page = _a.page, _d = _a.numberOfResults, numberOfResults = _d === void 0 ? 10 : _d;
        return __awaiter(this, void 0, void 0, function () {
            var requestUrl, body, response;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        requestUrl = "".concat(window.env.COVEO_SEARCH_URL, "?organizationId=").concat(window.env.COVEO_ORG_ID);
                        body = {
                            q: query,
                            numberOfResults: numberOfResults,
                            firstResult: (page - 1) * numberOfResults,
                        };
                        return [4 /*yield*/, this.makeRequest({ url: requestUrl, body: body })];
                    case 1:
                        response = _e.sent();
                        if (!(((_b = response === null || response === void 0 ? void 0 : response.results) === null || _b === void 0 ? void 0 : _b.length) === 0 && ((_c = response === null || response === void 0 ? void 0 : response.queryCorrections) === null || _c === void 0 ? void 0 : _c.length) > 0)) return [3 /*break*/, 3];
                        // @TODO - "did you mean" feature can be better implemented (eg: let the user know the query correction)
                        body.q = response.queryCorrections[0].correctedQuery;
                        return [4 /*yield*/, this.makeRequest({ url: requestUrl, body: body })];
                    case 2:
                        response = _e.sent();
                        _e.label = 3;
                    case 3: return [2 /*return*/, __assign(__assign({}, response), { numberOfResults: numberOfResults, pageCount: Math.ceil((response === null || response === void 0 ? void 0 : response.totalCount) / body.numberOfResults) })];
                }
            });
        });
    };
    SearchService.prototype.logSearchEvent = function (_a) {
        var query = _a.query, page = _a.page, searchQueryUid = _a.searchQueryUid, responseTime = _a.responseTime, _b = _a.actionCause, actionCause = _b === void 0 ? 'searchboxSubmit' : _b;
        return __awaiter(this, void 0, void 0, function () {
            var requestUrl, requestParams;
            return __generator(this, function (_c) {
                requestUrl = "".concat(window.env.COVEO_ANALYTICS_URL, "/analytics/search?org=").concat(window.env.COVEO_ORG_ID);
                requestParams = {
                    actionCause: actionCause,
                    responseTime: responseTime,
                    language: 'en',
                    queryText: query,
                    searchQueryUid: searchQueryUid,
                    anonymous: true,
                };
                this.makeRequest({ url: requestUrl, body: requestParams });
                metrics.track('submit:docs:search-action', { trackData: query, track_data2: page });
                return [2 /*return*/];
            });
        });
    };
    SearchService.prototype.recordClickthrough = function (_a) {
        var query = _a.query, searchQueryUid = _a.searchQueryUid, sourceName = _a.sourceName, documentPosition = _a.documentPosition, documentUri = _a.documentUri, documentUriHash = _a.documentUriHash, _b = _a.actionCause, actionCause = _b === void 0 ? 'documentOpen' : _b;
        return __awaiter(this, void 0, void 0, function () {
            var requestUrl, requestParams;
            return __generator(this, function (_c) {
                requestUrl = "".concat(window.env.COVEO_ANALYTICS_URL, "/analytics/click?org=").concat(window.env.COVEO_ORG_ID);
                requestParams = {
                    actionCause: actionCause,
                    documentPosition: documentPosition,
                    documentUri: documentUri,
                    documentUriHash: documentUriHash,
                    language: 'en',
                    searchQueryUid: searchQueryUid,
                    sourceName: sourceName,
                    anonymous: true,
                };
                this.makeRequest({ url: requestUrl, body: requestParams });
                metrics.track('click:docs:search:search-result', { trackData: query, track_data2: documentUri });
                return [2 /*return*/];
            });
        });
    };
    return SearchService;
}());
export default new SearchService();
