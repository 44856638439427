import * as DEFAULTS from '../../lib/helpers/defaults';

const getAccountContext = (userAccount, apiIdentifier, selectedClient) => {
  const account = { ...userAccount };

  if (apiIdentifier) {
    account.apiIdentifier = apiIdentifier;
  }

  const accountDecorated = selectedClient
    ? {
        appName: selectedClient.name,
        clientId: selectedClient.client_id,
        clientSecret: selectedClient.client_secret,
        callback: selectedClient.callbacks ? selectedClient.callbacks[0] : DEFAULTS.CALLBACK,
      }
    : {
        appName: DEFAULTS.APP_NAME,
        clientId: DEFAULTS.CLIENT_ID,
        clientSecret: DEFAULTS.CLIENT_SECRET,
        callback: DEFAULTS.CALLBACK,
      };

  return { ...account, ...accountDecorated };
};

export {
  // eslint-disable-next-line import/prefer-default-export
  getAccountContext,
};
