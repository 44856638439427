import { connectToStores } from 'fluxible-addons-react';
import { RouteStore } from 'fluxible-router';
import NavigationStore from '../../../stores/NavigationStore';
import SearchStore from '../../../stores/SearchStore';
import { SearchPage } from './SearchPage';
export default connectToStores(SearchPage, [SearchStore, RouteStore, NavigationStore], function (_a) {
    var getStore = _a.getStore;
    var _b = getStore(RouteStore).getCurrentRoute().query, query = _b.q, page = _b.page;
    return {
        query: query,
        page: page ? +page : 1,
        search: query ? getStore(SearchStore).getSearch({ query: query, page: page }) : undefined,
        terms: getStore(NavigationStore).getGlossary().terms,
    };
});
