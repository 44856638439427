var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Card, CardHeader, RowLayout, styled } from '@auth0/quantum-product';
import * as React from 'react';
import { Identicon } from '../Identicon';
import { NavLink } from '../NavLink';
import { MetricsId, withMetricsId } from '../WithMetricsId';
var StyledCard = styled(Card)(function (_a) {
    var theme = _a.theme;
    return ({
        borderRadius: theme.spacing(2),
    });
});
var StyledIdenticon = styled(Identicon)(function (_a) {
    var theme = _a.theme;
    return ({
        marginRight: theme.spacing(2),
    });
});
var NavCardComponent = function (_a) {
    var title = _a.title, description = _a.description, url = _a.url, icon = _a.icon, props = __rest(_a, ["title", "description", "url", "icon"]);
    return (React.createElement(NavLink, __assign({}, props, { href: url }),
        React.createElement(StyledCard, { button: true, size: "xlarge" },
            React.createElement(CardHeader, { avatar: icon && React.createElement(StyledIdenticon, { name: icon, color: "primary", fontSize: 48 }), title: title, subheader: description, titleTypographyProps: { color: 'text.primary', variant: 'h5' }, subheaderTypographyProps: { variant: 'h6', fontWeight: 'regular' } }))));
};
export var NavCard = withMetricsId(NavCardComponent, MetricsId.NavigationCard);
export var NavCards = function (_a) {
    var items = _a.items;
    return (React.createElement(RowLayout, { gutter: 2 }, items === null || items === void 0 ? void 0 : items.map(function (_a) {
        var title = _a.title, description = _a.description, url = _a.url, icon = _a.icon;
        return (React.createElement(NavCard, { key: url, title: title, description: description, url: url, icon: icon }));
    })));
};
