import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CodeHighlight from '../../CodeHighlight';
import changeTargetLinks from '../../../browser/targetLinks';
import replaceUserVars from '../../../../lib/replaceUserVars';
import styles from './styles.module.styl';

class DownloadInstructions extends Component {
  constructor() {
    super();
    this.downloadInstructionsWrapper = null;
    this.setDownloadInstructionsRef = (elem) => {
      this.downloadInstructionsWrapper = elem;
    };
  }

  componentDidMount() {
    changeTargetLinks(this.downloadInstructionsWrapper, this.props.isFramedMode);
  }

  render() {
    const { content, userContext } = this.props;

    return (
      <CodeHighlight>
        <div
          className={styles.downloadInstructionsWrapper}
          ref={this.setDownloadInstructionsRef}
          dangerouslySetInnerHTML={{ __html: replaceUserVars(content, userContext) }}
        />
      </CodeHighlight>
    );
  }
}

DownloadInstructions.propTypes = {
  content: PropTypes.string.isRequired,
  userContext: PropTypes.object.isRequired,
  isFramedMode: PropTypes.bool.isRequired,
};

export default DownloadInstructions;
