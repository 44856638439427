import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.styl';
import { performSelectApi } from '../../../action/performUserResourceActions';

function ApiSelector({ handleSelected, createApiUrl, userApis, selectedApiId }) {
  const handleChange = (e) => {
    const value = e.target.value;

    if (value === 'create_api') {
      const win = window.open(createApiUrl, '_blank');
      win.focus();
    } else if (value !== 'disabled') {
      handleSelected(performSelectApi, value);
    }
  };

  return (
    <div className={`${styles.formGroup} form-group`}>
      <label htmlFor="qs-download-modal-api" className="control-label">
        API
      </label>
      <select
        name="qs-download-modal-api"
        id="qs-download-modal-api"
        className="form-control"
        onChange={handleChange}
        value={userApis.length ? selectedApiId : 'disabled'}
      >
        {!userApis.length && [
          <option key="disabled" value="disabled" disabled>
            No Apis Available
          </option>,
          <option key="create_api" value="create_api">
            Create an API
          </option>,
        ]}

        {userApis.map((api) => (
          <option key={api.id} value={api.id}>
            {api.name}
          </option>
        ))}
      </select>
    </div>
  );
}

ApiSelector.propTypes = {
  handleSelected: PropTypes.func.isRequired,
  createApiUrl: PropTypes.string.isRequired,
  userApis: PropTypes.array.isRequired,
  selectedApiId: PropTypes.string,
};

export default ApiSelector;
