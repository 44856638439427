import React from 'react';
import PropTypes from 'prop-types';
import ApiExplorer from './ApiExplorer';

const LANGUAGES = [
  { key: 'http', name: 'HTTP' },
  { key: 'shell', name: 'Shell' },
  { key: 'javascript', name: 'JavaScript' },
];

const AuthExtensionPage = (props) => <ApiExplorer {...props} title="Authorization Extension" languages={LANGUAGES} />;

AuthExtensionPage.propTypes = {
  doc: PropTypes.object,
  currentRoute: PropTypes.object,
};

export default AuthExtensionPage;
