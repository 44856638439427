import { RowLayout, Text } from '@auth0/quantum-product';
import * as React from 'react';
import { SearchResultState } from '../../../../stores/SearchStore';
import { Spinner } from '../../../Spinner';
import { SearchResult } from './index';
export var SearchResults = function (_a) {
    var state = _a.state, page = _a.page, results = _a.results, numberOfResults = _a.numberOfResults, count = _a.count, onSelect = _a.onSelect;
    var resultsComponent;
    if (!state) {
        resultsComponent = React.createElement(Text, { variant: "body1" }, "Please enter a query to search our documentation.");
    }
    else {
        switch (state) {
            case SearchResultState.LOADING:
                resultsComponent = React.createElement(Spinner, null);
                break;
            case SearchResultState.LOADED:
                if (count === 0) {
                    resultsComponent = React.createElement(Text, { variant: "body1" }, "No results found. Would you like to try another search term?");
                    break;
                }
                resultsComponent = results === null || results === void 0 ? void 0 : results.map(function (item, index) {
                    return (React.createElement(SearchResult, { key: item.uniqueId, title: item.title, url: item.url, description: item.description, crumbs: item.breadcrumbs, onClick: function () {
                            return onSelect({
                                sourceName: item.source,
                                documentPosition: index + 1 + numberOfResults * (page - 1),
                                documentUri: item.url,
                                documentUriHash: item.urihash,
                            });
                        } }));
                });
                break;
            case SearchResultState.ERROR:
                resultsComponent = React.createElement(Text, { variant: "body1" }, "There was an error loading the search result. Please try again.");
                break;
            default:
                throw new Error("Unknown search state ".concat(state));
        }
    }
    return (React.createElement(RowLayout, { gutter: 3, sx: { maxWidth: 1000 } }, resultsComponent));
};
