import { addEventProperties } from '@a0/docs-components/metrics';
import BaseStore from 'fluxible/addons/BaseStore';
import { get, groupBy, pick, sortBy } from 'lodash';

import { APP_TYPES } from '../util/clientTypesHelper';
import { getAccountContext } from './helpers';

class UserStore extends BaseStore {
  constructor(dispatcher) {
    super(dispatcher);
    this.user = null;
    this.userResources = {};
    this.profile = {};
    this.framedAccount = undefined;
    this.dispatcher = dispatcher;
  }

  isAuthenticated() {
    const isAuthenticated = (this.user && this.user.isAuthenticated) || !!this.framedAccount;
    addEventProperties({ isAuthenticated });
    return isAuthenticated;
  }

  getUser() {
    return this.user;
  }

  getUserProfile() {
    return this.profile;
  }

  getClients() {
    return this.userResources.nonGlobalClients || [];
  }

  getClientsByType() {
    return this.userResources.clientsSortedByType || {};
  }

  getApis() {
    return this.userResources.nonGlobalApis || [];
  }

  getSelectedClientId() {
    return this.userResources.selectedClientId;
  }

  getSelectedApiId() {
    return this.userResources.selectedApiId;
  }

  getSelectedClient() {
    return this.getClients().find((client) => client.client_id === this.getSelectedClientId());
  }

  getSelectedApi() {
    return this.getApis().find((api) => api.id === this.getSelectedApiId());
  }

  getSelectedApiIdentifier() {
    const selectedApi = this.getSelectedApi();
    return selectedApi ? selectedApi.identifier : undefined;
  }

  getSelectedApiName() {
    const selectedApi = this.getSelectedApi();

    return selectedApi ? selectedApi.name : undefined;
  }

  getSelectedClientName() {
    return this.framedAccount ? get(this.getUserAccount(), 'appName') : get(this.getSelectedClient(), 'name');
  }

  getUserAccount() {
    if (this.framedAccount) {
      return this.framedAccount;
    }

    return this.user ? this.user.account : null;
  }

  getUserContext() {
    const context = Object.assign({}, this.user);

    if (this.framedAccount) {
      context.account = this.framedAccount;
    } else if (context.account) {
      const apiIdentifier = this.getSelectedApiIdentifier();
      const selectedClient = this.getSelectedClient();
      context.account = getAccountContext(context.account, apiIdentifier, selectedClient);
    }

    return context;
  }

  getQuickstartCompatibleClientsByType(type) {
    return type ? pick(this.getClientsByType(), [APP_TYPES.generic, APP_TYPES[type]]) : this.getClientsByType();
  }

  getQuickstartCompatibleClients(type) {
    return type
      ? this.getClients().filter((c) => c.app_type === APP_TYPES.generic || APP_TYPES[c.app_type] === type)
      : this.getClients();
  }

  userHasApis() {
    return !!(this.userResources && this.userResources.nonGlobalApis && this.userResources.nonGlobalApis.length);
  }

  handleUserLoaded({ user, profile = this.profile, userResources = this.userResources }) {
    this.user = user;
    this.profile = profile;
    this.userResources = userResources;
    this.userResources.clientsSortedByType = groupBy(
      sortBy(userResources.nonGlobalClients, (c) => c.name.toLowerCase()),
      'app_type'
    );

    this.emitChange();
  }

  handleFrameDataLoad({ account }) {
    this.framedAccount = account;
    this.emitChange();
  }

  handleAddedClient(payload) {
    this.userResources.nonGlobalClients = [...this.userResources.nonGlobalClients, payload];
    this.userResources.clientsSortedByType = groupBy(
      sortBy(this.userResources.nonGlobalClients, (c) => c.name.toLowerCase()),
      'app_type'
    );

    this.emitChange();
  }

  handleAddedApi(payload) {
    this.userResources.nonGlobalApis = [...this.userResources.nonGlobalApis, payload];
    this.emitChange();
  }

  handleSelectedClient(payload) {
    this.userResources.selectedClientId = payload.selectedId;
    this.emitChange();
  }

  handleUpdatedClient(payload) {
    this.userResources.nonGlobalClients = this.userResources.nonGlobalClients.map((client) =>
      client.client_id === payload.client_id ? payload : client
    );
    this.userResources.clientsSortedByType = groupBy(
      sortBy(this.userResources.nonGlobalClients, (c) => c.name.toLowerCase()),
      'app_type'
    );

    this.emitChange();
  }

  handleSelectedApi(payload) {
    this.userResources.selectedApiId = payload.selectedId;
    this.emitChange();
  }

  dehydrate() {
    return {
      user: this.user,
      profile: this.profile,
      userResources: this.userResources,
      framedAccount: this.framedAccount,
    };
  }

  rehydrate({ user, profile, userResources, framedAccount }) {
    this.user = user;
    this.profile = profile;
    this.userResources = userResources;
    this.framedAccount = framedAccount;
  }
}

UserStore.storeName = 'UserStore';
UserStore.handlers = {
  USER_LOADED: 'handleUserLoaded',
  FRAME_DATA_LOADED: 'handleFrameDataLoad',
  USER_CLIENTS_SELECTED: 'handleSelectedClient',
  USER_CLIENTS_UPDATED: 'handleUpdatedClient',
  USER_APIS_SELECTED: 'handleSelectedApi',
  USER_CLIENTS_ADDED: 'handleAddedClient',
  USER_APIS_ADDED: 'handleAddedApi',
};

export default UserStore;
