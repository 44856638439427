import { Card, styled, Text } from '@auth0/quantum-product';
import * as React from 'react';
import { NavLink } from '../../../NavLink';
var WidgetContainer = styled(Card)(function (_a) {
    var theme = _a.theme;
    return ({
        maxWidth: 800,
        margin: theme.spacing(2.5, 2.5, 2.5, 0),
        padding: theme.spacing(1, 2),
    });
});
export var GlossaryTermWidget = function (_a) {
    var title = _a.title, definition = _a.definition;
    return (React.createElement(WidgetContainer, null,
        React.createElement(NavLink, { variant: "h5", color: "textSecondary", href: "/docs/glossary" }, "Glossary"),
        React.createElement(Text, { variant: "h4" }, title),
        React.createElement(Text, { variant: "body2", component: "span", dangerouslySetInnerHTML: { __html: definition } })));
};
