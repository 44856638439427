import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import requiredIf from 'react-required-if';
import { Sticky } from 'react-sticky';

import { QUANTUM_UI } from '../../../../flags';
import { BetaBadge } from '../../BetaBadge/BetaBadge.old';
import RequiredUserResourcesTooltip from './RequiredUserResourcesTooltip';
import styles from './styles.module.styl';

const buttonStyle = QUANTUM_UI ? 'btn-success-quantum-theme' : 'btn-success';

const StickyHeader = ({
  showSampleButtons,
  isAuthenticated,
  requiredResources,
  title,
  showDownloadModal,
  githubSampleLink,
  trackViewGithubSample,
  handleLogin,
  beta,
}) => (
  <Sticky disableCompensation>
    {({ isSticky }) => (
      <div className={cx(styles.stickyHeader, { [styles.isSticky]: isSticky })}>
        <div className={cx(styles.container, 'container')}>
          <h3 className={styles.title}>
            {title} {beta && <BetaBadge />}
          </h3>
          {showSampleButtons && (
            <div className={styles.buttons}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={githubSampleLink}
                onClick={trackViewGithubSample}
                className={`${styles.btn} btn btn-transparent`}
              >
                View on Github
              </a>
              {isAuthenticated ? (
                <RequiredUserResourcesTooltip requiredResources={requiredResources}>
                  <button
                    disabled={!!requiredResources}
                    onClick={showDownloadModal}
                    className={`${styles.btn} ${buttonStyle} btn`}
                  >
                    Download Sample
                  </button>
                </RequiredUserResourcesTooltip>
              ) : (
                <button onClick={handleLogin} className={`${styles.btn} ${buttonStyle} btn`}>
                  Log In & Download Sample
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    )}
  </Sticky>
);

StickyHeader.defaultProps = {
  isAuthenticated: false,
  showSampleButtons: false,
  beta: false,
};

StickyHeader.propTypes = {
  beta: PropTypes.bool,
  showSampleButtons: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  requiredResources: requiredIf(
    PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
    (props) => props.showSampleButtons
  ),
  title: PropTypes.string,
  showDownloadModal: requiredIf(PropTypes.string, (props) => props.showSampleButtons),
  githubSampleLink: requiredIf(PropTypes.string, (props) => props.showSampleButtons),
  trackViewGithubSample: requiredIf(PropTypes.string, (props) => props.showSampleButtons),
  handleLogin: requiredIf(PropTypes.string, (props) => props.showSampleButtons),
};

export default StickyHeader;
