var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Box, Button, RowLayout, TextField } from '@auth0/quantum-product';
import * as React from 'react';
export var CommentBox = function (_a) {
    var isPositive = _a.isPositive, submit = _a.submit;
    var _b = __read(React.useState(''), 2), message = _b[0], setMessage = _b[1];
    var placeholder = isPositive ? 'Great! How can it improve?' : 'Oh no! How can it improve?';
    return (React.createElement(RowLayout, { gutter: 2 },
        React.createElement(TextField, { fullWidth: true, multiline: true, rows: 4, value: message, placeholder: placeholder, onChange: function (e) { return setMessage(e.target.value); } }),
        React.createElement(Box, { display: "flex", justifyContent: "center" },
            React.createElement(Button, { color: "primary", onClick: function () { return submit(message); }, disabled: !message }, "Submit"))));
};
