import { get } from 'lodash';
import { MetricsEventType } from './MetricsEventType';
export var heapEvent = [
    MetricsEventType.AccordionPanelCollapse,
    MetricsEventType.AccordionPanelExpand,
    MetricsEventType.AccordionPanelTimeOpen,
    MetricsEventType.AccordionSearchExpandAll,
    MetricsEventType.ApiDocsLanguageSelection,
    MetricsEventType.TabPanelClick,
    MetricsEventType.TabPanelTimeOpen,
    MetricsEventType.FeedbackSubmitComment,
    MetricsEventType.FeedbackBooleanClick,
    MetricsEventType.DefinitionTooltipOpen,
    MetricsEventType.DefinitionTooltipClick,
    MetricsEventType.LoginButtonClick,
    MetricsEventType.SilentLoginTriggered,
];
var heapMock = {
    track: function (_ev, _params) {
        // ...
    },
    identify: function (_id) {
        // ...
    },
    resetIdentity: function () {
        // ...
    },
    addUserProperties: function (_properties) {
        // ...
    },
    addEventProperties: function (_properties) {
        // ...
    },
    removeEventProperty: function (_propertyName) {
        // ...
    },
    clearEventProperties: function () {
        // ...
    },
};
var Heap = /** @class */ (function () {
    function Heap() {
    }
    Object.defineProperty(Heap.prototype, "heap", {
        get: function () {
            if (typeof window === 'undefined') {
                return heapMock;
            }
            return get(window, 'heap', heapMock);
        },
        enumerable: false,
        configurable: true
    });
    // Heap-defined methods
    Heap.prototype.identify = function (uniqueIdentifier) {
        var _this = this;
        return this.safeHeapCall(function () { return _this.heap.identify(uniqueIdentifier); });
    };
    Heap.prototype.resetIdentity = function () {
        var _this = this;
        return this.safeHeapCall(function () { return _this.heap.resetIdentity(); });
    };
    Heap.prototype.track = function (eventName, properties) {
        var _this = this;
        return this.safeHeapCall(function () { return _this.heap.track(eventName, properties); });
    };
    Heap.prototype.addEventProperties = function (properties) {
        var _this = this;
        return this.safeHeapCall(function () { return _this.heap.addEventProperties(properties); });
    };
    Heap.prototype.addUserProperties = function (properties) {
        var _this = this;
        return this.safeHeapCall(function () { return _this.heap.addUserProperties(properties); });
    };
    Heap.prototype.clearEventProperties = function () {
        var _this = this;
        return this.safeHeapCall(function () { return _this.heap.clearEventProperties(); });
    };
    Heap.prototype.removeEventProperty = function (propertyName) {
        var _this = this;
        return this.safeHeapCall(function () { return _this.heap.removeEventProperty(propertyName); });
    };
    // Helpers
    Heap.prototype.safeHeapCall = function (call) {
        try {
            call();
        }
        catch (err) {
            console.error('Error while trying to call heap', err);
        }
    };
    return Heap;
}());
export { Heap };
