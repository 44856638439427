var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Button, Link, styled } from '@auth0/quantum-product';
import * as React from 'react';
import { login, signup } from '../../../../action/navigateAuthentication';
import { useFluxible } from '../../../hooks/useFluxible';
var Container = styled('div')(function () { return ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '75%',
}); });
var StyledButton = styled(Button)(function (_a) {
    var theme = _a.theme;
    return (__assign({ padding: theme.spacing(2, 3) }, theme.typography.h4));
});
var StyledImage = styled('img')(function () { return ({
    width: '100%',
}); });
var Content = styled('div')(function () { return ({
    width: '50%',
}); });
var StyledParagraph = styled('p')(function (_a) {
    var theme = _a.theme;
    return ({
        fontSize: theme.typography.pxToRem(24),
        lineHeight: theme.typography.pxToRem(36),
        letterSpacing: -0.2,
        marginBottom: theme.spacing(5),
        textAlign: 'center',
    });
});
export var ActionHero = function (_a) {
    var image = _a.image, buttonText = _a.buttonText, buttonAction = _a.buttonAction, children = _a.children;
    return (React.createElement(Container, null,
        React.createElement(Content, null,
            React.createElement(StyledImage, { src: "/docs/img/quickstarts/".concat(image, ".svg"), alt: buttonText }),
            React.createElement(StyledParagraph, null, children),
            React.createElement(StyledButton, { variant: "contained", size: "medium", color: "primary", fullWidth: true, onClick: function () { return buttonAction(); } }, buttonText))));
};
export var SignUpHero = function () {
    var executeAction = useFluxible().executeAction;
    return (React.createElement(ActionHero, { image: "action_hero_dashboard", buttonText: "Sign Up", buttonAction: function () { return executeAction(signup); } },
        "Sign up for an",
        ' ',
        React.createElement(Link, { variant: "button", component: "button", sx: { fontSize: 'inherit', textTransform: 'none' }, onClick: function () { return executeAction(signup); } }, "Auth0 account"),
        ' ',
        "or",
        ' ',
        React.createElement(Link, { variant: "button", component: "button", sx: { fontSize: 'inherit', textTransform: 'none' }, onClick: function () { return executeAction(login(window.location.pathname)); } }, "log in"),
        ' ',
        "to your existing account to integrate directly with your own tenant."));
};
export var DashboardHero = function (loggedIn) {
    var description = loggedIn
        ? 'Congratulations! You have successfully added login to your application. Head over to the Manage Dashboard to continue configuring your application'
        : 'Now that you know how to add login and logout to an application Sign Up for an Auth0 account and configure your own application.';
    return (React.createElement(ActionHero, { image: "action_hero_platform", buttonText: "Go to Dashboard", buttonAction: function () {
            // need to create an executeAction for visiting the dashboard
            console.log('hi');
        } }, description));
};
