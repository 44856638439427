var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Alert, Button, FieldSet, Form, FormActions, styled, TextField, Toast } from '@auth0/quantum-product';
import * as React from 'react';
import { useEffect, useState } from 'react';
import updateClientAction from '../../../../action/updateClient';
import { updateClient } from '../../../../services/ClientService';
import ApplicationStore from '../../../../stores/ApplicationStore';
import UserStore from '../../../../stores/UserStore';
import ConfiguredWithClient from '../../../ConfiguredWithClient';
import { useFluxible } from '../../../hooks/useFluxible';
var FormContainer = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        margin: theme.spacing(5),
    });
});
var FormHeader = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        marginBottom: theme.spacing(2),
    });
});
function parseError(error) {
    if (error.errorCode === 'invalid_body') {
        var keys = ['callbacks', 'web_origins', 'allowed_logout_urls'];
        var errorKeys = keys.filter(function (key) { var _a; return (_a = error.message) === null || _a === void 0 ? void 0 : _a.includes(key); });
        return __assign(__assign({}, error), { errorKeys: errorKeys });
    }
    return error;
}
function arrayToString(items) {
    return items ? items.join(',') : '';
}
function stringToArray(value) {
    return value.split(',').filter(Boolean);
}
export var ConfigureApplication = function (_a) {
    var setView = _a.setView;
    var context = useFluxible();
    var selectedClient = context.getStore(UserStore).getSelectedClient();
    var _b = __read(useState(''), 2), error = _b[0], setError = _b[1];
    var _c = __read(useState(false), 2), isSuccessBarOpen = _c[0], setIsSuccessBarOpen = _c[1];
    var _d = __read(useState([]), 2), errorKeys = _d[0], setErrorKeys = _d[1];
    var _e = __read(useState({
        callbacks: arrayToString(selectedClient && selectedClient.callbacks),
        web_origins: arrayToString(selectedClient && selectedClient.web_origins),
        allowed_logout_urls: arrayToString(selectedClient && selectedClient.allowed_logout_urls),
    }), 2), state = _e[0], setState = _e[1];
    var csrfToken = context.getStore(ApplicationStore).getCSRFToken();
    var save = function () { return __awaiter(void 0, void 0, void 0, function () {
        var client, err_1, _a, errorKeys_1, message;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    setError('');
                    setErrorKeys([]);
                    return [4 /*yield*/, updateClient(selectedClient.client_id, {
                            callbacks: stringToArray(state.callbacks),
                            web_origins: stringToArray(state.web_origins),
                            allowed_logout_urls: stringToArray(state.allowed_logout_urls),
                        }, csrfToken)];
                case 1:
                    client = _b.sent();
                    context.executeAction(updateClientAction, client);
                    setIsSuccessBarOpen(true);
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _b.sent();
                    _a = parseError(err_1), errorKeys_1 = _a.errorKeys, message = _a.message;
                    if (errorKeys_1 === null || errorKeys_1 === void 0 ? void 0 : errorKeys_1.length) {
                        setErrorKeys(errorKeys_1);
                    }
                    else {
                        setError(message);
                    }
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleCallbackUrlsChange = function (e) {
        setState(__assign(__assign({}, state), { callbacks: e.target.value }));
    };
    var handleAllowedWebOriginsChange = function (e) {
        setState(__assign(__assign({}, state), { web_origins: e.target.value }));
    };
    var handleLogoutUrlsChange = function (e) {
        setState(__assign(__assign({}, state), { allowed_logout_urls: e.target.value }));
    };
    var handleSuccessClose = function () {
        setIsSuccessBarOpen(false);
    };
    useEffect(function () {
        setState({
            callbacks: arrayToString(selectedClient && selectedClient.callbacks),
            web_origins: arrayToString(selectedClient && selectedClient.web_origins),
            allowed_logout_urls: arrayToString(selectedClient && selectedClient.allowed_logout_urls),
        });
    }, [selectedClient]);
    return (React.createElement(FormContainer, null,
        React.createElement(FormHeader, null, selectedClient ? (React.createElement(ConfiguredWithClient, null)) : (React.createElement(Alert, { severity: "danger" }, "Create an application or select an existing application to configure."))),
        React.createElement(Form, { fullWidth: true, layout: "inline", onSubmit: function () { return save(); } },
            error && React.createElement(Alert, { severity: "danger" }, error),
            React.createElement(Toast, { message: "Successfully saved your changes.", severity: "success", onClose: handleSuccessClose, autoHideDuration: 2000, open: isSuccessBarOpen }),
            React.createElement(FieldSet, null,
                React.createElement(TextField, { fullWidth: true, label: "Callback URLs", placeholder: "http://localhost:3000", name: "callbacks", value: state.callbacks, onChange: handleCallbackUrlsChange, disabled: !selectedClient, error: errorKeys.includes('callbacks') && 'Provide a valid URI.' }),
                React.createElement(TextField, { fullWidth: true, label: "Logout URLs", placeholder: "http://localhost:3000", name: "allowed_logout_urls", value: state.allowed_logout_urls, onChange: handleLogoutUrlsChange, disabled: !selectedClient, error: errorKeys.includes('allowed_logout_urls') && 'Provide a valid URI.' }),
                React.createElement(TextField, { fullWidth: true, label: "Allowed Web Origins", placeholder: "http://localhost:3000", name: "web_origins", value: state.web_origins, onChange: handleAllowedWebOriginsChange, disabled: !selectedClient, error: errorKeys.includes('web_origins') && 'Provide a valid URI.' })),
            React.createElement(FormActions, null,
                React.createElement(Button, { color: "primary", variant: "contained", type: "submit", disabled: !selectedClient }, "Save"),
                React.createElement(Button, { variant: "outlined", onClick: function () { return setView('default'); } }, "Cancel")))));
};
