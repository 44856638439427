var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { getTheme } from '@a0/docs-components/theme';
import { ThemeProvider } from '@auth0/quantum-product';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { renderToStaticMarkup } from 'react-dom/server';
import { FeedbackSection } from '../FeedbackSection';
export var useApiFeedback = function () {
    var _a = __read(React.useState(null), 2), feedbackContainer = _a[0], setFeedbackContainer = _a[1];
    var _b = __read(React.useState(''), 2), feedbackHTML = _b[0], setFeedbackHTML = _b[1];
    React.useEffect(function () {
        setFeedbackHTML(renderToStaticMarkup(React.createElement(ThemeProvider, { theme: getTheme() },
            React.createElement(FeedbackSection, { trackingCode: "", trackingData: "" }))));
    }, []);
    var renderFeedback = function (ev) {
        var _a;
        var container = (_a = ev.target) === null || _a === void 0 ? void 0 : _a.closest('.feedback-container');
        if (!container) {
            return;
        }
        if (!container.getAttribute('data-feedback-operation') && document.location.href.includes('/api/authentication')) {
            container.setAttribute('data-feedback-operation', document.location.hash.replace('#', '').replace(/\W/g, '-'));
        }
        setFeedbackContainer(function (currentContainer) {
            // Restore visibility of previous feedback buttons
            if (currentContainer && currentContainer.offsetTop !== container.offsetTop) {
                var previousContainerEl = currentContainer.firstElementChild;
                previousContainerEl.style.display = 'flex';
            }
            return container;
        });
        var containerEl = container.firstElementChild;
        containerEl.style.display = 'none';
    };
    return {
        feedbackContainer: feedbackContainer,
        renderFeedback: renderFeedback,
        feedbackHTML: feedbackHTML,
    };
};
export var ApiFeedback = function (_a) {
    var _b;
    var container = _a.container, _c = _a.trackingCode, trackingCode = _c === void 0 ? '' : _c, trackingData = _a.trackingData;
    var feedbackTrackingData = (_b = trackingData !== null && trackingData !== void 0 ? trackingData : container.getAttribute('data-feedback-operation')) !== null && _b !== void 0 ? _b : '';
    return ReactDOM.createPortal(React.createElement(FeedbackSection, { trackingCode: trackingCode, trackingData: feedbackTrackingData, alignModal: "left" }), container);
};
