import { MetricsEventType } from '@a0/docs-components/metrics';
import { ArrowRightIcon, Box, Text, Tooltip } from '@auth0/quantum-product';
import React from 'react';
import { useFluxible } from '../hooks/useFluxible';
import { NavLink } from '../NavLink';
export var DefinitionTooltip = function (_a) {
    var _b = _a.children, children = _b === void 0 ? '' : _b, dataKey = _a.dataKey, _c = _a.term, term = _c === void 0 ? {
        title: '',
        definition: '',
        short: '',
    } : _c;
    var context = useFluxible();
    var title = term.title, definition = term.definition, short = term.short;
    var onTooltipOpen = React.useCallback(function () {
        context.trackEvent(MetricsEventType.DefinitionTooltipOpen, { title: title });
    }, [title]);
    var onDefinitionLinkClick = React.useCallback(function () {
        context.trackEvent(MetricsEventType.DefinitionTooltipClick, { title: title });
    }, [title]);
    return (React.createElement(Tooltip
    //  interactive @TODO - option?
    , { 
        //  interactive @TODO - option?
        enterDelay: 250, enterNextDelay: 250, leaveDelay: 500, placement: "top", onOpen: onTooltipOpen, title: React.createElement(React.Fragment, null,
            React.createElement(Text, { variant: "subtitle2", color: "inherit" }, title),
            React.createElement(Text, { variant: "caption", component: "span", dangerouslySetInnerHTML: { __html: short || definition } }),
            React.createElement(Box, { display: "flex", justifyContent: "flex-start" },
                React.createElement(NavLink, { href: "/docs/glossary?term=".concat(dataKey), endIcon: React.createElement(ArrowRightIcon, { size: 16 }), onClick: onDefinitionLinkClick }, "View Glossary"))) },
        React.createElement("span", { className: "tooltip-portal-underlined-word", dangerouslySetInnerHTML: { __html: children } })));
};
