var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { RowLayout, styled, Text } from '@auth0/quantum-product';
import * as React from 'react';
import performSearchAction from '../../../action/performSearch';
import SearchService from '../../../services/SearchService.client';
import { SearchResultState } from '../../../stores/SearchStore';
import { useFluxible } from '../../hooks/useFluxible';
import { ContentContainer } from '../../layouts';
import { Pagination } from '../../Pagination';
import { GlossaryTermWidget } from './GlossaryTermWidget';
import { SearchResults } from './SearchResult';
var SearchContainer = styled(ContentContainer)(function (_a) {
    var theme = _a.theme;
    return ({
        maxWidth: theme.layout.mobileBreakpoint,
        margin: '0 auto',
        padding: theme.spacing(0, 3),
    });
});
export var SearchPage = function (_a) {
    var terms = _a.terms, search = _a.search, query = _a.query, page = _a.page;
    var executeAction = useFluxible().executeAction;
    React.useEffect(function () {
        if (query) {
            executeAction(performSearchAction, { query: query, page: page });
        }
    }, []);
    var selectResult = function (selectedParams) {
        SearchService.recordClickthrough(__assign({ query: query, searchQueryUid: search.searchUid }, selectedParams));
    };
    var handlePageChange = function (page) {
        executeAction(performSearchAction, { query: query, page: page });
    };
    var title = search ? 'Search Results' : 'Search';
    var matchedTerm = (search === null || search === void 0 ? void 0 : search.state) === SearchResultState.LOADED &&
        (terms === null || terms === void 0 ? void 0 : terms.find(function (term) {
            if (term.title.toLowerCase() === (query === null || query === void 0 ? void 0 : query.toLowerCase())) {
                return true;
            }
            return false;
        }));
    return (React.createElement(SearchContainer, null,
        React.createElement(RowLayout, { gutter: matchedTerm ? 2 : 5, sx: { py: 10 } },
            React.createElement(Text, { variant: "h1" }, title),
            matchedTerm && React.createElement(GlossaryTermWidget, __assign({}, matchedTerm)),
            React.createElement(SearchResults, { page: page, state: search === null || search === void 0 ? void 0 : search.state, results: search === null || search === void 0 ? void 0 : search.results, numberOfResults: search === null || search === void 0 ? void 0 : search.numberOfResults, count: search === null || search === void 0 ? void 0 : search.totalCount, onSelect: selectResult })),
        (search === null || search === void 0 ? void 0 : search.state) === SearchResultState.LOADED && (React.createElement(Pagination, { activePage: page, pageCount: search === null || search === void 0 ? void 0 : search.pageCount, onChange: handlePageChange }))));
};
