import * as React from 'react';
import { Quickstart } from '../Quickstart/Quickstart.old';
export var QuickstartList = function (_a) {
    var quickstarts = _a.quickstarts, isFramedMode = _a.isFramedMode, onHomePage = _a.onHomePage;
    var carouselRef = React.useRef(null);
    React.useEffect(function () {
        if (carouselRef.current) {
            loadCarousel(carouselRef.current, onHomePage);
        }
    }, [onHomePage, carouselRef.current]);
    var carousel = null;
    var items = null;
    var hide = 'hide ';
    if (quickstarts) {
        hide = '';
        items = Object.keys(quickstarts).map(function (name) { return React.createElement(Quickstart, { key: name, quickstart: quickstarts[name] }); });
    }
    if (isFramedMode) {
        carousel = React.createElement("div", { className: "js-carousel" }, items);
    }
    else {
        carousel = (React.createElement("div", { className: "js-carousel", ref: carouselRef }, items));
    }
    return React.createElement("div", { className: "".concat(hide, " quickstart-list container") }, carousel);
};
function loadCarousel(element, onHomePage) {
    if (onHomePage === void 0) { onHomePage = false; }
    var responsive = onHomePage
        ? {
            0: { items: 1, stagePadding: 50, center: true },
            380: { items: 1, stagePadding: 70, center: true },
            570: { items: 2, stagePadding: 70, center: true },
            768: { items: 4, stagePadding: 0, center: false, mouseDrag: false, touchDrag: false },
            880: { items: 4, stagePadding: 0, center: false, mouseDrag: false, touchDrag: false },
        }
        : {
            0: { items: 1, stagePadding: 60, center: true },
            380: { items: 2, stagePadding: 0, center: true },
            570: { items: 3, stagePadding: 0, center: true },
            768: { items: 4, stagePadding: 0, center: false, mouseDrag: false, touchDrag: false },
            880: { items: 4, stagePadding: 0, autoWidth: true, center: false, mouseDrag: false, touchDrag: false },
        };
    if (typeof window !== 'undefined') {
        var $carousel = $(element);
        // @ts-ignore
        $carousel.owlCarousel({
            margin: 20,
            center: true,
            dots: true,
            navContainerClass: 'nav-carousel',
            navClass: ['prev', 'next'],
            navText: ['', ''],
            baseClass: 'js-carousel',
            itemClass: 'item',
            dotsClass: 'dots',
            dotClass: 'dot',
            nav: false,
            responsive: responsive,
            onChanged: function (change) {
                var toggleCarousel = function (target, hide) {
                    if (hide) {
                        $(target).children('.nav-carousel').hide();
                    }
                    else {
                        $(target).children('.nav-carousel').show();
                    }
                };
                if (onHomePage) {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    var width = change.currentTarget.scrollWidth;
                    if (width < 768) {
                        if (width >= 570) {
                            var endOfCarousel = change.item.index >= 2;
                            toggleCarousel(change.currentTarget, endOfCarousel);
                        }
                        else {
                            var endOfCarousel = change.item.index >= 3;
                            toggleCarousel(change.currentTarget, endOfCarousel);
                        }
                    }
                }
            },
        });
    }
}
