import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { QUANTUM_UI } from '../../../../flags';
import MoreSection from './MoreSection';
import newStyles from './styles.module.styl';
import oldStyles from './styles.old.module.styl';

const styles = QUANTUM_UI ? newStyles : oldStyles;

class FurtherReadingSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMore: false,
      showMoreCss: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({ showMore: !this.state.showMore });
    this.setState({ showMoreCss: !this.state.showMoreCss });
  }

  render() {
    const { title, description, items, moreItems } = this.props;

    return (
      <div className={styles.readingSection}>
        <h3 className={`${styles[title]} ${styles.bullet}`}>{title}</h3>
        <p>{description}</p>
        <ul className={this.state.showMore ? styles.showMore : ''}>
          {items.map((item, index) => (
            <li dangerouslySetInnerHTML={{ __html: item }} key={`${title}-${index}-item`} />
          ))}
          {moreItems.length > 0 && (
            <MoreSection
              section={title}
              items={moreItems}
              handleClick={this.handleClick}
              showMore={this.state.showMore}
            />
          )}
        </ul>
      </div>
    );
  }
}

FurtherReadingSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  moreItems: PropTypes.array.isRequired,
};

FurtherReadingSection.defaultProps = {
  title: '',
  description: '',
  items: [],
  moreItems: [],
};

export default FurtherReadingSection;
