export var accordionContainerStyles = function (theme) {
    return {
        '& .MuiExpansionPanelSummary-content': {
            margin: 0,
            display: 'flex',
            flexGrow: 1,
            transition: 'margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            '& [data-cosmos-key="avatar-block"]': {
                height: '100%',
                display: 'flex',
            },
        },
        '& .MuiExpansionPanel-root': {
            margin: 0,
            padding: theme.spacing(4),
            position: 'relative',
            transition: 'margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
        '& .MuiCollapse-hidden': {
            visibility: 'hidden',
        },
        '& .MuiExpansionPanelSummary-expandIcon': {
            width: '25px',
            height: '25px',
            alignSelf: 'baseline',
        },
        '& .MuiCollapse-root': {
            height: 0,
            transitionDuration: '268ms',
            minHeight: theme.spacing(0),
        },
        // Custom styling
        '& .accordion-control-buttons': {
            width: 'fit-content',
            margin: "0 0 ".concat(theme.spacing(1), " auto"),
            display: 'flex',
            gap: theme.spacing(0.625),
        },
        '& .accordion-panels': {
            boxShadow: 'none',
            display: 'grid',
            gap: theme.spacing(2),
            gridTemplateColumns: 'minmax(0px, 1fr)',
        },
        '& .accordion-panel': {
            border: "1px solid ".concat(theme.palette.divider),
            padding: theme.spacing(2),
            borderRadius: theme.spacing(1),
            boxShadow: 'none',
        },
        '& #accordion-summary': {
            display: 'flex',
            padding: 0,
            minHheight: 'unset',
            transition: 'min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
        '& .accordion-numbered-icon': {
            width: '2rem',
            height: '2rem',
            fontSize: '0.875rem',
            color: '#635dff',
            backgroundColor: '#e9e8ff',
            borderRadius: '50%',
            display: 'flex',
            overflow: 'hidden',
            position: 'relative',
            alignItems: 'center',
            fontWeight: 500,
            textTransform: 'uppercase',
            justifyContent: 'center',
        },
        '& .accordion-details': {
            marginTop: theme.spacing(2),
            marginLeft: 0,
            marginRight: theme.spacing(2),
        },
        '& .accordion-numbered-details': {
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(6),
            marginRight: theme.spacing(2),
        },
    };
};
