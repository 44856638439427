import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.styl';

const Introduction = ({ showLoginText, text, handleLogin }) => (
  <p>
    {text}
    {showLoginText && (
      <span>
        {' '}
        We recommend that you{' '}
        <a className={styles.logInButton} onClick={handleLogin}>
          log in
        </a>{' '}
        to follow this quickstart with examples configured for your account.
      </span>
    )}
  </p>
);

Introduction.propTypes = {
  showLoginText: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  handleLogin: PropTypes.func.isRequired,
};

export default Introduction;
