var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Box, RowLayout, styled, Text } from '@auth0/quantum-product';
import * as React from 'react';
import { TermDefinition } from '../TermDefinition';
var HeadingLine = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        width: '100%',
        borderBottom: "2px solid ".concat(theme.palette.primary.main),
    });
});
export var LetterSection = function (_a) {
    var letter = _a.letter, terms = _a.terms;
    return (React.createElement(RowLayout, { component: "section", gutter: 3 },
        React.createElement(Box, { display: "flex", position: "relative", sx: { gridGap: 1 } },
            React.createElement(Text, { id: letter, variant: "h2" }, letter),
            React.createElement(HeadingLine, null)),
        React.createElement(RowLayout, { component: "ul", gutter: 4, sx: { p: 0 } }, terms.map(function (term) { return (React.createElement(TermDefinition, __assign({ key: term.id }, term))); }))));
};
