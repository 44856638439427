import * as React from 'react';
import Helmet from 'react-helmet';
import { ContentRenderer } from '../../ContentRenderer';
import { Spinner } from '../../Spinner';
export var Tutorial = function (_a) {
    var _b = _a.doc, _c = _b === void 0 ? {} : _b, html = _c.html, meta = _c.meta, handleRender = _a.handleRender;
    if (!html) {
        return React.createElement(Spinner, null);
    }
    return (React.createElement("div", { className: "quickstart-docs-content" },
        React.createElement(Helmet, null,
            React.createElement("meta", { name: "robots", content: meta.beta ? 'noindex, nofollow' : 'all' })),
        React.createElement(ContentRenderer, { styleContent: true, html: html, meta: meta, afterRender: handleRender })));
};
