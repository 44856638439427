var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Button } from '@auth0/quantum-product';
import FileSaver from 'file-saver';
import * as React from 'react';
import { login } from '../../../../action/navigateAuthentication';
import { sendPackageDownloadEvent, sendViewOnGithubEvent } from '../../../../browser/quickstartMetrics';
import ApplicationStore from '../../../../stores/ApplicationStore';
import QuickstartStore from '../../../../stores/QuickstartStore';
import RouteStore from '../../../../stores/RouteStore';
import UserStore from '../../../../stores/UserStore';
import { useFluxible } from '../../../hooks/useFluxible';
import DownloadSampleModal from '../../../quickstarts/DownloadSampleModal';
var initialState = {
    showDownloadModal: false,
    downloadInProgress: false,
};
export var HeaderButtons = function () {
    var _a = __read(React.useState(initialState), 2), state = _a[0], setState = _a[1];
    var updateState = function (updates) {
        setState(__assign(__assign({}, state), updates));
    };
    var _b = useFluxible(), getStore = _b.getStore, executeAction = _b.executeAction, trackEvent = _b.trackEvent;
    var quickstartStore = getStore(QuickstartStore);
    var userStore = getStore(UserStore);
    var appStore = getStore(ApplicationStore);
    var currentArticle = quickstartStore.getCurrentArticle();
    var platform = quickstartStore.getCurrentPlatform();
    var currentQuickstartType = quickstartStore.getQuickstartType();
    var userAccount = userStore.getUserAccount();
    var user = userStore.getUser();
    var isAuthenticated = userStore.isAuthenticated();
    var selectedClient = userStore.getSelectedClient();
    var userClients = userStore.getQuickstartCompatibleClients(currentQuickstartType);
    var userClientsByType = userStore.getQuickstartCompatibleClientsByType(currentQuickstartType);
    var userHasApis = userStore.userHasApis();
    var selectedApiIdentifier = userStore.getSelectedApiIdentifier();
    var domainUrlDocs = appStore.getDomainUrlDocs();
    var isFramedMode = appStore.isFramedMode();
    var query = getStore(RouteStore).getCurrentRoute().query;
    var currentQuickstart = quickstartStore.getCurrentQuickstart();
    var userClient = selectedClient
        ? {
            client_id: selectedClient.client_id,
            client_secret: selectedClient.client_secret,
            callback_url: selectedClient.callbacks ? selectedClient.callbacks[0] : userAccount.callback,
        }
        : {
            client_id: userAccount.clientId,
            client_secret: userAccount.clientSecret,
            callback_url: userAccount.callback,
        };
    var handleLogin = function () {
        executeAction(login("".concat(window.location.pathname, "?download=true")));
    };
    var showModal = function () {
        updateState({ showDownloadModal: true });
    };
    var hideModal = function () {
        updateState({ showDownloadModal: false });
    };
    var downloadSample = function () {
        updateState({ downloadInProgress: true });
        var headers = new Headers({
            'Content-Type': 'application/json',
        });
        var csrfHidden = document.getElementById('__csrf');
        if (csrfHidden instanceof HTMLInputElement) {
            headers.append('csrf-token', csrfHidden.value);
        }
        fetch("".concat(domainUrlDocs, "/package/v2"), {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(__assign(__assign(__assign({}, currentArticle.githubData), userClient), { domain: userAccount.namespace, tenant: userAccount.tenant, api_id: selectedApiIdentifier })),
        })
            .then(function (response) {
            if (!response.ok) {
                return false;
            }
            return response.blob();
        })
            .then(function (blob) {
            if (blob) {
                FileSaver.saveAs(blob, "".concat(platform.name, "-").concat(currentArticle.name, ".zip"));
                sendPackageDownloadEvent(eventData);
            }
        })
            .finally(function () {
            updateState({ downloadInProgress: false });
        });
    };
    var userMissingRequiredResources = function () {
        if (currentArticle.showDownloadSample) {
            var requiredResources = currentArticle.sampleDownloadRequiredData;
            var missingClient = requiredResources.includes('client') && !userClients.length;
            var missingAPI = requiredResources.includes('api') && !userHasApis;
            return missingClient || missingAPI;
        }
        return false;
    };
    var missingResources = userMissingRequiredResources();
    var requiredDownloadData = currentArticle.sampleDownloadRequiredData;
    var showClient = requiredDownloadData ? requiredDownloadData.includes('client') : null;
    var showApi = requiredDownloadData ? requiredDownloadData.includes('api') : null;
    var eventData = {
        isFramedMode: isFramedMode,
        quickstart: currentQuickstart,
        platform: platform,
        article: currentArticle,
        user: user,
    };
    var sendViewGithubEvent = function () {
        sendViewOnGithubEvent(eventData);
    };
    React.useEffect(function () {
        updateState({ showDownloadModal: query.download ? true : false });
    }, []);
    return (React.createElement(React.Fragment, null,
        currentArticle.showDownloadSample && (React.createElement(Button, { href: currentArticle.githubSampleLink, variant: "outlined", target: "_blank", color: "default", onClick: sendViewGithubEvent }, "View on Github")),
        currentArticle.showDownloadSample && isAuthenticated && (React.createElement(Button, { target: "_blank", variant: "contained", color: "primary", onClick: showModal, disabled: missingResources }, "Download Sample")),
        currentArticle.showDownloadSample && !isAuthenticated && (React.createElement(Button, { color: "primary", variant: "contained", onClick: handleLogin }, "Log In & Download Sample")),
        !missingResources && (React.createElement(DownloadSampleModal, { show: state.showDownloadModal, handleHide: hideModal, showClient: showClient, showApi: showApi, showSelector: true, downloadSample: downloadSample, githubSampleLink: currentArticle.githubSampleLink, trackViewGithubSample: sendViewGithubEvent, downloadInstructions: currentArticle.downloadInstructions || platform.downloadInstructions, downloadInProgress: state.downloadInProgress, userClientsByType: userClientsByType, isFramedMode: isFramedMode }))));
};
