var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { DropdownMenuItem, DropdownMenuList, DropdownMenuListItem, styled, TitleBlock } from '@auth0/quantum-product';
import * as React from 'react';
var StyledDropdownMenuListItem = styled(DropdownMenuListItem)(function (_a) {
    var theme = _a.theme, isActive = _a.isActive, isSubitem = _a.isSubitem;
    return (__assign(__assign(__assign({}, (isSubitem && { paddingLeft: theme.spacing(2.5) })), (isActive && { color: theme.tokens.color_fg_link_primary })), { whiteSpace: 'normal' }));
});
export var ToC = function (_a) {
    var titles = _a.titles;
    var _b = __read(React.useState(''), 2), hash = _b[0], setHash = _b[1];
    React.useEffect(function () {
        var setCurrentHash = function () {
            setHash(location === null || location === void 0 ? void 0 : location.hash);
        };
        setCurrentHash(); // for first render
        window.addEventListener('hashchange', setCurrentHash, false);
        return function () { return window.removeEventListener('hashchange', setCurrentHash, false); };
    }, []);
    React.useEffect(function () {
        var callback = function (entries) {
            var e_1, _a;
            try {
                for (var entries_1 = __values(entries), entries_1_1 = entries_1.next(); !entries_1_1.done; entries_1_1 = entries_1.next()) {
                    var entry = entries_1_1.value;
                    if (entry.isIntersecting) {
                        var id = entry.target.getAttribute('id');
                        setHash("#".concat(id));
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (entries_1_1 && !entries_1_1.done && (_a = entries_1.return)) _a.call(entries_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        };
        var observer = new IntersectionObserver(callback, {
            rootMargin: '0% 0% -60% 0%',
            threshold: 1,
        });
        var headings = document.querySelectorAll('h2, h3');
        headings.forEach(function (heading) {
            observer.observe(heading);
        });
        return function () {
            observer.disconnect();
        };
    }, [titles]);
    return (React.createElement(React.Fragment, null,
        React.createElement(DropdownMenuItem, null,
            React.createElement(TitleBlock, { title: "On This Page", titleTypographyProps: { variant: 'subtitle2' }, sx: { minWidth: '120px' } })),
        React.createElement(DropdownMenuList, null, titles.map(function (item) { return (React.createElement(StyledDropdownMenuListItem, { key: item.content, title: item.content, href: "#".concat(item.anchor), isSubitem: item.level > 2, isActive: hash === "#".concat(item.anchor) })); }))));
};
