var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ArrowRightIcon, Breadcrumbs, BreadcrumbsItem, RowLayout, styled } from '@auth0/quantum-product';
import * as React from 'react';
import ApplicationStore from '../../stores/ApplicationStore';
import { useFluxible } from '../hooks/useFluxible';
import { SectionHeader } from './SectionHeader';
var MainMenuContainer = styled(RowLayout)(function (_a) {
    var theme = _a.theme, isStickyHeaderVisible = _a.isStickyHeaderVisible;
    return (__assign(__assign({ transform: 'translateY(0px)', transition: 'visibility 0s linear 0.2s, transform 0.2s linear' }, (!isStickyHeaderVisible && {
        transform: "translateY(".concat(theme.layout.headerHeight + 10, "px)"),
        transitionDelay: '0s',
    })), { gap: '0px' }));
});
export var MainMenu = function (_a) {
    var items = _a.items, showBackButton = _a.showBackButton, closeMainMenu = _a.closeMainMenu;
    var context = useFluxible();
    var appStore = context.getStore(ApplicationStore);
    var isStickyHeaderVisible = appStore.isStickyHeaderVisible();
    return (React.createElement(MainMenuContainer, { sx: { pt: 3 }, gutter: 5, isStickyHeaderVisible: isStickyHeaderVisible },
        React.createElement(Breadcrumbs, { "aria-label": "Back", sx: { paddingBottom: '24px' } }, showBackButton && (React.createElement(BreadcrumbsItem, { endIcon: React.createElement(ArrowRightIcon, null), label: "Back", variant: "subtitle2", color: "primary", onClick: closeMainMenu }))),
        React.createElement(RowLayout, { gutter: 2 },
            React.createElement(SectionHeader, { key: "/docs", title: "Home", href: "/docs", icon: "IdenticonAuth0LogoLight" }), items === null || items === void 0 ? void 0 :
            items.map(function (item) { return (React.createElement(SectionHeader, { key: item.url, title: item.title, href: item.url, icon: item.icon })); }))));
};
