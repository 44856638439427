import PropTypes from 'prop-types';
import React from 'react';

import { performSelectApi, performSelectClient } from '../../../action/performUserResourceActions';
import { mapOptionTypes } from '../../../util/clientTypesHelper';
import { Select } from '../../Select';
import styles from '../styles.module.styl';

const SelectResource = ({
  handleUserResourceChange,
  isClientIdInCode,
  isApiIdInCode,
  userApis,
  userClients,
  userClientsByType,
  selectedApiId,
  selectedClientId,
}) => (
  <div className={styles.selector}>
    <span className={styles.selectorDescription}>Configured with</span>
    {isClientIdInCode && (
      <Select
        options={userClients.map((nonGlobalClient) => ({
          label: nonGlobalClient.name,
          value: nonGlobalClient.client_id,
        }))}
        selected={userClients.findIndex((nonGlobalClient) => nonGlobalClient.client_id === selectedClientId)}
        handleChange={(e) => handleUserResourceChange(performSelectClient, e.target.value)}
        label="Application: "
      >
        {mapOptionTypes(userClientsByType)}
      </Select>
    )}
    {isApiIdInCode && (
      <Select
        options={userApis.map((api) => ({
          label: api.name,
          value: api.id,
        }))}
        selected={userApis.findIndex((api) => api.id === selectedApiId)}
        handleChange={(e) => handleUserResourceChange(performSelectApi, e.target.value)}
        label="API: "
      />
    )}
  </div>
);

SelectResource.propTypes = {
  handleUserResourceChange: PropTypes.func.isRequired,
  isClientIdInCode: PropTypes.bool.isRequired,
  isApiIdInCode: PropTypes.bool.isRequired,
  userApis: PropTypes.array,
  userClients: PropTypes.array,
  userClientsByType: PropTypes.object,
  selectedApiId: PropTypes.string,
  selectedClientId: PropTypes.string,
};

SelectResource.contextTypes = {
  executeAction: PropTypes.func,
};

export default SelectResource;
