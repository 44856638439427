import { Box, SearchIcon, styled, TextField } from '@auth0/quantum-product';
import * as React from 'react';
var StyledSearchIcon = styled(SearchIcon)(function (_a) {
    var theme = _a.theme;
    return ({
        height: '48px',
        marginRight: theme.spacing(2),
        color: theme.palette.text.secondary,
    });
});
export var SearchField = function (_a) {
    var value = _a.value, placeholder = _a.placeholder, onChange = _a.onChange;
    return (React.createElement(Box, null,
        React.createElement(TextField, { id: "search-input", fullWidth: true, value: value, placeholder: placeholder, onChange: onChange, startAdornment: React.createElement(StyledSearchIcon, { size: 16 }) })));
};
