var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { ArrowLeftIcon, ArrowRightIcon, Avatar, AvatarBlock, Button, ChevronDownIcon, ChevronRightIcon, DropdownMenu, DropdownMenuDivider, DropdownMenuItem, DropdownMenuList, DropdownMenuListItem, GridIcon, IconButton, Link, RefreshCcwIcon, Region, styled, Text, TitleBlock, useDropdownMenuState, } from '@auth0/quantum-product';
import Fade from '@mui/material/Fade';
import * as React from 'react';
import { useCurrentRoute } from '../hooks/useCurrentRoute';
import { TenantDescription } from './TenantDescription';
var ToggleButton = styled(Button)(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(0.5),
        marginTop: theme.spacing(0.25),
    });
});
var TenantMenuWrapper = styled('div')(function (_a) {
    var theme = _a.theme, show = _a.show;
    return ({
        position: 'absolute',
        height: '100%',
        background: theme.tokens.color_bg_layer_alternate,
        right: show ? 0 : '-100%',
        width: '100%',
        top: 0,
        transition: 'right 0.2s',
        display: 'flex',
        flexDirection: 'column',
    });
});
export var SwitchList = styled(DropdownMenuList)({
    flex: 1,
    overflowY: 'auto',
});
export var TenantName = styled(Text)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            maxWidth: 195,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textTransform: 'none',
            marginRight: theme.spacing(1)
        },
        _b[theme.breakpoints.down('sm')] = {
            display: 'none',
        },
        _b);
});
var SwitchBack = styled(IconButton)({
    position: 'absolute',
    left: 4,
    top: 6,
});
/**
 * Conditionally apply a workaround for the CSS transition bug in Safari 15.4.
 * Remove this workaround once the Safari bug is fixed.
 */
var isSafari154 = typeof navigator !== 'undefined' &&
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
    /version\/15\.[4-9]/i.test(navigator.userAgent);
export var AuthDropdown = function (_a) {
    var _b = _a.picture, picture = _b === void 0 ? '' : _b, manageUrl = _a.manageUrl, tenant = _a.tenant, tenants = _a.tenants, _c = _a.userName, userName = _c === void 0 ? '' : _c;
    var currentUrl = useCurrentRoute().url;
    var _d = useDropdownMenuState({ baseId: "user-menu" }), triggerProps = _d.triggerProps, menuProps = _d.menuProps;
    var _e = __read(React.useState(false), 2), showTenantMenu = _e[0], setShowTenantMenu = _e[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(ToggleButton, __assign({ size: "small", variant: "link" }, triggerProps, { endIcon: React.createElement(ChevronDownIcon, null) }),
            tenant && (React.createElement(TenantName, { variant: "subtitle2", noWrap: true }, tenant.name)),
            React.createElement(Avatar, { type: "user", src: picture, size: "xsmall", "aria-label": "user avatar" })),
        React.createElement(DropdownMenu, __assign({ size: "large", placement: "left", keepMounted: true, maxWidth: 275 }, menuProps, (isSafari154 && { TransitionComponent: Fade })),
            React.createElement(DropdownMenuItem, null,
                React.createElement(TitleBlock, { title: tenant.name, titleTypographyProps: { variant: 'subtitle2', noWrap: true }, description: React.createElement(TenantDescription, { locality: tenant.locality, label: tenant.environmentName }), descriptionTypographyProps: { variant: 'caption' } })),
            React.createElement(DropdownMenuList, null,
                React.createElement(DropdownMenuListItem, { title: "Open Dashboard", href: manageUrl, startIcon: React.createElement(GridIcon, null) }),
                React.createElement(DropdownMenuListItem, { sx: { textAlign: 'left' }, onClick: function () {
                        setShowTenantMenu(true);
                    }, title: "Switch tenant", startIcon: React.createElement(RefreshCcwIcon, null), endIcon: React.createElement(ChevronRightIcon, null) }),
                React.createElement(DropdownMenuDivider, null),
                React.createElement(DropdownMenuItem, null,
                    React.createElement(AvatarBlock, { avatar: React.createElement(Avatar, { alt: "profile", src: picture, type: "user" }), description: React.createElement(Link, { href: "".concat(manageUrl, "/profile") }, "View profile"), title: userName })),
                React.createElement(DropdownMenuDivider, null),
                React.createElement(DropdownMenuListItem, { title: "Logout", href: "/docs/auth/logout", startIcon: React.createElement(ArrowRightIcon, null) })),
            React.createElement(TenantMenuWrapper, { show: showTenantMenu },
                React.createElement(DropdownMenuItem, { style: { textAlign: 'center' } },
                    React.createElement(SwitchBack, { variant: "link", size: "small", onClick: function () {
                            setShowTenantMenu(false);
                        } },
                        React.createElement(ArrowLeftIcon, null)),
                    React.createElement(Text, { variant: "subtitle2", component: "span" }, "Switch tenant")),
                React.createElement(SwitchList, { autoFocus: false }, tenants.map(function (ten, idx) { return (React.createElement(DropdownMenuListItem, { key: "".concat(ten.name, "@").concat(ten.environment, "-").concat(idx), selected: tenant.name === ten.name && tenant.environment === ten.environment, href: "".concat(ten.url, "&returnTo=").concat(currentUrl), title: ten.name, description: React.createElement(Region, { locality: ten.locality, size: "small", label: ten.environmentName }) })); }))))));
};
