import { styled } from '@auth0/quantum-product';
import { connectToStores } from 'fluxible-addons-react';
import React from 'react';
import { performSelectClient } from '../../action/performUserResourceActions';
import ApplicationStore from '../../stores/ApplicationStore';
import QuickstartStore from '../../stores/QuickstartStore';
import UserStore from '../../stores/UserStore';
import canUseStore from '../../util/canUseStore';
import { useFluxible } from '../hooks/useFluxible';
import SelectClient from './SelectClient';
var SelectWrapper = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        marginLeft: theme.spacing(1.5),
        display: 'inline',
    });
});
var SelectLabel = styled('span')(function () { return ({
    fontWeight: 500,
}); });
var ConfiguredWithClient = function (_a) {
    var userClients = _a.userClients, selectedClientId = _a.selectedClientId, userClientsByType = _a.userClientsByType;
    var context = useFluxible();
    var handleUserResourceChange = function (action, selectedId) {
        context.executeAction(action, { selectedId: selectedId });
    };
    return (React.createElement("div", null,
        React.createElement(SelectLabel, null, "Configured with"),
        React.createElement(SelectWrapper, null,
            React.createElement(SelectClient, { clients: userClients, clientsByType: userClientsByType, selectedClientId: selectedClientId, handleClientChange: function (value) { return handleUserResourceChange(performSelectClient, value); } }))));
};
export default connectToStores(ConfiguredWithClient, [ApplicationStore, UserStore], function (context) {
    if (!canUseStore(context))
        return {};
    var userStore = context.getStore(UserStore);
    var currentQuickstartType = context.getStore(QuickstartStore).getQuickstartType();
    return {
        isAuthenticated: userStore.isAuthenticated(),
        userClients: userStore.getQuickstartCompatibleClients(currentQuickstartType),
        userClientsByType: userStore.getQuickstartCompatibleClientsByType(currentQuickstartType),
        selectedClientId: userStore.getSelectedClientId(),
    };
});
