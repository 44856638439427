import { RowLayout, styled } from '@auth0/quantum-product';
import * as React from 'react';
import NavigationStore from '../../../stores/NavigationStore';
import { ArticleFooter } from '../../ArticleFooter';
import { Breadcrumbs } from '../../Breadcrumbs';
import { useFluxible } from '../../hooks/useFluxible';
import { ContentContainer } from '../../layouts';
import { Sidebar } from '../../Sidebar';
import { Spinner } from '../../Spinner';
import { ToC } from '../../ToC';
import { VideoFooter } from '../../VideoFooter';
import { Article } from '../Article';
import { ArticleContextProvider, ArticleType } from '../ArticleContext';
import { LandingPage } from '../LandingPage';
import { ArticleLayout } from '../Layout';
var articleComponentFactory = function (type) {
    var component;
    switch (type) {
        case ArticleType.LandingPage:
            component = LandingPage;
            break;
        case ArticleType.Default:
        default:
            component = Article;
            break;
    }
    return component;
};
var ArticleContainer = styled(ContentContainer)({
    margin: '0 auto',
});
export var ArticleTemplate = function (_a) {
    var contentReady = _a.contentReady, doc = _a.doc, renderContent = _a.renderContent, url = _a.url;
    var meta = doc === null || doc === void 0 ? void 0 : doc.meta;
    var section = meta === null || meta === void 0 ? void 0 : meta.section;
    var classes = (meta === null || meta === void 0 ? void 0 : meta.classes) || '';
    var isVideoPage = classes.search('video-page') > -1;
    var context = useFluxible();
    var navigationStore = context.getStore(NavigationStore);
    var storeBreadcrumbs = navigationStore.getBreadcrumbs();
    var breadcrumbs = storeBreadcrumbs.length > 0 ? storeBreadcrumbs : (meta === null || meta === void 0 ? void 0 : meta.breadcrumbs) || [];
    var navigationItems = (meta && section && navigationStore.getNavigationItems(section, breadcrumbs)) || [];
    var sidebar = React.createElement(Sidebar, { section: section, items: navigationItems, maxDepth: 4, url: url });
    var toc = (meta === null || meta === void 0 ? void 0 : meta.toc) && React.createElement(ToC, { titles: meta === null || meta === void 0 ? void 0 : meta.titles.filter(function (t) { return t.level === 2 || t.level === 3; }) });
    var ArticleComponent = articleComponentFactory(meta === null || meta === void 0 ? void 0 : meta.type);
    return (React.createElement(ArticleContextProvider, { type: meta === null || meta === void 0 ? void 0 : meta.type },
        React.createElement(ArticleContainer, { fullWidth: true },
            React.createElement(ArticleLayout, { sidebar: sidebar, toc: toc },
                !contentReady && React.createElement(Spinner, { size: "large" }),
                contentReady && (React.createElement(RowLayout, { gutter: 5, sx: { py: 5 } },
                    React.createElement(Breadcrumbs, { crumbs: breadcrumbs }),
                    React.createElement("article", { id: "maincontent", role: "main", className: classes },
                        React.createElement(ArticleComponent, { meta: meta, navigationItems: navigationItems }, renderContent(doc))),
                    isVideoPage ? (React.createElement(VideoFooter, null)) : (React.createElement(ArticleFooter, { articleUrl: url, githubEditLink: meta === null || meta === void 0 ? void 0 : meta.editUrl, contentfulId: meta === null || meta === void 0 ? void 0 : meta.contentfulId }))))))));
};
