var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import copyToClipboard from 'copy-to-clipboard';
import { useState } from 'react';
export var useCopyToClipboard = function (timeoutMs) {
    if (timeoutMs === void 0) { timeoutMs = 2000; }
    var _a = __read(useState(false), 2), isCopied = _a[0], setIsCopied = _a[1];
    var timeout;
    return {
        isCopied: isCopied,
        copyToClipboard: function (code) {
            copyToClipboard(code);
            setIsCopied(true);
            clearTimeout(timeout);
            timeout = setTimeout(function () {
                setIsCopied(false);
            }, timeoutMs);
        },
    };
};
