var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { connectToStores } from 'fluxible-addons-react';
import * as React from 'react';
import { QUANTUM_QS } from '../../../flags';
import ApplicationStore from '../../stores/ApplicationStore';
import QuickstartStore from '../../stores/QuickstartStore';
import UserStore from '../../stores/UserStore';
import { QuickstartsPage as QuickstartsPageNew } from './QuickstartsPage';
import QuickstartsPageOld from './QuickstartsPage.old';
export var QuickstartsPage = QUANTUM_QS ? QuickstartsPageNew : QuickstartsPageOld;
var QuickstartPage = function (props) {
    if (QUANTUM_QS && !props.isFramedMode) {
        return React.createElement(QuickstartsPageNew, __assign({}, props));
    }
    else {
        return React.createElement(QuickstartsPageOld, __assign({}, props));
    }
};
export default connectToStores(QuickstartPage, [ApplicationStore, QuickstartStore, UserStore], function (context, _a) {
    var currentRoute = _a.currentRoute;
    return ({
        quickstarts: context.getStore(QuickstartStore).getQuickstarts(),
        isAuthenticated: context.getStore(UserStore).isAuthenticated(),
        isFramedMode: context.getStore(ApplicationStore).isFramedMode(),
        currentRoute: currentRoute,
    });
});
