import { IconButton, MenuIcon, styled, XIcon } from '@auth0/quantum-product';
import * as React from 'react';
var Root = styled(IconButton)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            display: 'inherit'
        },
        _b[theme.breakpoints.up('md')] = {
            display: 'none',
        },
        _b['& svg:first-child'] = {
            width: '1.5em',
            height: '1.5em',
        },
        _b);
});
export var HamburgerMenu = function (_a) {
    var expandMenu = _a.expandMenu, onClick = _a.onClick;
    return (React.createElement(Root, { onClick: onClick }, expandMenu ? React.createElement(XIcon, null) : React.createElement(MenuIcon, null)));
};
