var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Box, styled, Text } from '@auth0/quantum-product';
import { Pagination as MuiPagination, PaginationItem as MuiPaginationItem } from '@mui/material';
import * as React from 'react';
import { useFluxible } from '../hooks/useFluxible';
import { NavLink } from '../NavLink';
var isPreviousOrLast = function (type) { return type === 'previous' || type === 'next'; };
var PaginationItem = function (props) {
    return React.createElement(MuiPaginationItem, __assign({}, props, { variant: isPreviousOrLast(props.type) ? 'outlined' : 'text' }));
};
var StyledPagination = styled(MuiPagination)({
    width: 'auto',
    marginLeft: 0,
});
export var Pagination = function (_a) {
    var _b = _a.activePage, activePage = _b === void 0 ? 1 : _b, pageCount = _a.pageCount, onChange = _a.onChange;
    var trackEvent = useFluxible().trackEvent;
    var handleChangeEvent = function (event, page) {
        onChange(page);
    };
    return (React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4 } },
        React.createElement(StyledPagination, { color: "primary", shape: "rounded", size: "large", page: activePage, count: pageCount, onChange: handleChangeEvent, renderItem: function (item) { return React.createElement(PaginationItem, __assign({}, item)); } }),
        React.createElement(Text, { variant: "body2" },
            "Didn't find what you are looking for?\u00A0\u00A0",
            React.createElement(NavLink, { followLink: true, href: "https://community.auth0.com/", onClick: function () { return trackEvent('click:view-community'); }, target: "_blank", rel: "noreferrer noopener" }, "Ask the Auth0 Community"))));
};
