export default {
  name: 'mgmt',
  platforms: {
    go: {
      title: 'Go',
      name: 'go',
      logo: 'golang',
      sdk: {
        name: 'go-auth0',
        url: 'https://github.com/auth0/go-auth0',
      },
    },
    aspNet: {
      title: '.NET',
      name: 'aspnet',
      logo: 'dotnet',
      sdk: {
        name: 'auth0.net',
        url: 'https://github.com/auth0/auth0.net',
      },
    },
    java: {
      title: 'Java',
      name: 'java',
      logo: 'java',
      sdk: {
        name: 'Auth0 Java',
        url: 'https://github.com/auth0/auth0-java',
      },
    },
    nodejs: {
      title: 'Node.js',
      name: 'node',
      logo: 'nodejs',
      sdk: {
        name: 'node-auth0',
        url: 'https://github.com/auth0/node-auth0',
      },
    },
    php: {
      title: 'PHP',
      name: 'php',
      logo: 'php',
      sdk: {
        name: 'auth0-PHP',
        url: 'https://github.com/auth0/auth0-PHP',
      },
    },
    python: {
      title: 'Python',
      name: 'python',
      logo: 'python',
      sdk: {
        name: 'auth0-python',
        url: 'https://github.com/auth0/auth0-python',
      },
    },
    ruby: {
      title: 'Ruby',
      name: 'ruby',
      logo: 'ruby',
      sdk: {
        name: 'ruby-auth0',
        url: 'https://github.com/auth0/ruby-auth0',
      },
    },
  },
};
