import * as React from 'react';
import { useArticleContext } from '../Article';
import { DesktopSidebar } from './DesktopSidebar';
import { MobileSidebar } from './MobileSidebar';
export var SplitSidebar = function (_a) {
    var items = _a.items, _b = _a.maxDepth, maxDepth = _b === void 0 ? 2 : _b;
    var isMobile = useArticleContext().isMobile;
    var SidebarComponent = isMobile ? MobileSidebar : DesktopSidebar;
    return React.createElement(SidebarComponent, { items: items, maxDepth: maxDepth });
};
