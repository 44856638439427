import createCache from '@emotion/cache';
import { ColorScheme } from '../@types/ColorScheme';
var cacheLight;
var cacheDark;
export default function createEmotionCache(colorScheme) {
    // Ensure that the cache referenced in bootstrap and Application are the same
    if (colorScheme === ColorScheme.Dark || colorScheme === ColorScheme.SystemDark) {
        if (cacheDark) {
            return cacheDark;
        }
        cacheDark = createCache({ key: 'css-dark' });
        return cacheDark;
    }
    if (cacheLight) {
        return cacheLight;
    }
    cacheLight = createCache({ key: 'css-light' });
    return cacheLight;
}
