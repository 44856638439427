var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import replaceUserVars from '../../lib/replaceUserVars';
var replaceUserVarsInSampleFiles = function (sampleFiles, context) {
    return sampleFiles.map(function (sampleFile) { return (__assign(__assign({}, sampleFile), { content: replaceUserVars(sampleFile.content, context) })); });
};
export default replaceUserVarsInSampleFiles;
