var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Card, CardHeader, styled } from '@auth0/quantum-product';
import Fuse from 'fuse.js';
import { each, filter } from 'lodash';
import * as React from 'react';
import { StickyContainer } from 'react-sticky';
import QuickstartStore from '../../../stores/QuickstartStore';
import { Breadcrumbs } from '../../Breadcrumbs';
import { useFluxible } from '../../hooks/useFluxible';
import { ColumnStack, ContentContainer } from '../../layouts';
import { SearchField } from '../../SearchField';
import { Category } from './components/Category';
import { Hero } from './components/Hero';
import { Sidebar } from './components/Sidebar';
import { CATEGORIES } from './data';
var SEARCH_DEFAULTS = {
    threshold: 0.1,
    location: 0,
    maxPatternLength: 100,
    minMatchCharLength: 1,
    keys: ['title', 'name'],
};
var doSearch = function (categories, term) {
    if (!term)
        return { quickstarts: categories, noResults: false };
    var quickstarts = {};
    var noResults = true;
    each(categories, function (categoryQuickstarts, category) {
        var results = new Fuse(categoryQuickstarts, SEARCH_DEFAULTS).search(term);
        if (results.length) {
            noResults = false;
        }
        quickstarts[category] = results.map(function (result) { return result.item; });
    });
    return { quickstarts: quickstarts, noResults: noResults };
};
var Root = styled(ContentContainer)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b[theme.breakpoints.down(theme.layout.header1440 + theme.spacingPX(6))] = {
            marginLeft: theme.spacing(3),
        },
        _b.marginTop = '60px',
        _b);
});
var StyledColumnStack = styled(ColumnStack)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            alignItems: 'flex-start',
            gap: theme.spacing(5)
        },
        _b[theme.breakpoints.up('md')] = {
            gridTemplateColumns: '304px auto',
        },
        _b);
});
var SidebarContainer = styled('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            borderRight: "1px solid ".concat(theme.palette.divider),
            height: '100%'
        },
        _b[theme.breakpoints.down('md')] = {
            display: 'none',
        },
        _b);
});
var MainContentContainer = styled('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            padding: theme.spacing(7, 3, 0),
            width: '100%'
        },
        _b[theme.breakpoints.down('md')] = {
            padding: theme.spacing(7, 0, 0),
        },
        _b);
});
var breadcrumbStyle = {
    marginBottom: '24px',
};
export var QuickstartsPage = function (_a) {
    var currentRoute = _a.currentRoute;
    var getStore = useFluxible().getStore;
    var _b = __read(React.useState(''), 2), searchTerm = _b[0], setSearchTerm = _b[1];
    var QuickstartStorez = getStore(QuickstartStore);
    var storeBreadcrumbs = QuickstartStorez.getLandingPageBreadcrumbs();
    var breadcrumbs = storeBreadcrumbs.length > 0 ? storeBreadcrumbs : [];
    var quickstarts = QuickstartStorez.getQuickstarts();
    var divRef = React.useRef(null);
    React.useEffect(function () {
        var _a;
        var sectionId = currentRoute.params.quickstartId;
        var section = (_a = divRef.current) === null || _a === void 0 ? void 0 : _a.querySelector("#".concat(sectionId));
        section === null || section === void 0 ? void 0 : section.scrollIntoView();
    }, [divRef, currentRoute.params.quickstartId]);
    var categories = {};
    // hide beta quickstarts
    each(quickstarts, function (category, name) {
        categories[name] = filter(category.platforms, function (p) { return p.beta !== true; }).sort(function (a, b) {
            return a.title.localeCompare(b.title);
        });
    });
    var results = doSearch(categories, searchTerm);
    // Mapping categories to dom refs
    var categoryRefs = {};
    CATEGORIES.forEach(function (cat) { return (categoryRefs[cat] = React.useRef(null)); });
    // console.log(document.cookie);
    return (React.createElement(StickyContainer, null,
        React.createElement(Root, { fullWidth: true, ref: divRef },
            React.createElement(StyledColumnStack, null,
                React.createElement(SidebarContainer, null,
                    React.createElement(Sidebar, { categoryRefs: categoryRefs })),
                React.createElement(MainContentContainer, { id: "maincontent", role: "main" },
                    React.createElement(Breadcrumbs, { style: breadcrumbStyle, crumbs: breadcrumbs }),
                    React.createElement(Hero, null),
                    React.createElement(SearchField, { value: searchTerm, performSearch: setSearchTerm }),
                    CATEGORIES.map(function (category) { return (React.createElement(Category, { key: category, name: category, quickstarts: results.quickstarts[category], ref: categoryRefs[category] })); }),
                    results.noResults && (React.createElement(Card, { size: "large", sx: {
                            textAlign: 'center',
                            my: 8,
                        } },
                        React.createElement(CardHeader, { titleTypographyProps: { variant: 'h5' }, title: "No Quickstarts matches your search term \"".concat(searchTerm, "\"."), subheaderTypographyProps: { variant: 'body1' }, subheader: "There might be a spelling error in your search term, or we just don\u2019t support that language yet. Want\n    to view a list of all Quickstarts again? Clear your search term above." }))))))));
};
