var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { RowLayout, styled, Text } from '@auth0/quantum-product';
import * as React from 'react';
import { sendTutorialViewedEvent } from '../../../browser/quickstartMetrics';
import { ContentContainer } from '../../layouts';
import { QuickstartList } from '../QuickstartList';
var Root = styled('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            marginBottom: -theme.spacingPX(8),
            minHeight: 470,
            textAlign: 'unset'
        },
        _b[theme.breakpoints.down('sm')] = {
            height: 'auto',
        },
        _b);
});
var shouldSendMetrics = function (quickstart, prevQuickstart) {
    return quickstart && (!prevQuickstart || prevQuickstart.name !== quickstart.name);
};
export var TutorialNavigator = function (props) {
    var quickstart = props.quickstart, _a = props.firstQuestion, firstQuestion = _a === void 0 ? 'Choose your application type' : _a, subHeader = props.subHeader;
    var handleMetrics = function () {
        if (typeof document !== 'undefined') {
            sendTutorialViewedEvent(props);
        }
    };
    React.useEffect(function () {
        if (shouldSendMetrics(quickstart)) {
            handleMetrics();
        }
    }, [quickstart]);
    return (React.createElement(Root, null,
        React.createElement("div", { className: "js-tutorial-navigator" },
            React.createElement(ContentContainer, { gutter: 7 },
                React.createElement(RowLayout, { gutter: 2 },
                    React.createElement(Text, { style: { zIndex: 1 }, component: "h1", variant: "h1" }, firstQuestion),
                    subHeader && (React.createElement(Text, { sx: { zIndex: 1, fontWeight: 'light' }, variant: "h4" }, subHeader))),
                React.createElement(QuickstartList, __assign({}, props))))));
};
