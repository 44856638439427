import url from 'url';
var UrlSlugs;
(function (UrlSlugs) {
    UrlSlugs["Seach"] = "search";
    UrlSlugs["Glossary"] = "glossary";
})(UrlSlugs || (UrlSlugs = {}));
export var getPageTitleFromUrl = function (pageUrl) {
    var _a;
    var title;
    var slug = (_a = url.parse(pageUrl).pathname) === null || _a === void 0 ? void 0 : _a.split('/')[2];
    switch (slug) {
        case UrlSlugs.Seach:
            title = 'Search Results';
            break;
        case UrlSlugs.Glossary:
            title = 'Identity Glossary';
            break;
        default:
            break;
    }
    return title;
};
