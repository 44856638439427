var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Alert, Button, FieldSet, Form, FormActions, styled, TextField } from '@auth0/quantum-product';
import * as React from 'react';
import { useState } from 'react';
import createClientAction from '../../../../action/createClient';
import { performSelectClient } from '../../../../action/performUserResourceActions';
import { createClient } from '../../../../services/ClientService';
import ApplicationStore from '../../../../stores/ApplicationStore';
import { useFluxible } from '../../../hooks/useFluxible';
var FormContainer = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        margin: theme.spacing(5),
    });
});
var ERROR_MESSAGES = {
    insufficient_scope: 'You do not have permission to modify these settings. Please contact your admin for more details.',
};
export var CreateApplication = function (_a) {
    var type = _a.type, setView = _a.setView;
    var context = useFluxible();
    var _b = __read(useState({ name: '', type: type }), 2), state = _b[0], setState = _b[1];
    var _c = __read(useState(''), 2), error = _c[0], setError = _c[1];
    var csrfToken = context.getStore(ApplicationStore).getCSRFToken();
    var save = function () { return __awaiter(void 0, void 0, void 0, function () {
        var client, err_1, apiError;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, createClient(state, csrfToken)];
                case 1:
                    client = _a.sent();
                    context.executeAction(createClientAction, client);
                    context.executeAction(performSelectClient, { selectedId: client.client_id });
                    setView('configure');
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    apiError = err_1;
                    setError(ERROR_MESSAGES[apiError.errorCode] || apiError.message);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleNameChange = function (e) {
        setState(__assign(__assign({}, state), { name: e.target.value }));
    };
    return (React.createElement(FormContainer, null,
        React.createElement(Form, { fullWidth: true, layout: "inline" },
            error && React.createElement(Alert, { severity: "danger" }, error),
            React.createElement(FieldSet, null,
                React.createElement(TextField, { fullWidth: true, helperText: "You can change this later in the application settings.", label: "Application Name", placeholder: "My App", onChange: handleNameChange })),
            React.createElement(FormActions, null,
                React.createElement(Button, { color: "primary", variant: "contained", onClick: function () { return save(); }, disabled: Boolean(error) }, "Save"),
                React.createElement(Button, { variant: "outlined", onClick: function () { return setView('default'); } }, "Cancel")))));
};
