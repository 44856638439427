import metrics from '@a0/docs-components/metrics';
import { NavLink } from 'fluxible-router';
import * as React from 'react';
export var Breadcrumbs = function (_a) {
    var crumbs = _a.crumbs;
    return (React.createElement("ul", { className: "breadcrumb", itemScope: true, itemType: "http://schema.org/BreadcrumbList" }, crumbs.map(function (crumb, index) { return (React.createElement("li", { itemProp: "itemListElement", itemScope: true, itemType: "http://schema.org/ListItem", key: crumb.title + crumb.url, onClick: function () { return metrics.track('click:breadcrumb', { title: crumb.title, url: crumb.url }); } },
        React.createElement(NavLink, { itemProp: "item", href: crumb.url, followLink: crumb.forceFullReload, activeClass: "active" },
            React.createElement("span", { className: "text", itemProp: "name" }, crumb.title),
            React.createElement("meta", { itemProp: "position", content: (index + 1).toString() })))); })));
};
