var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import { MainMenu } from './MainMenu';
import { SidebarSection } from './SidebarSection';
export var DesktopSidebar = function (_a) {
    var items = _a.items, _b = _a.maxDepth, maxDepth = _b === void 0 ? 2 : _b;
    var _c = __read(React.useState(false), 2), showMainMenu = _c[0], setShowMainMenu = _c[1];
    var toggleMainMenu = function () { return setShowMainMenu(!showMainMenu); };
    var activeSection = items === null || items === void 0 ? void 0 : items.find(function (section) { return section.isActiveUrl === true; });
    return showMainMenu || !activeSection ? (React.createElement(MainMenu, { items: items, showBackButton: !!activeSection, closeMainMenu: toggleMainMenu })) : (React.createElement(SidebarSection, { section: activeSection, maxDepth: maxDepth, openMainMenu: toggleMainMenu }));
};
