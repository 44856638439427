import { Box, styled, Text } from '@auth0/quantum-product';
import * as React from 'react';
var Root = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        background: 'url(/docs/img/quickstarts/header-bg.svg) no-repeat right center, linear-gradient(333.17deg, #3EC6EB -1.51%, #635DFF 101.22%)',
        boxShadow: '0px 24px 34px rgba(16, 113, 242, 0.2)',
        borderRadius: 20,
        margin: theme.spacing(0, 0, 5),
        width: '100%',
        height: 294,
        display: 'flex',
    });
});
export var Hero = function () { return (React.createElement(Root, null,
    React.createElement(Box, { sx: {
            flex: 1,
            ml: { lg: 7, sm: 4, xs: 2 },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 3,
            color: 'color_fg_on_brand_primary',
        } },
        React.createElement(Text, { variant: "h1", color: "inherit" }, "Quickstarts"),
        React.createElement(Text, { variant: "body1", color: "inherit" }, "Step-by-step guides to quickly integrate Auth0 into your application")),
    React.createElement(Box, { sx: { mr: 3, ml: 4, display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' } },
        React.createElement("img", { style: { maxWidth: '100%', minWidth: '50%' }, src: "/docs/img/quickstarts/header_img.svg", alt: "quickstarts header" })))); };
