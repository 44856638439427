import * as React from 'react';
import { ColumnStack } from '../../layouts';
import { FeedbackMode } from '../feedbackModes';
import { BooleanFeedbackButton } from './BooleanFeedbackButton';
export var BooleanFeedback = function (_a) {
    var onChange = _a.onChange, value = _a.value, useButtons = _a.useButtons;
    return (
    // @TODO override on ColumnLayout breakpoints
    React.createElement(ColumnStack, { sx: { gridTemplateColumns: ['none', 'none', 'none'] }, gutter: (useButtons && 2) || 0 },
        React.createElement(BooleanFeedbackButton, { value: FeedbackMode.Positive, checked: value === FeedbackMode.Positive, disabled: value !== FeedbackMode.None, onClick: function () { return onChange(FeedbackMode.Positive); }, useButtons: useButtons }),
        React.createElement(BooleanFeedbackButton, { value: FeedbackMode.Negative, checked: value === FeedbackMode.Negative, disabled: value !== FeedbackMode.None, onClick: function () { return onChange(FeedbackMode.Negative); }, useButtons: useButtons })));
};
