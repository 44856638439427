var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from '@auth0/quantum-product';
import { NavLink } from 'fluxible-router';
import * as React from 'react';
import parse from 'url-parse';
var StyledNavLink = styled(NavLink)(function (_a) {
    var theme = _a.theme, _b = _a.depth, depth = _b === void 0 ? 0 : _b, isActiveUrl = _a.isActiveUrl, isCurrentUrl = _a.isCurrentUrl;
    return ({
        paddingLeft: 0,
        marginLeft: theme.spacing(depth),
        maxWidth: 300 - depth * 8,
        '& .QuantumSidebarLink-title': {
            color: isCurrentUrl
                ? theme.tokens.color_fg_link_primary
                : isActiveUrl && depth === 0
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary,
        },
        '&:focus': {
            boxShadow: 'none',
        },
    });
});
export var ArticleLink = function (_a) {
    var children = _a.children, external = _a.external, forceFullReload = _a.forceFullReload, anchor = _a.anchor, url = _a.url, onClick = _a.onClick, props = __rest(_a, ["children", "external", "forceFullReload", "anchor", "url", "onClick"]);
    var href = url && !external ? parse(url).pathname : url;
    return (React.createElement("span", { onClick: onClick },
        React.createElement(StyledNavLink, __assign({ followLink: forceFullReload, href: anchor ? "#".concat(anchor) : href }, props), children)));
};
