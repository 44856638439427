import { connectToStores } from 'fluxible-addons-react';
import NavigationStore from '../../../stores/NavigationStore';
import QuickstartStore from '../../../stores/QuickstartStore';
import UserStore from '../../../stores/UserStore';
import { HomePage } from './HomePage';
export default connectToStores(HomePage, [NavigationStore, QuickstartStore], function (context) { return ({
    isAuthenticated: context.getStore(UserStore).isAuthenticated(),
    quickstarts: context.getStore(QuickstartStore).getQuickstarts(),
    cards: context.getStore(NavigationStore).getCards(),
}); });
