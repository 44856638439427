import metrics from '@a0/docs-components/metrics';
import PropTypes from 'prop-types';
import React from 'react';

import { QUANTUM_UI } from '../../../../flags';
import newStyles from './styles.module.styl';
import oldStyles from './styles.old.module.styl';

const styles = QUANTUM_UI ? newStyles : oldStyles;

const ReadyToBuild = ({ ctaText, ctaLink, position }) => (
  <div className={styles.readyToBuild}>
    <span>Ready to start building?</span>

    <a href={ctaLink} onClick={() => metrics.track('click:readyToBuild', { trackData: position })}>
      <button
        style={{ backgroundColor: QUANTUM_UI && '#635dff' }}
        className={`btn btn-success ${styles.readyToBuildButton}`}
      >
        {ctaText}
      </button>
    </a>
  </div>
);

ReadyToBuild.propTypes = {
  ctaText: PropTypes.string.isRequired,
  ctaLink: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
};

ReadyToBuild.defaultProps = {
  ctaText: 'Go To Quickstart',
  ctaLink: '',
  position: '',
};

export default ReadyToBuild;
