var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// eslint-disable-next-line simple-import-sort/imports
import Prism from 'prismjs';
import 'prismjs/components/prism-markup-templating';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'prismjs/components/prism-csharp';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-powershell';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-ruby';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-django';
import 'prismjs/components/prism-swift';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-kotlin';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-go';
import 'prismjs/components/prism-go-module';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-groovy';
import 'prismjs/components/prism-dart';
import 'prismjs/plugins/line-numbers/prism-line-numbers';
import 'prismjs/plugins/line-highlight/prism-line-highlight';
import { styled } from '@auth0/quantum-product';
import React, { useEffect, useRef } from 'react';
import { CopyButton } from './CopyButton';
var CodeHighlightPrismWrapper = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        position: 'relative',
        '& button.copy-button': {
            position: 'absolute',
            right: '0.5em',
            top: '0.5em',
        },
        '& pre.code-highlight-prism': {
            border: theme.palette.mode === 'dark' ? "1px solid ".concat(theme.tokens.color_border_default) : 0,
        },
        '& code.code-highlight-prism': {
            color: theme.unsafe_globalTokens.color_global_functional_static_neutral_white,
        },
        '& .token.keyword': {
            color: theme.unsafe_globalTokens.color_global_functional_light_green_400,
        },
        '& .token.property, & .token.constant, & .token.symbol, & .token.deleted': {
            color: theme.unsafe_globalTokens.color_global_functional_light_green_400,
        },
        '& .token.selector, & .token.string, & .token.char, & .token.builtin, & .token.inserted': {
            color: '#bcbcff',
        },
        '& .token.boolean, & .token.number': {
            color: '#bcbcff',
        },
        '& .token.class-name, & .token.attr-name, & .token .punctuation': {
            color: theme.unsafe_globalTokens.color_global_functional_light_purple_400,
        },
        '& .token.attr-value, & .token.tag, & .token.script .punctuation, & .token.atrule': {
            color: '#bcbcff',
        },
        '& .token.function': {
            color: theme.unsafe_globalTokens.color_global_functional_static_neutral_white,
        },
        '& .token.regex, & .token.important': {
            color: theme.unsafe_globalTokens.color_global_functional_light_purple_400,
        },
        '& .token.namespace': {
            opacity: 1,
        },
        '& .line-highlight': {
            background: 'rgba(85, 85, 85, 0.2)',
            borderBottom: '0.5px solid rgba(255, 255, 255, 0.25)',
            borderTop: '0.5px solid rgba(255, 255, 255, 0.25)',
        },
        '& .line-numbers': __assign({}, theme.typography.code1),
        '& .line-numbers .line-numbers-rows': {
            borderRight: 'none',
            color: '#5a5f66',
        },
        '& :not(pre) > code[class*="language-"], & pre[class*="language-"]': {
            background: theme.palette.mode === 'dark'
                ? theme.tokens.color_bg_page
                : theme.unsafe_globalTokens.color_global_functional_dark_neutral_100,
        },
        '& pre[class*="language-"]': {
            margin: 0,
        },
        '& .token.package:before, & .token.package:after': {
            display: 'none',
        },
        '& .token.package': {
            background: 'none',
            border: 'none',
        },
    });
});
var CodeHighlightPrism = function (_a) {
    var language = _a.language, highlightedLines = _a.highlightedLines, code = _a.code, _b = _a.showLineNumbers, showLineNumbers = _b === void 0 ? true : _b, _c = _a.showCopyButton, showCopyButton = _c === void 0 ? false : _c;
    var el = useRef(null);
    var preClassNames = ['code-highlight-prism', "language-".concat(language)];
    if (highlightedLines) {
        preClassNames.push('code-highlight-prism');
    }
    if (showLineNumbers) {
        preClassNames.push('line-numbers');
        preClassNames.push('linkable-line-numbers');
    }
    var preClassName = preClassNames.join(' ');
    useEffect(function () {
        if (el.current) {
            Prism.highlightElement(el.current);
        }
    }, [code, highlightedLines]);
    return (React.createElement(CodeHighlightPrismWrapper, null,
        React.createElement("pre", { className: preClassName, "data-line": highlightedLines },
            React.createElement("code", { className: "code-highlight-prism language-".concat(language), style: { fontFamily: 'unset' }, ref: el }, code)),
        showCopyButton ? React.createElement(CopyButton, { code: code }) : null));
};
export default CodeHighlightPrism;
