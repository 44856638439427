import { useMediaQuery, useTheme } from '@auth0/quantum-product';
import * as React from 'react';
export var ArticleType;
(function (ArticleType) {
    ArticleType["Default"] = "article";
    ArticleType["LandingPage"] = "landingPage";
})(ArticleType || (ArticleType = {}));
var ArticleContext = React.createContext({ isMobile: false, hasFullwidth: false, articleType: ArticleType.Default });
export var ArticleContextProvider = function (_a) {
    var _b = _a.type, type = _b === void 0 ? ArticleType.Default : _b, children = _a.children;
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down(theme.layout.tabletBreakpoint));
    var hasFullwidth = useMediaQuery(theme.breakpoints.up(theme.layout.header1440 + 8 * 6));
    var context = { isMobile: isMobile, hasFullwidth: hasFullwidth, articleType: type };
    return React.createElement(ArticleContext.Provider, { value: context }, children);
};
export var useArticleContext = function () { return React.useContext(ArticleContext); };
