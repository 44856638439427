import { Button, CheckIcon, CopyIcon, styled } from '@auth0/quantum-product';
import React from 'react';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
var CopyButtonLabel = styled('span')(function (_a) {
    var theme = _a.theme;
    return ({
        marginLeft: theme.spacing(1),
    });
});
var CopyButton = function (_a) {
    var code = _a.code;
    var _b = useCopyToClipboard(), isCopied = _b.isCopied, copyToClipboard = _b.copyToClipboard;
    var handleCopyCode = function () { return copyToClipboard(code); };
    return (React.createElement(Button, { size: "medium", variant: "outlined", color: "default", onClick: handleCopyCode }, isCopied ? (React.createElement(React.Fragment, null,
        React.createElement(CheckIcon, null),
        React.createElement(CopyButtonLabel, null, "Copied"))) : (React.createElement(React.Fragment, null,
        React.createElement(CopyIcon, null),
        React.createElement(CopyButtonLabel, null, "Copy")))));
};
export default CopyButton;
