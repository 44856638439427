import { connectToStores } from 'fluxible-addons-react';
import { RouteStore } from 'fluxible-router';
import NavigationStore from '../../../stores/NavigationStore';
import { GlossaryPage } from './GlossaryPage';
export default connectToStores(GlossaryPage, [RouteStore, NavigationStore], function (_a) {
    var _b;
    var getStore = _a.getStore;
    var route = getStore(RouteStore).getCurrentRoute();
    var glossary = getStore(NavigationStore).getGlossary();
    return {
        termLetters: glossary.termLetters,
        termsByLetter: glossary.termsByLetter,
        terms: glossary.terms,
        query: (_b = route === null || route === void 0 ? void 0 : route.query) === null || _b === void 0 ? void 0 : _b.term,
    };
});
