import { Box, Spinner, StackLayout, StackLayoutItem, Text } from '@auth0/quantum-product';
import * as React from 'react';
export var LoadingPage = function () {
    return (React.createElement(Box, { display: "flex", justifyContent: "center", alignItems: "center", minHeight: 400 },
        React.createElement(StackLayout, null,
            React.createElement(StackLayoutItem, null,
                React.createElement(Text, { variant: "h5" }, "Loading...")),
            React.createElement(StackLayoutItem, null,
                React.createElement(Spinner, null)))));
};
