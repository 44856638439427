import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import styles from './styles.module.styl';

const RequiredUserResourcesTooltip = ({ requiredResources, children }) => {
  if (!requiredResources) return children;

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id="required-user-data-tooltip" className={cx(styles.resourcesTooltip, 'in')}>
          Create {requiredResources} to download a configured sample
        </Tooltip>
      }
    >
      <div className={styles.resourcesTooltipWrapper}>{children}</div>
    </OverlayTrigger>
  );
};

RequiredUserResourcesTooltip.propTypes = {
  requiredResources: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]).isRequired,
  children: PropTypes.node.isRequired,
};

export default RequiredUserResourcesTooltip;
