import * as React from 'react';
import navigateToQuickstart from '../../../action/navigateToQuickstart';
import { useFluxible } from '../../hooks/useFluxible';
export var Quickstart = function (_a) {
    var quickstart = _a.quickstart;
    var executeAction = useFluxible().executeAction;
    var handleClick = function () {
        executeAction(navigateToQuickstart, {
            quickstartId: quickstart.name,
        });
    };
    return (React.createElement("div", { className: "quickstart", "data-type": quickstart.name, onClick: handleClick },
        React.createElement("div", { className: "symbol" }),
        React.createElement("strong", { className: "title" }, quickstart.title),
        React.createElement("p", { className: "description" }, quickstart.description),
        React.createElement("p", { className: "sample" }, quickstart.example),
        React.createElement("div", { className: "cta" },
            React.createElement("button", { className: "btn btn-success btn-sm" }, "Launch Quickstart"))));
};
