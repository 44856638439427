import { styled } from '@auth0/quantum-product';
export var TopContent = styled('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
            gridGap: theme.spacing(3)
        },
        _b[theme.breakpoints.down(theme.layout.mobileBreakpoint)] = {
            flexDirection: 'column',
            alignItems: 'center',
        },
        _b);
});
