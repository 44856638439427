import { connectToStores } from 'fluxible-addons-react';
import NavigationStore from '../../stores/NavigationStore';
import canUseStore from '../../util/canUseStore';
import { Header } from './Header';
export default connectToStores(Header, [NavigationStore], function (context) {
    if (!canUseStore(context))
        return {};
    return {
        sections: context.getStore(NavigationStore).getSections(),
    };
});
