var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import metrics from '@a0/docs-components/metrics';
import { Box, Button, FieldSet, Form, styled, TextField } from '@auth0/quantum-product';
import * as React from 'react';
var CheckpointCompletionState;
(function (CheckpointCompletionState) {
    CheckpointCompletionState["Ready"] = "READY";
    CheckpointCompletionState["Success"] = "SUCCESS";
    CheckpointCompletionState["Failure"] = "FAILURE";
    CheckpointCompletionState["Thanks"] = "THANKS";
})(CheckpointCompletionState || (CheckpointCompletionState = {}));
var Container = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: theme.spacing(3),
        position: 'static',
        left: '0px',
        top: '40px',
        margin: theme.spacing(5, 0),
        borderRadius: 8,
        backgroundColor: theme.tokens.color_bg_selected_subtle,
    });
});
var StyledHeader = styled('h5')(function () { return ({
    marginTop: '0px',
    marginBottom: '10px',
}); });
var ContentContainer = styled('div')(function () { return ({
    lineHeight: '20px',
    fontSize: '14px',
}); });
var StyledButton = styled(Button)(function (_a) {
    var theme = _a.theme;
    return ({
        backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : undefined,
    });
});
var StyledButtonSuccess = styled(Button)(function (_a) {
    var theme = _a.theme;
    return ({
        backgroundColor: theme.unsafe_globalTokens.color_global_functional_static_neutral_300,
        color: theme.unsafe_globalTokens.color_global_functional_static_neutral_white,
        '&:active': {
            color: theme.unsafe_globalTokens.color_global_functional_light_neutral_50,
        },
        '&:hover, &:active': {
            backgroundColor: theme.unsafe_globalTokens.color_global_functional_dark_neutral_300,
        },
    });
});
export var Checkpoint = function (props) {
    var _a = __read(React.useState(CheckpointCompletionState.Ready), 2), completionState = _a[0], setCompletionState = _a[1];
    var _b = __read(React.useState(''), 2), comment = _b[0], setComment = _b[1];
    var getEventData = function (positive) {
        return {
            value: positive,
            track_data2: props.index,
        };
    };
    var sendCommentFeedback = function (positive, comment) {
        // Extract the new fragment from props
        var newFragment = props.feedbackSectionURL;
        // Get the current URL and remove any existing fragment
        var baseUrl = window === null || window === void 0 ? void 0 : window.location.href.split('#')[0];
        // Construct the new URL with the new fragment
        var newUrl = "".concat(baseUrl, "#").concat(newFragment);
        metrics.track('checkpoint:submit-comment', __assign(__assign({}, getEventData(positive)), { trackData: comment, sectionURL: newUrl }));
        try {
            fetch('/docs/submit-feedback', {
                method: 'POST',
                headers: new Headers({
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                }),
                body: JSON.stringify({
                    page_title: document.title,
                    page_url: newUrl,
                    positive: positive,
                    comment: comment,
                }),
            });
        }
        catch (err) {
            console.error('Error submitting feedback', err);
        }
    };
    var handleCommentFeedback = function (event) {
        event.preventDefault();
        sendCommentFeedback(completionState === CheckpointCompletionState.Success, comment || '');
        setCompletionState(CheckpointCompletionState.Thanks);
    };
    var sendBooleanFeedback = function (positive) {
        metrics.track('checkpoint:submit', getEventData(positive));
    };
    var handleCommentChange = function (event) {
        setComment(event.target.value);
    };
    var completeCheckpoint = function (checkpointState) {
        if (checkpointState === CheckpointCompletionState.Success ||
            checkpointState === CheckpointCompletionState.Failure) {
            sendBooleanFeedback(checkpointState === CheckpointCompletionState.Success);
        }
        setCompletionState(checkpointState);
        setComment('');
    };
    return (React.createElement(Container, null,
        React.createElement(StyledHeader, null, "Checkpoint"),
        completionState === CheckpointCompletionState.Ready && (React.createElement(React.Fragment, null,
            React.createElement(ContentContainer, null,
                React.createElement("div", { dangerouslySetInnerHTML: { __html: props.content } })),
            React.createElement(Box, { sx: { display: 'flex', gap: 1, marginTop: 2 } },
                React.createElement(StyledButtonSuccess, { size: "medium", color: "primary", onClick: function () { return completeCheckpoint(CheckpointCompletionState.Success); } }, "It worked!"),
                React.createElement(StyledButton, { size: "medium", color: "default", variant: "outlined", onClick: function () { return completeCheckpoint(CheckpointCompletionState.Failure); } }, "Something's not right")))),
        completionState === CheckpointCompletionState.Success && (React.createElement(React.Fragment, null,
            React.createElement(Form, { sx: { width: '100%' }, layout: "inline", onSubmit: handleCommentFeedback },
                React.createElement(FieldSet, null,
                    React.createElement(TextField, { fullWidth: true, multiline: true, rows: 3, placeholder: 'Glad to hear that! Any suggestions for improvement?', onChange: handleCommentChange })),
                React.createElement(Box, { sx: { display: 'flex', gap: 1, marginTop: 2 } },
                    React.createElement(StyledButtonSuccess, { size: "medium", color: "primary", type: "submit", disabled: !comment }, "Submit"),
                    React.createElement(StyledButton, { size: "medium", color: "default", variant: "outlined", onClick: function () { return completeCheckpoint(CheckpointCompletionState.Ready); } }, "Cancel"))))),
        completionState === CheckpointCompletionState.Failure && (React.createElement(React.Fragment, null,
            React.createElement(ContentContainer, null,
                React.createElement("div", { dangerouslySetInnerHTML: { __html: props.failureContent } })),
            React.createElement(Form, { sx: { width: '100%' }, layout: "inline", onSubmit: handleCommentFeedback },
                React.createElement(FieldSet, null,
                    React.createElement(TextField, { fullWidth: true, multiline: true, rows: 3, placeholder: 'How can we improve?', onChange: handleCommentChange })),
                React.createElement(Box, { sx: { display: 'flex', gap: 1, marginTop: 2 } },
                    React.createElement(StyledButtonSuccess, { size: "medium", color: "primary", type: "submit", disabled: !comment }, "Submit"),
                    React.createElement(StyledButton, { size: "medium", color: "default", variant: "outlined", onClick: function () { return completeCheckpoint(CheckpointCompletionState.Ready); } }, "Cancel"))))),
        completionState === CheckpointCompletionState.Thanks && (React.createElement(React.Fragment, null,
            React.createElement(ContentContainer, null,
                React.createElement("p", null, "Thank you for your feedback!"),
                React.createElement(StyledButton, { size: "medium", color: "default", variant: "outlined", onClick: function () { return completeCheckpoint(CheckpointCompletionState.Ready); } }, "Ok"))))));
};
