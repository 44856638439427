var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { Box, SidebarItems, SidebarLink } from '@auth0/quantum-product';
import * as React from 'react';
import { Sticky } from 'react-sticky';
var sidebarLinks = [
    {
        title: 'Single-page app libraries',
        id: 'spa',
    },
    {
        title: 'Regular web app libraries',
        id: 'webapp',
    },
    {
        title: 'Back-end / API libraries',
        id: 'backend',
    },
    {
        title: 'Native / mobile libraries',
        id: 'native',
    },
    {
        title: 'Management API SDK libraries',
        id: 'mgmt',
    },
    {
        title: 'Auth0 Lock SDK libraries',
        id: 'lock',
    },
];
export var Sidebar = function () {
    var _a = __read(React.useState(''), 2), activeLink = _a[0], setActiveLink = _a[1];
    var _b = __read(React.useState(''), 2), hash = _b[0], setHash = _b[1];
    React.useEffect(function () {
        var setCurrentHash = function () {
            setHash(location === null || location === void 0 ? void 0 : location.hash);
        };
        setCurrentHash(); // for first render
        window.addEventListener('hashchange', setCurrentHash, false);
        return function () { return window.removeEventListener('hashchange', setCurrentHash, false); };
    }, []);
    React.useEffect(function () {
        var callback = function (entries) {
            var e_1, _a;
            try {
                for (var entries_1 = __values(entries), entries_1_1 = entries_1.next(); !entries_1_1.done; entries_1_1 = entries_1.next()) {
                    var entry = entries_1_1.value;
                    if (entry.isIntersecting) {
                        var id = entry.target.getAttribute('data-library-type');
                        setHash("#".concat(id));
                        setActiveLink(id);
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (entries_1_1 && !entries_1_1.done && (_a = entries_1.return)) _a.call(entries_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        };
        var observer = new IntersectionObserver(callback, {
            rootMargin: '0px 0px -50%',
            threshold: 0,
        });
        var headings = document.querySelectorAll('h3[data-library-type]');
        headings.forEach(function (heading) {
            observer.observe(heading);
        });
        return function () {
            observer.disconnect();
        };
    }, []);
    return (React.createElement(Sticky, null, function (_a) {
        var style = _a.style, isSticky = _a.isSticky;
        return (React.createElement(Box, { component: "nav", sx: { pt: 7, display: 'flex', justifyContent: 'flex-end' }, style: __assign(__assign({}, style), { marginTop: isSticky ? '70px' : '0px' }) },
            React.createElement(SidebarItems, null, sidebarLinks.map(function (sb) { return (React.createElement(SidebarLink, { sx: { '& > a': { pl: 0 }, '& .QuantumSidebarLink-startIcon': { display: 'none' } }, title: sb.title, href: "#".concat(sb.id), color: "textSecondary", isActive: sb.id === activeLink })); }))));
    }));
};
