import QuickstartStore from '../stores/QuickstartStore';
export default function loadQuickstarts(context) {
    var logger = context.getService('LoggingService');
    var success = function (_a) {
        var quickstarts = _a.quickstarts;
        context.dispatch('QUICKSTARTS_LOAD_SUCCESS', { quickstarts: quickstarts });
        logger.debug('Platforms loaded successfully.');
        return Promise.resolve();
    };
    var failure = function (err) {
        context.dispatch('QUICKSTARTS_LOAD_FAILURE', { err: err });
        logger.warn('Error loading quickstarts.', { err: err });
        return Promise.resolve();
    };
    // First, check to see if the content has already been loaded.
    var quickstarts = context.getStore(QuickstartStore).getQuickstarts();
    if (quickstarts) {
        return success({ quickstarts: quickstarts });
    }
    // If the cards haven't been loaded (or a previous load resulted in
    // an error), try to load it using the NavigationService.
    context.dispatch('QUICKSTARTS_LOADING');
    return context.getService('NavigationService').loadQuickstarts().then(success).catch(failure);
}
