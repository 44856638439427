import Auth0ContactForm from 'auth0-contact-form';
import * as React from 'react';
import { useFluxible } from '../hooks/useFluxible';
export var VideoFooter = function () {
    var context = useFluxible();
    var contactFormRef = React.useRef();
    React.useEffect(function () {
        contactFormRef.current = new Auth0ContactForm({
            source: 'docs',
            modalTitle: 'Contact for Instructor-led Training',
            scheduling: false,
            includePhoneField: false,
            postUrl: 'https://wtg-docs-forms.sandbox.auth0-extend.com/form-handler',
            includeRoleField: true,
            dictionary: {
                send: 'Send',
            },
            onModalOpen: function () {
                context.trackEvent('open:talk-to-training');
            },
            onFormSuccess: function (metricsData) {
                context.trackEvent('register:lead:talk-to-training-modal', metricsData);
                context.trackEvent('sent:talk-to-training', metricsData);
            },
            onFormFail: function (metricsData) {
                context.trackEvent('fail:register:lead:talk-to-training-modal', metricsData);
                context.trackEvent('fail-sent:talk-to-training', metricsData);
            },
        });
    }, []);
    return (React.createElement("div", { className: "try-banner" },
        React.createElement("span", null, "Interested in instructor-led version of this training?"),
        React.createElement("a", { onClick: function () { var _a; return (_a = contactFormRef === null || contactFormRef === void 0 ? void 0 : contactFormRef.current) === null || _a === void 0 ? void 0 : _a.show(); }, className: "btn btn-success btn-lg" }, "Contact Training")));
};
