var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Image, styled, useTheme } from '@auth0/quantum-product';
import * as React from 'react';
import { assetUrl } from '../../util/assetUrl';
import { SectionPipelineButton } from './SectionPipelineButton';
import { SectionPipelineLink } from './SectionPipelineLink';
var SectionPipelineDimensions;
(function (SectionPipelineDimensions) {
    SectionPipelineDimensions[SectionPipelineDimensions["width"] = 1202] = "width";
    SectionPipelineDimensions[SectionPipelineDimensions["height"] = 424] = "height";
})(SectionPipelineDimensions || (SectionPipelineDimensions = {}));
var SizeMultipliers;
(function (SizeMultipliers) {
    SizeMultipliers[SizeMultipliers["small"] = 0.6] = "small";
    SizeMultipliers[SizeMultipliers["medium"] = 0.85] = "medium";
    SizeMultipliers[SizeMultipliers["large"] = 1] = "large";
})(SizeMultipliers || (SizeMultipliers = {}));
var sectionpipeline = {
    width: {
        small: SectionPipelineDimensions.width * SizeMultipliers.small,
        medium: SectionPipelineDimensions.width * SizeMultipliers.medium,
        large: SectionPipelineDimensions.width * SizeMultipliers.large,
    },
    height: {
        small: SectionPipelineDimensions.height * SizeMultipliers.small,
        medium: SectionPipelineDimensions.height * SizeMultipliers.medium,
        large: SectionPipelineDimensions.height * SizeMultipliers.large,
    },
};
var SectionPipelineContainer = styled('div')(function (_a) {
    var _b;
    var theme = _a.theme, align = _a.align;
    return (_b = {
            display: 'flex',
            justifyContent: align
        },
        _b[theme.breakpoints.down(theme.layout.landingBreakpoint)] = {
            justifyContent: 'center',
        },
        _b[theme.breakpoints.down(theme.layout.mobileBreakpoint)] = {
            display: 'none',
        },
        _b);
});
var AdjustableWrapper = styled('div')(function (_a) {
    var _b;
    var theme = _a.theme, size = _a.size;
    return (_b = {
            width: sectionpipeline.width[size === 'small' ? 'medium' : 'large'],
            height: sectionpipeline.height[size === 'small' ? 'medium' : 'large']
        },
        _b[theme.breakpoints.down(theme.layout.landingBreakpoint)] = {
            width: sectionpipeline.width[size === 'small' ? 'small' : 'medium'],
            height: sectionpipeline.height[size === 'small' ? 'small' : 'medium'],
        },
        _b[theme.breakpoints.down(theme.layout.tabletBreakpoint)] = {
            width: sectionpipeline.width.medium,
            height: sectionpipeline.height.medium,
        },
        _b[theme.breakpoints.down(theme.layout.landingContentWidth + theme.spacingPX(10))] = {
            width: sectionpipeline.width.small,
            height: sectionpipeline.height.small,
        },
        _b);
});
var SectionPipelineWrapper = styled('div')(function (_a) {
    var _b;
    var theme = _a.theme, _c = _a.size, size = _c === void 0 ? 'large' : _c;
    return (__assign(__assign({ position: 'relative', transformOrigin: 'top left' }, (size === 'small' && {
        transform: "scale(".concat(SizeMultipliers.medium, ")"),
    })), (_b = {}, _b[theme.breakpoints.down(theme.layout.landingBreakpoint)] = {
        transform: "scale(".concat(size === 'small' ? SizeMultipliers.small : SizeMultipliers.medium, ")"),
    }, _b[theme.breakpoints.down(theme.layout.tabletBreakpoint)] = {
        transform: "scale(".concat(SizeMultipliers.medium, ")"),
    }, _b[theme.breakpoints.down(theme.layout.landingContentWidth + theme.spacingPX(10))] = {
        transform: "scale(".concat(SizeMultipliers.small, ")"),
    }, _b)));
});
export var SectionPipeline = function (_a) {
    var _b = _a.align, align = _b === void 0 ? 'center' : _b, size = _a.size, active = _a.active;
    var theme = useTheme();
    var sectionPipelineImg = theme.palette.mode === 'dark' ? "site-graph-dark.svg" : 'site-graph.svg';
    return (React.createElement(SectionPipelineContainer, { align: align },
        React.createElement(AdjustableWrapper, { size: size },
            React.createElement(SectionPipelineWrapper, { size: size },
                React.createElement(Image, { alt: "sectionpipeline-background", src: assetUrl("/docs/img/".concat(sectionPipelineImg)), disableResponsive: true }),
                React.createElement(SectionPipelineLink, { name: "Get Started", href: "/docs/get-started", active: active },
                    React.createElement(SectionPipelineButton, { id: "Get Started", size: "large", top: 32, left: 0 })),
                React.createElement(SectionPipelineLink, { name: "Authenticate", href: "/docs/authenticate", active: active },
                    React.createElement(SectionPipelineButton, { id: "Authenticate", size: "large", top: 32, left: 185 }),
                    React.createElement(SectionPipelineButton, { id: "Provision Users - Database", size: "small", top: 313, left: 25 }),
                    React.createElement(SectionPipelineButton, { id: "Provision Users - SocialProviders", size: "small", top: 313, left: 123 }),
                    React.createElement(SectionPipelineButton, { id: "Add Login", size: "small", top: 313, left: 270 })),
                React.createElement(SectionPipelineLink, { name: "Manage Users", href: "/docs/manage-users", active: active },
                    React.createElement(SectionPipelineButton, { id: "Manage Users", size: "large", top: 32, left: 461 })),
                React.createElement(SectionPipelineLink, { name: "Customize", href: "/docs/customize", active: active },
                    React.createElement(SectionPipelineButton, { id: "Customize", size: "large", top: 32, left: 737 }),
                    React.createElement(SectionPipelineButton, { id: "Brand", size: "small", top: 313, left: 675 }),
                    React.createElement(SectionPipelineButton, { id: "Extend - Actions", size: "small", top: 313, left: 820 }),
                    React.createElement(SectionPipelineButton, { id: "Extend - Extensions", size: "small", top: 313, left: 920 })),
                React.createElement(SectionPipelineLink, { name: "Secure", href: "/docs/secure", active: active },
                    React.createElement(SectionPipelineButton, { id: "Secure", size: "medium", top: 124, left: 927 })),
                React.createElement(SectionPipelineLink, { name: "Deploy and Monitor", href: "/docs/deploy-monitor", active: active },
                    React.createElement(SectionPipelineButton, { id: "Deploy", size: "large", top: 32, left: 1094 }))))));
};
