var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import useScrollPosition from '@a0/docs-components/hooks/useScrollPosition';
import { Box, SidebarItems, SidebarLink } from '@auth0/quantum-product';
import * as React from 'react';
import { Sticky } from 'react-sticky';
import { CATEGORIES } from '../../data';
var CATEGORIES_REVERSED = __spreadArray([], __read(CATEGORIES), false).reverse();
var sidebarLinks = [
    {
        title: 'Regular Web App',
        id: 'webapp',
    },
    {
        title: 'Single-Page App',
        id: 'spa',
    },
    {
        title: 'Native/Mobile App',
        id: 'native',
    },
    {
        title: 'Backend/API',
        id: 'backend',
    },
];
export var Sidebar = function (_a) {
    var categoryRefs = _a.categoryRefs;
    var _b = __read(React.useState(''), 2), activeLink = _b[0], setActiveLink = _b[1];
    // updates component on scroll
    useScrollPosition();
    var activeCategory = CATEGORIES_REVERSED.find(function (category) {
        var _a, _b;
        var ref = categoryRefs[category];
        var pos = (_b = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect()) === null || _b === void 0 ? void 0 : _b.y;
        return pos < 60;
    });
    React.useEffect(function () {
        setActiveLink(activeCategory);
    });
    return (React.createElement(Sticky, null, function (_a) {
        var style = _a.style, isSticky = _a.isSticky;
        return (React.createElement(Box, { component: "nav", sx: { pt: 7 }, style: __assign(__assign({}, style), { marginTop: isSticky ? '70px' : '45px' }) },
            React.createElement(SidebarItems, null, sidebarLinks.map(function (sb) { return (React.createElement(SidebarLink, { sx: { '& > a': { pl: 0 }, '& .QuantumSidebarLink-startIcon': { display: 'none' } }, title: sb.title, href: "#".concat(sb.id), color: "textSecondary", isActive: sb.id === activeLink })); }))));
    }));
};
