import PropTypes from 'prop-types';
import React from 'react';

import { QUANTUM_UI } from '../../../../flags';
import newStyles from './styles.module.styl';
// import AnchorTitle from '../../AnchorTitle';
import oldStyles from './styles.old.module.styl';

const styles = QUANTUM_UI ? newStyles : oldStyles;

const MicrositeSteps = (props) => {
  return (
    <React.Fragment>
      {/* Hide Steps Title for Now */}
      {/* <AnchorTitle title={props.title} tag="h3" /> */}
      <ol className={styles.list}>
        {props.steps.map((step, index) => (
          <li>
            <div className={styles.bullet}>
              <span>{index + 1}</span>
            </div>
            <div className={styles.step} dangerouslySetInnerHTML={{ __html: step }} />
          </li>
        ))}
      </ol>
    </React.Fragment>
  );
};

MicrositeSteps.propTypes = {
  title: PropTypes.string.isRequired,
  steps: PropTypes.array.isRequired,
};

MicrositeSteps.defaultProps = {
  title: 'Steps',
  steps: [],
};

export default MicrositeSteps;
