import { useTheme } from '@auth0/quantum-product';
import * as React from 'react';
export var TutorialBackground = function () {
    var theme = useTheme();
    var strokeColor = theme.tokens.color_border_bold;
    return (React.createElement("svg", { width: "733", height: "424", viewBox: "0 0 733 424", preserveAspectRatio: "none", fill: "none" },
        React.createElement("path", { d: "M768.506 20.3184C668.526 -6.45543 571.754 -9.66825 472.761 38.257C417.71 65.0308 375.743 80.2919 320.445 51.3761C208.614 -7.52637 112.09 125.272 1.24701 21.1217", stroke: strokeColor, strokeWidth: "0.3324", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M768.506 41.202C711.974 25.9409 657.663 16.0346 602.365 19.5152C559.904 21.9249 517.197 33.1699 473.995 54.0535C444.865 68.2436 419.191 79.2209 393.517 82.4338C370.558 85.1112 347.353 81.6306 321.185 68.5114C272.553 44.1472 226.882 54.589 181.706 66.1017C122.458 81.3628 63.9508 99.0336 1 42.0053", stroke: strokeColor, strokeWidth: "0.3823", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M768.753 62.0854C712.468 46.8243 659.145 34.7761 604.094 36.6503C561.88 37.989 518.678 49.234 475.477 70.1176C446.346 84.04 420.672 95.5528 394.998 98.7656C371.793 101.711 348.587 98.4979 322.42 85.9142C273.787 62.6209 227.87 72.795 182.447 84.5755C122.952 99.8366 64.4447 118.311 1.24701 62.8887", stroke: strokeColor, strokeWidth: "0.4322", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M768.753 83.2368C712.468 67.9757 660.379 53.5178 605.575 53.7855C563.361 54.0533 520.159 65.2983 476.711 86.1819C447.58 100.104 421.66 111.885 395.985 115.098C372.78 118.311 349.328 115.365 323.407 103.317C274.774 81.0949 228.857 91.269 183.187 103.049C123.445 118.311 64.6913 137.32 1.49359 83.7723", stroke: strokeColor, strokeWidth: "0.482", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M768.753 104.121C712.715 88.5919 661.367 72.2598 607.056 70.9211C564.842 69.8502 521.394 81.0952 477.945 101.979C448.568 115.901 422.647 127.682 397.22 131.43C374.015 134.911 350.316 132.233 324.395 120.721C275.762 99.5692 229.598 109.743 183.681 121.524C123.693 136.785 64.6916 156.33 1.24701 104.924", stroke: strokeColor, strokeWidth: "0.5319", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M768.753 125.272C712.715 109.743 662.601 91.0016 608.537 88.3242C566.57 86.1823 522.875 97.4273 479.18 118.311C449.803 132.233 423.882 144.014 398.207 148.03C374.755 151.51 351.303 149.369 325.135 138.391C276.256 118.311 230.092 128.485 184.175 140.265C123.939 155.527 64.6916 175.875 1.24701 126.075", stroke: strokeColor, strokeWidth: "0.5818", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M768.753 146.156C712.714 130.627 663.835 109.743 610.018 105.459C568.051 101.979 524.356 113.224 480.414 134.375C451.037 148.298 424.869 160.346 399.442 164.362C375.989 168.11 352.29 166.236 326.369 156.062C277.49 137.053 231.079 147.227 184.915 159.007C124.433 174.268 65.185 195.152 1.49359 147.227", stroke: strokeColor, strokeWidth: "0.6316", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M768.753 167.039C712.961 151.243 665.069 128.217 611.499 122.327C569.779 117.775 525.59 129.02 481.648 149.904C452.271 163.826 425.856 176.142 400.429 180.158C376.73 184.175 353.278 182.568 327.11 172.93C278.23 154.723 231.573 165.165 185.409 176.946C124.68 192.474 65.185 213.894 1.49359 167.843", stroke: strokeColor, strokeWidth: "0.6815", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M768.753 188.191C712.961 172.394 666.057 146.959 612.981 139.73C571.26 133.84 527.071 145.352 483.129 166.236C453.752 180.158 427.337 192.474 401.91 197.026C378.211 201.042 354.512 199.971 328.344 190.868C279.465 173.733 232.56 184.175 186.149 195.955C125.174 211.484 65.4319 233.438 1.49359 189.262", stroke: strokeColor, strokeWidth: "0.7314", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M768.753 209.074C712.962 193.278 667.291 165.7 614.215 156.865C572.742 149.904 528.306 161.149 484.117 182.3C454.74 196.223 428.078 208.806 402.651 213.358C378.952 217.642 355.006 216.839 328.838 208.271C279.959 192.207 233.054 202.648 186.397 214.429C125.421 229.958 65.4322 252.716 1.24701 210.145", stroke: strokeColor, strokeWidth: "0.7812", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M768.753 230.226C713.208 214.429 668.525 184.442 615.696 174C574.223 165.701 529.54 177.213 485.351 198.365C455.974 212.287 429.312 225.139 403.885 229.69C379.939 234.242 355.993 233.438 330.072 225.674C280.946 210.681 234.041 221.122 187.137 232.903C125.914 248.432 65.6787 271.725 1.49359 231.297", stroke: strokeColor, strokeWidth: "0.8311", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M768.753 251.109C713.208 235.045 669.76 202.916 617.177 191.136C575.951 181.765 531.021 193.278 486.585 214.161C456.961 228.084 430.3 240.935 404.873 245.755C380.927 250.306 356.981 250.038 330.813 242.809C281.687 228.887 234.535 239.329 187.631 251.109C126.161 266.638 65.6787 290.735 1.49359 251.913", stroke: strokeColor, strokeWidth: "0.881", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M768.753 271.993C713.208 255.928 670.747 221.658 618.659 208.271C577.432 197.561 532.502 209.074 487.82 230.225C458.196 244.148 431.287 257.267 406.107 262.354C382.161 267.173 357.968 267.173 331.8 260.48C282.674 247.629 235.276 257.803 188.124 269.851C126.408 285.38 65.9256 310.012 1.49359 273.064", stroke: strokeColor, strokeWidth: "0.9308", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M768.753 293.144C713.455 277.08 671.982 240.4 620.14 225.407C579.16 213.626 533.737 225.139 489.054 246.29C459.43 260.213 432.522 273.332 407.094 278.687C382.902 283.774 358.709 284.041 332.788 277.883C283.661 266.103 236.016 276.277 188.865 288.325C126.902 303.854 66.1725 329.289 1.49359 294.215", stroke: strokeColor, strokeWidth: "0.9807", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M768.753 314.028C713.455 297.696 673.216 258.873 621.621 242.541C580.641 229.422 535.218 240.935 490.288 262.086C460.664 276.009 433.509 289.396 408.329 294.75C384.136 299.837 359.943 300.641 333.775 295.018C284.402 284.309 236.757 294.483 189.359 306.531C127.148 322.328 66.1725 348.03 1.49359 314.831", stroke: strokeColor, strokeWidth: "1.0305", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M768.753 335.179C713.455 318.847 674.45 277.883 623.102 259.944C582.369 245.754 536.452 257.267 491.523 278.418C461.899 292.341 434.497 305.995 409.316 311.618C384.876 316.973 360.684 318.044 334.516 312.957C285.143 303.318 237.498 313.492 189.852 325.54C127.395 341.337 66.1725 367.575 1.49359 336.25", stroke: strokeColor, strokeWidth: "1.0804", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M768.753 356.062C713.702 339.73 675.438 296.357 624.584 276.812C583.851 261.283 537.934 273.063 492.757 294.215C462.886 308.137 435.731 321.792 410.304 327.682C385.864 333.305 361.424 334.643 335.257 330.092C285.883 321.524 237.991 331.698 190.099 343.746C127.396 359.543 66.1728 386.585 1.24701 357.133", stroke: strokeColor, strokeWidth: "1.1303", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M768.753 376.946C713.702 360.614 676.672 314.831 625.818 293.947C585.085 277.347 539.168 288.86 493.745 310.279C463.874 324.202 436.472 338.124 411.291 344.014C386.852 349.905 362.165 351.511 336.244 347.495C286.624 339.998 238.732 350.172 190.84 362.221C127.889 378.017 66.4196 405.594 1.24701 378.017", stroke: strokeColor, strokeWidth: "1.1801", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M768.753 398.098C713.702 381.498 677.907 333.573 627.299 311.35C586.813 293.412 540.402 305.192 494.979 326.344C465.108 340.266 437.706 354.456 412.279 360.346C387.592 366.237 363.153 368.111 336.985 364.898C287.365 358.472 239.226 368.646 191.334 380.695C128.136 396.491 66.4196 424.871 1.24701 398.901", stroke: strokeColor, strokeWidth: "1.23", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M768.753 418.981C713.949 402.382 678.894 352.047 628.78 328.218C588.294 308.941 541.884 320.989 496.213 342.14C466.343 356.063 438.694 370.253 413.513 376.679C388.827 382.837 364.14 384.979 337.972 382.301C288.352 376.946 239.966 387.12 191.828 399.169C128.383 414.965 66.6665 443.881 1.24701 420.052", stroke: strokeColor, strokeWidth: "1.2799", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M768.753 440.133C713.949 423.533 680.128 371.056 630.261 345.621C590.022 325.273 543.365 337.053 497.694 358.472C467.824 372.395 439.928 386.853 414.747 393.278C390.061 399.704 365.127 402.114 339.206 399.972C289.586 395.688 241.201 405.862 192.815 417.91C129.37 433.707 67.1601 463.426 1.7406 441.204", stroke: strokeColor, strokeWidth: "1.3297", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M769 461.016C714.196 444.149 681.61 389.53 631.989 362.756C591.75 341.069 544.846 353.117 499.176 374.269C469.305 388.191 441.409 402.917 416.229 409.343C391.295 415.768 366.609 418.713 340.441 417.107C290.574 413.894 242.188 424.068 193.556 436.116C129.864 451.913 67.407 482.167 1.7406 461.819", stroke: strokeColor, strokeWidth: "1.3796", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M769 481.9C714.443 465.032 682.844 408.004 633.471 379.624C593.478 356.598 546.327 368.646 500.41 390.066C470.292 403.988 442.396 418.714 417.216 425.675C392.283 432.368 367.349 435.581 341.181 434.51C291.314 432.368 242.682 442.275 194.049 454.591C130.111 470.655 67.6538 501.445 1.7406 482.971", stroke: strokeColor, strokeWidth: "1.4295", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M769 503.051C714.443 486.184 684.078 426.746 634.952 397.027C594.96 372.93 547.561 384.979 501.644 406.398C471.527 420.32 443.384 435.313 418.45 442.275C393.517 449.236 368.337 452.716 342.169 452.181C292.302 451.11 243.422 461.016 194.543 473.332C130.358 489.397 67.6538 520.722 1.7406 504.122", stroke: strokeColor, strokeWidth: "1.4793", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M769 523.935C714.442 506.8 685.065 445.22 636.433 414.162C596.687 388.727 549.042 401.043 502.878 422.462C472.761 436.385 444.618 451.646 419.438 458.607C394.257 465.836 369.077 469.852 343.156 469.584C293.289 469.316 244.163 479.49 195.283 491.806C130.851 507.871 67.9004 539.999 1.98718 525.006", stroke: strokeColor, strokeWidth: "1.5292", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M769 545.086C714.69 527.951 686.3 463.961 637.667 431.297C597.922 404.523 550.277 416.839 503.866 438.259C473.748 452.181 445.359 467.442 420.425 474.671C395.245 481.9 370.065 486.184 343.897 486.719C293.783 487.79 244.657 497.696 195.53 510.012C130.852 526.077 67.6538 558.741 1.7406 545.622", stroke: strokeColor, strokeWidth: "1.5791", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M769 565.97C714.689 548.834 687.534 482.703 639.148 448.432C599.65 420.588 551.511 432.904 505.1 454.323C474.983 468.245 446.346 483.774 421.413 491.271C395.986 498.767 370.805 503.319 344.637 504.39C294.524 506.532 245.397 516.438 196.024 528.754C131.098 544.818 67.9005 578.018 1.49359 567.041", stroke: strokeColor, strokeWidth: "1.6289", strokeMiterlimit: "10" })));
};
