var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState } from 'react';
export function useActiveFile(files, activeFileName, highlight) {
    var _a = __read(useState(''), 2), highlighted = _a[0], setHighlighted = _a[1];
    var _b = __read(useState(''), 2), filename = _b[0], setFilename = _b[1];
    // When highlight changes, we need to update the activeFile
    useEffect(function () {
        var _a = __read((highlight === null || highlight === void 0 ? void 0 : highlight.split('#')) || [], 2), fileName = _a[0], highlightedLines = _a[1];
        setFilename(fileName);
        setHighlighted(highlightedLines);
    }, [highlight]);
    // When the activeFile changes, we need to update the highlighted
    // When the activeFile.filename matches the filename segment in highlight, highlighting should be shown
    // If not, we should hide the highlighting.
    useEffect(function () {
        var _a = __read((highlight === null || highlight === void 0 ? void 0 : highlight.split('#')) || [], 2), filename = _a[0], highlightedLines = _a[1];
        if (filename === activeFileName) {
            setHighlighted(highlightedLines);
        }
        else {
            setHighlighted('');
        }
    }, [activeFileName]);
    // When the `files` change, we need to update the `activeFile` to ensure it reflects the latest value in `files`
    useEffect(function () {
        if (activeFileName) {
            setFilename(activeFileName);
        }
    }, [files]);
    return {
        highlighted: highlighted,
        filename: filename,
    };
}
