import { connectToStores } from 'fluxible-addons-react';
import React from 'react';
import QuickstartStore from '../../stores/QuickstartStore';
import canUseStore from '../../util/canUseStore';
import ConfiguredWithClient from '../ConfiguredWithClient';
import ConfiguredWithApi from './ConfiguredWithApi';
var ConfiguredWith = function (_a) {
    var quickstartType = _a.quickstartType;
    return quickstartType === 'backend' ? React.createElement(ConfiguredWithApi, null) : React.createElement(ConfiguredWithClient, null);
};
export default connectToStores(ConfiguredWith, [QuickstartStore], function (context) {
    if (!canUseStore(context))
        return {};
    var quickstartType = context.getStore(QuickstartStore).getQuickstartType();
    return {
        quickstartType: quickstartType,
    };
});
