import PropTypes from 'prop-types';
import React from 'react';

import { QUANTUM_UI } from '../../../../flags';
import newStyles from './styles.module.styl';
import oldStyles from './styles.old.module.styl';

const styles = QUANTUM_UI ? newStyles : oldStyles;

const MoreSection = ({ section, items, showMore, handleClick }) => {
  if (showMore) {
    return (
      <React.Fragment>
        <a className={styles.lessLink} onClick={handleClick}>
          Show Less
        </a>

        {items.map((item, index) => (
          <li dangerouslySetInnerHTML={{ __html: item }} key={`${section}-${index}-item`} />
        ))}
      </React.Fragment>
    );
  }

  return (
    <a className={styles.moreLink} onClick={handleClick}>
      + {items.length} More
    </a>
  );
};

MoreSection.propTypes = {
  section: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  showMore: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
};

MoreSection.defaultProps = {
  section: '',
  items: [],
  showMore: false,
};

export default MoreSection;
