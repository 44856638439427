import { BaseStore } from 'fluxible/addons';
import { cloneDeep } from 'lodash';

import replaceUserVars from '../../lib/replaceUserVars';
import normalizeUrl from '../util/normalizeUrl';
import replaceUserVarsInPortals from '../util/replaceUserVarsInPortals';
import replaceUserVarsInSampleFiles from '../util/replaceUserVarsInSampleFiles';
import { getAccountContext } from './helpers';
import LoadState from './LoadState';

class DocumentStore extends BaseStore {
  constructor(dispatcher) {
    super(dispatcher);
    this.user = undefined;
    this.userResources = {};
    this.docs = {};
    this.framedAccount = undefined;
  }

  getDocument(url) {
    const doc = this.docs[normalizeUrl(url)];

    if (!doc) return undefined;
    if (doc.state === LoadState.LOADED && !doc.html) this.replaceVarsInDocument(doc);
    return doc;
  }

  getClients() {
    return this.userResources.nonGlobalClients || [];
  }

  getApis() {
    return this.userResources.nonGlobalApis || [];
  }

  getSelectedClientId() {
    return this.userResources.selectedClientId;
  }

  getSelectedApiId() {
    return this.userResources.selectedApiId;
  }

  getSelectedClient() {
    return this.getClients().find((client) => client.client_id === this.getSelectedClientId());
  }

  getSelectedApi() {
    return this.getApis().find((api) => api.id === this.getSelectedApiId());
  }

  getSelectedApiIdentifier() {
    const selectedApi = this.getSelectedApi();
    return selectedApi ? selectedApi.identifier : undefined;
  }

  handleSelectedClient(payload) {
    this.userResources.selectedClientId = payload.selectedId;
    Object.keys(this.docs).forEach((url) => this.replaceVarsInDocument(this.docs[url]));
    this.emitChange();
  }

  handleSelectedApi(payload) {
    this.userResources.selectedApiId = payload.selectedId;
    Object.keys(this.docs).forEach((url) => this.replaceVarsInDocument(this.docs[url]));
    this.emitChange();
  }

  replaceVarsInDocument(doc) {
    if (doc.state !== 'LOADED') {
      return;
    }

    const context = Object.assign({}, this.user);

    if (this.framedAccount) {
      context.account = this.framedAccount;
    } else if (context.account) {
      const apiIdentifier = this.getSelectedApiIdentifier();
      const selectedClient = this.getSelectedClient();
      context.account = getAccountContext(context.account, apiIdentifier, selectedClient);
      //`getAccountContext` will use the `apiIdentifier` and set it on `context.account` when defined.
      // As `replaceUserVars*` uses `context.apiIdentifier`, we need to ensure we update that with `context.account.apiIdentifier` when defined.
      context.apiIdentifier = context.account.apiIdentifier || context.apiIdentifier;
    }

    if (context.account) {
      doc.html = replaceUserVars(doc.originalHtml, context);

      if (doc.meta) {
        if (!doc.meta.originalPortals) {
          doc.meta.originalPortals = cloneDeep(doc.meta.portals);
        }

        doc.meta.portals = replaceUserVarsInPortals(doc.meta.originalPortals, context);

        if (!doc.meta.originalSampleFiles && doc.meta.sampleFiles) {
          doc.meta.originalSampleFiles = cloneDeep(doc.meta.sampleFiles);
        }

        if (doc.meta.originalSampleFiles) {
          doc.meta.sampleFiles = replaceUserVarsInSampleFiles(doc.meta.originalSampleFiles, context);
        }
      }
    } else {
      doc.html = doc.originalHtml;
    }
  }

  handleDocumentLoading({ url }) {
    this.docs[url] = { state: LoadState.LOADING };
    this.emitChange();
  }

  handleDocumentLoaded({ url, doc }) {
    this.docs[url] = { state: LoadState.LOADED, originalHtml: doc.html, meta: doc.meta };
    this.emitChange();
  }

  handleDocumentLoadFailure({ url, err }) {
    this.docs[url] = { state: LoadState.ERROR, err };
    this.emitChange();
  }

  handleUserLoaded(props) {
    const { user, userResources = this.userResources } = props;
    this.user = user;
    this.userResources = userResources;

    Object.keys(this.docs).forEach((url) => this.replaceVarsInDocument(this.docs[url]));
    this.emitChange();
  }

  handleFrameDataLoad({ account }) {
    this.framedAccount = account;
    Object.keys(this.docs).forEach((url) => this.replaceVarsInDocument(this.docs[url]));
    this.emitChange();
  }

  handleAddedClient(payload) {
    this.userResources.nonGlobalClients = [...this.userResources.nonGlobalClients, payload];
    this.emitChange();
  }

  dehydrate() {
    return {
      docs: this.docs,
      user: this.user,
      userResources: this.userResources,
      framedAccount: this.framedAccount,
    };
  }

  rehydrate(state) {
    this.docs = state.docs;
    this.user = state.user;
    this.framedAccount = state.framedAccount;
    this.userResources = state.userResources;
  }
}

DocumentStore.storeName = 'DocumentStore';
DocumentStore.handlers = {
  DOCUMENT_LOADING: 'handleDocumentLoading',
  DOCUMENT_LOAD_SUCCESS: 'handleDocumentLoaded',
  DOCUMENT_LOAD_FAILURE: 'handleDocumentLoadFailure',
  USER_LOADED: 'handleUserLoaded',
  FRAME_DATA_LOADED: 'handleFrameDataLoad',
  DOCUMENT_CLIENTS_SELECTED: 'handleSelectedClient',
  DOCUMENT_APIS_SELECTED: 'handleSelectedApi',
  USER_CLIENTS_ADDED: 'handleAddedClient',
};

export default DocumentStore;
