import * as React from 'react';
import styles from './styles.module.styl';
/**
 * Select: Drop-down list.
 */
export var Select = function (_a) {
    var options = _a.options, _b = _a.selected, selected = _b === void 0 ? 0 : _b, _c = _a.label, label = _c === void 0 ? '' : _c, color = _a.color, handleChange = _a.handleChange, children = _a.children;
    var selectedOption = options[selected] || { label: '', value: '' };
    return (React.createElement("div", { className: styles.select },
        label && React.createElement("span", null, label),
        React.createElement("span", { className: "".concat(styles.value, " text-truncate"), style: { color: color } }, selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.label),
        React.createElement("i", { className: "icon-budicon-460" }),
        React.createElement("select", { 
            // Pass event and selected option object to onChange handler
            onChange: function (event) {
                return handleChange(event, options.filter(function (opt) { return opt.value === event.target.value; })[0]);
            }, value: selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.value }, children ||
            options.map(function (opt) { return (React.createElement("option", { key: opt.value, value: opt.value }, opt.label)); }))));
};
