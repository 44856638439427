var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { CssBaseline, ThemeProvider } from '@auth0/quantum-product';
import { CacheProvider } from '@emotion/react';
import mediaQuery from 'css-mediaquery';
import React from 'react';
import { ColorScheme } from '../../@types/ColorScheme';
import { getTheme } from '../../theme';
import createEmotionCache from '../../theme/createEmotionCache';
var isSafari = typeof navigator !== 'undefined' && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
var ThemeManagerContext = React.createContext({
    colorScheme: ColorScheme.Light,
    setColorScheme: function (colorScheme) { },
});
var prefersDarkModeQuery = '(prefers-color-scheme: dark)';
// const expiredCookie = `color-scheme=;path=/docs;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
var mutationCalled = false;
var currentColorScheme = 'light';
var darkModeMutationCallback = function (mutationsList) {
    var e_1, _a;
    try {
        for (var mutationsList_1 = __values(mutationsList), mutationsList_1_1 = mutationsList_1.next(); !mutationsList_1_1.done; mutationsList_1_1 = mutationsList_1.next()) {
            var mutation = mutationsList_1_1.value;
            if (mutation.type !== 'attributes' || mutation.attributeName !== 'data-theme') {
                return;
            }
            if (!mutationCalled && currentColorScheme === 'dark') {
                mutationCalled = true;
                mutation.target.setAttribute('data-theme', 'dark');
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (mutationsList_1_1 && !mutationsList_1_1.done && (_a = mutationsList_1.return)) _a.call(mutationsList_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
};
///
// This is a major hack to block docusaurus from resetting the data-theme attribute to light when it's dark
// We basically listen for the next data-theme set that occurs immediately after we set it, then disconnect the observer on the next turn of the event loop
///
var blockDownStreamSetDataTheme = function () {
    var darkModeAttributeObserver = new MutationObserver(darkModeMutationCallback);
    darkModeAttributeObserver.observe(document.documentElement, { attributes: true });
    setTimeout(function () {
        darkModeAttributeObserver.disconnect();
    });
};
export var useThemeManager = function () { return React.useContext(ThemeManagerContext); };
export var ThemeManager = function (_a) {
    var preferredColorScheme = _a.preferredColorScheme, isMobile = _a.isMobile, children = _a.children, _b = _a.baseline, baseline = _b === void 0 ? true : _b, _c = _a.ssr, ssr = _c === void 0 ? true : _c;
    var _d = __read(React.useState(preferredColorScheme || ColorScheme.SystemLight), 2), colorScheme = _d[0], setColorScheme = _d[1];
    var setColorSchemePreference = React.useCallback(function (colorScheme, persistent) {
        if (persistent === void 0) { persistent = false; }
        var colorSchemeCookie = "color-scheme=".concat(colorScheme, ";path=/docs;");
        if (persistent) {
            var expiresDate = new Date();
            expiresDate.setUTCFullYear(expiresDate.getUTCFullYear() + 1);
            colorSchemeCookie += "expires=".concat(expiresDate.toUTCString(), ";");
        }
        if (window.location.protocol !== 'http:') {
            colorSchemeCookie += 'secure';
        }
        document.cookie = colorSchemeCookie;
    }, []);
    var refreshColorScheme = React.useCallback(function (colorScheme) {
        currentColorScheme = colorScheme.includes('dark') ? 'dark' : 'light';
        window.localStorage.setItem('theme', currentColorScheme);
        if (!ssr) {
            setColorScheme(colorScheme);
            document.documentElement.setAttribute('data-theme', currentColorScheme);
            blockDownStreamSetDataTheme();
        }
        else {
            document.location.reload();
        }
    }, []);
    var setPreferredColorScheme = function (colorScheme) {
        if (!colorScheme || colorScheme === 'system') {
            var systemColorScheme = window.matchMedia(prefersDarkModeQuery).matches
                ? ColorScheme.SystemDark
                : ColorScheme.SystemLight;
            setColorSchemePreference(systemColorScheme);
            refreshColorScheme(systemColorScheme);
        }
        else {
            setColorSchemePreference(colorScheme, true);
            refreshColorScheme(colorScheme);
        }
    };
    React.useEffect(function () {
        var _a;
        if (!preferredColorScheme) {
            if (document.cookie.includes('color-scheme=')) {
                var parsedColorScheme = (_a = document.cookie.match(/color-scheme=([^;]+);?/)) === null || _a === void 0 ? void 0 : _a[1];
                if (parsedColorScheme) {
                    refreshColorScheme(parsedColorScheme);
                    return;
                }
            }
            if (window.matchMedia(prefersDarkModeQuery).matches) {
                setColorSchemePreference(ColorScheme.SystemDark);
                refreshColorScheme(ColorScheme.SystemDark);
                return;
            }
        }
        else {
            window.localStorage.setItem('theme', preferredColorScheme.includes('dark') ? 'dark' : 'light');
            setColorScheme(preferredColorScheme);
        }
    }, [preferredColorScheme]);
    React.useEffect(function () {
        var colorSchemeSwitchListener = function (e) {
            if (colorScheme === ColorScheme.Light || colorScheme === ColorScheme.Dark) {
                return;
            }
            var newScheme = e.matches ? ColorScheme.SystemDark : ColorScheme.SystemLight;
            setColorSchemePreference(newScheme);
            refreshColorScheme(newScheme);
        };
        var darkModeMatchList = window.matchMedia(prefersDarkModeQuery);
        darkModeMatchList.addEventListener('change', colorSchemeSwitchListener);
        return function () {
            darkModeMatchList.removeEventListener('change', colorSchemeSwitchListener);
        };
    }, [colorScheme]);
    var ssrMatchMedia = function (query) { return ({
        matches: mediaQuery.match(query, {
            width: isMobile ? 920 : 1920,
        }),
    }); };
    var theme = getTheme({
        colorScheme: colorScheme,
        props: {
            MuiUseMediaQuery: {
                ssrMatchMedia: ssrMatchMedia,
            },
        },
    });
    var cache = createEmotionCache(colorScheme);
    return (React.createElement(ThemeManagerContext.Provider, { value: {
            colorScheme: colorScheme,
            setColorScheme: setPreferredColorScheme,
        } },
        React.createElement(CacheProvider, { value: cache },
            React.createElement(ThemeProvider, { theme: theme },
                baseline && React.createElement(CssBaseline, null),
                children))));
};
