import PropTypes from 'prop-types';
import React from 'react';

import { signup } from '../../client/action/navigateAuthentication';
import { QUANTUM_UI } from '../../flags';

const buttonStyle = QUANTUM_UI ? 'btn-success-quantum-theme' : 'btn-success';

const TryBanner = (props, context) => (
  <div id="try-banner" data-search-index="false">
    <div className="try-banner try-banner-alt">
      <span>Use Auth0 for FREE</span>
      <button onClick={() => context.executeAction(signup)} className={`${buttonStyle} btn btn-lg`}>
        Create free Account
      </button>
    </div>
  </div>
);

TryBanner.contextTypes = {
  executeAction: PropTypes.func.isRequired,
};

export default TryBanner;
