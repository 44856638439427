import { styled } from '@auth0/quantum-product';
import { connectToStores } from 'fluxible-addons-react';
import PropTypes from 'prop-types';
import React from 'react';
import QuickstartStore from '../../../stores/QuickstartStore';
import UserStore from '../../../stores/UserStore';
import canUseStore from '../../../util/canUseStore';
import ConfiguredWith from '../../ConfiguredWith';
import Login from './Login';
var CodeBlockHeaderWrapper = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        marginBottom: theme.spacing(3),
        display: 'flex',
        justifyContent: 'space-between',
    });
});
var CodeBlockHeader = function (_a, context) {
    var _b = _a.isAuthenticated, isAuthenticated = _b === void 0 ? false : _b, quickstartType = _a.quickstartType, children = _a.children;
    return (React.createElement(CodeBlockHeaderWrapper, null,
        !isAuthenticated ? React.createElement(Login, { quickstartType: quickstartType }) : React.createElement(ConfiguredWith, null),
        " ",
        children));
};
CodeBlockHeader.contextTypes = {
    executeAction: PropTypes.func.isRequired,
};
export default connectToStores(CodeBlockHeader, [QuickstartStore, UserStore], function (context) {
    if (!canUseStore(context))
        return {};
    var userStore = context.getStore(UserStore);
    var quickstartStore = context.getStore(QuickstartStore);
    return {
        isAuthenticated: userStore.isAuthenticated(),
        quickstartType: quickstartStore.getQuickstartType(),
    };
});
