/* global $ */

export default function setTargetLinks(elem, isFramedMode) {
  // Only change target when inside manage
  if (!isFramedMode) return;

  $(elem)
    .find('a')
    .each(function () {
      if (this.target) return;
      if (this.getAttribute('role') === 'tab') return;

      if (isQuickStartLink(this.href)) {
        // If quickstart link in Manage, open up in Frame
        $(this).attr('href', `${this.href}?framed=1`);
      } else {
        $(this).attr('target', '_top');
      }
    });
}

function isQuickStartLink(link) {
  return !!link.match(new RegExp('^https://auth0\\.com/docs/quickstart'));
}
