import { styled } from '@auth0/quantum-product';
import { NavLink } from 'fluxible-router';
import * as React from 'react';
import ApplicationStore from '../../../../stores/ApplicationStore';
import QuickstartStore from '../../../../stores/QuickstartStore';
import { useFluxible } from '../../../hooks/useFluxible';
var Title = styled('h2')(function () { return ({
    // temporary overrides for inactive steps
    color: 'unset !important',
}); });
var Container = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        marginTop: theme.spacing(4),
    });
});
export var NextSteps = function (_a) {
    var items = _a.items;
    var getStore = useFluxible().getStore;
    var appStore = getStore(ApplicationStore);
    var platform = getStore(QuickstartStore).getCurrentPlatform();
    return (React.createElement(Container, null,
        React.createElement(Title, null, "Next Steps"),
        React.createElement("p", null, "Excellent work! If you made it this far, you should now have login, logout, and user profile information running in your application."),
        React.createElement("p", null, "This concludes our quickstart tutorial, but there is so much more to explore. To learn more about what you can do with Auth0, check out:"),
        React.createElement("ul", null,
            React.createElement("li", null,
                React.createElement("a", { href: appStore.getDashboardUrl(), target: "_blank" }, "Auth0 Dashboard"),
                ' ',
                "- Learn how to configure and manage your Auth0 tenant and applications"),
            platform && platform.sdk && (React.createElement("li", null,
                React.createElement("a", { href: platform.sdk.url, target: "_blank" },
                    platform.sdk.name,
                    " SDK"),
                ' ',
                "- Explore the SDK used in this tutorial more fully")),
            React.createElement("li", null,
                React.createElement("a", { href: "https://marketplace.auth0.com/", target: "_blank" }, "Auth0 Marketplace"),
                ' ',
                "- Discover integrations you can enable to extend Auth0\u2019s functionality"),
            items.map(function (item) { return (React.createElement("li", null,
                React.createElement(NavLink, { key: item.href, href: item.href, followLink: true }, item.text))); }))));
};
