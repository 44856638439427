import { RowLayout, styled } from '@auth0/quantum-product';
export var ContentContainer = styled(RowLayout)(function (_a) {
    var _b;
    var theme = _a.theme, fullWidth = _a.fullWidth, maxWidth = _a.maxWidth;
    return (_b = {
            maxWidth: fullWidth ? theme.layout.header1440 : maxWidth || theme.layout.homePageContentWidth,
            width: 'auto',
            margin: '0 auto'
        },
        _b[theme.breakpoints.down(theme.layout.tabletBreakpoint)] = {
            maxWidth: '100%',
            margin: theme.spacing(0, 3),
        },
        _b);
});
