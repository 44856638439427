import setTargetLinks from './targetLinks';
import setOutboundLinks from './outboundLinks';

export default (element, isFramedMode) => {
  if (element && element.innerHTML) {
    setTargetLinks(element, isFramedMode);
    setOutboundLinks(element);

    // Execute any scripts that came through innerHTML
    const dom = $(element.innerHTML);
    dom.filter('script').each(function () {
      $.globalEval(this.text || this.textContent || this.innerHTML || '');
    });
  }
};
