import { Box, styled } from '@auth0/quantum-product';
import { connectToStores } from 'fluxible-addons-react';
import React from 'react';
import ApplicationStore from '../../../stores/ApplicationStore';
import QuickstartStore from '../../../stores/QuickstartStore';
import UserStore from '../../../stores/UserStore';
import canUseStore from '../../../util/canUseStore';
import { useFluxible } from '../../hooks/useFluxible';
import CreateResource from './CreateResource';
import Login from './Login';
import SelectResource from './SelectResource';
var Root = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        height: 56,
        padding: theme.spacing(0, 2),
        display: 'flex',
        background: theme.palette.mode === 'light' ? theme.tokens.color_bg_layer_alternate : 'transparent',
        border: "1px solid ".concat(theme.palette.mode === 'light' ? 'transparent' : theme.tokens.color_border_default),
        borderBottom: 0,
        borderRadius: '3px 3px 0 0',
    });
});
var CodeBlockHeader = function (_a) {
    var isAuthenticated = _a.isAuthenticated, isApiIdInCode = _a.isApiIdInCode, isClientIdInCode = _a.isClientIdInCode, _b = _a.userClientsByType, userClientsByType = _b === void 0 ? {} : _b, _c = _a.userClients, userClients = _c === void 0 ? [] : _c, _d = _a.userApis, userApis = _d === void 0 ? [] : _d, selectedClientId = _a.selectedClientId, selectedApiId = _a.selectedApiId, dashboardClientsUrl = _a.dashboardClientsUrl, dashboardApisUrl = _a.dashboardApisUrl, children = _a.children, currentQuickstartType = _a.currentQuickstartType;
    var context = useFluxible();
    var handleUserResourceChange = function (action, selectedId) {
        context.executeAction(action, { selectedId: selectedId });
    };
    var userHasClient = !!userClients.length;
    var userHasApi = !!userApis.length;
    var showCreateClientMessage = isClientIdInCode && !userHasClient;
    var showCreateApiMessage = isApiIdInCode && !userHasApi;
    var showCreateResourceText = showCreateClientMessage || showCreateApiMessage;
    var state;
    if (!isAuthenticated) {
        state = 'LOGIN';
    }
    else {
        state = showCreateResourceText ? 'CREATE_RESOURCE' : 'SELECT_RESOURCE';
    }
    return (React.createElement(Root, null,
        {
            LOGIN: React.createElement(Login, null),
            CREATE_RESOURCE: (React.createElement(CreateResource, { showCreateClientMessage: showCreateClientMessage, dashboardClientsUrl: dashboardClientsUrl, dashboardApisUrl: dashboardApisUrl, currentQuickstartType: currentQuickstartType })),
            SELECT_RESOURCE: (React.createElement(SelectResource, { selectedClientId: selectedClientId, selectedApiId: selectedApiId, userApis: userApis, isApiIdInCode: isApiIdInCode, isClientIdInCode: isClientIdInCode, userClients: userClients, userClientsByType: userClientsByType, handleUserResourceChange: handleUserResourceChange })),
            default: null,
        }[state],
        children));
};
export default connectToStores(CodeBlockHeader, [ApplicationStore, UserStore], function (context) {
    if (!canUseStore(context))
        return {};
    var applicationStore = context.getStore(ApplicationStore);
    var userStore = context.getStore(UserStore);
    var currentQuickstartType = context.getStore(QuickstartStore).getQuickstartType();
    return {
        isAuthenticated: userStore.isAuthenticated(),
        userClients: userStore.getQuickstartCompatibleClients(currentQuickstartType),
        userClientsByType: userStore.getQuickstartCompatibleClientsByType(currentQuickstartType),
        userApis: userStore.getApis(),
        selectedClientId: userStore.getSelectedClientId(),
        selectedApiId: userStore.getSelectedApiId(),
        dashboardClientsUrl: applicationStore.getDashboardClientsUrl(),
        dashboardApisUrl: applicationStore.getDashboardApisUrl(),
        currentQuickstartType: currentQuickstartType,
    };
});
