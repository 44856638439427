var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { throttle } from 'lodash';
import { useEffect, useState } from 'react';
import canUseDOM from '../util/canUseDOM';
var getScrollPosition = function () {
    return { x: window.scrollX, y: window.scrollY };
};
var useScrollPosition = function (throttleAmount) {
    if (throttleAmount === void 0) { throttleAmount = 100; }
    var _a = __read(useState({ x: 0, y: 0 }), 2), scrollPos = _a[0], setScrollPos = _a[1];
    // eslint-disable-next-line consistent-return
    useEffect(function () {
        if (!canUseDOM) {
            return;
        }
        setScrollPos(getScrollPosition());
        var handleScroll = throttle(function () {
            setScrollPos(getScrollPosition());
        }, throttleAmount);
        // Bind lisenter
        window.addEventListener('scroll', handleScroll);
        // Unbind listener on Unmount
        return function () { return window.removeEventListener('scroll', handleScroll); };
    }, []);
    return scrollPos;
};
export default useScrollPosition;
