var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Spinner, styled } from '@auth0/quantum-product';
import { connectToStores } from 'fluxible-addons-react';
import React, { useEffect, useState } from 'react';
import quickstartAppExplorerActiveFileChanged from '../../action/quickstartAppExplorerActiveFileChanged';
import QuickstartStore from '../../stores/QuickstartStore';
import canUseStore from '../../util/canUseStore';
import CodeHighlightPrism from '../CodeHighlightPrism';
import { useFluxible } from '../hooks/useFluxible';
import CopyButton from './CopyButton/CopyButton';
import AppExplorerHeader from './Header';
import { useActiveFile } from './useActiveFile';
var AppExplorerCodeWrapper = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        backgroundColor: theme.palette.mode === 'dark'
            ? theme.tokens.color_bg_page
            : theme.unsafe_globalTokens.color_global_functional_dark_neutral_100,
        borderRadius: '8px',
        border: theme.palette.mode === 'dark' ? "1px solid ".concat(theme.tokens.color_border_default) : 0,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        maxHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& pre code': {
            backgroundColor: "".concat(theme.palette.mode === 'dark'
                ? theme.tokens.color_bg_page
                : theme.unsafe_globalTokens.color_global_functional_dark_neutral_100, " !important"),
        },
    });
});
var CodeHighlightPrismWrapper = styled('div')(function () { return ({
    flexGrow: 1,
    overflow: 'auto',
    '& pre.code-highlight-prism': {
        // override default dark mode border since it's applied to outer wrapper here
        border: 'none',
    },
}); });
var AppExplorerContent = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        color: '#f8f8f2',
        flexGrow: 1,
        width: '100%',
        position: 'relative',
        overflow: 'auto',
    });
});
var AppExplorerMenu = styled('ul')(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        listStyle: 'none',
        margin: 0,
        padding: 0,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        '& li': {
            padding: '2px 8px 2px 8px',
            borderRadius: '50px',
            margin: 0,
        },
        '& li.active': {
            background: theme.palette.grey[100],
        },
        '& li.active button': {
            color: theme.palette.grey[900],
        },
        '& button': {
            background: 'none',
            border: 'none',
            color: '#bdc4cf',
        },
    });
});
var AppExplorerCode = function (_a) {
    var highlightedLines = _a.highlightedLines, activeFile = _a.activeFile;
    return (React.createElement(CodeHighlightPrismWrapper, null, activeFile && (React.createElement(CodeHighlightPrism, { language: activeFile.language, highlightedLines: (highlightedLines === null || highlightedLines === void 0 ? void 0 : highlightedLines.replace(/:/g, '-')) || '', code: activeFile.content }))));
};
var AppExplorer = function (_a) {
    var files = _a.files, highlight = _a.highlight, activeFileName = _a.activeFileName;
    var context = useFluxible();
    var _b = useActiveFile(files, activeFileName, highlight), filename = _b.filename, highlighted = _b.highlighted;
    var setActiveFileName = function (filename) {
        context.executeAction(quickstartAppExplorerActiveFileChanged, {
            file: (filename && files.find(function (f) { return f.filename === filename; })) || files[0],
        });
    };
    var _c = __read(useState(undefined), 2), activeFile = _c[0], setActiveFile = _c[1];
    useEffect(function () {
        setActiveFileName(filename);
    }, [filename]);
    useEffect(function () {
        var name = activeFileName || filename;
        setActiveFile((name && files.find(function (f) { return f.filename === name; })) || files[0]);
    }, [files, activeFileName]);
    return files && files.length && activeFile ? (React.createElement(React.Fragment, null,
        React.createElement(AppExplorerHeader, null,
            React.createElement(CopyButton, { code: activeFile.content })),
        React.createElement(AppExplorerContent, null,
            React.createElement(AppExplorerCodeWrapper, null,
                React.createElement(AppExplorerMenu, null, files.map(function (file) { return (React.createElement("li", { key: file.filename, className: activeFileName === file.filename ? 'active' : '' },
                    React.createElement("button", { onClick: function () { return setActiveFileName(file.filename); } }, file.filename))); })),
                React.createElement(AppExplorerCode, { activeFile: activeFile, highlightedLines: highlighted }))))) : (React.createElement(Spinner, null));
};
export default connectToStores(AppExplorer, [QuickstartStore], function (context) {
    if (!canUseStore(context))
        return {};
    return {
        activeFileName: context.getStore(QuickstartStore).getAppExplorerActiveFileName(),
    };
});
