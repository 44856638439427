export default {
  name: 'lock',
  platforms: {
    lockForWeb: {
      title: 'Lock for Web',
      name: 'lock',
      description: 'Bug fixes only',
      logo: 'auth0',
      sdk: {
        name: 'Lock',
        url: 'https://github.com/auth0/lock',
        referenceUrl: '/docs/libraries/lock',
      },
    },
    lockForIOS: {
      title: 'Lock for iOS',
      name: 'lock',
      description: 'Bug fixes only',
      logo: 'auth0',
      sdk: {
        name: 'Lock.swift',
        url: 'https://github.com/auth0/Lock.swift',
        referenceUrl: '/docs/libraries/lock-swift',
      },
    },
    lockForAndroid: {
      title: 'Lock for Android',
      name: 'lock',
      description: 'Bug fixes only',
      logo: 'auth0',
      sdk: {
        name: 'Lock.Android',
        referenceUrl: '/docs/libraries/lock-android',
        url: 'https://github.com/auth0/Lock.Android',
      },
    },
  },
};
