import { styled } from '@auth0/quantum-product';
import { assetUrl } from '../../../util/assetUrl';
export var Symbol = styled('div')(function (_a) {
    var theme = _a.theme, type = _a.type;
    return ({
        borderRadius: theme.spacing(2),
        height: 100,
        width: 100,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        backgroundColor: theme.palette.common.white,
        position: 'relative',
        backgroundSize: '100%',
        boxShadow: '0 1px 14px 0 rgba(0, 0, 0, 0.1)',
        backgroundImage: "url(".concat(assetUrl("/docs/img/quickstarts/".concat(type, "-quantum.svg")), ")"),
    });
});
