import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.styl';

const ConfiguredResources = ({ showClient, showApi, selectedClientName, selectedApiName }) => (
  <React.Fragment>
    {showClient && (
      <span className={styles.resourceItem}>
        the application <code>{selectedClientName}</code>
      </span>
    )}
    {showClient && showApi && <span> and </span>}
    {showApi && (
      <span className={styles.resourceItem}>
        the API <code>{selectedApiName}</code>
      </span>
    )}
  </React.Fragment>
);

ConfiguredResources.propTypes = {
  showClient: PropTypes.bool,
  showApi: PropTypes.bool,
  selectedClientName: PropTypes.string,
  selectedApiName: PropTypes.string,
};

export default ConfiguredResources;
