import { connectToStores } from 'fluxible-addons-react';
import { QUANTUM_UI } from '../../../../flags';
import ApplicationStore from '../../../stores/ApplicationStore';
import QuickstartStore from '../../../stores/QuickstartStore';
import { TutorialNavigator } from './TutorialNavigator';
import { TutorialNavigator as TutorialNavigatorOld } from './TutorialNavigator.old';
export default connectToStores(QUANTUM_UI ? TutorialNavigator : TutorialNavigatorOld, [ApplicationStore, QuickstartStore], function (context) {
    var appStore = context.getStore(ApplicationStore);
    var quickstartStore = context.getStore(QuickstartStore);
    return {
        quickstarts: quickstartStore.getQuickstarts(),
        quickstart: quickstartStore.getCurrentQuickstart(),
        isFramedMode: appStore.isFramedMode(),
        breadcrumbs: quickstartStore.getBreadcrumbs(),
    };
});
