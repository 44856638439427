import { Box, Drawer, RowLayout, styled } from '@auth0/quantum-product';
import * as React from 'react';
import { NavLink } from '../../NavLink';
import { SearchBox } from '../../SearchBox';
import { ActionArea } from '../ActionArea';
var Root = styled(Drawer)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            zIndex: 0,
            '& .MuiDrawer-paperAnchorTop': {
                zIndex: 19,
                top: 64,
                padding: theme.spacing(3),
                bottom: 0,
            }
        },
        _b[theme.breakpoints.up('md')] = {
            display: 'none',
        },
        _b);
});
var DrawerBody = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    });
});
var Links = styled(RowLayout)(function (_a) {
    var theme = _a.theme;
    return ({
        overflowY: 'auto',
        flex: 1,
        alignContent: 'flex-start',
        margin: theme.spacing(2, -3, 0),
    });
});
var Link = styled(NavLink)(function (_a) {
    var theme = _a.theme;
    return ({
        borderBottom: "1px solid ".concat(theme.palette.divider),
        height: theme.spacing(9),
        display: 'flex',
        whiteSpace: 'nowrap',
        padding: theme.spacing(0, 3),
    });
});
export var NavigationDrawer = function (_a) {
    var show = _a.show, sections = _a.sections, currentSection = _a.currentSection, isAuthenticated = _a.isAuthenticated, user = _a.user, onClose = _a.onClose;
    return (React.createElement(Root, { variant: "persistent", ModalProps: { BackdropProps: { invisible: true } }, PaperProps: { elevation: 2 }, anchor: "top", open: show, onClose: onClose, "data-search-index": "false" },
        React.createElement(DrawerBody, null,
            React.createElement(SearchBox, { placement: "drawer", onChange: function () { return onClose(); } }),
            React.createElement(Links, { gutter: 0 }, sections.map(function (section) { return (React.createElement(Link, { href: section.url, variant: "subtitle1", key: section.id, color: currentSection === section.id ? 'primary' : 'textPrimary', onClick: function () { return onClose(); } }, section.title)); })),
            React.createElement(ActionArea, { placement: "drawer", isAuthenticated: isAuthenticated, user: user }))));
};
