var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
import { IdenticonActions, IdenticonApi, IdenticonApplications, IdenticonAttributes, IdenticonAuth0LogoLight, IdenticonAuthenticationA, IdenticonBranding, IdenticonCustomization, IdenticonDatabase, IdenticonExtensions, IdenticonGettingStarted, IdenticonHelp, IdenticonLogin, IdenticonMonitoring, IdenticonSdk, IdenticonSecurity, IdenticonSocialProviders, IdenticonUserManagement, IdenticonVideo, } from '@auth0/quantum-product';
import * as React from 'react';
var Section;
(function (Section) {
    Section["Api"] = "api";
    Section["Authenticate"] = "authenticate";
    Section["Customize"] = "customize";
    Section["DeployMonitor"] = "deploy-monitor";
    Section["GetStarted"] = "get-started";
    Section["Glossary"] = "glossary";
    Section["Home"] = "home";
    Section["Landing"] = "articles";
    Section["ManageUsers"] = "manage-users";
    Section["Quickstart"] = "quickstart";
    Section["Quickstarts"] = "quickstarts";
    Section["SDKs"] = "libraries";
    Section["Secure"] = "secure";
    Section["Troubleshoot"] = "troubleshoot";
    Section["Videos"] = "videos";
})(Section || (Section = {}));
var IdenticonName;
(function (IdenticonName) {
    IdenticonName["Api"] = "IdenticonApi";
    IdenticonName["Auth0"] = "IdenticonAuth0LogoLight";
    IdenticonName["Authenticate"] = "IdenticonAuthenticationA";
    IdenticonName["Customize"] = "IdenticonCustomize";
    IdenticonName["DeployMonitor"] = "IdenticonMonitoring";
    IdenticonName["GetStarted"] = "IdenticonGettingStarted";
    IdenticonName["Glossary"] = "IdenticonAttributes";
    IdenticonName["ManageUsers"] = "IdenticonUserManagement";
    IdenticonName["Quickstarts"] = "IdenticonApplications";
    IdenticonName["SDKs"] = "IdenticonSdk";
    IdenticonName["Secure"] = "IdenticonSecurity";
    IdenticonName["Troubleshoot"] = "IdenticonTroubleshoot";
    IdenticonName["Videos"] = "IdenticonVideo";
})(IdenticonName || (IdenticonName = {}));
export var SectionIdenticonName = (_a = {},
    _a[Section.Api] = IdenticonName.Api,
    _a[Section.Authenticate] = IdenticonName.Authenticate,
    _a[Section.Customize] = IdenticonName.Customize,
    _a[Section.DeployMonitor] = IdenticonName.DeployMonitor,
    _a[Section.GetStarted] = IdenticonName.GetStarted,
    _a[Section.Glossary] = IdenticonName.Glossary,
    _a[Section.Home] = IdenticonName.Auth0,
    _a[Section.Landing] = IdenticonName.Auth0,
    _a[Section.ManageUsers] = IdenticonName.ManageUsers,
    _a[Section.Quickstart] = IdenticonName.Quickstarts,
    _a[Section.Quickstarts] = IdenticonName.Quickstarts,
    _a[Section.SDKs] = IdenticonName.SDKs,
    _a[Section.Secure] = IdenticonName.Secure,
    _a[Section.Troubleshoot] = IdenticonName.Troubleshoot,
    _a[Section.Videos] = IdenticonName.Videos,
    _a);
export var getIdenticonNameFromSection = function (section) {
    return SectionIdenticonName[section];
};
export var Identicon = function (_a) {
    var name = _a.name, props = __rest(_a, ["name"]);
    var IconComponent;
    switch (name) {
        case 'IdenticonAuth0LogoLight':
            IconComponent = IdenticonAuth0LogoLight;
            break;
        case 'IdenticonGettingStarted':
            IconComponent = IdenticonGettingStarted;
            break;
        case 'IdenticonAuthenticationA':
            IconComponent = IdenticonAuthenticationA;
            break;
        case 'IdenticonUserManagement':
            IconComponent = IdenticonUserManagement;
            break;
        case 'IdenticonCustomize':
            IconComponent = IdenticonCustomization;
            break;
        case 'IdenticonSecurity':
            IconComponent = IdenticonSecurity;
            break;
        case 'IdenticonMonitoring':
            IconComponent = IdenticonMonitoring;
            break;
        case 'IdenticonTroubleshoot':
            IconComponent = IdenticonHelp;
            break;
        case 'IdenticonDatabase':
            IconComponent = IdenticonDatabase;
            break;
        case 'IdenticonSocialProviders':
            IconComponent = IdenticonSocialProviders;
            break;
        case 'IdenticonLogin':
            IconComponent = IdenticonLogin;
            break;
        case 'IdenticonBranding':
            IconComponent = IdenticonBranding;
            break;
        case 'IdenticonActions':
            IconComponent = IdenticonActions;
            break;
        case 'IdenticonExtensions':
            IconComponent = IdenticonExtensions;
            break;
        case 'IdenticonApi':
            IconComponent = IdenticonApi;
            break;
        case 'IdenticonApplications':
            IconComponent = IdenticonApplications;
            break;
        case 'IdenticonSdk':
            IconComponent = IdenticonSdk;
            break;
        case 'IdenticonVideo':
            IconComponent = IdenticonVideo;
            break;
        case 'IdenticonAttributes':
            IconComponent = IdenticonAttributes;
            break;
        default:
            break;
    }
    if (!IconComponent) {
        throw new Error("No icon found with the name \"".concat(name, "\""));
    }
    // @ts-ignore
    return React.createElement(IconComponent, __assign({}, props));
};
