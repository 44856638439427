import { defaultTheme as theme, styled, Text } from '@auth0/quantum-product';
import * as React from 'react';
import { ContentStyler } from '../../../../ContentRenderer';
import { NavLink } from '../../../../NavLink';
import { MetricsId } from '../../../../WithMetricsId';
var StyledText = styled(Text)({
    marginBottom: theme.spacing(1),
});
var TermDefinitionItems = styled('li')({
    listStyle: 'none',
});
var StyledLink = styled(NavLink)(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'inline-flex',
        fontWeight: theme.typography.fontWeightMedium,
        '&:hover': {
            color: theme.palette.primary.main,
            textDecoration: 'underline',
        },
    });
});
export var TermDefinition = function (_a) {
    var id = _a.id, title = _a.title, definition = _a.definition, associatedPage = _a.associatedPage;
    return (React.createElement(TermDefinitionItems, null,
        React.createElement(StyledText, { variant: "h5", id: id }, title),
        React.createElement(ContentStyler, null,
            React.createElement(Text, { variant: "body1", component: "span", dangerouslySetInnerHTML: { __html: definition } })),
        (associatedPage === null || associatedPage === void 0 ? void 0 : associatedPage.url) && (associatedPage === null || associatedPage === void 0 ? void 0 : associatedPage.title) && (React.createElement(Text, { variant: "body1" },
            "To learn more, see",
            ' ',
            React.createElement(StyledLink, { "data-metrics-id": MetricsId.GlossaryLearnMoreLink, href: associatedPage.url }, associatedPage.title),
            "."))));
};
