var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { ArrowLeftIcon, ArrowRightIcon, Box, Breadcrumbs, BreadcrumbsItem, ChevronDownIcon, ChevronUpIcon, SidebarItems, styled, } from '@auth0/quantum-product';
import * as React from 'react';
import { SectionHeader } from './SectionHeader';
import { SidebarSubsection } from './SidebarSubsection';
var Root = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(0, -theme.layout.mobileMarginX),
    });
});
var StlyedBreadcrumbs = styled(Breadcrumbs)(function (_a) {
    var theme = _a.theme;
    return ({
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(theme.layout.mobileMarginX),
    });
});
var MobileSectionTitle = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        padding: theme.spacing(2, theme.layout.mobileMarginX),
        borderBottom: "1px solid ".concat(theme.palette.divider),
        alignItems: 'center',
    });
});
var MobileSidebarSection = styled(SidebarItems)(function (_a) {
    var theme = _a.theme;
    return ({
        borderBottom: "1px solid ".concat(theme.palette.divider),
        padding: theme.spacing(2, theme.layout.mobileMarginX),
        '& li:first-child > a': {
            paddingTop: 0,
        },
        '& .externalLink': {
            marginLeft: theme.spacing(1),
        },
    });
});
export var MobileSidebar = function (_a) {
    var items = _a.items, _b = _a.maxDepth, maxDepth = _b === void 0 ? 2 : _b;
    var _c = __read(React.useState(false), 2), openDropdown = _c[0], setOpenDropdown = _c[1];
    var _d = __read(React.useState(false), 2), openMainMenu = _d[0], setOpenMainMenu = _d[1];
    var activeSection = items.find(function (i) { return i.isActiveUrl === true; });
    var breadcrumbText = openMainMenu ? 'Back' : 'Main Menu';
    return (React.createElement(Root, null,
        !!activeSection && (React.createElement(StlyedBreadcrumbs, { "aria-label": breadcrumbText },
            React.createElement(BreadcrumbsItem, { startIcon: !openMainMenu ? React.createElement(ArrowLeftIcon, null) : undefined, endIcon: openMainMenu ? React.createElement(ArrowRightIcon, null) : undefined, label: breadcrumbText, variant: "subtitle2", color: "primary", onClick: function () {
                    setOpenMainMenu(!openMainMenu);
                    setOpenDropdown(false);
                } }))),
        openMainMenu || !activeSection ? (React.createElement(React.Fragment, null,
            React.createElement(MobileSectionTitle, null,
                React.createElement(SectionHeader, { key: "Home", title: "Home", href: "/docs", icon: "IdenticonAuth0LogoLight" })),
            items.map(function (item) { return (React.createElement(MobileSectionTitle, null,
                React.createElement(SectionHeader, { key: item.title, title: item.title, href: item.url, icon: item.icon }))); }))) : (React.createElement(React.Fragment, null,
            React.createElement(MobileSectionTitle, { display: "flex", justifyContent: "space-between", onClick: function () { return setOpenDropdown(!openDropdown); } },
                React.createElement(SectionHeader, { title: activeSection.title, href: activeSection.url, icon: activeSection.icon }),
                openDropdown ? React.createElement(ChevronUpIcon, { size: 24 }) : React.createElement(ChevronDownIcon, { size: 24 })),
            openDropdown && (React.createElement(MobileSidebarSection, null, activeSection.children.map(function (item) { return (React.createElement(SidebarSubsection, { key: item.title, title: item.title, items: item.children, maxDepth: maxDepth })); })))))));
};
