import { format } from 'url';

export default function getQuickstartUrl(
  { quickstartId, platformId, versionId, articleId } = {},
  isFramedMode,
  isSingleQuickstartMode
) {
  const pathname = [
    '/docs',
    ...(quickstartId ? ['quickstart', quickstartId] : ['quickstarts']),
    platformId,
    versionId,
    articleId,
  ]
    .filter(Boolean)
    .join('/');

  const query = Object.assign({}, isFramedMode ? { framed: 1 } : {}, isSingleQuickstartMode ? { sq: 1 } : {});

  return format({ pathname, query });
}
