import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StickyContainer } from 'react-sticky';

const ErrorPage = ({ error }) => {
  const stack = error.stack ? <pre style={{ padding: '30px', overflowX: 'auto' }}>{error.stack}</pre> : null;

  return (
    <StickyContainer>
      <div className="docs-single docs-error">
        <div className="js-doc-template container error-page">
          <img
            className="error-image"
            src="https://cdn2.auth0.com/website/docs/404/404-file-icon.svg"
            role="presentation"
          />
          <h2 className="error-status-code">{error.statusCode} Error</h2>
          <h1 className="error-title">{error.message}</h1>
          {error.statusCode === 404 ? (
            <p className="error-description">
              You may have mistyped the address or the page was moved or deleted. <br />
              You can search for more results or head to our <a href="https://auth0.com/docs">homepage</a>.
            </p>
          ) : (
            <p className="error-description">
              For assistance open a support ticket at our <a href="https://support.auth0.com">Support Center</a>.
            </p>
          )}
          {stack}
        </div>
      </div>
    </StickyContainer>
  );
};

ErrorPage.propTypes = {
  error: PropTypes.object,
};

export default ErrorPage;
