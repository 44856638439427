var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { styled } from '@auth0/quantum-product';
import { get } from 'lodash';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { StickyContainer } from 'react-sticky';
import initSampleBox from '../../../browser/sampleBox';
import setInnerHtmlHooks from '../../../browser/setInnerHtmlHooks';
import { ArticleTemplate } from '../../Article/Template';
import { ContentRenderer } from '../../ContentRenderer';
import { FeedbackWidget } from '../../FeedbackWidget';
import { useFluxible } from '../../hooks/useFluxible';
import { ImageMagnify, useImageMagnify } from '../../ImageMagnify';
import MicrositeTemplate from '../../Microsites/Template';
var templateComponent = function (name) {
    var templateName = name || 'default';
    var template;
    switch (templateName) {
        case 'microsite':
            template = MicrositeTemplate;
            break;
        default:
            template = ArticleTemplate;
            break;
    }
    return template;
};
var TemplateContainer = styled('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            padding: theme.spacing(0, 3),
            marginTop: '60px'
        },
        _b[theme.breakpoints.down(theme.layout.tabletBreakpoint)] = {
            padding: 0,
        },
        _b);
});
export var ArticlePage = function (_a) {
    var _b, _c;
    var doc = _a.doc, url = _a.url, isFramedMode = _a.isFramedMode;
    var context = useFluxible();
    var prevDocRef = React.useRef();
    var contentRef = React.useRef(null);
    var _d = __read(useImageMagnify(contentRef.current), 2), imgSrc = _d[0], setImgSrc = _d[1];
    var Template = templateComponent((_b = doc === null || doc === void 0 ? void 0 : doc.meta) === null || _b === void 0 ? void 0 : _b.template);
    var docHasToc = !!((_c = doc === null || doc === void 0 ? void 0 : doc.meta) === null || _c === void 0 ? void 0 : _c.toc);
    var docIsLoaded = !!(doc && (doc.html || doc.meta));
    var showSidebarFeedback = url.indexOf('/docs/microsites') !== 0 && url.indexOf('/docs/videos') !== 0;
    var sendArticleViewedEvent = function () {
        context.trackEvent('view:article:feedbackExperiment');
    };
    var executeEmbeddedScripts = function () {
        if (contentRef.current) {
            $('script', contentRef.current).each(function (idx, item) {
                if (item.src) {
                    $(item).remove();
                    var el = document.createElement('script');
                    el.src = item.src;
                    document.body.appendChild(el);
                }
                else {
                    $.globalEval(item.text || item.textContent || item.innerHTML || '');
                }
            });
        }
    };
    var handleContentRender = function (element) {
        executeEmbeddedScripts();
        initSampleBox();
        setInnerHtmlHooks(element, isFramedMode);
    };
    React.useEffect(function () {
        var docIsLoaded = !!(doc && (doc.html || doc.meta));
        if (docIsLoaded) {
            sendArticleViewedEvent();
        }
    }, []);
    React.useEffect(function () {
        var _a, _b;
        var newDoc = doc;
        var prevDoc = prevDocRef.current;
        prevDocRef.current = newDoc;
        var newDocUrl = newDoc && (newDoc === null || newDoc === void 0 ? void 0 : newDoc.meta) && ((_a = newDoc === null || newDoc === void 0 ? void 0 : newDoc.meta) === null || _a === void 0 ? void 0 : _a.url);
        var prevDocUrl = prevDoc && (prevDoc === null || prevDoc === void 0 ? void 0 : prevDoc.meta) && ((_b = prevDoc === null || prevDoc === void 0 ? void 0 : prevDoc.meta) === null || _b === void 0 ? void 0 : _b.url);
        if (newDocUrl && newDocUrl !== prevDocUrl) {
            sendArticleViewedEvent();
        }
    });
    return (React.createElement("div", { className: "\n        docs-article\n        doc-with-sidebar\n        ".concat(docHasToc ? 'docs-with-toc' : '', "\n      ") },
        React.createElement(Helmet, null,
            React.createElement("meta", { name: "robots", content: get(doc, 'meta.sitemap') !== false ? 'all' : 'noindex, nofollow' })),
        React.createElement(StickyContainer, null,
            React.createElement("div", { className: "document" },
                React.createElement(TemplateContainer, { id: "template-container" },
                    React.createElement(ImageMagnify, { imgSrc: imgSrc, onClose: function () { return setImgSrc(''); } }),
                    React.createElement(Template, { doc: doc, url: url, contentReady: docIsLoaded, renderContent: function (templateDoc) { return (React.createElement("div", { ref: contentRef },
                            React.createElement(ContentRenderer, { styleContent: true, html: templateDoc.html, meta: templateDoc.meta, afterRender: handleContentRender }))); } })),
                docIsLoaded && showSidebarFeedback && React.createElement(FeedbackWidget, null)))));
};
