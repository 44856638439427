var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Card, CardHeader, RowLayout, styled } from '@auth0/quantum-product';
import { connectToStores } from 'fluxible-addons-react';
import { RouteStore } from 'fluxible-router';
import Fuse from 'fuse.js';
import { each, filter } from 'lodash';
import React from 'react';
import { StickyContainer } from 'react-sticky';
import NavigationStore from '../../../stores/NavigationStore';
import QuickstartStore from '../../../stores/QuickstartStore';
import { ColumnStack, ContentContainer } from '../../layouts';
import { SearchField } from '../../SearchField';
import { Category } from './components/Category';
import { Hero } from './components/Hero';
import { Sidebar } from './components/Sidebar';
import LockCategories from './data/LockCategories';
import MgmtCategories from './data/MgmtCategories';
var FUSE_DEFAULTS = {
    threshold: 0.1,
    location: 0,
    maxPatternLength: 100,
    minMatchCharLength: 1,
    keys: ['title', 'name'],
};
var Root = styled(ContentContainer)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b[theme.breakpoints.down(theme.layout.header1440 + theme.spacingPX(6))] = {
            marginLeft: theme.spacing(3),
        },
        _b.marginTop = '60px',
        _b);
});
var StyledColumnStack = styled(ColumnStack)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            alignItems: 'flex-start',
            gap: theme.spacing(4)
        },
        _b[theme.breakpoints.up('md')] = {
            gridTemplateColumns: 'minmax(250px, 304px) auto',
        },
        _b);
});
var SidebarContainer = styled('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            height: '100%'
        },
        _b[theme.breakpoints.down('md')] = {
            display: 'none',
        },
        _b);
});
var MainContentContainer = styled(RowLayout)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            padding: theme.spacing(7, 6, 0),
            gap: theme.spacing(4),
            width: '100%'
        },
        _b[theme.breakpoints.down('md')] = {
            padding: theme.spacing(7, 0, 0),
        },
        _b);
});
var LibraryPage = function (_a) {
    var librariesByCategory = _a.librariesByCategory, query = _a.query, categories = _a.categories;
    var _b = __read(React.useState(query), 2), search = _b[0], setSearch = _b[1];
    var _c = __read(React.useState(librariesByCategory), 2), libraries = _c[0], setLibraries = _c[1];
    React.useEffect(function () {
        if (search.length > 0) {
            var libraries_1 = {};
            each(librariesByCategory, function (categorysLibraries, key) {
                var results = new Fuse(categorysLibraries, FUSE_DEFAULTS).search(search);
                libraries_1[key] = results.map(function (result) { return result.item; });
            });
            setLibraries(libraries_1);
        }
        else {
            setLibraries(librariesByCategory);
        }
    }, [search, librariesByCategory]);
    return (React.createElement(StickyContainer, null,
        React.createElement(Root, { fullWidth: true },
            React.createElement(StyledColumnStack, null,
                React.createElement(SidebarContainer, null,
                    React.createElement(Sidebar, null)),
                React.createElement(MainContentContainer, { id: "maincontent", role: "main" },
                    React.createElement(Hero, null),
                    React.createElement(SearchField, { value: search, performSearch: function (searchString) { return setSearch(searchString); } }),
                    React.createElement(RowLayout, { gutter: 3 },
                        React.createElement(Category, __assign({}, categories.spa, { sdks: libraries.spa })),
                        React.createElement(Category, __assign({}, categories.webapp, { sdks: libraries.webapp })),
                        React.createElement(Category, __assign({}, categories.backend, { sdks: libraries.backend })),
                        React.createElement(Category, __assign({}, categories.native, { sdks: libraries.native })),
                        React.createElement(Category, __assign({}, categories.mgmt, { sdks: libraries.mgmt })),
                        React.createElement(Category, __assign({}, categories.lock, { sdks: libraries.lock }))),
                    Object.values(libraries).flat().length === 0 && (React.createElement(Card, { size: "large", sx: {
                            textAlign: 'center',
                            my: 8,
                        } },
                        React.createElement(CardHeader, { titleTypographyProps: { variant: 'h5' }, title: "No libraries matches your search term \"".concat(search, "\"."), subheaderTypographyProps: { variant: 'body1' }, subheader: "There might be a spelling error in your search term, or we just don\u2019t support that language yet. Want\n                  to view a list of all libraries again? Clear your search term above." }))))))));
};
export default connectToStores(LibraryPage, [RouteStore, NavigationStore, QuickstartStore], function (_a) {
    var getStore = _a.getStore;
    var _b = getStore(RouteStore).getCurrentRoute().query, _c = _b === void 0 ? {} : _b, _d = _c.term, term = _d === void 0 ? '' : _d;
    var quickstarts = getStore(QuickstartStore).getQuickstarts();
    var categories = {
        lock: LockCategories,
        mgmt: MgmtCategories,
    };
    // hide beta quickstarts
    each(quickstarts, function (category, name) {
        categories[name] = __assign(__assign({}, category), { platforms: filter(category.platforms, function (p) { return p.beta !== true; }) });
    });
    var librariesByCategory = {};
    each(categories, function (category, name) {
        librariesByCategory[name] = filter(category.platforms, function (p) { return !!p.sdk; }).sort(function (a, b) {
            return a.title.localeCompare(b.title);
        });
    });
    return {
        query: term,
        categories: categories,
        librariesByCategory: librariesByCategory,
    };
});
