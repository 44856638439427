import { mapValues } from 'lodash';

import replaceUserVars from '../../lib/replaceUserVars';

const replaceUserVarsInPortalProp = (prop, context) => {
  if (typeof prop === 'string') {
    return replaceUserVars(prop, context);
  }

  // recursively replace strings within objects and arrays
  if (typeof prop === 'object' && prop !== null) {
    if (Array.isArray(prop)) {
      return prop.map((value) => replaceUserVarsInPortalProp(value, context));
    } else {
      return mapValues(prop, (value) => replaceUserVarsInPortalProp(value, context));
    }
  }

  // for any other case return the original value
  return prop;
};

const replaceUserVarsInPortals = (portals, context) =>
  portals.map((portal) => ({
    ...portal,
    props: replaceUserVarsInPortalProp(portal.props, context),
  }));

export default replaceUserVarsInPortals;
