import NavigationStore from '../stores/NavigationStore';
import loadQuickstarts from './loadQuickstarts';
var loadLandingPage = function (context, payload) {
    var navigationSections = context.getStore(NavigationStore).getNavigationItems(payload.doc.meta.section);
    var currentNavigationSection = navigationSections === null || navigationSections === void 0 ? void 0 : navigationSections.find(function (item) { return item.url === payload.doc.meta.url; });
    var requiresQuickstartLoading = currentNavigationSection === null || currentNavigationSection === void 0 ? void 0 : currentNavigationSection.children.some(function (item) { return item === null || item === void 0 ? void 0 : item.quickstarts; });
    if (requiresQuickstartLoading) {
        return loadQuickstarts(context);
    }
};
export default loadLandingPage;
