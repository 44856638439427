import { NavLink } from 'fluxible-router';
import * as React from 'react';
import { NextStepsList } from './NextStepsList';
import styles from './styles.module.styl';
export var NextStepsSection = function (_a) {
    var metadataNextSteps = _a.metadataNextSteps, sidebarArticlesNextSteps = _a.sidebarArticlesNextSteps, platformTitle = _a.platformTitle, isQuickstart = _a.isQuickstart;
    return (React.createElement("div", { className: styles.nextSteps },
        metadataNextSteps && (React.createElement(NextStepsList, { title: "What can you do next?", items: metadataNextSteps, linkComponent: "a", linkProps: {
                target: '_blank',
            } })),
        isQuickstart && sidebarArticlesNextSteps && (React.createElement(NextStepsList, { title: "More ".concat(platformTitle, " tutorials"), items: sidebarArticlesNextSteps, linkComponent: NavLink }))));
};
