import * as React from 'react';
import { TenantDescription } from './TenantDescription.old';
export var TenantMenuItem = function (_a) {
    var name = _a.name, regionName = _a.regionName, regionCode = _a.regionCode, subscription = _a.subscription, selected = _a.selected, url = _a.url;
    return (React.createElement("li", { className: "tenant-menu-item ".concat(selected ? 'selected-tenant' : '') },
        React.createElement("a", { href: url, title: "".concat(name, " (").concat(regionName, ")"), onClick: function (e) {
                e.preventDefault();
                window.location.href = "".concat(url, "&returnTo=").concat(window.location.pathname);
            } },
            React.createElement("div", { className: "tenant-name truncate" }, name),
            React.createElement(TenantDescription, { regionCode: regionCode, regionName: regionName, subscription: subscription }))));
};
