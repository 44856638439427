var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ColumnLayout, Spinner, styled } from '@auth0/quantum-product';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { StickyContainer } from 'react-sticky';
import { sendTutorialViewedEvent } from '../../../browser/quickstartMetrics';
import ApplicationStore from '../../../stores/ApplicationStore';
import DocumentStore from '../../../stores/DocumentStore';
import QuickstartStore from '../../../stores/QuickstartStore';
import UserStore from '../../../stores/UserStore';
import { ContentContainer } from '../../layouts';
import { ActionView } from './ActionView';
import { Header } from './Header';
import { StepsView } from './StepsView';
var TutorialContainer = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        padding: theme.spacing(0, 3),
    });
});
var TutorialContent = styled(ContentContainer)({
    margin: '0 auto',
});
export var TutorialPageNew = function (_a) {
    var isFramedMode = _a.isFramedMode, context = _a.context;
    var userStore = context.getStore(UserStore);
    var quickstartStore = context.getStore(QuickstartStore);
    var isAuthenticated = userStore.isAuthenticated();
    var article = quickstartStore.getCurrentArticle();
    var doc = article && context.getStore(DocumentStore).getDocument(article.url);
    if (!doc || !doc.html) {
        return React.createElement(Spinner, null);
    }
    var meta = doc.meta;
    var steps = meta.steps || [];
    var stepsReverseOrder = __spreadArray([], __read(steps), false).reverse();
    var initialStepState = {
        number: 0,
        content: meta.title,
        anchor: meta.title,
        actionView: 'default',
        codeHighlight: null,
    };
    var _b = __read(React.useState(initialStepState), 2), activeStep = _b[0], setActiveStep = _b[1];
    React.useEffect(function () {
        sendTutorialViewedEvent({
            isFramedMode: context.getStore(ApplicationStore).isFramedMode(),
            quickstart: quickstartStore.getCurrentQuickstart(),
            platform: quickstartStore.getCurrentPlatform(),
            article: article,
            user: userStore.getUser(),
        });
    }, []);
    return (React.createElement(StickyContainer, null,
        React.createElement(Helmet, null,
            React.createElement("meta", { name: "robots", content: meta.beta ? 'noindex, nofollow' : 'all' })),
        React.createElement(Header, { activeStep: activeStep }),
        React.createElement(TutorialContainer, null,
            React.createElement(TutorialContent, { id: "maincontent", role: "main", fullWidth: true },
                React.createElement(ColumnLayout, { gutter: 5, distribution: '1/2 1/2' },
                    React.createElement(StepsView, { isFramedMode: isFramedMode, setActiveStepState: setActiveStep, activeStep: activeStep, initialStepState: initialStepState, steps: stepsReverseOrder }),
                    React.createElement(ActionView, { isAuthenticated: isAuthenticated, activeStep: activeStep, files: meta.sampleFiles }))))));
};
