import { find } from 'lodash';

import ApplicationStore from '../stores/ApplicationStore';

/**
 * This function is designed to accept a set of parameters (usually from fluxible routes)
 * and walk the quickstart metadata hierarchy until it finds the associated position.
 */
export default function resolveQuickstart(quickstarts, params, context) {
  const { quickstartId, platformId, versionOrArticleId, versionId, articleId } = params;

  let quickstart;
  let platform;
  let version;
  let article;

  // This supports paths like /docs/quickstart/spa.
  if (quickstartId) {
    quickstart = quickstarts[quickstartId];
    if (!quickstart) {
      const err = new Error(`No such app type ${quickstartId} exists.`);
      err.statusCode = 404;

      throw err;
    }
  }

  // This supports paths like /docs/quickstart/spa/react.
  if (quickstart && platformId) {
    platform = quickstart.platforms[platformId];
    if (!platform) {
      const err = Error(`No such platform ${platformId} exists in the app type ${quickstart.name}.`);
      err.statusCode = 404;
      throw err;
    }
  }

  if (platform) {
    // This supports paths like /docs/quickstart/spa/react/:versionOrArticleId, which may either
    // represent an article in the current version OR the first article of a specific version.
    if (versionOrArticleId) {
      // First check to see if there is a version with the specified name.
      if (platform.versions) {
        version = find(platform.versions, { name: versionOrArticleId });
      }
      // If the version didn't match (or the platform isn't versioned) it must be an article.
      if (!version) {
        article = find(platform.articles, { name: versionOrArticleId });

        // If no article, check hiddenArticles before throwing 404
        if (!article) {
          article = find(platform.hiddenArticles, { name: versionOrArticleId });
          if (article) article.hidden = true;
        }

        if (!article) {
          const err = Error(`No such article ${versionOrArticleId} exists in the platform ${platform.name}.`);
          err.statusCode = 404;
          throw err;
        }
      }
    }

    // This supports paths like /docs/quickstart/spa/react/:versionId/:articleId.
    if (versionId) {
      if (platform.versions) {
        version = find(platform.versions, { name: versionId });
      }
      if (!version) {
        const err = Error(`No such version ${versionId} exists for the platform ${platform.name}.`);
        err.statusCode = 404;
        throw err;
      }
    }

    // If the platform is versioned, but we haven't selected a version yet, use the current version.
    // This supports paths like /docs/quickstart/spa/react.
    if (!version && platform.versions) {
      version = platform.versions[platform.currentVersion];
    }

    // If we haven't selected an article, resolve it by ID the article list of the platform
    // This supports paths like /docs/quickstart/spa/react and /docs/quickstarta/spa/react/:versionId.
    if (!article && articleId) {
      const articleList = version ? version.articles : platform.articles;
      if (articleId) {
        article = find(articleList, { name: articleId });
      }
    }

    // If no selected article and in framed(dashboard) mode, dont AB test, grab the default article
    if (!article && context.getStore(ApplicationStore).isFramedMode()) {
      article = platform.defaultArticle;
    }

    // If no article has been found, grab first one from the list of articles
    if (!article) {
      const articleList = version ? version.articles : platform.articles;
      article = articleList[0];
    }
  }

  // By now, we've resolved the correct position in the quickstart hierarchy, so build
  // the URL to the page that it represents.
  const tokens = ['/docs', 'quickstart'];
  if (quickstart) tokens.push(quickstart.name);
  if (platform) tokens.push(platform.name);
  if (version) tokens.push(version.name);
  if (article) tokens.push(article.name);
  const url = tokens.join('/');

  return { quickstart, platform, version, article, url };
}
