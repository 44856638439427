import { Text } from '@auth0/quantum-product';
import * as React from 'react';
import { NavLink } from '../../NavLink';
import { Suggestions } from './styled';
var windowExists = typeof window !== 'undefined';
var ENV = windowExists ? window.env.NODE_ENV : process.env.NODE_ENV;
export var EditLink = function (_a) {
    var contentfulId = _a.contentfulId, githubEditLink = _a.githubEditLink, trackData = _a.trackData;
    var content;
    if (contentfulId && ENV !== 'production') {
        content = (React.createElement(React.Fragment, null,
            React.createElement(Text, { variant: "body2", color: "textSecondary" }, 'Any suggestion or typo? '),
            React.createElement(NavLink, { followLink: true, color: "primary", href: "https://app.contentful.com/spaces/cdy7uua7fh8z/entries/".concat(contentfulId), onClick: function () { return trackData('click:edit-contentful'); }, target: "_blank", rel: "noreferrer noopener nofollow" }, "Edit on Contentful")));
    }
    else if (githubEditLink) {
        content = (React.createElement(React.Fragment, null,
            React.createElement(Text, { variant: "body2", color: "textSecondary" }, 'Any suggestion or typo? '),
            React.createElement(NavLink, { followLink: true, color: "primary", href: githubEditLink, onClick: function () { return trackData('click:edit-github'); }, target: "_blank", rel: "noreferrer noopener nofollow" }, "Edit on GitHub")));
    }
    return React.createElement(Suggestions, null, content);
};
