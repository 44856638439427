import cx from 'classnames';
import * as React from 'react';
import styles from './styles.module.styl';
export var BooleanFeedbackButton = function (_a) {
    var _b;
    var _c = _a.isPositive, isPositive = _c === void 0 ? false : _c, isActive = _a.isActive, isDisabled = _a.isDisabled, handleClick = _a.handleClick, iconCode = _a.iconCode;
    return (React.createElement("button", { className: cx(styles.button, isPositive ? styles.buttonYes : styles.buttonNo, (_b = {}, _b[styles.active] = isActive, _b), 'btn', 'btn-transparent', 'btn-sm'), disabled: isDisabled, onClick: handleClick },
        React.createElement("span", { className: cx(styles.buttonIcon, 'btn-icon', "icon-budicon-".concat(iconCode)) }),
        isPositive ? 'Yes' : 'No'));
};
