import { Box, styled } from '@auth0/quantum-product';
import * as React from 'react';
import { Spinner } from './Spinner';
var SpinnerContainer = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        height: '100vh',
        width: '100vw',
        backgroundColor: theme.palette.background.paper,
    });
});
export var OverlaySpinner = function () { return (React.createElement(SpinnerContainer, { display: "flex", justifyContent: "center" },
    React.createElement(Spinner, null))); };
