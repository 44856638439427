import { styled } from '@auth0/quantum-product';
import * as React from 'react';
var LogoSvg = styled('svg')(function (_a) {
    var theme = _a.theme;
    return ({
        verticalAlign: 'middle',
        width: 'auto',
        fill: theme.tokens.color_fg_bold,
    });
});
export var Logo = function () { return (React.createElement(LogoSvg, { height: "30", viewBox: "0 0 324 64", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M67.0272 28.168L75.2983 6.45352H78.4861L86.7251 28.168H83.8471L81.5564 22.3062H72.1106L69.8199 28.168H67.0272ZM80.7448 19.8566L76.8094 9.4005L72.9062 19.8566H80.7448Z" }),
    React.createElement("path", { d: "M98.6866 12.3475H101.228V28.168H99.0283L98.6866 26.0608C97.7575 27.2374 96.241 28.3552 93.6406 28.3552C90.1698 28.3552 87.4146 26.4031 87.4146 21.1563V12.3475H89.9562V21.0012C89.9562 24.2904 91.4407 26.0287 94.0731 26.0287C96.9512 26.0287 98.6866 23.8251 98.6866 20.2577V12.3475Z" }),
    React.createElement("path", { d: "M102.44 14.6719V12.3453H105.292V7.91148H107.833V12.3453H111.923V14.6719H107.833V24.0102C107.833 25.3419 108.298 25.8393 109.659 25.8393H112.169V28.1659H109.414C106.381 28.1659 105.297 26.8341 105.297 24.0423V14.6719H102.44Z" }),
    React.createElement("path", { d: "M127.707 19.3539V28.1627H125.166V19.509C125.166 16.2197 123.585 14.4815 120.862 14.4815C117.92 14.4815 116.094 16.685 116.094 20.2524V28.1627H113.552V6.45352H116.094V14.9521C117.023 13.4653 118.635 12.1603 121.294 12.1603C124.824 12.1603 127.702 14.1124 127.702 19.3592L127.707 19.3539Z" }),
    React.createElement("path", { d: "M129.475 16.8121C129.475 10.64 132.817 6.26501 137.714 6.26501C142.61 6.26501 145.921 10.64 145.921 16.8121V17.8069C145.921 24.2891 142.578 28.3539 137.714 28.3539C132.849 28.3539 129.475 24.2891 129.475 17.8069V16.8121ZM143.288 16.903C143.288 11.7525 141.056 8.6504 137.714 8.6504C134.371 8.6504 132.107 11.7525 132.107 16.903V17.7106C132.107 22.8611 134.339 25.9632 137.714 25.9632C141.088 25.9632 143.288 22.8611 143.288 17.7106V16.903Z" }),
    React.createElement("path", { d: "M83.9645 49.8342C83.9645 54.6745 80.7447 57.9317 76.3768 57.9317C73.7124 57.9317 71.9182 56.8459 70.9571 55.327L70.6154 57.7445H68.4154V36.0299H70.9571V44.4697C72.009 43.0096 73.7124 41.742 76.3768 41.742C80.7447 41.742 83.9645 44.689 83.9645 49.8395V49.8342ZM81.3641 49.8342C81.3641 46.3898 79.2602 44.0044 76.1312 44.0044C73.0022 44.0044 70.9304 46.3952 70.9304 49.7753C70.9304 53.1555 73.0342 55.6693 76.1312 55.6693C79.2282 55.6693 81.3641 53.2786 81.3641 49.8395V49.8342Z" }),
    React.createElement("path", { d: "M83.8683 41.9228H86.5648L91.4292 54.5504L96.1388 41.9228H98.8353L91.5574 60.4122C90.5642 62.9581 89.9768 63.9475 87.7502 63.9475H84.7173V61.621H86.976C88.4337 61.621 88.6793 61.2466 89.2667 59.7597L90.0409 57.8343L83.879 41.9228H83.8683Z" }),
    React.createElement("path", { d: "M117.812 57.9238C111.709 57.9238 107.469 53.2707 107.469 46.8794C107.469 40.488 111.714 35.8349 117.812 35.8349C123.91 35.8349 128.155 40.488 128.155 46.8794C128.155 53.2707 123.91 57.9238 117.812 57.9238ZM117.812 55.4422C122.335 55.4422 125.4 51.8748 125.4 46.8794C125.4 41.8839 122.335 38.3166 117.812 38.3166C113.289 38.3166 110.224 41.8839 110.224 46.8794C110.224 51.8748 113.289 55.4422 117.812 55.4422Z" }),
    React.createElement("path", { d: "M130.11 36.0299H132.651V50.3316L140.581 41.9239H143.71L137.548 48.4703L144.142 57.7445H141.077L135.845 50.2995L132.657 53.5888V57.7445H130.115V36.0299H130.11Z" }),
    React.createElement("path", { d: "M144.35 44.2494V41.9228H147.201V37.489H149.743V41.9228H153.833V44.2494H149.743V53.5877C149.743 54.9194 150.207 55.4168 151.569 55.4168H154.079V57.7434H151.323C148.291 57.7434 147.207 56.4116 147.207 53.6198V44.2494H144.35Z" }),
    React.createElement("path", { d: "M170.382 55.4167V57.7432H168.988C166.884 57.7432 166.169 56.8447 166.137 55.2937C165.144 56.7217 163.569 57.9304 160.84 57.9304C157.369 57.9304 155.02 56.1922 155.02 53.3094C155.02 50.1432 157.22 48.3782 161.369 48.3782H166.014V47.2925C166.014 45.244 164.556 44.0032 162.079 44.0032C159.847 44.0032 158.362 45.0568 158.053 46.672H155.511C155.885 43.57 158.389 41.7408 162.202 41.7408C166.228 41.7408 168.55 43.7572 168.55 47.4476V54.363C168.55 55.2027 168.86 55.4167 169.57 55.4167H170.382ZM161.123 50.5175C158.864 50.5175 157.593 51.3572 157.593 53.1543C157.593 54.7053 158.923 55.759 161.032 55.759C164.193 55.759 166.019 53.9298 166.019 51.2931V50.5175H161.123Z" }),
    React.createElement("path", { d: "M3.37137 27.7827C13.8455 26.0542 22.0567 17.383 23.7767 6.89172L24.3538 1.84927C24.4967 1.04797 23.9538 -0.0681241 22.9424 0.0120057C15.0283 0.63015 7.55986 3.24582 3.40565 4.94571C1.34283 5.7928 0 7.79605 0 10.0282V26.4777C0 27.4507 0.874269 28.1948 1.83425 28.0403L3.37137 27.7884V27.7827Z" }),
    React.createElement("path", { d: "M28.8289 6.89209C30.5545 17.3834 38.7658 26.0546 49.2342 27.7831L50.7713 28.0349C51.7313 28.1952 52.6056 27.4511 52.6056 26.4724V10.0229C52.6056 7.79068 51.2627 5.78744 49.1999 4.94035C45.04 3.23473 37.5773 0.624791 29.6631 0.00664632C28.646 -0.0734835 28.126 1.05406 28.246 1.84391L28.8231 6.88636L28.8289 6.89209Z" }),
    React.createElement("path", { d: "M49.2262 32.3908C34.9122 35.2182 28.2666 44.7479 28.2666 62.7886C28.2666 63.6929 29.1637 64.3225 29.918 63.8188C36.5007 59.3716 50.9862 47.7643 52.4776 33.2436C52.5347 31.4178 50.2548 32.2763 49.2262 32.3908Z" }),
    React.createElement("path", { d: "M3.37664 32.3908C17.6907 35.2182 24.3363 44.7479 24.3363 62.7886C24.3363 63.6929 23.4391 64.3225 22.6849 63.8188C16.1021 59.3716 1.61668 47.7643 0.125275 33.2436C0.0681328 31.4178 2.34809 32.2763 3.37664 32.3908Z" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M195.049 64V4.90798e-08L196.17 0V64H195.049Z" }),
    React.createElement("path", { d: "M233.834 48.2579H224.228V15.2937H233.834C244.289 15.2937 250.411 21.7452 250.411 31.7758C250.411 41.7593 244.289 48.2579 233.834 48.2579ZM226.771 17.6483V45.9033H233.834C242.829 45.9033 247.821 40.3936 247.821 31.7758C247.821 23.1109 242.829 17.6483 233.834 17.6483H226.771Z" }),
    React.createElement("path", { d: "M265.093 48.5405C258.359 48.5405 253.65 43.5487 253.65 36.2966C253.65 29.0916 258.359 24.0998 265.093 24.0998C271.827 24.0998 276.583 29.0916 276.583 36.2966C276.583 43.5487 271.827 48.5405 265.093 48.5405ZM265.093 46.3743C270.414 46.3743 274.088 42.2302 274.088 36.2966C274.088 30.4101 270.414 26.219 265.093 26.219C259.772 26.219 256.099 30.4101 256.099 36.2966C256.099 42.2302 259.772 46.3743 265.093 46.3743Z" }),
    React.createElement("path", { d: "M290.821 48.5405C284.087 48.5405 279.519 43.69 279.519 36.3437C279.519 29.0916 284.134 24.0998 290.868 24.0998C296.284 24.0998 300.004 27.1137 300.993 32.0583H298.497C297.649 28.4794 294.824 26.219 290.821 26.219C285.547 26.219 281.968 30.4101 281.968 36.3437C281.968 42.2773 285.594 46.3743 290.821 46.3743C294.777 46.3743 297.649 44.1138 298.45 40.582H300.993C300.051 45.4324 296.236 48.5405 290.821 48.5405Z" }),
    React.createElement("path", { d: "M303.636 40.9116H306.085C306.273 44.3493 309.24 46.4684 313.808 46.4684C317.717 46.4684 320.683 44.6789 320.683 41.8534C320.683 38.3216 317.434 37.8035 313.431 37.2384C308.534 36.5792 304.107 35.6844 304.107 30.8811C304.107 26.7841 307.921 24.0527 313.243 24.0998C318.423 24.0998 322.237 26.4544 322.661 31.1165H320.212C319.789 28.0085 317.057 26.1719 313.243 26.1719C309.193 26.1719 306.509 27.9614 306.509 30.6927C306.509 34.0362 309.805 34.46 313.525 34.978C318.658 35.6844 323.085 36.6733 323.085 41.7122C323.085 45.9504 318.941 48.5405 313.808 48.5405C307.827 48.5405 303.825 45.8562 303.636 40.9116Z" }))); };
