var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import useScrollPosition from '@a0/docs-components/hooks/useScrollPosition';
import { MetricsEventType } from '@a0/docs-components/metrics';
import canUseDom from '@a0/docs-components/util/canUseDOM';
import { Box } from '@auth0/quantum-product';
import * as React from 'react';
import { BooleanFeedback } from '../FeedbackSection/BooleanFeedback';
import { FeedbackMode } from '../FeedbackSection/feedbackModes';
import { useFluxible } from '../hooks/useFluxible';
import { CloseButton } from './CloseButton';
import { CommentBox } from './CommentBox';
import { WidgetContainer, WidgetTitle } from './styled';
export var FeedbackWidget = function () {
    var context = useFluxible();
    var _a = __read(React.useState(true), 2), widgetVisible = _a[0], setWidgetVisible = _a[1];
    var _b = __read(React.useState(FeedbackMode.None), 2), vote = _b[0], setVote = _b[1];
    var _c = __read(React.useState(false), 2), done = _c[0], setDone = _c[1];
    var _d = __read(React.useState(), 2), footer = _d[0], setFooter = _d[1];
    var scrollPos = useScrollPosition();
    React.useEffect(function () {
        setFooter(document.querySelector('[data-article-footer]'));
    }, []);
    if (!canUseDom()) {
        return React.createElement(React.Fragment, null);
    }
    if (done) {
        return React.createElement(React.Fragment, null);
    }
    var submitVote = function (v) {
        setVote(v);
        context.trackEvent(MetricsEventType.FeedbackBooleanClick, {
            value: v,
            component: 'FeedbackWidget',
            dwh: { event: 'feedback:submit', properties: { value: v === FeedbackMode.Positive } },
        });
    };
    var dismissWidget = function () {
        setWidgetVisible(false);
        if (vote === FeedbackMode.None) {
            context.trackEvent('feedback:dismiss');
        }
    };
    var submitComment = function (comment) {
        setDone(true);
        context.trackEvent(MetricsEventType.FeedbackSubmitComment, {
            value: vote,
            comment: comment,
            component: 'FeedbackWidget',
            dwh: {
                event: 'feedback:submit-comment',
                properties: { value: vote === FeedbackMode.Positive, trackData: comment },
            },
        });
        fetch('/docs/submit-feedback', {
            method: 'POST',
            headers: new Headers({
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
                page_title: document.title,
                page_url: window.location.href,
                positive: vote === FeedbackMode.Positive,
                comment: comment,
            }),
        });
    };
    var footerVisible;
    if (footer) {
        var top_1 = footer.scrollTop + footer.offsetTop;
        var height = footer.clientHeight;
        var footerPos = top_1 + height - window.innerHeight;
        footerVisible = scrollPos.y >= footerPos;
    }
    return (React.createElement(WidgetContainer, { feedbackSent: vote !== FeedbackMode.None, isVisible: widgetVisible, isFooterVisible: footerVisible },
        React.createElement(CloseButton, { onClick: dismissWidget }),
        React.createElement(WidgetTitle, { variant: "caption" }, vote !== FeedbackMode.None ? 'Thanks for the feedback!' : 'Is this article helpful?'),
        React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-evenly" }, vote === FeedbackMode.None && React.createElement(BooleanFeedback, { useButtons: true, value: vote, onChange: submitVote })),
        vote !== FeedbackMode.None && React.createElement(CommentBox, { isPositive: vote === FeedbackMode.Positive, submit: submitComment })));
};
