var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Content, ExternalLinkIcon, fade, styled } from '@auth0/quantum-product';
import React from 'react';
import { renderToString } from 'react-dom/server';
// Portal component styles
import { accordionContainerStyles } from '../Accordion';
import { alertContainerStyles } from '../AlertContainer';
import { definitionTooltipStyles } from '../DefinitionTooltip';
import { tabsContainerStyles } from '../TabsContainer';
import { titleStyles } from '../Title';
// @ts-ignore // @TODO typing issue?
export var ContentStyler = styled(Content)(function (_a) {
    var _b, _c;
    var theme = _a.theme;
    var externalIconSvg = encodeURIComponent(renderToString(React.createElement(ExternalLinkIcon, { width: 14, height: 14, color: theme.tokens.color_fg_link_primary })));
    return __assign(__assign(__assign(__assign(__assign((_b = { '& > *, > p, > img, li > img': {
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
            }, '& img': {
                maxWidth: '100%',
            }, '& h1': __assign({}, theme.typography.h1), '& h2': __assign({}, theme.typography.h2), '& h3': __assign({}, theme.typography.h3), '& h4': __assign({}, theme.typography.h4), '& h5': __assign({}, theme.typography.h5), '& p': __assign(__assign({}, theme.typography.body1), { letterSpacing: -0.01, margin: theme.spacing(0, 0, 2) }), '& small': __assign(__assign({}, theme.typography.body2), { letterSpacing: 0 }), '& b, strong': {
                letterSpacing: 0,
                fontWeight: theme.typography.fontWeightBold,
            }, '& a:not(.QuantumLink-root,.btn)': {
                fontWeight: theme.typography.fontWeightMedium,
                color: theme.tokens.color_fg_link_primary,
                display: 'inline-flex',
                textDecoration: 'none',
            }, '& a:not(.QuantumLink-root,.btn):hover': {
                textDecoration: 'underline',
                color: theme.tokens.color_fg_link_primary_hover,
            }, 
            // Block primary link styling on alerts
            '& .QuantumAlert-root a': {
                textDecoration: 'underline',
                color: 'inherit',
                '&:hover': {
                    color: 'inherit',
                },
            }, '& a[href^=http]::after': {
                marginLeft: theme.spacing(0.5),
                position: 'relative',
                top: 1,
                content: "url(\"data:image/svg+xml,".concat(externalIconSvg, "\")"),
            }, '& ul, & ol': {
                paddingLeft: theme.spacing(5),
            }, '& ul > li': {
                listStyleType: 'disc',
            }, '& ol > li': {
                listStyleType: 'number',
            }, '& ul > li, ol > li': {
                fontSize: theme.typography.pxToRem(16),
                margin: theme.spacing(1, 0),
            }, '& .tablew': {
                overflowX: 'auto',
                scrollBehavior: 'smooth',
                webkitOverflowScrolling: 'touch',
            }, '& .table': (_c = {
                    fontSize: theme.typography.pxToRem(14),
                    letterSpacing: 0.15,
                    borderCollapse: 'collapse'
                },
                _c[theme.breakpoints.down(theme.layout.mobileBreakpoint)] = {
                    '& th': {
                        whiteSpace: 'nowrap',
                    },
                },
                _c), '& .table tr > td, .table tr > th': {
                lineHeight: theme.typography.pxToRem(20),
                padding: theme.spacing(1),
                verticalAlign: 'top',
                borderTop: "1px solid ".concat(theme.palette.divider),
            }, '& .table th': {
                textAlign: 'left',
            }, '& .table > thead > tr > th': {
                borderBottom: "2px solid ".concat(theme.palette.divider),
                borderTop: 'none',
            }, '& .table > tbody > tr:nth-child(odd)': {
                background: theme.tokens.color_bg_layer_alternate,
            }, 
            // @TODO - temp override for inherited components.min.css. Let's remove it long term
            '& .panel': {
                backgroundColor: 'inherit',
            }, '& .aside-container': {
                padding: theme.spacing(4),
                borderRadius: theme.spacing(3),
                border: "1px solid ".concat(theme.palette.divider),
                '& .aside-heading': {
                    marginBottom: theme.spacing(1.5),
                    '& h4': {
                        marginTop: 0,
                        marginBottom: 0,
                    },
                },
                '& .aside-body ul': {
                    paddingLeft: theme.spacing(3),
                },
            }, '& code:not(.code-highlight-prism), p > code': __assign(__assign(__assign({}, theme.typography.code1), { padding: theme.spacing(0, 0.75, 0.25), borderRadius: '3px' }), (theme.palette.mode === 'dark' && { background: 'inherit' })), '& code small, p > code small': __assign({}, theme.typography.code2), 
            // components.css overrides
            '& .code-picker .languages-bar': {
                '& .dropdown .dropdown-menu': {
                    background: theme.tokens.color_bg_layer_elevated,
                    listStyle: 'none',
                    paddingLeft: theme.spacing(3),
                },
                '& > ul': __assign(__assign({}, (theme.palette.mode === 'dark' && {
                    background: 'none',
                    border: "1px solid ".concat(theme.tokens.color_border_default),
                    borderBottom: 0,
                })), { '& li': {
                        listStyleType: 'none',
                    }, '& > li.active > a': {
                        color: theme.tokens.color_fg_link_primary_pressed,
                    } }),
            }, 
            // Connections section
            '& .connections-container': {
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                '&:after': {
                    content: 'none',
                    flex: 'auto',
                },
            }, '& .connection': {
                padding: theme.spacing(3, 2),
                border: "1px solid ".concat(theme.palette.divider),
                flexBasis: '23%',
                marginBottom: theme.spacing(2),
                marginRight: '2.6666666%',
                overflow: 'hidden',
                transition: 'transform 0.2s, border 0.2s',
                '&:nth-child(4n)': {
                    marginRight: 0,
                },
                '&.connection-public:hover': {
                    border: "1px solid ".concat(theme.palette.divider),
                    boxShadow: '0px 2px 4px rgb(0 0 0 / 12%)',
                    transform: 'scale(1.02)',
                    textDecoration: 'none',
                },
                '&.connection-public:focus': {
                    boxShadow: "".concat(fade(theme.palette.primary.main, 0.25), " 0px 0px 0px 0.25em"),
                },
            } }, _b[theme === null || theme === void 0 ? void 0 : theme.breakpoints.down('sm')] = {
        '& .connection': {
            flexBasis: '48%',
            marginRight: '4%',
            '&:nth-child(2n)': {
                marginRight: 0,
            },
        },
    }, _b['& .connection-content'] = {
        textAlign: 'center',
    }, _b['& .connection-title'] = {
        fontSize: theme.typography.pxToRem(18),
        lineHeight: theme.typography.pxToRem(20),
        marginTop: theme.spacing(2),
        marginBottom: 0,
    }, _b['& .connection-image-wrap'] = {
        display: 'inline-block',
        verticalAlign: 'middle',
        '& img': {
            maxHeight: 60,
            maxWidth: 60,
        },
    }, _b), titleStyles(theme)), definitionTooltipStyles(theme)), alertContainerStyles(theme)), tabsContainerStyles(theme)), accordionContainerStyles(theme));
});
