import { CheckIcon, CopyIcon, IconButton, styled } from '@auth0/quantum-product';
import React from 'react';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
var StyledIconButton = styled(IconButton)(function () { return ({
    color: '#BCBAFF',
    border: 'none',
    backgroundColor: 'none',
    background: 'none',
}); });
export var CopyButton = function (_a) {
    var code = _a.code;
    var _b = useCopyToClipboard(), isCopied = _b.isCopied, copyToClipboard = _b.copyToClipboard;
    var handleCopyCode = function () { return copyToClipboard(code); };
    return (React.createElement(StyledIconButton, { className: "copy-button", size: "medium", color: "primary", variant: "outlined", onClick: handleCopyCode }, isCopied ? React.createElement(CheckIcon, null) : React.createElement(CopyIcon, null)));
};
