import { BaseStore } from 'fluxible/addons';

export const SearchResultState = {
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  ERROR: 'ERROR',
};

class SearchStore extends BaseStore {
  constructor(dispatcher) {
    super(dispatcher);
    this.searches = {};
  }

  handleResultLoading({ query, page }) {
    this.searches[`${query}:${page}`] = {
      query,
      page,
      state: SearchResultState.LOADING,
    };
    this.emitChange();
  }

  handleResultLoaded({ query, page, response }) {
    const search = this.searches[`${query}:${page}`];

    // format results from Coveo response
    const results = response?.results?.map(
      ({ uniqueId, title, uri, raw: { description, breadcrumbs, urihash, source } }) => ({
        uniqueId,
        title,
        url: uri,
        urihash,
        source,
        description,
        breadcrumbs: breadcrumbs && JSON.parse(breadcrumbs),
      })
    );

    // deduplicate results that have the same title and description
    const dedupResults = Object.values(
      results.reduce((acc, result) => {
        const key = result.title + ':' + result.description;
        // keep the ones that have breadcrumbs
        if (acc[key] && acc[key].breadcrumbs) {
          return { ...acc };
        }
        return { ...acc, [key]: result };
      }, {})
    );

    Object.assign(search, {
      state: SearchResultState.LOADED,
      searchUid: response?.searchUid,
      pageCount: response?.pageCount,
      totalCount: response?.totalCount,
      numberOfResults: response?.numberOfResults,
      results: dedupResults,
    });
    this.emitChange();
  }

  handleResultLoadFailed({ query, page, err }) {
    const search = this.searches[`${query}:${page}`];
    search.state = SearchResultState.ERROR;
    search.error = err;
    this.emitChange();
  }

  getSearch({ query, page }) {
    return this.searches[`${query}:${page}`];
  }

  dehydrate() {
    return {
      searches: this.searches,
    };
  }

  rehydrate(state) {
    this.searches = state.results;
  }
}

SearchStore.storeName = 'SearchStore';
SearchStore.handlers = {
  SEARCH_RESULT_LOADING: 'handleResultLoading',
  SEARCH_RESULT_LOAD_SUCCESS: 'handleResultLoaded',
  SEARCH_RESULT_LOAD_FAILURE: 'handleResultLoadFailed',
};

export default SearchStore;
