var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import { sendTutorialViewedEvent } from '../../../browser/quickstartMetrics';
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs.old';
import PlatformList from '../PlatformList';
import { QuickstartList } from '../QuickstartList/QuickstartList.old';
import QuickstartSuggestModal from '../QuickstartSuggestModal';
var shouldSendMetrics = function (quickstart, prevQuickstart) {
    return quickstart && (!prevQuickstart || prevQuickstart.name !== quickstart.name);
};
export var TutorialNavigator = function (props) {
    var quickstart = props.quickstart, quickstarts = props.quickstarts, _a = props.firstQuestion, firstQuestion = _a === void 0 ? 'Choose your application type' : _a, subHeader = props.subHeader, isFramedMode = props.isFramedMode, _b = props.largeHeader, largeHeader = _b === void 0 ? true : _b, breadcrumbs = props.breadcrumbs, _c = props.onHomePage, onHomePage = _c === void 0 ? false : _c;
    var _d = __read(React.useState(false), 2), showSuggestionModal = _d[0], setShowSuggestionModal = _d[1];
    var _e = __read(React.useState(false), 2), suggestionSent = _e[0], setSuggestionSent = _e[1];
    var _f = __read(React.useState(''), 2), searchTerm = _f[0], setSearchTerm = _f[1];
    var _g = __read(React.useState(false), 2), searchActive = _g[0], setSearchActive = _g[1];
    var handleMetrics = function () {
        if (typeof document !== 'undefined') {
            sendTutorialViewedEvent(props);
        }
    };
    var handleSuggestionSent = function () {
        setSearchTerm('');
        setSuggestionSent(false);
    };
    var handleSearchChange = function (e) {
        setSearchTerm(e.target.value.substr(0, 80));
        setSearchActive(true);
    };
    React.useEffect(function () {
        if (shouldSendMetrics(quickstart)) {
            handleMetrics();
        }
    }, [quickstart]);
    var picker;
    var question;
    var breadcrumbsComponent;
    if (quickstart) {
        picker = (React.createElement(PlatformList, __assign({ searchActive: searchActive, searchTerm: searchTerm, handleSuggestClick: function () {
                setShowSuggestionModal(true);
            } }, props)));
        question = quickstart.question;
        breadcrumbsComponent = React.createElement(Breadcrumbs, { crumbs: breadcrumbs });
    }
    else {
        picker = React.createElement(QuickstartList, __assign({}, props));
        question = firstQuestion;
    }
    var TitleElementType = largeHeader ? 'h1' : 'p';
    return (React.createElement("div", { id: "tutorial-navigator" },
        React.createElement("div", { className: "js-tutorial-navigator" },
            React.createElement("div", { className: "tutorial-wizard" },
                React.createElement("div", { className: "container" },
                    breadcrumbsComponent,
                    React.createElement(TitleElementType, { className: "navigator-title" }, question),
                    subHeader && React.createElement("h2", { className: "header-description" }, subHeader),
                    quickstart && (React.createElement("div", null,
                        React.createElement(QuickstartSuggestModal, { open: showSuggestionModal, suggestion: searchTerm, closeModal: function () {
                                setShowSuggestionModal(false);
                            }, handleSuggestionSent: handleSuggestionSent }),
                        React.createElement("div", { className: "quickstart-search-input" },
                            React.createElement("i", { className: "icon icon-budicon-489" }),
                            React.createElement("input", { className: "form-control input", value: searchTerm, placeholder: "Search by technology name", onChange: handleSearchChange })),
                        suggestionSent && (React.createElement("p", { className: "quickstart-suggestion-sent" }, "Your suggestion has been sent, thanks for your help!"))))),
                picker))));
};
