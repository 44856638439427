import { Text } from '@auth0/quantum-product';
import * as React from 'react';
import parse from 'url-parse';
import { Breadcrumbs, isApiDocsUrl } from '../../../Breadcrumbs';
import { NavLink } from '../../../NavLink';
export var SearchResult = function (_a) {
    var title = _a.title, url = _a.url, description = _a.description, crumbs = _a.crumbs, onClick = _a.onClick;
    var isManagementApiDocsUrl = isApiDocsUrl(url);
    var isRelativeUrl = url.startsWith('https://auth0.com/docs');
    var href = isRelativeUrl ? parse(url).pathname : url;
    return (React.createElement("div", { onClick: onClick },
        React.createElement(NavLink, { variant: "h5", href: href, followLink: !isRelativeUrl || isManagementApiDocsUrl }, title),
        crumbs && React.createElement(Breadcrumbs, { crumbs: crumbs }),
        !isManagementApiDocsUrl && description && (React.createElement(Text, { variant: "body2", color: "textSecondary" }, description))));
};
