import { Box, styled } from '@auth0/quantum-product';
import { connectToStores } from 'fluxible-addons-react';
import React from 'react';
import * as defaults from '../../../lib/helpers/defaults';
import UserStore from '../../stores/UserStore';
import canUseStore from '../../util/canUseStore';
import CodeHighlightPrism from '../CodeHighlightPrism';
import { FeedbackSection } from '../FeedbackSection';
import CodeBlockHeader from './Header';
var Root = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        marginBottom: theme.spacing(3),
    });
});
var CodeBlock = function (_a) {
    var code = _a.code, tabPaneFooter = _a.tabPaneFooter, selectedClientId = _a.selectedClientId, selectedApiIdentifier = _a.selectedApiIdentifier, index = _a.index, language = _a.language;
    // Only show the header if the client id or api id is present in the code
    var isClientIdInCode = code.includes(selectedClientId || defaults.CLIENT_ID);
    var isApiIdInCode = code.includes(selectedApiIdentifier || defaults.API_IDENTIFIER);
    var showHeader = isClientIdInCode || isApiIdInCode;
    return (React.createElement(Root, null,
        showHeader && React.createElement(CodeBlockHeader, { isApiIdInCode: isApiIdInCode, isClientIdInCode: isClientIdInCode }),
        React.createElement(CodeHighlightPrism, { code: code, language: language, showCopyButton: true }),
        tabPaneFooter && React.createElement("div", { className: "tab-pane-footer", dangerouslySetInnerHTML: { __html: tabPaneFooter } }),
        React.createElement(FeedbackSection, { trackingCode: "quickstarts:code-snippet", trackingData: index })));
};
export default connectToStores(CodeBlock, [UserStore], function (context) {
    if (!canUseStore(context))
        return {};
    var userStore = context.getStore(UserStore);
    return {
        selectedClientId: userStore.getSelectedClientId(),
        selectedApiIdentifier: userStore.getSelectedApiIdentifier(),
    };
});
