var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import { Sticky } from 'react-sticky';
import { useFluxible } from '../hooks/useFluxible';
import { SidebarItem } from './SidebarItem.old';
// Fix Remove IOS Rubber effect
// https://github.com/luster-io/prevent-overscroll/blob/master/index.js
var removeIOSRubberEffect = function (element) {
    /* eslint-disable no-param-reassign */
    element === null || element === void 0 ? void 0 : element.addEventListener('touchmove', function () {
        var top = element.scrollTop;
        var totalScroll = element.scrollHeight;
        var currentScroll = top + element.offsetHeight;
        if (top === 0) {
            element.scrollTop = 1;
        }
        else if (currentScroll === totalScroll) {
            element.scrollTop = top - 1;
        }
    });
    /* eslint-enable no-param-reassign */
};
export var Sidebar = function (_a) {
    var items = _a.items, _b = _a.maxDepth, maxDepth = _b === void 0 ? 2 : _b, section = _a.section, isQuickstart = _a.isQuickstart;
    var context = useFluxible();
    var _c = __read(React.useState(false), 2), openDropdown = _c[0], setOpenDropdown = _c[1];
    var sidebarContent = React.useRef(null);
    var sidebarScrollable = React.useRef(null);
    var scrollSideBarIntoView = function () {
        var active = $('.sidebar-item-depth0.is-current')[0];
        var list = $('ul.sidebar-item-list')[0];
        if (active && list)
            list.scrollTop = active.offsetTop;
    };
    var handleToggle = function () {
        $('html, body').toggleClass('overflow-hidden', !openDropdown);
        if (sidebarContent.current) {
            // Set scrollable menu area acording to scroll offset
            var sidebarTopOffset = sidebarContent.current.getBoundingClientRect().top;
            var sidebarHeight = "calc(100vh - ".concat(sidebarTopOffset.toString(), "px)");
            if (!openDropdown) {
                sidebarContent.current.style.height = sidebarHeight;
            }
            else {
                sidebarContent.current.style.height = '';
            }
        }
        setOpenDropdown(!openDropdown);
    };
    var handleClick = function (url) { return function () {
        context.trackEvent('select:user_resource', { trackData: url });
    }; };
    var displayMobileTitle = function () {
        var activeItem = items.find(function (i) { return i.isActiveUrl; });
        return (activeItem && activeItem.title) || '';
    };
    React.useEffect(function () {
        removeIOSRubberEffect(sidebarScrollable === null || sidebarScrollable === void 0 ? void 0 : sidebarScrollable.current);
        scrollSideBarIntoView();
    }, []);
    React.useEffect(function () {
        scrollSideBarIntoView();
        // replacing componentDidUpdate - if either props or state changes
    }, [items, maxDepth, section, isQuickstart, openDropdown]);
    return (React.createElement(Sticky, null, function (_a) {
        var isSticky = _a.isSticky, style = _a.style;
        return (React.createElement("div", { style: style, className: isSticky ? 'sticky' : '' },
            React.createElement("div", { className: "sidebar ".concat(isQuickstart ? 'is-quickstart' : 'is-article') },
                React.createElement("div", { className: "section-title" }, section),
                React.createElement("ul", { ref: sidebarContent, className: "\n                sidebar-item-list\n                sidebar-item-list-depth0\n                ".concat(openDropdown ? 'is-dropdown-open' : '', "\n              ") },
                    React.createElement("div", { className: "mobile-dropdown-trigger", onClick: handleToggle, role: "button" },
                        React.createElement("h5", { className: "mobile-dropdown-title" },
                            React.createElement("span", null, displayMobileTitle())),
                        React.createElement("i", { className: "mobile-dropdown-icon icon-budicon-".concat(openDropdown ? '462' : '460') })),
                    React.createElement("div", { className: "mobile-dropdown-content scrollable", ref: sidebarScrollable }, items.map(function (item) { return (React.createElement(SidebarItem, __assign({}, item, { article: item, key: item.url, url: item.url, currentDepth: 0, maxDepth: maxDepth, handleOnClick: handleClick }))); }))))));
    }));
};
export var MemoizedSidebar = React.memo(Sidebar, function (prevProps, nextProps) {
    // There are situations when the pages are transitioning when the section or the url
    // may not yet be known. To provide a smooth transition we dont want to rerender this
    // component until the new page's values are set.
    if (!nextProps.section || !nextProps.url) {
        return true;
    }
    return false;
});
