var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Divider, DropdownMenu, DropdownMenuList, DropdownMenuListItem, IconButton, MonitorIcon, MoonIcon, styled, SunIcon, useDropdownMenuState, } from '@auth0/quantum-product';
import * as React from 'react';
import { ColorScheme } from '../../../@types/ColorScheme';
import { useThemeManager } from '../../ThemeManager';
var Root = styled('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            display: 'flex',
            gap: theme.spacing(0.5),
            alignItems: 'center',
            margin: theme.spacing(0, 0.5, 0, 1.5)
        },
        _b[theme.breakpoints.down('md')] = {
            flexDirection: 'row-reverse',
        },
        _b);
});
var colorSchemeOptions = [
    {
        title: 'Light',
        icon: React.createElement(SunIcon, null),
        id: 'light',
    },
    {
        title: 'Dark',
        icon: React.createElement(MoonIcon, null),
        id: 'dark',
    },
    {
        title: 'System',
        icon: React.createElement(MonitorIcon, null),
        id: 'system',
    },
];
export var ColorSchemeMenu = function () {
    var _a;
    var _b = useThemeManager(), _c = _b.colorScheme, colorScheme = _c === void 0 ? ColorScheme.SystemLight : _c, setColorScheme = _b.setColorScheme;
    var _d = useDropdownMenuState({ baseId: 'color-scheme-menu' }), triggerProps = _d.triggerProps, menuProps = _d.menuProps;
    return (React.createElement(Root, null,
        React.createElement(Divider, { orientation: "vertical", variant: "middle", flexItem: true }),
        React.createElement(IconButton, __assign({}, triggerProps), (_a = colorSchemeOptions.find(function (o) { return colorScheme.startsWith(o.id); })) === null || _a === void 0 ? void 0 : _a.icon),
        React.createElement(DropdownMenu, __assign({ size: "default", placement: "left", keepMounted: true, maxWidth: 150 }, menuProps),
            React.createElement(DropdownMenuList, null, colorSchemeOptions.map(function (_a) {
                var title = _a.title, icon = _a.icon, id = _a.id;
                return (React.createElement(DropdownMenuListItem, { key: id, sx: { '& .QuantumDropdownMenuListItem-content': { flex: 'none' } }, onClick: function (ev) {
                        setColorScheme(id);
                        if (menuProps.onClose) {
                            menuProps.onClose(ev, 'itemSelect');
                        }
                    }, selected: colorScheme.endsWith(id), title: title, startIcon: icon }));
            })))));
};
