import { IconButton, styled } from '@auth0/quantum-product';
export var CloseIconButton = styled(IconButton)(function (_a) {
    var theme = _a.theme;
    return ({
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        borderRadius: '50%',
        padding: theme.spacing(0.5),
        height: 24,
        width: 24,
    });
});
