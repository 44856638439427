var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { RowLayout, SidebarItems, styled, Text } from '@auth0/quantum-product';
import * as React from 'react';
import { SidebarItem } from './SidebarItem';
var StyledSidebarItems = styled(SidebarItems)(function (_a) {
    var theme = _a.theme;
    return ({
        overflowY: 'scroll',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: 0,
            background: 'transparent',
        },
        '& li:first-child > a': {
            paddingTop: 0,
        },
        '& .externalLink': {
            marginLeft: theme.spacing(1),
        },
    });
});
export var DesktopSidebar = function (_a) {
    var items = _a.items, _b = _a.maxDepth, maxDepth = _b === void 0 ? 2 : _b, section = _a.section;
    return (React.createElement(RowLayout, { sx: { height: '100%', pt: 5, alignContent: 'flex-start' }, gutter: 3 },
        React.createElement(Text, { variant: "overline", color: "textSecondary" }, section),
        React.createElement(StyledSidebarItems, null, items.map(function (item) { return (React.createElement(SidebarItem, __assign({}, item, { article: item, key: item.url, url: item.url, currentDepth: 0, maxDepth: maxDepth }))); }))));
};
