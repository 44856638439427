import { Box, styled, Text } from '@auth0/quantum-product';
import * as React from 'react';
import parse from 'url-parse';
import { BetaBadge } from '../BetaBadge';
import { LanguageSelector } from './LanguageSelector';
import { VersionSelector } from './VersionSelector';
var MetaContainer = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'flex',
        marginTop: theme.spacing(2.5),
        '& > *': {
            marginRight: theme.spacing(2),
        },
    });
});
export var HeaderWrapper = function (_a) {
    var _b = _a.meta, title = _b.title, beta = _b.beta, version = _b.version, versioning = _b.versioning, languages = _b.languages, url = _b.url;
    return (React.createElement(Box, { marginBottom: 5 },
        React.createElement(Text, { variant: "h1" },
            title,
            beta && React.createElement(BetaBadge, null)),
        (versioning || languages) && (React.createElement(MetaContainer, null,
            versioning && React.createElement(VersionSelector, { url: url, version: version, versioning: versioning }),
            languages && React.createElement(LanguageSelector, { languages: languages, pathname: parse(url).pathname })))));
};
