var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Button, CheckIcon, styled, useTheme, XIcon } from '@auth0/quantum-product';
import * as React from 'react';
import { FeedbackMode } from '../feedbackModes';
var StyledButton = styled(Button)(function (_a) {
    var theme = _a.theme, isChecked = _a.isChecked, isDisabled = _a.isDisabled;
    return (__assign(__assign({ padding: theme.spacing(0, 1), '&:focus': {
            boxShadow: 'none',
        } }, ((isChecked || isDisabled) && {
        cursor: 'default',
        '&:hover': {
            backgroundColor: theme.palette.background.paper,
        },
    })), (isDisabled && {
        opacity: 0.5,
    })));
});
export var BooleanFeedbackButton = function (_a) {
    var onClick = _a.onClick, value = _a.value, checked = _a.checked, disabled = _a.disabled, useButtons = _a.useButtons;
    var theme = useTheme();
    var checkGreen = theme.palette.success.main;
    var xRed = theme.palette.error.main;
    var handleClick = function () {
        !disabled && !checked && onClick();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledButton, { size: "small", variant: useButtons ? 'outlined' : 'link', color: useButtons ? 'default' : 'primary', onClick: handleClick, isChecked: checked, isDisabled: disabled && !checked, startIcon: useButtons && (value === FeedbackMode.Positive ? React.createElement(CheckIcon, { color: checkGreen }) : React.createElement(XIcon, { color: xRed })) }, value === FeedbackMode.Positive ? 'Yes' : 'No'),
        !useButtons && value === FeedbackMode.Positive && React.createElement("span", null, "/")));
};
