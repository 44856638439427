import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.styl';

const Requirements = ({ list }) => (
  <div className={styles.requirements}>
    <span className={styles.title}>System requirements: </span>
    <span>{list.join(' | ')}</span>
  </div>
);

Requirements.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Requirements;
