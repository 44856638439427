import * as React from 'react';
import { performSelectApi } from '../../../../action/performUserResourceActions';
import UserStore from '../../../../stores/UserStore';
import { useFluxible } from '../../../hooks/useFluxible';
import { SelectResource } from './SelectResource';
export var SelectAPI = function (_a) {
    var setView = _a.setView;
    var context = useFluxible();
    var apis = context.getStore(UserStore).getApis();
    var selectedId = context.getStore(UserStore).getSelectedApiId();
    var save = function (selectedId) {
        context.executeAction(performSelectApi, { selectedId: selectedId });
        setView('');
    };
    return (React.createElement(SelectResource, { resources: apis, selectedId: selectedId, renderOptions: apis.map(function (api) { return (React.createElement("option", { key: api.id, value: api.id }, api.name)); }), onSave: save, label: "Select your API", helperText: "Select the API to configure for this quickstart", onCancel: function () { return setView('default'); } }));
};
