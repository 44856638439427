var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Box, RowLayout, styled } from '@auth0/quantum-product';
import * as React from 'react';
import ApplicationStore from '../../../stores/ApplicationStore';
import { useFluxible } from '../../hooks/useFluxible';
import { ColumnStack } from '../../layouts';
import { ArticleType, useArticleContext } from '../ArticleContext';
var StyledColumnStack = styled(ColumnStack)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            alignItems: 'flex-start',
            gap: theme.spacing(5)
        },
        _b[theme.breakpoints.up('md')] = {
            gridTemplateColumns: 'none',
        },
        _b);
});
var StyledRowLayout = styled(RowLayout)(function (_a) {
    var theme = _a.theme;
    return ({
        gap: 0,
    });
});
var SidebarContainer = styled('div')(function (_a) {
    var theme = _a.theme, isMobile = _a.isMobile, isStickyHeaderVisible = _a.isStickyHeaderVisible;
    return (__assign({}, (!isMobile && {
        width: theme.layout.sidebarWidth,
        height: "calc(100vh - ".concat(isStickyHeaderVisible ? 0 : theme.layout.headerHeight, "px)"),
        position: 'sticky',
        top: 0,
        paddingRight: theme.spacing(0.5),
        paddingBottom: theme.spacing(2),
        borderRight: "1px solid ".concat(theme.palette.divider),
    })));
});
var OuterContentContainer = styled(Box)(function (_a) {
    var isMobile = _a.isMobile;
    return (__assign({ height: '100%' }, (isMobile && {
        display: 'flex',
        justifyContent: 'center',
    })));
});
var ContentContainer = styled('div')(function (_a) {
    var _b, _c;
    var theme = _a.theme, type = _a.type;
    return (__assign(__assign({ height: '100%', width: theme.layout.articleContentWidth }, (type === ArticleType.LandingPage && (_b = {
            width: theme.layout.landingContentWidth
        },
        _b[theme.breakpoints.down(theme.layout.landingBreakpoint)] = {
            width: "calc(100vw - ".concat(theme.layout.sidebarWidth + theme.spacingPX(15), "px)"),
        },
        _b[theme.breakpoints.down(theme.layout.tabletBreakpoint)] = {
            width: theme.layout.landingContentWidth,
        },
        _b[theme.breakpoints.down(theme.layout.landingContentWidth + theme.spacingPX(10))] = {
            width: theme.layout.articleContentWidth,
        },
        _b))), (_c = {}, _c[theme.breakpoints.down(theme.layout.mobileBreakpoint)] = {
        width: '100%',
    }, _c)));
});
var TocContainer = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        position: 'sticky',
        top: theme.layout.headerHeight + theme.spacingPX(8),
        marginLeft: theme.spacing(5),
        maxWidth: theme.layout.tocWidth,
        minWidth: theme.layout.tocWidth,
        borderLeft: "2px solid ".concat(theme.palette.divider),
    });
});
var Layout = function (_a) {
    var children = _a.children, isMobile = _a.isMobile;
    return isMobile ? React.createElement(StyledRowLayout, null, children) : React.createElement(StyledColumnStack, null, children);
};
export var ArticleLayout = function (_a) {
    var children = _a.children, sidebar = _a.sidebar, toc = _a.toc;
    var context = useFluxible();
    var appStore = context.getStore(ApplicationStore);
    var isStickyHeaderVisible = appStore.isStickyHeaderVisible();
    var _b = useArticleContext(), isMobile = _b.isMobile, hasFullwidth = _b.hasFullwidth, articleType = _b.articleType;
    return (React.createElement(Layout, { isMobile: isMobile },
        React.createElement(SidebarContainer, { isMobile: isMobile, isStickyHeaderVisible: isStickyHeaderVisible, "data-search-index": "false" }, sidebar),
        React.createElement(OuterContentContainer, { isMobile: isMobile },
            React.createElement(ContentContainer, { type: articleType }, children)),
        hasFullwidth && toc && React.createElement(TocContainer, null, toc)));
};
