var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { ArrowRightIcon, Box, ChevronDownIcon, ChevronUpIcon, SidebarItems, styled, Text, } from '@auth0/quantum-product';
import { NavLink } from 'fluxible-router';
import * as React from 'react';
import parse from 'url-parse';
import { ColumnStack } from '../layouts';
import { SidebarItem } from './SidebarItem';
var Root = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(0, -theme.layout.mobileMarginX),
    });
});
var MobileDropdownTitle = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        padding: theme.spacing(2, theme.layout.mobileMarginX),
        borderBottom: "1px solid ".concat(theme.palette.divider),
    });
});
var MobileSidebarItem = styled(MobileDropdownTitle)(function (_a) {
    var theme = _a.theme;
    return ({
        borderBottom: "1px solid ".concat(theme.palette.divider),
        padding: theme.spacing(2, theme.layout.mobileMarginX),
    });
});
var MobileSidebarItems = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        padding: 0,
    });
});
var MobileSidebarSubitems = styled(SidebarItems)({});
var StyledArrowRightIcon = styled(ArrowRightIcon)(function (_a) {
    var theme = _a.theme;
    return ({
        color: theme.palette.text.secondary,
    });
});
export var MobileSidebar = function (_a) {
    var items = _a.items, _b = _a.maxDepth, maxDepth = _b === void 0 ? 2 : _b;
    var _c = __read(React.useState(false), 2), openDropdown = _c[0], setOpenDropdown = _c[1];
    var displayMobileTitle = function () {
        var activeItem = items.find(function (i) { return i.isActiveUrl; });
        return (activeItem === null || activeItem === void 0 ? void 0 : activeItem.title) || '';
    };
    return (React.createElement(Root, null,
        React.createElement(MobileDropdownTitle, { sx: { display: 'flex', justifyContent: 'space-between' }, onClick: function () { return setOpenDropdown(!openDropdown); } },
            React.createElement(Text, { variant: "subtitle1" }, displayMobileTitle()),
            openDropdown ? React.createElement(ChevronUpIcon, null) : React.createElement(ChevronDownIcon, null)),
        openDropdown && (React.createElement(MobileSidebarItems, null, items.map(function (item) { return (React.createElement(MobileSidebarItem, { sx: { display: 'flex', flexDirection: 'column' } },
            React.createElement(ColumnStack, { sx: { display: 'flex' }, gutter: 1 },
                React.createElement(NavLink, { href: item.url && !item.external ? parse(item.url).pathname : item.url, followLink: item.forceFullReload },
                    React.createElement(Text, { variant: "subtitle2", color: item.isActiveUrl ? 'textPrimary' : 'textSecondary' }, item.title)),
                item.forceFullReload && React.createElement(StyledArrowRightIcon, { size: 16 })),
            item.isActiveUrl && item.children ? (React.createElement(MobileSidebarSubitems, null, item.children.map(function (item) { return (React.createElement(SidebarItem, __assign({}, item, { article: item, key: item.url, url: item.url, currentDepth: 1, maxDepth: maxDepth }))); }))) : null)); })))));
};
