import { ColumnLayout, styled } from '@auth0/quantum-product';
import * as React from 'react';
import { NavLink } from '../../NavLink';
var Root = styled(ColumnLayout)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            marginRight: theme.spacing(3),
            gridAutoColumns: 'min-content',
            gridAutoFlow: 'column',
            gridTemplateColumns: 'none'
        },
        _b[theme.breakpoints.up('md')] = {
            gridTemplateColumns: 'none',
        },
        _b[theme.breakpoints.down(theme.layout.sidebarWidth + theme.layout.articleContentWidth)] = {
            gap: theme.spacing(2),
        },
        _b[theme.breakpoints.down('md')] = {
            display: 'none',
        },
        _b);
});
var Link = styled(NavLink)(function (_a) {
    var theme = _a.theme;
    return ({
        fontWeight: theme.typography.fontWeightMedium,
        whiteSpace: 'nowrap',
    });
});
export var NavLinks = function (_a) {
    var currentSection = _a.currentSection, sections = _a.sections;
    return (React.createElement(Root, { gutter: 5 }, sections.map(function (section) { return (React.createElement(Link, { href: section.url, key: section.id, color: currentSection === section.id ? 'primary' : 'textPrimary' }, section.title)); })));
};
