var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var alertContainerStyles = function (theme) {
    return {
        // Non-Portal Alert
        '& .alert-content > p': __assign(__assign({}, theme.typography.body2), { letterSpacing: 0 }),
        '& .alert-content bold, .alert-content strong': {
            fontWeight: theme.typography.fontWeightMedium,
        },
        '& .alert-content > p > a': {
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.text.primary,
        },
        '& .alert-content > p > a:hover': {
            textDecoration: 'none',
        },
        '& code .alert-content': __assign({}, theme.typography.code1),
        '& .QuantumAlert-standardWarning,  & .alert-portal-severity-warning': {
            backgroundColor: theme.tokens.color_bg_state_caution_subtle,
            '& $icon': {
                color: theme.tokens.color_fg_state_caution,
            },
        },
        '& .QuantumAlert-standardInfo,  & .alert-portal-severity-info': {
            backgroundColor: theme.tokens.color_bg_state_info_subtle,
            '& $icon': {
                color: theme.tokens.color_fg_state_info,
            },
        },
        // Custom styles
        '& .alert-portal-content': {
            '&:last-child': {
                marginBottom: 0,
            },
            '& p': __assign(__assign({}, theme.typography.body2), { letterSpacing: 0, '&:last-child': {
                    marginBottom: 0,
                } }),
            '& code': __assign(__assign({}, theme.typography.code1), { paddingLeft: 3, paddingRight: 3 }),
            '& bold, & strong, & b': {
                fontWeight: theme.typography.fontWeightMedium,
                fontSize: theme.typography.body2.fontSize,
            },
        },
    };
};
