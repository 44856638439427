import { Button, styled } from '@auth0/quantum-product';
import PropTypes from 'prop-types';
import React from 'react';
import { login } from '../../../action/navigateAuthentication';
var StyledLogin = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'flex',
        alignItems: 'center',
        '& p': {
            marginBottom: 0,
            marginLeft: theme.spacing(1),
        },
    });
});
var Login = function (_a, context) {
    var quickstartType = _a.quickstartType;
    var handleLogin = function () {
        context.executeAction(login(window.location.pathname));
    };
    return (React.createElement(StyledLogin, null,
        React.createElement(Button, { size: "medium", variant: "outlined", color: "default", onClick: function () { return handleLogin(); } }, "Log In"),
        React.createElement("p", null,
            "to configure with your own ",
            quickstartType === 'backend' ? 'API' : 'application',
            ".")));
};
Login.contextTypes = {
    executeAction: PropTypes.func,
};
export default Login;
