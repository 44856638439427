import { Avatar, AvatarBlock, Box, DownloadIcon, FileIcon, GitHubIcon, Link, styled, TableCell, TableRow, Text, ZapIcon, } from '@auth0/quantum-product';
import React from 'react';
import { assetUrl } from '../../../../../util/assetUrl';
var IconLink = styled(Link)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            margin: theme.spacing(0, 2)
        },
        _b[theme.breakpoints.down('sm')] = {
            margin: theme.spacing(0, 0.5),
        },
        _b);
});
export var Entry = function (_a) {
    var title = _a.title, name = _a.name, description = _a.description, quickstartUrl = _a.url, _b = _a.sdk, sdkUrl = _b.url, referenceUrl = _b.referenceUrl, logo = _a.logo, _c = _a.defaultArticle, _d = _c === void 0 ? {} : _c, githubSampleLink = _d.githubSampleLink;
    return (React.createElement(TableRow, { key: name },
        React.createElement(TableCell, null,
            React.createElement(AvatarBlock, { avatar: React.createElement(Avatar, { sx: { backgroundColor: 'transparent' }, imgFit: "contain", type: "logo", src: assetUrl("/docs/img/platforms/".concat(logo, ".svg")) }), title: title })),
        React.createElement(TableCell, { sx: { display: { xs: 'none', sm: 'table-cell' } } }, description && (React.createElement(Text, { component: "span", color: "text.secondary" }, description))),
        React.createElement(TableCell, { align: "right", sx: { width: '1%' } },
            React.createElement(Box, { sx: { display: 'flex', flexDirection: { xs: 'column', sm: 'row' } } },
                React.createElement(IconLink, { href: sdkUrl, color: "textPrimary", startIcon: React.createElement(GitHubIcon, null) }, "Github"),
                githubSampleLink && (React.createElement(IconLink, { href: githubSampleLink, color: "textPrimary", startIcon: React.createElement(DownloadIcon, null) }, "Sample App")),
                quickstartUrl && (React.createElement(IconLink, { href: quickstartUrl, color: "textPrimary", startIcon: React.createElement(ZapIcon, null) }, "Quickstart")),
                referenceUrl && (React.createElement(IconLink, { href: referenceUrl, color: "textPrimary", startIcon: React.createElement(FileIcon, null) }, "Documentation"))))));
};
