var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Box, Spinner as QuantumSpinner } from '@auth0/quantum-product';
import * as React from 'react';
export var Spinner = function (props) { return (React.createElement(Box, { display: "flex", justifyContent: "center", alignItems: "center", height: "100%" },
    React.createElement(QuantumSpinner, __assign({}, props)))); };
