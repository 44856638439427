import metrics from '@a0/docs-components/metrics';
import { CLIENT_ID as DEFAULT_CLIENT_ID } from '../../lib/helpers/defaults';
import ApplicationStore from '../stores/ApplicationStore';
import DocumentStore from '../stores/DocumentStore';
import QuickstartStore from '../stores/QuickstartStore';
import UserStore from '../stores/UserStore';
var selectResource = function (context, payload) {
    var domainUrlDocs = context.getStore(ApplicationStore).getDomainUrlDocs();
    var csrfToken = context.getStore(ApplicationStore).getCSRFToken();
    var fetchService = context.getService('FetchService');
    metrics.track('select:user_resource', payload);
    return fetchService("".concat(domainUrlDocs, "/auth/session"), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'csrf-token': csrfToken,
        },
        body: JSON.stringify(payload),
    });
};
export function performSelectClient(context, payload) {
    var selectedId = payload.selectedId;
    if (context.getStore(DocumentStore).getSelectedClientId() === selectedId &&
        context.getStore(UserStore).getSelectedClientId() === selectedId) {
        return Promise.resolve();
    }
    context.dispatch('USER_CLIENTS_SELECTED', { selectedId: selectedId });
    context.dispatch('DOCUMENT_CLIENTS_SELECTED', { selectedId: selectedId });
    return selectResource(context, { selectedClientId: selectedId });
}
export function performSelectApi(context, payload) {
    var selectedId = payload.selectedId;
    if (context.getStore(DocumentStore).getSelectedApiId() === selectedId &&
        context.getStore(UserStore).getSelectedApiId() === selectedId) {
        return Promise.resolve();
    }
    context.dispatch('USER_APIS_SELECTED', { selectedId: selectedId });
    context.dispatch('DOCUMENT_APIS_SELECTED', { selectedId: selectedId });
    return selectResource(context, { selectedApiId: selectedId });
}
export function setSelectClientForTutorial(context, isFramedMode) {
    if (isFramedMode) {
        // Framed mode will provide selected client
        return Promise.resolve();
    }
    var userStore = context.getStore(UserStore);
    if (!userStore.isAuthenticated()) {
        // No need to select application for non-authenticated users
        return Promise.resolve();
    }
    var quickstartType = context.getStore(QuickstartStore).getQuickstartType();
    var compatibleClients = userStore.getQuickstartCompatibleClients(quickstartType);
    if (compatibleClients.find(function (c) { return c.client_id === userStore.getSelectedClientId(); })) {
        // Client is compatible, no need to select new one
        return Promise.resolve();
    }
    var newClient = compatibleClients[0];
    var selectedId = newClient ? newClient.client_id : DEFAULT_CLIENT_ID;
    return performSelectClient(context, { selectedId: selectedId });
}
