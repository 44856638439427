import { connectToStores } from 'fluxible-addons-react';
import * as React from 'react';
import changeApiChoiceViewAction from '../../../../action/changeApiChoiceView';
import QuickstartStore from '../../../../stores/QuickstartStore';
import canUseStore from '../../../../util/canUseStore';
import { useFluxible } from '../../../hooks/useFluxible';
import { CreateAPI } from './CreateAPI';
import { SelectAPI } from './SelectAPI';
export var ApiChoice = function (_a) {
    var view = _a.view, children = _a.children;
    var context = useFluxible();
    var setView = function (view) {
        context.executeAction(changeApiChoiceViewAction, { view: view });
    };
    switch (view) {
        case 'create':
            return React.createElement(CreateAPI, { setView: setView });
        case 'select':
            return React.createElement(SelectAPI, { setView: setView });
        default:
            return React.createElement(React.Fragment, null, children);
    }
};
export default connectToStores(ApiChoice, [QuickstartStore], function (context) {
    if (!canUseStore(context))
        return {};
    var quickstartStore = context.getStore(QuickstartStore);
    return {
        view: quickstartStore.getApiChoiceView(),
    };
});
