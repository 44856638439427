import PropTypes from 'prop-types';
import React from 'react';

import { QUANTUM_UI } from '../../../../flags';
import AnchorTitle from '../../AnchorTitle';
import FurtherReadingSection from './FurtherReadingSection';
import newStyles from './styles.module.styl';
import oldStyles from './styles.old.module.styl';

const styles = QUANTUM_UI ? newStyles : oldStyles;

const FurtherReading = (props) => {
  return (
    <React.Fragment>
      <AnchorTitle title="Keep reading" tag="h2" />

      <div className={styles.furtherReadingContainer}>
        <FurtherReadingSection
          title="Guides"
          description="Step-by-step instructions for tasks"
          items={props.guides}
          moreItems={props.moreGuides}
        />
        <FurtherReadingSection
          title="References"
          description="API endpoints, libraries, and best practices"
          items={props.references}
          moreItems={props.moreReferences}
        />
        <FurtherReadingSection
          title="Concepts"
          description="Understand the fundamentals"
          items={props.concepts}
          moreItems={props.moreConcepts}
        />
      </div>
    </React.Fragment>
  );
};

FurtherReading.propTypes = {
  concepts: PropTypes.array.isRequired,
  guides: PropTypes.array.isRequired,
  references: PropTypes.array.isRequired,
  moreConcepts: PropTypes.array.isRequired,
  moreGuides: PropTypes.array.isRequired,
  moreReferences: PropTypes.array.isRequired,
};

FurtherReading.defaultProps = {
  concepts: [],
  guides: [],
  references: [],
  moreConcepts: [],
  moreGuides: [],
  moreReferences: [],
};

export default FurtherReading;
