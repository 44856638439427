import { Breadcrumbs as QuantumBreadcrumbs, BreadcrumbsItem as QuantumBreadcrumbsItem } from '@auth0/quantum-product';
import * as React from 'react';
import parse from 'url-parse';
import { useFluxible } from '../hooks/useFluxible';
import { NavLink } from '../NavLink';
export function isApiDocsUrl(url) {
    if (url) {
        var path = url.startsWith('https://auth0.com/docs') ? parse(url).pathname : url;
        return path.startsWith('/docs/api/management/v2');
    }
    return false;
}
export var Breadcrumbs = function (_a) {
    var crumbs = _a.crumbs, style = _a.style;
    var context = useFluxible();
    return (React.createElement(QuantumBreadcrumbs, { itemScope: true, itemType: "http://schema.org/BreadcrumbList", style: style && style }, crumbs.map(function (crumb, index) { return (React.createElement("span", { itemProp: "itemListElement", itemScope: true, itemType: "http://schema.org/ListItem", key: crumb.title + crumb.url, onClick: function () { return context.trackEvent('click:breadcrumb', { title: crumb.title, url: crumb.url }); } },
        React.createElement(NavLink, { itemProp: "item", href: crumb.url, followLink: crumb.forceFullReload || isApiDocsUrl(crumb.url) },
            React.createElement(QuantumBreadcrumbsItem, { itemProp: "name", component: "button", label: crumb.title })),
        React.createElement("meta", { itemProp: "position", content: (index + 1).toString() }))); })));
};
