import { styled } from '@auth0/quantum-product';
import * as React from 'react';
import ApplicationStore from '../../../stores/ApplicationStore';
import QuickstartStore from '../../../stores/QuickstartStore';
import { useFluxible } from '../../hooks/useFluxible';
import { ColumnStack, ContentContainer } from '../../layouts';
import { Spinner } from '../../Spinner';
import { Quickstart } from '../Quickstart';
var ListContainer = styled(ContentContainer)(function (_a) {
    var theme = _a.theme;
    return ({
        overflowX: 'auto',
        scrollSnapType: 'x mandatory',
        scrollBehavior: 'smooth',
        webkitOverflowScrolling: 'touch',
        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        paddingBottom: theme.spacing(0.5),
    });
});
var ListWrapper = styled(ColumnStack)(function (_a) {
    var _b;
    var theme = _a.theme, _c = _a.variant, variant = _c === void 0 ? 'home' : _c;
    return (_b = {},
        _b[theme.breakpoints.down(variant === 'home' ? theme.layout.homePageDesktopBreakpoint : theme.layout.landingBreakpoint)] = {
            gridAutoFlow: 'row',
        },
        _b[theme.breakpoints.down('xs')] = {
            gridAutoFlow: 'row',
            justifyItems: 'center',
        },
        _b);
});
export var QuickstartList = function (_a) {
    var quickstarts = _a.quickstarts, variant = _a.variant;
    var items = null;
    if (quickstarts) {
        items = Object.keys(quickstarts).map(function (name) { return (React.createElement(Quickstart, { key: name, variant: variant, showCTA: false, quickstart: quickstarts[name] })); });
    }
    return (React.createElement(ListContainer, null,
        React.createElement(ListWrapper, { sx: {
                gridTemplateColumns: ['none', '1fr 1fr', 'repeat(2, minmax(0,1fr))'],
                gridAutoColumns: ['1fr', 'min-content', 'min-content'],
            }, gutter: 4, variant: variant }, items)));
};
export var QuickstartListWithContext = function (_a) {
    var variant = _a.variant;
    var context = useFluxible();
    var appStore = context.getStore(ApplicationStore);
    var quickstartStore = context.getStore(QuickstartStore);
    var isFramedMode = appStore.isFramedMode();
    var quickstarts = quickstartStore.getQuickstarts();
    return quickstarts ? (React.createElement(QuickstartList, { variant: variant, quickstarts: quickstarts, isFramedMode: isFramedMode })) : (React.createElement(Spinner, { size: "large" }));
};
