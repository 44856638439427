/* global $ */

export default function initSampleBox({ apiIdentifier, isFramedMode, handleDownloadPackage } = {}) {
  const $sampleBox = $('#package');
  if ($sampleBox.length === 0) return;

  // Setup Download Button Hooks
  const $downloadButton = $sampleBox.find('.package-buttons a.download-package');
  let downloadLink = $downloadButton.attr('href');

  if (isFramedMode && downloadLink && !$sampleBox.data('manageUrlConfigured')) {
    // If in manage (framed), change link to point to downloader for manage
    const manageInsertPoint = downloadLink.indexOf('/package');
    downloadLink = `${downloadLink.substr(0, manageInsertPoint)}/manage${downloadLink.substr(manageInsertPoint)}`;
    $downloadButton.attr('href', downloadLink);
    $sampleBox.data('manageUrlConfigured', true);
  }

  if (apiIdentifier && downloadLink && !$sampleBox.data('apiDownloadConfigured')) {
    const downloadUrl = downloadLink.split('&api_id=')[0];
    downloadLink = `${downloadUrl}&api_id=${apiIdentifier}`;
    $downloadButton.attr('href', downloadLink);
    $sampleBox.data('apiDownloadConfigured', true);
  }

  if (downloadLink && !$sampleBox.data('handleDownloadPackageConfigured')) {
    $downloadButton.on('click', handleDownloadPackage);
    $sampleBox.data('handleDownloadPackageConfigured', true);
  }

  // Setup Sample Requirement Hooks
  const $sampleRequirements = $sampleBox.find('.package-requirements');

  if ($sampleRequirements.length !== 0 && !$sampleBox.data('sampleRequirementsConfigured')) {
    const $requirementsToggle = $sampleBox.find('.package-requirements-toggle');
    const $requirementsToggleText = $requirementsToggle.find('.text');
    const $requirementsToggleIcon = $requirementsToggle.find('.icon');
    let requirementsOpen = false;

    $($requirementsToggle).on('click', () => {
      requirementsOpen = !requirementsOpen;
      $($sampleRequirements).slideToggle(200);
      $($requirementsToggleText).text(`${requirementsOpen ? 'Hide' : 'Show'} requirements`);
      $($requirementsToggleIcon).toggleClass('icon-budicon-460 icon-budicon-462');
    });

    $sampleBox.data('sampleRequirementsConfigured', true);
  }
}
