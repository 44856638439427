import { styled } from '@auth0/quantum-product';
export var Gallery = styled('div')(function (_a) {
    var _b;
    var theme = _a.theme, size = _a.size;
    return (_b = {
            display: 'grid',
            gap: theme.spacing(3),
            gridTemplateColumns: size === 'large' ? 'repeat(auto-fill, minmax(450px, 1fr))' : 'repeat(auto-fill, minmax(384px, 1fr))'
        },
        _b[theme.breakpoints.down('sm')] = {
            gridTemplateColumns: '1fr',
        },
        _b);
});
