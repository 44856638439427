import loadCards from '../action/loadCards';
import loadDocument from '../action/loadDocument';
import loadLandingPage from '../action/loadLandingPage';
import loadPageTitle from '../action/loadPageTitle';
import loadQuickstarts from '../action/loadQuickstarts';
import tutorialNavigatorActions from '../action/tutorialNavigatorActions';
import { ArticleType } from '../components/Article';
import ArticlePage from '../components/pages/ArticlePage';
import AuthApiPage from '../components/pages/AuthApiPage';
import AuthExtensionPage from '../components/pages/AuthExtensionPage';
import ErrorPage from '../components/pages/ErrorPage';
import GlossaryPage from '../components/pages/GlossaryPage';
import HomePage from '../components/pages/HomePage';
import LibraryPage from '../components/pages/LibraryPage';
import { LoadingPage } from '../components/pages/LoadingPage';
import QuickstartsPage from '../components/pages/QuickstartsPage.new';
import SearchPage from '../components/pages/SearchPage';
import { TutorialPage } from '../components/pages/TutorialPage';
import normalizeUrl from '../util/normalizeUrl';
export default {
    home: {
        path: '/docs',
        method: 'get',
        handler: HomePage,
        action: function (context) {
            return Promise.all([loadQuickstarts(context).then(function () { return tutorialNavigatorActions.home(context); }), loadCards(context)]);
        },
    },
    errorPage: {
        path: '/docs/error',
        method: 'get',
        handler: ErrorPage,
    },
    quickstarts: {
        path: '/docs/quickstarts',
        method: 'get',
        section: 'quickstarts',
        handler: QuickstartsPage,
        action: function (context) { return loadQuickstarts(context).then(function () { return tutorialNavigatorActions.quickstartList(context); }); },
    },
    quickstartAppType: {
        path: '/docs/quickstart/:quickstartId',
        method: 'get',
        section: 'quickstarts',
        handler: QuickstartsPage,
        action: function (context, payload) {
            return loadQuickstarts(context).then(function () { return tutorialNavigatorActions.quickstart(context, payload); });
        },
    },
    quickstartPlatform: {
        path: '/docs/quickstart/:quickstartId/:platformId',
        method: 'get',
        section: 'quickstarts',
        handler: TutorialPage,
        action: function (context, payload) {
            return loadQuickstarts(context).then(function () { return tutorialNavigatorActions.platform(context, payload); });
        },
    },
    quickstartArticleOrVersion: {
        path: '/docs/quickstart/:quickstartId/:platformId/:versionOrArticleId',
        method: 'get',
        section: 'quickstarts',
        handler: TutorialPage,
        action: function (context, payload) {
            return loadQuickstarts(context).then(function () { return tutorialNavigatorActions.article(context, payload); });
        },
    },
    quickstartArticleWithVersion: {
        path: '/docs/quickstart/:quickstartId/:platformId/:versionId/:articleId',
        method: 'get',
        section: 'quickstarts',
        handler: TutorialPage,
        action: function (context, payload) {
            return loadQuickstarts(context).then(function () { return tutorialNavigatorActions.article(context, payload); });
        },
    },
    search: {
        path: '/docs/search',
        method: 'get',
        handler: SearchPage,
        action: loadPageTitle,
    },
    glossary: {
        path: '/docs/glossary',
        method: 'get',
        handler: GlossaryPage,
        action: loadPageTitle,
    },
    library: {
        path: '/docs/libraries',
        method: 'get',
        handler: LibraryPage,
        action: loadQuickstarts,
    },
    authApiExplorer: {
        path: '/docs/api/authentication',
        method: 'get',
        section: 'apis',
        handler: AuthApiPage,
        action: loadDocument,
    },
    authExtensionExplorer: {
        path: '/docs/api/authorization-extension',
        method: 'get',
        section: 'apis',
        handler: AuthExtensionPage,
        action: loadDocument,
    },
    contentful: {
        path: '/docs/contentful/:contentfulId',
        method: 'get',
        handler: ArticlePage,
        action: loadDocument,
    },
    apiDocs: {
        path: '/docs/api/management/*',
        method: 'get',
        handler: LoadingPage,
        action: function (context, payload) {
            if (typeof window !== 'undefined') {
                window.history.pushState({}, '', normalizeUrl(payload.url));
                window.history.go();
            }
        },
    },
    article: {
        path: '/docs/*',
        method: 'get',
        handler: ArticlePage,
        action: function (context, payload) {
            return loadDocument(context, payload).then(function (doc) {
                var _a;
                if (((_a = doc === null || doc === void 0 ? void 0 : doc.meta) === null || _a === void 0 ? void 0 : _a.type) === ArticleType.LandingPage) {
                    return loadLandingPage(context, { doc: doc });
                }
            });
        },
    },
};
