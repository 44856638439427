import useScrollPosition from '@a0/docs-components/hooks/useScrollPosition';
import { RowLayout, styled } from '@auth0/quantum-product';
import * as React from 'react';
import setInnerHtmlHooks from '../../../../browser/setInnerHtmlHooks';
import DocumentStore from '../../../../stores/DocumentStore';
import QuickstartStore from '../../../../stores/QuickstartStore';
import { FeedbackSection } from '../../../ArticleFooter/FeedbackSection';
import { Breadcrumbs } from '../../../Breadcrumbs';
import { ContentRenderer, ContentStyler } from '../../../ContentRenderer';
import { useFluxible } from '../../../hooks/useFluxible';
import { NextSteps } from './NextSteps';
var QuickstartContent = styled(ContentStyler)(function (_a) {
    var theme = _a.theme;
    return ({
        paddingBottom: '25vh',
        '& .step-content:not(.active) *, & a:not(.active) h2': {
            color: '#676C74',
        },
        '& h1, & h2:not(.MuiTypography-root), & h3': {
            margin: theme.spacing(4, 0, 2),
        },
        '& ul': {
            marginBottom: theme.spacing(2),
        },
        '& .panel-default': {
            color: 'inherit',
            backgroundColor: 'inherit',
            borderColor: theme.tokens.color_border_default,
            marginBottom: theme.spacing(3),
            '& .panel-heading': {
                padding: theme.spacing(1.25, 2),
                borderBottom: 0,
                backgroundColor: theme.tokens.color_bg_layer_alternate_bold,
                '& h3': {
                    color: theme.tokens.color_fg_bold,
                    margin: 0,
                },
            },
        },
    });
});
var FeedbackContainer = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        border: "1px solid ".concat(theme.tokens.color_border_default),
        padding: theme.spacing(5),
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
        borderRadius: theme.spacing(0.5),
    });
});
var findActiveStep = function (container) { return function (step) {
    var _a;
    var location = (_a = container.querySelector("#".concat(step.anchor))) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
    // step should become active when it is about 1/3 from the top of the page
    if (location && location.top <= window.innerHeight * 0.33) {
        return step;
    }
}; };
var changeActiveStep = function (container, props) {
    var _a, _b, _c, _d, _e, _f;
    var steps = props.steps, activeStep = props.activeStep, setActiveStepState = props.setActiveStepState, initialStepState = props.initialStepState;
    var active = steps.find(findActiveStep(container));
    if (active) {
        if (active.number !== activeStep.number) {
            (_a = container.querySelector("#step-content-".concat(active.number))) === null || _a === void 0 ? void 0 : _a.classList.add('active');
            (_b = container.querySelector("#".concat(active.anchor))) === null || _b === void 0 ? void 0 : _b.classList.add('active');
            (_c = container.querySelector("#step-content-".concat(activeStep.number))) === null || _c === void 0 ? void 0 : _c.classList.remove('active');
            (_d = container.querySelector("#".concat(activeStep.anchor))) === null || _d === void 0 ? void 0 : _d.classList.remove('active');
            setActiveStepState(active);
        }
    }
    else {
        if (activeStep.number !== initialStepState.number) {
            (_e = container.querySelector("#step-content-".concat(activeStep.number))) === null || _e === void 0 ? void 0 : _e.classList.remove('active');
            (_f = container.querySelector("#".concat(activeStep.anchor))) === null || _f === void 0 ? void 0 : _f.classList.remove('active');
            setActiveStepState(initialStepState);
        }
    }
};
export var StepsView = function (props) {
    var _a, _b;
    var isFramedMode = props.isFramedMode;
    var getStore = useFluxible().getStore;
    var quickstartStore = getStore(QuickstartStore);
    var article = quickstartStore.getCurrentArticle();
    var breadcrumbs = quickstartStore.getBreadcrumbs();
    var metadataNextSteps = quickstartStore.getMetadataNextSteps();
    var doc = article && getStore(DocumentStore).getDocument(article.url);
    var html = doc.html, meta = doc.meta, title = doc.title;
    var stepsRef = React.useRef(null);
    var container = stepsRef === null || stepsRef === void 0 ? void 0 : stepsRef.current;
    var scrollPos = useScrollPosition(250);
    React.useEffect(function () {
        if (container) {
            changeActiveStep(container, props);
        }
    }, [scrollPos.y]);
    return (React.createElement(RowLayout, { gutter: 5, sx: { py: 5 } },
        React.createElement(Breadcrumbs, { crumbs: breadcrumbs }),
        React.createElement(QuickstartContent, null,
            React.createElement("div", { ref: stepsRef },
                React.createElement("h1", null, title),
                React.createElement("p", null, doc.description),
                React.createElement(ContentRenderer, { html: html, meta: meta, afterRender: function (e) { return setInnerHtmlHooks(e, isFramedMode); } }),
                React.createElement(NextSteps, { items: metadataNextSteps ? metadataNextSteps : [] }),
                React.createElement(FeedbackContainer, null,
                    React.createElement(FeedbackSection, { articleUrl: article === null || article === void 0 ? void 0 : article.url, githubEditLink: (_a = doc === null || doc === void 0 ? void 0 : doc.meta) === null || _a === void 0 ? void 0 : _a.editUrl, contentfulId: (_b = doc === null || doc === void 0 ? void 0 : doc.meta) === null || _b === void 0 ? void 0 : _b.contentfulId, isQuickstart: true }))))));
};
