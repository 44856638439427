import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.styl';

const Header = ({ title, description }) => (
  <div className={styles.header}>
    <h1>{title}</h1>
    <h4>{description}</h4>
  </div>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

Header.defaultProps = {
  title: '',
  description: '',
};

export default Header;
