import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.styl';
import { mapOptionTypes } from '../../../util/clientTypesHelper';
import { performSelectClient } from '../../../action/performUserResourceActions';

function ClientSelector({ handleSelected, userClientsByType, selectedClientId }) {
  return (
    <div className={`${styles.formGroup} form-group`}>
      <label htmlFor="qs-download-modal-client" className="control-label">
        Application
      </label>
      <select
        name="qs-download-modal-client"
        id="qs-download-modal-client"
        className="form-control"
        onChange={(e) => handleSelected(performSelectClient, e.target.value)}
        value={selectedClientId}
      >
        {mapOptionTypes(userClientsByType)}
      </select>
    </div>
  );
}

ClientSelector.propTypes = {
  handleSelected: PropTypes.func.isRequired,
  userClientsByType: PropTypes.object.isRequired,
  selectedClientId: PropTypes.string,
};

export default ClientSelector;
