var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Card, styled, Text } from '@auth0/quantum-product';
import * as React from 'react';
import { ContentStyler } from '../ContentRenderer';
var Type;
(function (Type) {
    Type["Overiew"] = "overview";
    Type["Prerequisites"] = "prerequisites";
})(Type || (Type = {}));
var StyledCard = styled(Card)(function (_a) {
    var theme = _a.theme, type = _a.type;
    return (__assign(__assign({ borderRadius: theme.spacing(3) }, (type === Type.Prerequisites && {
        background: theme.tokens.color_bg_layer_alternate,
        border: 'none',
    })), { '& h4': {
            marginBottom: theme.spacing(2),
        } }));
});
var Title = styled(Text)(function (_a) {
    var theme = _a.theme;
    return ({
        marginBottom: theme.spacing(2),
    });
});
export var OverviewContainer = function (_a) {
    var type = _a.type, _b = _a.content, content = _b === void 0 ? '' : _b;
    return (React.createElement(StyledCard, { type: type, size: "large" },
        React.createElement(Title, { variant: "h4" }, type === Type.Overiew ? 'Overview' : 'Before you start'),
        React.createElement(ContentStyler, { dangerouslySetInnerHTML: {
                __html: content,
            } })));
};
