import { Box, LinkIcon, Text } from '@auth0/quantum-product';
import * as React from 'react';
// @todo: Move all titles specific styles to a index.styl inside this folder
var updateWindowHash = function (id) {
    window.location.hash = id;
};
export var Title = function (_a) {
    var title = _a.title, anchor = _a.anchor, tag = _a.tag;
    return (React.createElement(Box, { className: "title-portal-container", onClick: function () { return updateWindowHash(anchor); } },
        React.createElement(LinkIcon, { className: "title-portal-icon", size: 16 }),
        React.createElement(Text, { className: "title-portal-text", id: anchor, variant: tag, dangerouslySetInnerHTML: { __html: title } })));
};
