import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import navigateToQuickstart from '../../../action/navigateToQuickstart';
import setInnerHtmlHooks from '../../../browser/setInnerHtmlHooks';
import resolveQuickstart from '../../../util/resolveQuickstart';
import { Select } from '../../Select';
import styles from './styles.module.styl';

class TutorialVersionSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDetails: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleToggleDetails = this.handleToggleDetails.bind(this);
  }

  handleChange(evt) {
    const { quickstarts, quickstart, platform, article } = this.props;
    const target = resolveQuickstart(
      quickstarts,
      {
        quickstartId: quickstart.name,
        platformId: platform.name,
        versionId: evt.target.value,
      },
      this.context
    );
    let targetArticle = find(target.version.articles, { name: article.name });
    if (!targetArticle) targetArticle = target.version.articles[0];
    this.context.executeAction(navigateToQuickstart, {
      quickstartId: target.quickstart.name,
      platformId: target.platform.name,
      versionId: target.version.name,
      articleId: targetArticle.name,
    });
  }

  handleToggleDetails(e) {
    e.preventDefault();
    this.setState({ showDetails: !this.state.showDetails });
  }

  render() {
    const { platform, version } = this.props;

    if (!platform || !platform.versions || !version) {
      return null;
    }

    const options = Object.keys(platform.versions).map((name) => ({
      label: platform.versions[name].title,
      value: name,
    }));

    return (
      <div className={styles.tutorialVersionSelector}>
        <Select
          label="Version: "
          options={options}
          selected={options.findIndex((v) => v.value === version.name)}
          handleChange={this.handleChange}
        />

        {version.details && (
          <div>
            <span className="info-icon">i</span>
            <a href="" onClick={this.handleToggleDetails}>
              {this.state.showDetails ? 'Hide Details' : 'View Details'}
            </a>
          </div>
        )}

        {this.state.showDetails && version.details && (
          <div className="version-details-panel">
            <h5>Version Details</h5>
            <p ref={setInnerHtmlHooks} dangerouslySetInnerHTML={{ __html: version.details }} />
          </div>
        )}
      </div>
    );
  }
}

TutorialVersionSelector.contextTypes = {
  executeAction: PropTypes.func,
};

TutorialVersionSelector.propTypes = {
  quickstarts: PropTypes.object,
  quickstart: PropTypes.object,
  platform: PropTypes.object,
  version: PropTypes.object,
  article: PropTypes.object,
};

export default TutorialVersionSelector;
