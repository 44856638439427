import { APIError } from '../util/errors';
export var createApi = function (body, csrfToken) {
    var headers = new Headers({
        'Content-Type': 'application/json',
        'csrf-token': csrfToken,
    });
    return fetch('/docs/meta/api/v2/api', {
        headers: headers,
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(body),
    })
        .then(function (response) { return response.json(); })
        .then(function (response) {
        if (response.errorCode) {
            throw new APIError(response.message, response.errorCode);
        }
        return response;
    });
};
