export var titleStyles = function (theme) { return ({
    '& .title-portal-container': {
        display: 'flex',
        alignItems: 'baseline',
        gap: theme.spacingPX(0.5),
        transform: "translateX(-".concat(theme.spacingPX(2.5), "px)"),
        '&:hover': {
            cursor: 'pointer',
            '& .title-portal-icon': {
                opacity: 1,
            },
        },
        '& .title-portal-icon': {
            color: theme.palette.text.secondary,
            opacity: 0,
        },
        '& .title-portal-text': {
            marginTop: theme.spacing(3),
            scrollMarginTop: "".concat(theme.layout.headerHeight + theme.spacingPX(3), "px"),
        },
        '& h2.title-portal-text': {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(1),
        },
    },
}); };
