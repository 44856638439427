import { Avatar, Box, Card, CardHeader, ChevronRightIcon, GitHubIcon, IdenticonApplications, IdenticonGettingStarted, RowLayout, StackLayout, StackLayoutItem, styled, } from '@auth0/quantum-product';
import { connectToStores } from 'fluxible-addons-react';
import * as React from 'react';
import changeApiChoiceViewAction from '../../../../action/changeApiChoiceView';
import changeAppChoiceViewAction from '../../../../action/changeAppChoiceView';
import QuickstartStore from '../../../../stores/QuickstartStore';
import canUseStore from '../../../../util/canUseStore';
import { useFluxible } from '../../../hooks/useFluxible';
import { ApiChoice } from './ApiChoice';
import { AppChoice } from './AppChoice';
var StyledCard = styled(Card)(function (_a) {
    var theme = _a.theme;
    return ({
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(3),
        width: '100%',
    });
});
var ResourceItem = function (_a) {
    var title = _a.title, avatar = _a.avatar;
    return (React.createElement(StyledCard, null,
        React.createElement(StackLayout, { gutter: 3 },
            React.createElement(StackLayoutItem, { sx: { flex: 1 } },
                React.createElement(CardHeader, { sx: { mb: 0 }, avatar: React.createElement(Avatar, { color: "primary", variant: "rounded" }, avatar), title: title, titleTypographyProps: { style: { fontWeight: 'normal' } } })),
            React.createElement(StackLayoutItem, { sx: { flex: 'none', width: 60, justifyContent: 'flex-end', display: 'flex' } },
                React.createElement(Box, { color: "grey.400" },
                    React.createElement(ChevronRightIcon, null))))));
};
var Container = styled('div')(function () { return ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '75%',
}); });
var StyledList = styled(RowLayout)(function (_a) {
    var theme = _a.theme;
    return ({
        width: '75%',
        listStyle: 'none',
        display: 'grid',
        gap: theme.spacing(4),
        padding: theme.spacing(5),
        borderRadius: '16px',
        border: "1px solid ".concat(theme.tokens.color_border_default),
    });
});
var StyledItem = styled('li')(function () { return ({
    cursor: 'pointer',
}); });
export var ResourceChoice = function (_a) {
    var article = _a.article, view = _a.view, quickstartType = _a.quickstartType;
    var context = useFluxible();
    var resourceType = quickstartType === 'backend' ? 'API' : 'application';
    var setView = function (view) {
        context.executeAction(quickstartType === 'backend' ? changeApiChoiceViewAction : changeAppChoiceViewAction, {
            view: view,
        });
    };
    var children = (React.createElement(Container, null,
        React.createElement(StyledList, { component: "ul" },
            React.createElement(StyledItem, { onClick: function () { return setView('create'); } },
                React.createElement(ResourceItem, { title: "Create a new ".concat(resourceType), avatar: React.createElement(IdenticonGettingStarted, { color: "primary" }) })),
            React.createElement(StyledItem, { onClick: function () { return setView('select'); } },
                React.createElement(ResourceItem, { title: "Integrate with an existing ".concat(resourceType), avatar: React.createElement(IdenticonApplications, { color: "primary" }) })),
            React.createElement(StyledItem, null,
                React.createElement("a", { href: article.githubSampleLink },
                    React.createElement(ResourceItem, { title: "View a sample ".concat(resourceType), avatar: React.createElement(GitHubIcon, null) }))))));
    return quickstartType === 'backend' ? (React.createElement(ApiChoice, { view: view }, children)) : (React.createElement(AppChoice, { type: quickstartType, view: view }, children));
};
export default connectToStores(ResourceChoice, [QuickstartStore], function (context) {
    if (!canUseStore(context))
        return {};
    var quickstartStore = context.getStore(QuickstartStore);
    var quickstartType = quickstartStore.getQuickstartType();
    return {
        article: quickstartStore.getCurrentArticle(),
        view: quickstartType === 'backend' ? quickstartStore.getApiChoiceView() : quickstartStore.getAppChoiceView(),
        quickstartType: quickstartType,
    };
});
