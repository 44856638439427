import { Divider, RowLayout, styled } from '@auth0/quantum-product';
import * as React from 'react';
import { FeedbackSection } from './FeedbackSection';
import { NextStepsSection } from './NextStepsSection';
var Root = styled(RowLayout)(function (_a) {
    var theme = _a.theme;
    return ({
        border: "1px solid ".concat(theme.tokens.color_border_default),
        borderRadius: theme.spacing(0.5),
        margin: theme.spacing(4, 0, 5),
        padding: theme.spacing(5),
    });
});
export var ArticleFooter = function (_a) {
    var articleUrl = _a.articleUrl, contentfulId = _a.contentfulId, githubEditLink = _a.githubEditLink, platformTitle = _a.platformTitle, sidebarArticlesNextSteps = _a.sidebarArticlesNextSteps, metadataNextSteps = _a.metadataNextSteps, _b = _a.isQuickstart, isQuickstart = _b === void 0 ? false : _b;
    return (React.createElement(Root, { "data-article-footer": "true", "data-search-index": "false" },
        (metadataNextSteps || sidebarArticlesNextSteps) && (React.createElement(React.Fragment, null,
            React.createElement(NextStepsSection, { isQuickstart: isQuickstart, platformTitle: platformTitle, sidebarArticlesNextSteps: sidebarArticlesNextSteps, metadataNextSteps: metadataNextSteps }),
            React.createElement(Divider, null))),
        React.createElement(FeedbackSection, { articleUrl: articleUrl, githubEditLink: githubEditLink, contentfulId: contentfulId, isQuickstart: isQuickstart })));
};
