import { Box, Button, Text } from '@auth0/quantum-product';
import React from 'react';
import { login } from '../../../action/navigateAuthentication';
import { useFluxible } from '../../hooks/useFluxible';
var Login = function () {
    var fluxibleContext = useFluxible();
    var handleLogin = function () {
        fluxibleContext.executeAction(login(window.location.pathname));
    };
    return (React.createElement(Box, { sx: { display: 'flex', gap: 1, alignItems: 'baseline', mb: 1, mt: 1.5 } },
        React.createElement(Button, { size: "small", variant: "outlined", onClick: handleLogin }, "Log in"),
        React.createElement(Text, { component: "span" }, "to configure this snippet with your account")));
};
export default Login;
