import React from 'react';
import { map } from 'lodash';

const APP_TYPES = {
  regular_web: 'webapp',
  webapp: 'regular_web',
  spa: 'spa',
  native: 'native',
  generic: undefined,
};

const APP_TYPE_DESCRIPTIONS = {
  regular_web: 'Web Applications',
  spa: 'Single Page Applications',
  native: 'Native Applications',
  non_interactive: 'Machine to Machine Applications',
  undefined: 'Generic Applications',
};

const QUICKSTART_TYPE_DESCRIPTIONS = {
  webapp: 'Web Application',
  spa: 'Single Page Application',
  native: 'Native Application',
};

const mapOptionTypes = (userClientsByType) =>
  map(userClientsByType, (clients, type) => (
    <optgroup key={type} label={APP_TYPE_DESCRIPTIONS[type] || type}>
      {clients.map((client) => (
        <option key={client.client_id} value={client.client_id}>
          {client.name}
        </option>
      ))}
    </optgroup>
  ));

export { APP_TYPES, APP_TYPE_DESCRIPTIONS, QUICKSTART_TYPE_DESCRIPTIONS, mapOptionTypes };
