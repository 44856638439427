var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { useArticleContext } from '../Article';
import { SplitSidebar } from '../SplitSidebar';
import { DesktopSidebar } from './DesktopSidebar';
import { MobileSidebar } from './MobileSidebar';
var BaseSidebar = function (props) {
    var isMobile = useArticleContext().isMobile;
    return isMobile ? React.createElement(MobileSidebar, __assign({}, props)) : React.createElement(DesktopSidebar, __assign({}, props));
};
var SidebarSelector = function (props) {
    var SidebarComponent = (props === null || props === void 0 ? void 0 : props.section) === 'articles' ? SplitSidebar : BaseSidebar;
    return React.createElement(SidebarComponent, __assign({}, props));
};
export var Sidebar = React.memo(SidebarSelector, function (prevProps, nextProps) {
    // There are situations when the pages are transitioning when the section may not yet be known.
    // To provide a smooth transition we dont want to rerender this component until the new page's values are set.
    if (!nextProps.section) {
        return true;
    }
    return false;
});
