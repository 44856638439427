/* global $ */
import metrics from '@a0/docs-components/metrics';

function executeClick({ target, href }, event) {
  if (target === '_blank' || target === '_top' || event.metaKey) {
    window.open(href);
  } else {
    window.location.assign(href);
  }
}

export default function outboundLinks(elem) {
  $(elem)
    .find('span[data-trackOutbound] a')
    .on('click', (e) => {
      e.preventDefault();
      const { target } = e;

      let timeoutExecuted = false;
      const timeout = setTimeout(() => {
        timeoutExecuted = true;
        executeClick(target);
      }, 1000);

      metrics.track('click:outbound-link', { trackData: target.href }, () => {
        if (!timeoutExecuted) {
          clearTimeout(timeout);
          executeClick(target);
        }
      });
    });
}
