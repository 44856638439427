import { styled } from '@auth0/quantum-product';
import * as React from 'react';
import AppExplorer from '../../../AppExplorer';
import { SignUpHero } from './ActionHero';
import ResourceChoice from './ResourceChoice';
var Container = styled('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            top: 0,
            paddingTop: theme.spacing(7.5),
            height: '100vh',
            position: 'sticky',
            width: '100%'
        },
        _b[theme.breakpoints.down('sm')] = {
            display: 'none',
        },
        _b);
});
var AppExplorerContainer = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    });
});
var renderActionView = function (isAuthenticated, activeStep, files) {
    switch (activeStep.actionView) {
        case 'code':
            return (React.createElement(AppExplorerContainer, null,
                React.createElement(AppExplorer, { files: files, highlight: activeStep.codeHighlight })));
        // TODO: case 'configure': <ConfigureApplication />
        // TODO: case 'atTheEnd: <DashboardHero />
        default:
            return isAuthenticated ? React.createElement(ResourceChoice, null) : React.createElement(SignUpHero, null);
    }
};
export var ActionView = function (_a) {
    var isAuthenticated = _a.isAuthenticated, activeStep = _a.activeStep, files = _a.files;
    return React.createElement(Container, null, renderActionView(isAuthenticated, activeStep, files));
};
