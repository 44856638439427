var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import styles from './styles.module.styl';
import { TenantDescription } from './TenantDescription.old';
import { TenantMenuItem } from './TenantMenuItem.old';
export var AuthDropdown = function (_a) {
    var _b = _a.picture, picture = _b === void 0 ? '' : _b, manageUrl = _a.manageUrl, tenant = _a.tenant, tenants = _a.tenants, _c = _a.userName, userName = _c === void 0 ? '' : _c;
    var menuRef = React.useRef(null);
    var _d = __read(React.useState(false), 2), showMainMenu = _d[0], setShowMainMenu = _d[1];
    var _e = __read(React.useState(false), 2), showTenantMenu = _e[0], setShowTenantMenu = _e[1];
    var handleDocumentClick = function (e) {
        if (!menuRef.current) {
            return;
        }
        if (!menuRef.current.contains(e.target) || e.target.dataset.menuToggle) {
            closeMenu();
        }
    };
    var closeMenu = function () {
        setShowTenantMenu(false);
        setShowMainMenu(false);
        document.removeEventListener('click', handleDocumentClick, false);
    };
    var toggleMenu = function (open, menuName) {
        return function (e) {
            e.preventDefault();
            if (menuName === 'mainMenu') {
                if (open) {
                    document.addEventListener('click', handleDocumentClick, false);
                }
                else {
                    document.removeEventListener('click', handleDocumentClick, false);
                }
                setShowMainMenu(open);
            }
            if (menuName === 'tenantMenu') {
                setShowTenantMenu(open);
            }
        };
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { onClick: function (e) {
                toggleMenu(!showMainMenu, 'mainMenu')(e);
            }, className: "btn btn-dro btn-username" },
            tenant && (React.createElement("div", { className: "username-text truncate", title: tenant.name }, tenant.name)),
            React.createElement("div", { className: "user-picture" },
                React.createElement("img", { src: picture, alt: "avatar", className: "avatar" }),
                React.createElement("i", { className: "icon-budicon-460" }))),
        React.createElement("div", { ref: menuRef, className: "dropdown tenant-dropdown ".concat(showMainMenu ? 'open' : '') },
            React.createElement("div", { className: "tenant-menu-container dropdown-menu ".concat(showTenantMenu ? 'switch-tenant' : '') },
                React.createElement("div", { className: "tenant-menu-slide" },
                    React.createElement("ul", { className: "tenant-menu" },
                        tenant && (React.createElement("li", { className: styles.tenantMenuHeader },
                            React.createElement("a", null,
                                React.createElement("h5", { className: styles.tenantName }, tenant.name),
                                React.createElement(TenantDescription, { regionCode: tenant.regionCode, regionName: tenant.regionName, subscription: tenant.subscription })))),
                        React.createElement("li", { className: "tenant-menu-item" },
                            React.createElement("a", { href: manageUrl, tabIndex: 0, role: "menuitem", target: "_blank" },
                                React.createElement("i", { className: "tenant-menu-icon icon-budicon-394" }),
                                React.createElement("span", { className: "icon-text" }, "Open Dashboard"))),
                        tenants.length > 0 && (React.createElement("li", { className: "tenant-menu-item" },
                            React.createElement("a", { onClick: function (e) {
                                    toggleMenu(!showTenantMenu, 'tenantMenu')(e);
                                }, tabIndex: 0, role: "menuitem", className: "tenant-switch-btn" },
                                React.createElement("i", { className: "tenant-menu-icon icon-budicon-436" }),
                                React.createElement("span", { className: "icon-text" }, "Switch tenant")))),
                        React.createElement("li", { className: "divider" }),
                        React.createElement("li", null,
                            React.createElement("div", { className: "user-header clearfix" },
                                React.createElement("div", { className: "profile-picture" },
                                    React.createElement("img", { alt: "profile", className: "picture", src: picture })),
                                React.createElement("div", { className: "profile-info" },
                                    React.createElement("h5", { className: "user-name" }, userName),
                                    React.createElement("a", { href: "".concat(manageUrl, "/profile"), target: "_blank" }, "View profile")))),
                        React.createElement("li", { className: "divider" }),
                        React.createElement("li", { className: "tenant-menu-item" },
                            React.createElement("a", { href: "/docs/auth/logout", "data-menu-toggle": true },
                                React.createElement("i", { className: "tenant-menu-icon icon-budicon-519" }),
                                React.createElement("span", { className: "icon-text" }, "Logout")))),
                    React.createElement("ul", { id: "tenant-selector-pane", className: "tenant-menu" },
                        React.createElement("a", { onClick: function (e) {
                                toggleMenu(!showTenantMenu, 'tenantMenu')(e);
                            }, role: "menuitem", tabIndex: 0, className: "tenant-selector-back-arrow tenant-switch-btn" },
                            React.createElement("i", { className: "icon-budicon-521" })),
                        React.createElement("li", { className: "tenant-selector-header" },
                            React.createElement("h5", null, "Switch tenant")),
                        React.createElement("ul", { className: "tenant-selector-inner tenant-menu" }, tenants.map(function (t) { return (React.createElement(TenantMenuItem, __assign({ "data-menu-toggle": true, key: t.url }, t, { selected: tenant.name === t.name && tenant.region === t.region }))); }))))))));
};
