import { Box, styled, Text } from '@auth0/quantum-product';
import * as React from 'react';
import { useFluxible } from '../hooks/useFluxible';
import { Identicon } from '../Identicon';
import { NavLink } from '../NavLink';
var SectionTitle = styled(Text)(function (_a) {
    var theme = _a.theme;
    return ({
        fontWeight: theme.typography.fontWeightMedium,
    });
});
export var SectionHeader = function (_a) {
    var title = _a.title, href = _a.href, _b = _a.icon, icon = _b === void 0 ? 'IdenticonGettingStarted' : _b;
    var context = useFluxible();
    var handleClick = function (url) {
        context.trackEvent('select:user_resource', { trackData: url });
    };
    return (React.createElement(NavLink, { href: href, onClick: function () { return handleClick(href); } },
        React.createElement(Box, { sx: { display: 'flex', alignItems: 'center', gap: 1 } },
            React.createElement(Identicon, { name: icon, fontSize: 18, color: "primary" }),
            React.createElement(SectionTitle, { color: "text.primary", variant: "h6" }, title))));
};
