var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Spinner } from '@auth0/quantum-product';
import * as React from 'react';
import QuickstartStore from '../../stores/QuickstartStore';
import { TutorialPageNew } from './TutorialPage.new';
import TutorialPageOld from './TutorialPage.old';
export var TutorialPage = function (props) {
    var article = props.context.getStore(QuickstartStore).getCurrentArticle();
    if (!article) {
        return React.createElement(Spinner, null);
    }
    return article.interactive ? React.createElement(TutorialPageNew, __assign({}, props)) : React.createElement(TutorialPageOld, __assign({}, props));
};
