export var layout = {
    sidebarWidth: 304,
    articleContentWidth: 776,
    landingContentWidth: 1024,
    header1440: 1440,
    headerFullWidth: '100%',
    homePageContentWidth: 1216,
    headerHeight: 64,
    mobileMarginX: 3,
};
