import React from 'react';
import PropTypes from 'prop-types';
import MediumImageZoom from 'react-medium-image-zoom';

const ImageZoom = ({ src, alt }) => <MediumImageZoom image={{ src, alt }} />;

ImageZoom.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

ImageZoom.defaultProps = {
  alt: '',
};

export default ImageZoom;
