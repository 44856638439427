import { MetricsEventType } from '@a0/docs-components/metrics';
import { Avatar, AvatarBlock, Box, ChevronDownIcon, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelHeader, ExpansionPanelSummary, styled, } from '@auth0/quantum-product';
import * as React from 'react';
import { useFluxible } from '../hooks/useFluxible';
var StyledPanel = styled(ExpansionPanel)(function (_a) {
    var theme = _a.theme;
    return ({
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
    });
});
var StyledExpansionDetails = styled(ExpansionPanelDetails)(function (_a) {
    var theme = _a.theme, numbered = _a.numbered;
    return ({
        marginLeft: numbered ? theme.spacing(6) : 0,
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
    });
});
var NumberedHeader = function (_a) {
    var index = _a.index, header = _a.header, description = _a.description;
    return (React.createElement(AvatarBlock, { avatar: React.createElement(Avatar, { alt: "stepper", initials: "".concat(index + 1), type: "user", color: "primary", size: "small", className: "accordion-numbered-icon" }), title: header, titleTypographyProps: { variant: 'subtitle1', component: 'span' }, description: description }));
};
export var AccordionPanel = function (_a) {
    var type = _a.type, header = _a.header, subheader = _a.subheader, content = _a.content, index = _a.index, expanded = _a.expanded, onChange = _a.onChange;
    var context = useFluxible();
    var openedRef = React.useRef(0);
    React.useEffect(function () {
        if (expanded) {
            openedRef.current = new Date().getTime();
        }
        else if (openedRef.current !== 0) {
            var timeOpenInSeconds = Math.round((new Date().getTime() - openedRef.current) / 1000);
            context.trackEvent(MetricsEventType.AccordionPanelTimeOpen, {
                title: header,
                timeOpenInSeconds: timeOpenInSeconds,
            });
        }
    }, [expanded]);
    var handleOnChange = function () {
        onChange();
        if (!expanded) {
            context.trackEvent(MetricsEventType.AccordionPanelExpand, { title: header });
        }
        else {
            context.trackEvent(MetricsEventType.AccordionPanelCollapse, { title: header });
        }
    };
    return (React.createElement(StyledPanel, { className: "accordion-panel", key: index, expanded: expanded, onChange: handleOnChange },
        React.createElement(ExpansionPanelSummary, { id: "accordion-summary", expandIcon: React.createElement(ChevronDownIcon, null) }, type === 'numbered' ? (React.createElement(NumberedHeader, { index: index, header: header, description: subheader })) : (React.createElement(ExpansionPanelHeader, { title: header, titleTypographyProps: { variant: 'subtitle1', component: 'span' }, subheader: subheader }))),
        React.createElement(StyledExpansionDetails, { numbered: type === 'numbered', className: type === 'numbered' ? 'accordion-numbered-details' : 'accordion-details' },
            React.createElement(Box, { dangerouslySetInnerHTML: {
                    __html: content,
                } }))));
};
