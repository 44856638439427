var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Divider, RowLayout, styled, Text } from '@auth0/quantum-product';
import * as React from 'react';
import NavigationStore from '../../../stores/NavigationStore';
import { useFluxible } from '../../hooks/useFluxible';
import { ContentContainer } from '../../layouts';
import { NavCards } from '../../NavCard';
import TutorialNavigator from '../../quickstarts/TutorialNavigator';
import { TutorialBackground } from '../../quickstarts/TutorialNavigator/TutorialBackground';
import { SearchBox } from '../../SearchBox/SearchBox';
import { SectionPipeline } from '../../SectionPipeline';
var TopFold = styled(RowLayout)(function (_a) {
    var theme = _a.theme;
    return ({
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.background.homePage : 'inherit',
        borderBottom: theme.palette.mode === 'dark' ? "1px solid ".concat(theme.tokens.color_border_default) : 'none',
        paddingTop: theme.spacing(8),
        borderBottomLeftRadius: theme.spacing(5),
        borderBottomRightRadius: theme.spacing(5),
        position: 'relative',
        marginBottom: theme.spacingPX(8) + 120,
    });
});
var Background = styled('div')(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            position: 'absolute',
            right: 0,
            bottom: 0,
            display: 'flex',
            overflow: 'hidden',
            borderBottomRightRadius: theme.spacing(5)
        },
        _b[theme.breakpoints.down(theme.layout.homePageDesktopBreakpoint)] = {
            left: 0,
            borderBottomLeftRadius: theme.spacing(5),
            '& svg': {
                width: '100%',
            },
        },
        _b);
});
export var HomePage = function (props) {
    var context = useFluxible();
    var navigationStore = context.getStore(NavigationStore);
    var navigationItems = navigationStore.getNavigationItems('articles');
    return (React.createElement(React.Fragment, null,
        React.createElement(TopFold, { id: "maincontent", role: "main", gutter: 8 },
            React.createElement(SearchBox, { placement: "homepage", placeholder: "Search the docs" }),
            React.createElement(TutorialNavigator, __assign({ largeHeader: true, firstQuestion: 'Start Building', subHeader: 'Choose your application type to get started', onHomePage: true }, props)),
            React.createElement(Background, null,
                React.createElement(TutorialBackground, null))),
        React.createElement(ContentContainer, { gutter: 5 },
            React.createElement(Text, { variant: "h1", component: "h1" }, "Learn about Auth0"),
            React.createElement(SectionPipeline, { active: "All" }),
            React.createElement(Text, { variant: "h4", sx: { fontWeight: 'regular' }, color: "textSecondary" }, "Rely on the Auth0 identity platform to add sophisticated authentication and authorization to your applications. Centralize and manage users from multiple identity providers and give them branded, seamless signup and login experiences. Finely control access with a degree of customization that can accommodate even the most complex security requirements."),
            React.createElement(Divider, { orientation: "horizontal" }),
            React.createElement(NavCards, { items: navigationItems }))));
};
