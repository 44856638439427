import * as React from 'react';
import styles from './styles.module.styl';
var regionDecorator = function (code, name) {
    // Region PUS3 should appear as just another US environment on the frontend
    if (code === 'pus3') {
        return { code: 'us', name: 'US East' };
    }
    if (code === 'stage') {
        return { code: 'us', name: 'Stage' };
    }
    return { code: code, name: name };
};
export var TenantDescription = function (_a) {
    var regionCode = _a.regionCode, regionName = _a.regionName, subscription = _a.subscription;
    var region = regionDecorator(regionCode, regionName);
    return (React.createElement(React.Fragment, null,
        React.createElement("i", { className: styles.regionFlag, style: {
                background: "url(\"https://cdn.auth0.com/manhattan/versions/1.2247.0/assets/".concat(region.code, ".svg\") no-repeat"),
            } }),
        React.createElement("span", { className: styles.regionName }, region.name),
        subscription && React.createElement("span", { className: styles.subscriptionName }, " - ".concat(subscription.name))));
};
