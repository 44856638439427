var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { styled } from '@auth0/quantum-product';
import { withStyles } from '@mui/styles';
import * as React from 'react';
var rightMargin = 60;
var StyledWidgetContainer = styled('div')(function (_a) {
    var _b;
    var theme = _a.theme, feedbackSent = _a.feedbackSent, isVisible = _a.isVisible, isFooterVisible = _a.isFooterVisible;
    return (__assign(__assign(__assign((_b = { zIndex: 6, position: 'fixed', right: rightMargin, bottom: theme.spacing(3), padding: theme.spacing(4, 2, 2, 2), borderRadius: theme.spacing(1), border: theme.palette.mode === 'dark' ? "1px solid ".concat(theme.tokens.color_border_bold) : 'none', background: theme.palette.background.default, boxShadow: "".concat(theme.spacing(0, 0.5, 1), "  rgba(0, 0, 0, 0.1)") }, _b[theme.breakpoints.down('md')] = {
        display: 'none',
    }, _b), (isFooterVisible
        ? {
            animation: "slideOut 0.5s forwards",
            '-webkit-animation': "slideOut 0.5s forwards",
        }
        : {
            animation: "slideIn 0.5s forwards",
            '-webkit-animation': "slideIn 0.5s forwards",
        })), (!isVisible && {
        display: 'none',
    })), (feedbackSent && {
        padding: theme.spacing(2),
        width: 300,
    })));
});
var StyledWidgetContainerWrapper = function (_a) {
    var children = _a.children, feedbackSent = _a.feedbackSent, isVisible = _a.isVisible, isFooterVisible = _a.isFooterVisible;
    return (React.createElement(StyledWidgetContainer, { feedbackSent: feedbackSent, isVisible: isVisible, isFooterVisible: isFooterVisible, "data-search-index": "false" }, children));
};
// @TODO - remove withStyles workaround with MUI v5 https://github.com/mui-org/material-ui/issues/24851
export var WidgetContainer = withStyles({
    '@global @keyframes slideIn': {
        '0%': {
            transform: "translateX(calc(100% + ".concat(rightMargin, "px))"),
        },
        '100%': {
            transform: 'translateX(0%)',
        },
    },
    '@global @keyframes slideOut': {
        '0%': {
            transform: 'translateX(0%)',
        },
        '100%': {
            transform: "translateX(calc(100% + ".concat(rightMargin, "px))"),
        },
    },
})(StyledWidgetContainerWrapper);
