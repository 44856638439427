var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { MetricsEventType } from '@a0/docs-components/metrics';
import { Box, Tab, TabList, TabPanel, Tabs } from '@auth0/quantum-product';
import * as React from 'react';
import { useFluxible } from '../hooks/useFluxible';
import { MetricsId } from '../WithMetricsId';
export var TabsContainer = function (_a) {
    var tabs = _a.tabs;
    var _b = __read(React.useState(tabs[0].label), 2), selectedTab = _b[0], setSelectedTab = _b[1];
    var context = useFluxible();
    var openedRef = React.useRef(0);
    React.useEffect(function () {
        openedRef.current = new Date().getTime();
    }, [selectedTab]);
    var handleOnChange = function (tab) {
        var timeOpenInSeconds = Math.round((new Date().getTime() - openedRef.current) / 1000);
        context.trackEvent(MetricsEventType.TabPanelClick, { title: tab });
        context.trackEvent(MetricsEventType.TabPanelTimeOpen, {
            title: selectedTab,
            timeOpenInSeconds: timeOpenInSeconds,
        });
        setSelectedTab(tab);
    };
    return (React.createElement(Tabs, { baseId: "TabsContainer", "data-metrics-id": MetricsId.TabPanel, onChange: function (tab) { return handleOnChange(tab); }, value: selectedTab },
        React.createElement(TabList, null, tabs.map(function (tab) { return (React.createElement(Tab, { label: tab.label, value: tab.label })); })),
        React.createElement(Box, { paddingY: 2 }, tabs.map(function (tab) { return (React.createElement(TabPanel, { value: tab.label },
            React.createElement(Box, { dangerouslySetInnerHTML: {
                    __html: tab.content,
                } }))); }))));
};
