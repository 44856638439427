import cx from 'classnames';
import FileSaver from 'file-saver';
import { connectToStores } from 'fluxible-addons-react';
import { partial } from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { StickyContainer } from 'react-sticky';

import { login } from '../../../action/navigateAuthentication';
import {
  sendPackageDownloadEvent,
  sendTutorialViewedEvent,
  sendViewOnGithubEvent,
} from '../../../browser/quickstartMetrics';
import setInnerHtmlHooks from '../../../browser/setInnerHtmlHooks';
import ApplicationStore from '../../../stores/ApplicationStore';
import DocumentStore from '../../../stores/DocumentStore';
import QuickstartStore from '../../../stores/QuickstartStore';
import UserStore from '../../../stores/UserStore';
import { ArticleFooter } from '../../ArticleFooter';
import { BetaBadge } from '../../BetaBadge/BetaBadge.old';
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs.old';
import DownloadBox from '../../quickstarts/DownloadBox';
import DownloadSampleModal from '../../quickstarts/DownloadSampleModal';
import Introduction from '../../quickstarts/Introduction';
import PlatformInformation from '../../quickstarts/PlatformInformation';
import Requirements from '../../quickstarts/Requirements';
import Sidebar from '../../quickstarts/Sidebar';
import StickyHeader from '../../quickstarts/StickyHeader';
import Tutorial from '../../quickstarts/Tutorial';
import TutorialVersionSelector from '../../quickstarts/TutorialVersionSelector';
import { Spinner } from '../../Spinner';
import TryBanner from '../../TryBanner';
import SampleRequiredUserResources from './SampleRequiredUserResources';
import styles from './styles.module.styl';

// eslint-disable-next-line no-confusing-arrow
const getTitle = (platform, article) =>
  platform.articles.length === 1 ? platform.title : `${platform.title}: ${article.title}`;

class TutorialPage extends React.Component {
  static propTypes = {
    quickstarts: PropTypes.object,
    quickstart: PropTypes.object,
    platform: PropTypes.object,
    version: PropTypes.object,
    article: PropTypes.object,
    doc: PropTypes.object,
    userAccount: PropTypes.object,
    selectedClient: PropTypes.object,
    selectedApiIdentifier: PropTypes.object,
    userHasApis: PropTypes.bool,
    userClientsByType: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    currentQuickstartType: PropTypes.string,
    currentRoute: PropTypes.object.isRequired,
    isFramedMode: PropTypes.bool.isRequired,
    dashboardClientsUrl: PropTypes.string.isRequired,
    dashboardApisUrl: PropTypes.string.isRequired,
    domainUrlDocs: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
    userClients: PropTypes.array.isRequired,
    sidebarItems: PropTypes.array.isRequired,
    sidebarBreadcrumbs: PropTypes.array.isRequired,
    metadataNextSteps: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    sidebarArticlesNextSteps: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  };

  static contextTypes = {
    getStore: PropTypes.func,
    executeAction: PropTypes.func,
    trackEvent: PropTypes.func.isRequired,
  };

  state = {
    showDownloadModal: false,
    downloadInProgress: false,
  };

  componentDidMount() {
    this.handleMetrics(this.props.article);
    if (this.props.currentRoute.query.download) {
      this.setDownloadModalVisibility(true);
    }
  }

  componentDidUpdate(prevProps) {
    this.handleMetrics(this.props.article, prevProps.article);
  }

  setDownloadModalVisibility = (visible) => {
    this.setState({ showDownloadModal: visible });
  };
  showDownloadModal = partial(this.setDownloadModalVisibility, true);
  hideDownloadModal = partial(this.setDownloadModalVisibility, false);

  handleContentRender = (elem) => {
    setInnerHtmlHooks(elem, this.props.isFramedMode);
  };

  handleLogin = () => {
    this.context.executeAction(login(`${window.location.pathname}?download=true`));
  };

  downloadSample = () => {
    const { domainUrlDocs, article, platform, isFramedMode, userAccount, selectedClient, selectedApiIdentifier } =
      this.props;

    this.setState({ downloadInProgress: true });

    const userClient =
      !isFramedMode && selectedClient
        ? {
            client_id: selectedClient.client_id,
            client_secret: selectedClient.client_secret,
            callback_url: selectedClient.callbacks ? selectedClient.callbacks[0] : userAccount.callback,
          }
        : {
            client_id: userAccount.clientId,
            client_secret: userAccount.clientSecret,
            callback_url: userAccount.callback,
          };

    let csrfToken;
    const csrfHidden = document.getElementById('__csrf');
    if (csrfHidden) {
      csrfToken = csrfHidden.value;
    }

    fetch(`${domainUrlDocs}/package/v2`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'csrf-token': csrfToken,
      }),
      body: JSON.stringify({
        ...article.githubData,
        ...userClient,
        domain: userAccount.namespace,
        tenant: userAccount.tenant,
        api_id: selectedApiIdentifier,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return false;
        }

        return response.blob();
      })
      .then((blob) => {
        if (blob) {
          FileSaver.saveAs(blob, `${platform.name}-${article.name}.zip`);
          sendPackageDownloadEvent(this.props);
        }
      })
      .finally(() => {
        this.setState({ downloadInProgress: false });
      });
  };

  getSampleRequiredResources = () => {
    const {
      article,
      userClients,
      userHasApis,
      dashboardClientsUrl,
      dashboardApisUrl,
      currentQuickstartType,
      isFramedMode,
    } = this.props;
    let resources = null;

    if (!isFramedMode && article.showDownloadSample) {
      const requiredResources = article.sampleDownloadRequiredData;
      const showCreateClient = !userClients.length && requiredResources.includes('client');
      const showCreateAPI = !userHasApis && requiredResources.includes('api');

      if (showCreateClient || showCreateAPI) {
        resources = (
          <SampleRequiredUserResources
            showCreateClient={showCreateClient}
            showCreateAPI={showCreateAPI}
            dashboardClientsUrl={dashboardClientsUrl}
            dashboardApisUrl={dashboardApisUrl}
            currentQuickstartType={currentQuickstartType}
          />
        );
      }
    }

    return resources;
  };

  sendViewOnGithubEvent = () => {
    sendViewOnGithubEvent(this.props);
  };

  handleMetrics(article, prevArticle) {
    if (typeof document === 'undefined') return;
    if (article && (!prevArticle || prevArticle.url !== article.url)) {
      sendTutorialViewedEvent(this.props);
    }
  }

  render() {
    const {
      quickstarts,
      quickstart,
      platform,
      article,
      doc,
      version,
      isAuthenticated,
      isFramedMode,
      breadcrumbs,
      sidebarItems,
      sidebarBreadcrumbs,
      metadataNextSteps,
      sidebarArticlesNextSteps,
      userClientsByType,
    } = this.props;

    if (!platform || !article) {
      return <Spinner />;
    }

    const title = getTitle(platform, article);
    const showSidebar = platform.articles.length > 1 && !isFramedMode;

    const userMissingRequiredResources = this.getSampleRequiredResources();

    return (
      <div className={cx('docs-quickstart', { 'doc-with-sidebar': showSidebar })}>
        <div id="tutorial-template" className="docs-single animated fadeIn">
          <StickyContainer>
            <StickyHeader
              showSampleButtons={article.showDownloadSample}
              isAuthenticated={isAuthenticated}
              requiredResources={userMissingRequiredResources}
              title={title}
              showDownloadModal={this.showDownloadModal}
              githubSampleLink={article.githubSampleLink}
              trackViewGithubSample={this.sendViewOnGithubEvent}
              handleLogin={this.handleLogin}
              beta={platform.beta}
            />
            <div className="js-doc-template tutorial-page container">
              <div className={cx('row', styles.tutorialRow)}>
                {showSidebar && (
                  <div className={cx(styles.sidebarContainer, 'sidebar-container', 'col-md-3')}>
                    <Sidebar
                      breadcrumbs={sidebarBreadcrumbs}
                      url={this.props.currentRoute.url}
                      topLevelLinks={sidebarItems}
                      childLevelLinks={doc && doc.meta && doc.meta.titles.filter((t) => t.level === 2)}
                    />
                  </div>
                )}
                <div className={`col-md-${isFramedMode ? 12 : 9} ${!isFramedMode ? 'docs-content-container' : ''}`}>
                  <div className={styles.breadcrumbContainer}>
                    <Breadcrumbs crumbs={breadcrumbs} />
                  </div>
                  <section>
                    <article id="maincontent" role="main" className="docs-content">
                      <h1 className="tutorial-title">
                        {title} {platform.beta && <BetaBadge />}
                      </h1>

                      <PlatformInformation author={platform.author} githubData={article ? article.githubData : {}} />

                      <TutorialVersionSelector
                        quickstarts={quickstarts}
                        quickstart={quickstart}
                        platform={platform}
                        version={version}
                        article={article}
                      />
                      <Introduction
                        text={article.description}
                        showLoginText={!isAuthenticated}
                        handleLogin={this.handleLogin}
                      />
                      {article.showDownloadSample && (
                        <Fragment>
                          {!userMissingRequiredResources && (
                            <DownloadSampleModal
                              show={this.state.showDownloadModal}
                              handleHide={this.hideDownloadModal}
                              showClient={article.sampleDownloadRequiredData.includes('client')}
                              showApi={article.sampleDownloadRequiredData.includes('api')}
                              showSelector={!isFramedMode}
                              downloadSample={this.downloadSample}
                              githubSampleLink={article.githubSampleLink}
                              trackViewGithubSample={this.sendViewOnGithubEvent}
                              downloadInstructions={article.downloadInstructions || platform.downloadInstructions}
                              downloadInProgress={this.state.downloadInProgress}
                              userClientsByType={userClientsByType}
                              isFramedMode={isFramedMode}
                            />
                          )}
                          <DownloadBox
                            isAuthenticated={isAuthenticated}
                            requiredResources={userMissingRequiredResources}
                            showSteps={article.showSteps}
                            steps={doc && doc.meta && doc.meta.titles}
                            description={article.downloadBoxDescription}
                            showDownloadModal={this.showDownloadModal}
                            githubSampleLink={article.githubSampleLink}
                            trackViewGithubSample={this.sendViewOnGithubEvent}
                            handleLogin={this.handleLogin}
                            isFramedMode={isFramedMode}
                          />
                        </Fragment>
                      )}
                      {article.showDownloadSample && article.requirements && (
                        <Requirements list={article.requirements} />
                      )}
                      <Tutorial doc={doc} handleRender={this.handleContentRender} />
                      <ArticleFooter
                        articleUrl={article.url}
                        githubEditLink={article.githubEditLink}
                        platformTitle={platform.title}
                        metadataNextSteps={metadataNextSteps}
                        sidebarArticlesNextSteps={sidebarArticlesNextSteps}
                        isQuickstart
                      />
                    </article>
                  </section>
                  {!isAuthenticated && !isFramedMode && <TryBanner />}
                </div>
              </div>
            </div>
          </StickyContainer>
        </div>
      </div>
    );
  }
}

export default connectToStores(
  TutorialPage,
  [ApplicationStore, DocumentStore, QuickstartStore, UserStore],
  (context) => {
    const appStore = context.getStore(ApplicationStore);
    const quickstartStore = context.getStore(QuickstartStore);
    const userStore = context.getStore(UserStore);
    const currentQuickstartType = context.getStore(QuickstartStore).getQuickstartType();
    const article = quickstartStore.getCurrentArticle();
    const doc = article && context.getStore(DocumentStore).getDocument(article.url);
    const sidebarItems = quickstartStore.getSidebarItems();

    return {
      quickstarts: quickstartStore.getQuickstarts(),
      quickstart: quickstartStore.getCurrentQuickstart(),
      platform: quickstartStore.getCurrentPlatform(),
      version: quickstartStore.getCurrentVersion(),
      sidebarItems,
      article,
      doc,
      currentQuickstartType,
      userAccount: userStore.getUserAccount(),
      selectedClient: userStore.getSelectedClient(),
      selectedApiIdentifier: userStore.getSelectedApiIdentifier(),
      userClients: userStore.getQuickstartCompatibleClients(currentQuickstartType),
      userClientsByType: userStore.getQuickstartCompatibleClientsByType(currentQuickstartType),
      userHasApis: userStore.userHasApis(),
      isAuthenticated: userStore.isAuthenticated(),
      isFramedMode: appStore.isFramedMode(),
      dashboardClientsUrl: appStore.getDashboardClientsUrl(),
      dashboardApisUrl: appStore.getDashboardApisUrl(),
      domainUrlDocs: appStore.getDomainUrlDocs(),
      breadcrumbs: quickstartStore.getBreadcrumbs(),
      sidebarBreadcrumbs: quickstartStore.getSidebarBreadcrumbs(),
      metadataNextSteps: quickstartStore.getMetadataNextSteps(),
      sidebarArticlesNextSteps: quickstartStore.getSidebarArticlesNextSteps(),
    };
  }
);
