var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import BaseStore from 'fluxible/addons/BaseStore';
var ApplicationStore = /** @class */ (function (_super) {
    __extends(ApplicationStore, _super);
    function ApplicationStore(dispatcher) {
        var _this = _super.call(this, dispatcher) || this;
        _this.flags = {};
        _this.state = { isStickyHeaderVisible: false };
        _this.pageTitle = '';
        _this.pageDescription = '';
        _this.domainUrlApp = '';
        _this.dashboardUrl = '';
        _this.domainUrlDocs = '';
        _this.domainUrlSignup = '';
        _this.csrfToken = '';
        return _this;
    }
    ApplicationStore.prototype.isMobile = function () {
        return this.flags.mobile;
    };
    ApplicationStore.prototype.isFramedMode = function () {
        return !!this.flags.framed;
    };
    ApplicationStore.prototype.getPreferredColorScheme = function () {
        return this.flags.preferredColorScheme;
    };
    ApplicationStore.prototype.getTheme = function () {
        return this.flags.theme || 'light';
    };
    ApplicationStore.prototype.isSingleQuickstartMode = function () {
        return !!this.flags.singleQuickstart;
    };
    ApplicationStore.prototype.isStickyHeaderVisible = function () {
        return !!this.state.isStickyHeaderVisible;
    };
    ApplicationStore.prototype.getPageTitle = function () {
        return this.pageTitle;
    };
    ApplicationStore.prototype.getPageDescription = function () {
        return this.pageDescription;
    };
    ApplicationStore.prototype.getDomainUrlApp = function () {
        return this.domainUrlApp;
    };
    ApplicationStore.prototype.getDashboardUrl = function () {
        return this.dashboardUrl;
    };
    ApplicationStore.prototype.getDashboardClientsUrl = function () {
        return "".concat(this.getDashboardUrl(), "/applications");
    };
    ApplicationStore.prototype.getDashboardApisUrl = function () {
        return "".concat(this.getDashboardUrl(), "/apis");
    };
    ApplicationStore.prototype.getDomainUrlSignup = function () {
        return this.domainUrlSignup;
    };
    ApplicationStore.prototype.getDomainUrlDocs = function () {
        return this.domainUrlDocs;
    };
    ApplicationStore.prototype.getCSRFToken = function () {
        return this.csrfToken;
    };
    ApplicationStore.prototype.handleModeFlagsLoaded = function (payload) {
        this.flags = payload.flags;
        this.emitChange();
    };
    ApplicationStore.prototype.handlePageMetadata = function (payload) {
        this.pageTitle = payload.pageTitle;
        this.pageDescription = payload.pageDescription;
        this.emitChange();
    };
    ApplicationStore.prototype.handleEnvVariablesLoaded = function (payload) {
        Object.assign(this, payload);
        this.emitChange();
    };
    ApplicationStore.prototype.handleStickyHeaderVisibilityUpdate = function (payload) {
        this.state.isStickyHeaderVisible = payload.isStickyHeaderVisible;
        this.emitChange();
    };
    ApplicationStore.prototype.dehydrate = function () {
        return {
            flags: this.flags,
            state: this.state,
            pageTitle: this.pageTitle,
            pageDescription: this.pageDescription,
            domainUrlApp: this.domainUrlApp,
            dashboardUrl: this.dashboardUrl,
            domainUrlDocs: this.domainUrlDocs,
            domainUrlSignup: this.domainUrlSignup,
            csrfToken: this.csrfToken,
        };
    };
    ApplicationStore.prototype.rehydrate = function (state) {
        this.flags = state.flags;
        this.state = state.state;
        this.pageTitle = state.pageTitle;
        this.pageDescription = state.pageDescription;
        this.domainUrlApp = state.domainUrlApp;
        this.dashboardUrl = state.dashboardUrl;
        this.domainUrlDocs = state.domainUrlDocs;
        this.domainUrlSignup = state.domainUrlSignup;
        this.csrfToken = state.csrfToken;
    };
    return ApplicationStore;
}(BaseStore));
ApplicationStore.storeName = 'ApplicationStore';
ApplicationStore.handlers = {
    MODE_FLAGS_LOADED: 'handleModeFlagsLoaded',
    UPDATE_PAGE_METADATA: 'handlePageMetadata',
    LOAD_ENV_VARIABLES: 'handleEnvVariablesLoaded',
    UPDATE_STICKY_HEADER_VISIBILITY: 'handleStickyHeaderVisibilityUpdate',
};
export default ApplicationStore;
