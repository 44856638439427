import DocumentStore from '../stores/DocumentStore';
import LoadState from '../stores/LoadState';
import normalizeUrl from '../util/normalizeUrl';
export default function loadDocument(context, payload) {
    var logger = context.getService('LoggingService');
    var url = normalizeUrl(payload.url);
    var contentfulId = (payload.params || {}).contentfulId;
    context.dispatch('DOCUMENT_SELECTED', { url: url });
    var success = function (doc) {
        if (payload.name === 'article' || payload.name === 'contentful') {
            context.dispatch('UPDATE_PAGE_METADATA', {
                pageTitle: doc.meta.title,
                pageDescription: doc.meta.description,
            });
            context.dispatch('ARTICLE_LOAD_SUCCESS', { url: url, doc: doc });
        }
        context.dispatch('DOCUMENT_LOAD_SUCCESS', { url: url, doc: doc });
        logger.debug('Document loaded successfully.', { url: url });
        return Promise.resolve(doc);
    };
    var failure = function (err) {
        context.dispatch('DOCUMENT_LOAD_FAILURE', { url: url, err: err });
        logger.warn('Error loading document.', { url: url, err: err });
        return Promise.resolve();
    };
    // First, check to see if the doc has already been loaded.
    if (!contentfulId || typeof window !== 'undefined') {
        // contentfulId should reload from the server
        var doc = context.getStore(DocumentStore).getDocument(url);
        if (doc) {
            if (doc.state === LoadState.LOADED) {
                // If it has been loaded, just return it.
                return success(doc);
            }
            else if (doc.state === LoadState.LOADING) {
                // If it's already being loaded, don't load it again.
                return Promise.resolve();
            }
        }
    }
    // If the document hasn't been loaded (or a previous load resulted in
    // an error), try to load it using the DocumentService.
    context.dispatch('DOCUMENT_LOADING', { url: url });
    if (contentfulId) {
        return context.getService('DocumentService').loadContentfulPreview(contentfulId).then(success).catch(failure);
    }
    return context.getService('DocumentService').load(url).then(success).catch(failure);
}
