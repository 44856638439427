export var tabsContainerStyles = function (theme) {
    return {
        '& .MuiTabs-flexContainer': {
            display: 'flex',
        },
        '& .MuiTab-root': {
            marginLeft: 0,
            marginRight: theme.spacing(2),
            fontFamily: "Inter, fakt-web, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
            padding: '6px 12px',
            overflow: 'hidden',
            position: 'relative',
            fontSize: '0.875rem',
            maxWidth: '264px',
            minWidth: 'unset',
            boxSizing: 'border-box',
            minHeight: '48px',
            textAlign: 'center',
            flexShrink: 0,
            fontWeight: 400,
            lineHeight: 1.71429,
            whiteSpace: 'normal',
            paddingLeft: 0,
            paddingRight: 0,
            letterSpacing: '0.01071em',
            textTransform: 'none',
            '& .MuiTab-root:first-child': {
                marginLeft: 0,
            },
        },
        '& .MuiTabs-scroller': {
            flex: '1 1 auto',
            display: 'inline-block',
            position: 'relative',
            whiteSpace: 'nowrap',
            borderBottom: "1px solid ".concat(theme.palette.divider),
            width: '100%',
        },
        '& .MuiTab-textColorPrimary.Mui-selected': {
            borderBottom: "1px solid ".concat(theme.palette.primary.dark),
        },
        '& .MuiTab-wrapper': {
            width: '100%',
            display: 'inline-flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        '& .MuiTab-labelIcon': {
            minHeight: theme.spacing(9),
            paddingTop: theme.spacing(1),
        },
        '& .MuiTab-labelIcon .MuiTab-wrapper > *:first-child': {
            marginBottom: theme.spacing(1),
        },
        '& .MuiTab-textColorInherit': {
            color: 'inherit',
            opacity: 0.7,
        },
        '& .MuiTab-textColorInherit.Mui-selected': {
            opacity: 1,
        },
        '& .MuiTab-textColorInherit.Mui-disabled': {
            opacity: 0.5,
        },
        '& .MuiTab-textColorPrimary': {
            color: '#65676e',
            fontWeight: 400,
        },
        '& .MuiTab-textColorPrimary.Mui-disabled': {
            color: '#65676e',
        },
        '& .MuiTab-fullWidth': {
            flexGrow: 1,
            maxWidth: 'none',
            flexBasis: 0,
            flexShrink: 1,
        },
        '& .MuiTab-wrapped': {
            fontSize: '0.75rem',
            lineHeight: 1.5,
        },
        '& div[role=tabpanel] ol': {
            listStyle: 'inherit',
            paddingLeft: theme.spacing(3),
        },
    };
};
