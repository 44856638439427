var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import cx from 'classnames';
import { partial } from 'lodash';
import * as React from 'react';
import { useFluxible } from '../../hooks/useFluxible';
import { BooleanFeedbackButton } from './BooleanFeedbackButton.old';
import { EditLink } from './EditLink.old';
import styles from './styles.module.styl';
var FeedbackFooterMode = {
    READY: 'READY',
    INPUT: 'INPUT',
    THANKS: 'THANKS',
};
var FeedbackButtonSelected = {
    NONE: 'NONE',
    YES: 'YES',
    NO: 'NO',
};
var initialState = {
    mode: FeedbackFooterMode.READY,
    selected: FeedbackButtonSelected.NONE,
    comment: '',
};
export var FeedbackSection = function (_a) {
    var articleUrl = _a.articleUrl, contentfulId = _a.contentfulId, githubEditLink = _a.githubEditLink, _b = _a.isQuickstart, isQuickstart = _b === void 0 ? false : _b;
    var context = useFluxible();
    var _c = __read(React.useState(initialState), 2), state = _c[0], setState = _c[1];
    React.useEffect(function () {
        setState(__assign({}, initialState));
    }, [articleUrl]);
    var updateState = function (updates) {
        setState(__assign(__assign({}, state), updates));
    };
    var trackData = function (eventName, data) {
        context.trackEvent(eventName, __assign({ path: window.location.pathname, url: window.location.toString(), title: document.title.split('-').length > 0 ? document.title[0].trim() : document.title, referrer: document.referrer }, data));
    };
    var handleCommentChange = function (e) {
        updateState({ comment: e.target.value });
    };
    var handleBooleanFeedback = function (isPositive) {
        if (state.mode === FeedbackFooterMode.READY) {
            trackData('feedback:submit', { value: isPositive });
            updateState({
                mode: FeedbackFooterMode.INPUT,
                selected: isPositive ? FeedbackButtonSelected.YES : FeedbackButtonSelected.NO,
            });
        }
    };
    var handlePositiveFeedback = partial(handleBooleanFeedback, true);
    var handleNegativeFeedback = partial(handleBooleanFeedback, false);
    var sendCommentFeedback = function (positive, comment) {
        fetch('/docs/submit-feedback', {
            method: 'POST',
            headers: new Headers({
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
                page_title: document.title,
                page_url: window.location.href,
                positive: positive,
                comment: comment,
            }),
        });
        trackData('feedback:submit-comment', {
            value: positive,
            trackData: comment,
        });
    };
    var handleCommentFeedback = function (event) {
        event.preventDefault();
        sendCommentFeedback(state.selected === FeedbackButtonSelected.YES, state.comment);
        updateState({ mode: FeedbackFooterMode.THANKS });
    };
    var thanksTitle = 'Thanks you for your feedback!';
    var readyTitle = isQuickstart ? 'Did it work?' : 'Was this article helpful?';
    var title = state.mode === FeedbackFooterMode.THANKS ? thanksTitle : readyTitle;
    return (React.createElement("div", null,
        React.createElement("div", { className: styles.topContent },
            React.createElement("div", null,
                React.createElement("h5", { className: styles.title }, title),
                state.mode !== FeedbackFooterMode.THANKS && (React.createElement("div", { className: styles.buttons },
                    React.createElement(BooleanFeedbackButton, { isPositive: true, isActive: state.selected === FeedbackButtonSelected.YES, isDisabled: state.selected === FeedbackButtonSelected.NO, handleClick: handlePositiveFeedback, iconCode: "470" }),
                    React.createElement(BooleanFeedbackButton, { isActive: state.selected === FeedbackButtonSelected.NO, isDisabled: state.selected === FeedbackButtonSelected.YES, handleClick: handleNegativeFeedback, iconCode: "471" })))),
            React.createElement(EditLink, { contentfulId: contentfulId, githubEditLink: githubEditLink, trackData: trackData })),
        state.mode === FeedbackFooterMode.INPUT && (React.createElement("div", { className: styles.feedbackForm },
            React.createElement("form", { className: "clearfix", onSubmit: handleCommentFeedback },
                React.createElement("textarea", { onChange: handleCommentChange, className: cx('form-control', styles.textArea), rows: 3, placeholder: state.selected === FeedbackButtonSelected.YES
                        ? 'Glad to hear that! Any way we can improve?'
                        : 'Sorry to hear that. How can we help you?' }),
                React.createElement("button", { type: "submit", className: "btn btn-primary", disabled: !state.comment }, "Send"),
                React.createElement("div", { className: styles.communitySection },
                    "Can't find what you are looking for?  Check out ",
                    React.createElement("a", { href: "https://community.auth0.com/", onClick: function () { return trackData('click:view-community'); }, target: "_blank", rel: "noreferrer noopener" }, "Auth0 Community")))))));
};
