// @ts-ignore
import hljs from 'highlight.js';
import React from 'react';
var CodeHighlight = function (_a) {
    var children = _a.children;
    var codeEl = React.useRef(null);
    var highlightCode = function (el) {
        var nodes = el.querySelectorAll('pre code');
        for (var i = 0; i < nodes.length; i += 1) {
            hljs.highlightBlock(nodes[i]);
        }
    };
    React.useEffect(function () {
        if (codeEl.current) {
            highlightCode(codeEl.current);
        }
    }, [codeEl, children]);
    return React.createElement("div", { ref: codeEl }, children);
};
export default CodeHighlight;
