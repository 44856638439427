import { RowLayout, styled } from '@auth0/quantum-product';
export var FeedbackModal = styled(RowLayout)(function (_a) {
    var theme = _a.theme;
    return ({
        position: 'absolute',
        top: "calc(100% + ".concat(theme.spacing(0.5), ")"),
        width: 336,
        backgroundColor: theme.palette.background.default,
        boxShadow: "0 0 ".concat(theme.spacing(0.5), " 0 rgba(0,0,0,0.1)"),
        padding: theme.spacing(3),
        zIndex: 1,
    });
});
