var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Divider, RowLayout, styled, Text } from '@auth0/quantum-product';
import * as React from 'react';
import { ContentStyler } from '../ContentRenderer';
import { NavCards } from '../NavCard';
import { QuickstartListWithContext } from '../quickstarts/QuickstartList';
import { SectionPipeline } from '../SectionPipeline';
var LandingTitle = styled(Text)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            fontSize: theme.typography.pxToRem(80),
            lineHeight: theme.typography.pxToRem(88),
            letterSpacing: -2.2
        },
        _b[theme.breakpoints.down(theme.layout.landingBreakpoint)] = {
            fontSize: theme.typography.pxToRem(56),
            lineHeight: theme.typography.pxToRem(64),
            letterSpacing: -1.3,
        },
        _b[theme.breakpoints.down(theme.layout.mobileBreakpoint)] = {
            fontSize: theme.typography.pxToRem(32),
            lineHeight: theme.typography.pxToRem(36),
            letterSpacing: -0.3,
        },
        _b);
});
var StyledContent = styled(ContentStyler)(function (_a) {
    var theme = _a.theme;
    return ({
        '& li > p': {
            margin: 0,
        },
        '& p': __assign(__assign({}, theme.typography.h4), { fontWeight: theme.typography.fontWeightRegular, color: theme.palette.text.primary }),
    });
});
var LandingSubsection = function (_a) {
    var title = _a.title, description = _a.description, quickstarts = _a.quickstarts, children = _a.children;
    return (React.createElement(RowLayout, { gutter: 4 },
        React.createElement(Text, { variant: "h2" }, title),
        description && (React.createElement(Text, { variant: "h5", sx: { fontWeight: 'regular' }, color: "textSecondary" }, description)),
        quickstarts && React.createElement(QuickstartListWithContext, { variant: "landing" }),
        React.createElement(NavCards, { items: children === null || children === void 0 ? void 0 : children.filter(function (item) { return item.showCards; }) })));
};
var LandingSubsections = function (_a) {
    var items = _a.items;
    return (React.createElement(React.Fragment, null, items === null || items === void 0 ? void 0 : items.filter(function (item) { return item.showCards; }).map(function (_a) {
        var title = _a.title, description = _a.description, quickstarts = _a.quickstarts, children = _a.children;
        return (React.createElement(React.Fragment, null,
            React.createElement(Divider, { orientation: "horizontal" }),
            React.createElement(LandingSubsection, { title: title, description: description, quickstarts: quickstarts, children: children })));
    })));
};
export var LandingPage = function (_a) {
    var meta = _a.meta, navigationItems = _a.navigationItems;
    var section = navigationItems.find(function (item) { return item.url === meta.url; });
    return (React.createElement(RowLayout, { gutter: 5 },
        React.createElement(LandingTitle, { variant: "h1" }, meta.title),
        (meta === null || meta === void 0 ? void 0 : meta.sectionPipeline) && React.createElement(SectionPipeline, __assign({ size: "small", align: "left" }, meta.sectionPipeline.props)),
        (meta === null || meta === void 0 ? void 0 : meta.content) && React.createElement(StyledContent, { dangerouslySetInnerHTML: { __html: meta.content } }),
        section ? React.createElement(LandingSubsections, { items: section.children }) : React.createElement(NavCards, { items: navigationItems })));
};
